import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_GetLocalization from "@/server/responses/ApiResponse_GetLocalization";

export default class ApiProxy_Localization extends ApiProxy {

    /** Retrieves the default region and language settings for the user. */
    async getDefaults() {

        const response = await this.get("/init");

        return new ApiResponse_GetLocalization(response)
    }
}
