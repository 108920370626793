import axios from 'axios';

/** Wraps the axios api client, handling header configuration. */
export default class AxiosApiClient {

    _instance;

    constructor() {
        axios.defaults.headers.post["Content-Type"] = "application/json";

        this._instance = axios.create({
            baseURL: window.location.origin + "/api"
        });
    }

    async get(...params) {
        return this._instance.get(...params);
    }

    async delete(...params) {
        return this._instance.delete(...params);
    }

    async post(...params) {
        return this._instance.post(...params);
    }

    async put(...params) {
        return this._instance.put(...params);
    }

    setDeviceName(deviceName) {
        this._instance.defaults.headers.common['x-device-name'] = deviceName;
    }

    setRegion(region) {
        this._instance.defaults.headers.common['x-region-key'] = region;
    }

    setLanguage(language) {
        this._instance.defaults.headers.common['lang'] = language;
    }

    setAuthToken(token) {
        this._instance.defaults.headers.common['Authorization'] = token;
    }

    removeAuthToken() {
        this._instance.defaults.headers.common['Authorization'] = null;
    }
}


