import FileReaderAsync from "@/utils/FileReaderAsync";


export default class ImageFile {

    _file;
    _base64;

    get rawFile() { return this._file; }

    get base64() { return this._base64; }

    static async wrap(imageFile) {

        const base64 = await FileReaderAsync.imageFileToBase64(imageFile);

        return new ImageFile(imageFile, base64);
    }

    constructor(file, base64) {
        this._file = file;
        this._base64 = base64;
    }
}
