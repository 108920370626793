<template>
    <Bar_Large class="duel-bar-votes" mask-image="duels/bar-votes.png" @click.native="barClicked()">
        <template v-slot:fill>
            <div class="duel-bar-fill-wrapper challenger">
                <span class="duel-bar-text challenger">{{ duel.challengerVotePercent }}%</span>
                <div class="duel-bar-fill challenger" ref="fill-challenger" :style="votePercentToWidth(duel.challengerVotePercent)"></div>
            </div>
            <div class="duel-bar-fill-wrapper challenged">
                <div class="duel-bar-fill challenged" ref="fill-challenged" :style="votePercentToWidth(duel.challengedVotePercent)"></div>
                <span class="duel-bar-text challenged">{{ duel.challengedVotePercent }}%</span>
            </div>
        </template>
    </Bar_Large>
</template>

<script>

    import Duel from "@/server/structures/Duel";
    import Bar_Large from "@/ui/components/common/Bar_Large";

    export default {
        name: "Duel_Bar_VotingResult",
        components: { Bar_Large },
        props: {
            duel: Duel
        },
        methods: {
            votePercentToWidth(votePercent) {
                return {
                    width : votePercent + "%"
                }
            },
            barClicked() {
                // EventBus.$emit(Events.VoteBarClicked, this.duel)
            }
        }
    }
</script>

<style>

    .duel-bar-votes {
        position: relative;
        width: 100%;
        height: 19px;
    }

    .duel-bar-votes .duel-bar-fill-wrapper {
        position: absolute;
        height: 100%;
        width: 50%;
    }
    .duel-bar-votes .duel-bar-fill-wrapper.challenger {
        right: 50%;
    }
    .duel-bar-votes .duel-bar-fill-wrapper.challenged {
        right: 0;
    }

    .duel-bar-votes .duel-bar-fill-wrapper .duel-bar-fill {
        position: absolute;
        height: 100%;
    }
    .duel-bar-votes .duel-bar-fill-wrapper .duel-bar-fill.challenger {
        right: 0;
        background-color: #ff6256;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }
    .duel-bar-votes .duel-bar-fill-wrapper .duel-bar-fill.challenged {
        left: 0;
        background-color: #f9b234;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    .duel-bar-votes .duel-bar-text {
        position: absolute;
        color: white;
        font-size: 10pt;
        top: 2px;
        z-index: 1;
    }
    .duel-bar-votes .duel-bar-fill-wrapper.challenger .duel-bar-text {
        float: left;
        left: 5px;
    }
    .duel-bar-votes .duel-bar-fill-wrapper.challenged .duel-bar-text {
        float: right;
        right: 5px;
    }
</style>
