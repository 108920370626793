<template>
    <div class="w-100">
        <Form_InputField
            class="gray"
            @focus.native="openJokeForm"
            auto-blur
            v-popover="{ name : (isAuthenticated ? '' : 'popover-login-post')}"
            :placeholder="textPrompt"
        />

        <PostCreation_Modal_Text :mode="mode" :duel-response-id="duelResponseId" ref="modal-create-joke" />
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Form_InputField from "@/ui/components/common/Form_InputField";
import Modules from "@/store/Modules";
import Getters from "@/store/Getters";
import PostCreation_Modal_Text from "@/ui/components/feed/post_creation/PostCreation_Modal_Text";

export default {
    name: "PostCreation_Text",
    components: { PostCreation_Modal_Text, Form_InputField },
    props: {
        mode: Number,
        duelResponseId: [Number, null],
        textPrompt: String
    },
    computed: {
        ...mapGetters(Modules.AUTH, { isAuthenticated: Getters.Auth.IS_AUTHENTICATED })
    },
    methods: {
        openJokeForm() {
            if(!this.isAuthenticated) return;

            this.$refs['modal-create-joke'].show();
        }
    }
}
</script>
