import CollectionStatus from "@/store/types/CollectionStatus";
import Getters from "@/store/Getters";
import Actions from "@/store/Actions";
import Mutations from "@/store/Mutations";
import ArrayEx from "@/utils/ArrayEx";

/**
 * Generic template for a module that interacts with a paginated remote collection.
 */
export default function (loadPostsCallback, initialState) {

    return {
        namespaced: true,
        state: () => ({
            status: CollectionStatus.Ready,
            entries: [],
            page: 0,
            lastPageReached: false,
            ...initialState
        }),
        getters: {
            [Getters.Collection.STATUS_IS]: (state) => (status) => {
                return state.status === status;
            },
            [Getters.Collection.STATUS_IS_READY]: (state) => {
                return state.status === CollectionStatus.Ready;
            },
            [Getters.Collection.STATUS_IS_LOADING]: (state) => {
                return state.status === CollectionStatus.Loading;
            },
            [Getters.Collection.STATUS_IS_ERROR]: (state) => {
                return state.status === CollectionStatus.Error;
            },
            [Getters.Collection.GET_LAST_PAGE_REACHED](state) {
                return state.lastPageReached;
            },
            [Getters.Collection.GET_ENTRIES](state) {
                return state.entries;
            }
        },
        mutations: {
            [Mutations.Collection._RESET](state) {
                state.entries = [];
                state.page = 0;
                state.lastPageReached = false;
            },
            [Mutations.Collection._SET_STATUS](state, status) {
                state.status = status;
            },
            [Mutations.Collection._INCREMENT_PAGE](state) {
                state.page++;
            },
            [Mutations.Collection._ADD_NEW_ENTRIES](state, { entries, lastPageReached }) {
                state.lastPageReached = lastPageReached;
                state.entries = ArrayEx.unique(state.entries.concat(entries));
            },
        },
        actions: {
            [Actions.Collection.PUSH_TO_TOP]({ state }, topEntry) {
                state.entries = state.entries.filter((curEntry) => {
                    return curEntry.id !== topEntry.id;
                });

                state.entries.unshift(topEntry);
            },
            async [Actions.Collection.RELOAD]({ commit, dispatch }) {

                commit(Mutations.Collection._RESET);

                dispatch(Actions.Collection.LOAD_MORE);
            },
            async [Actions.Collection.LOAD_MORE](context) {

                const { commit, state } = context;

                commit(Mutations.Collection._INCREMENT_PAGE);

                commit(Mutations.Collection._SET_STATUS, CollectionStatus.Loading);

                const result = await loadPostsCallback(context, state.page);

                if (result.success) {

                    commit(Mutations.Collection._SET_STATUS, CollectionStatus.Ready);

                    commit(Mutations.Collection._ADD_NEW_ENTRIES, {
                        entries: result.entries,
                        lastPageReached: result.lastPage === state.page
                    });
                }
                else {

                    commit(Mutations.Collection._SET_STATUS, CollectionStatus.Error);
                }
            },
        }
    }
}
