<template>
    <div class="duel-post-header">
        <img class="post-header-flag"
             :src="isChallenger ? $icons.DuelsIcons.PostChallenger : $icons.DuelsIcons.PostChallenged"
             alt="post header flag"
        />
        <div class="post-header-text" :class="{ challenged: !isChallenger }">
            <slot></slot>
        </div>
        <Post_Text_Views class="post-header-view-count">{{ $t('general.views') }} {{ viewCount }}</Post_Text_Views>
    </div>
</template>

<script>

import Post_Text_Views from "@/ui/components/feed/post/Post_Text_Views";

export default {
    name: "Duel_Post_Header",
    components: { Post_Text_Views },
    props: {
        isChallenger: Boolean,
        viewCount: Number
    }

}
</script>

<style>

.duel-post-header {
    margin-bottom: 0.2em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.duel-post-header .post-header-view-count {
    margin-left: auto;
}

.duel-post-header .post-header-text {
    color: #FF6255;
    text-transform: uppercase;
}

.duel-post-header .post-header-text.challenged {
    color: #F9B233;
}

.duel-post-header .post-header-text,
.duel-post-header .post-header-view-count {
    line-height: 1.6;
}

.duel-post-header .post-header-flag {
    width: 1.9em;
    height: 1.9em;
}
</style>
