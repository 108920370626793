<template>
    <Panel_Hint v-if="!hidden" class="px-4 pb-2 pt-4 mb-2">

        <Header_Medium>
            <slot name="header"> </slot>
        </Header_Medium>

        <Text_Default class="font-weight-light">
            <slot name="explanation"> </slot>
        </Text_Default>

        <div class="w-100 flex-row d-flex justify-content-center">
            <Button_Default class="text-center" @click.native="hide">{{ $t('general.tutorial-close') }}</Button_Default>
        </div>
    </Panel_Hint>
</template>

<script>
    import Panel_Hint from "@/ui/components/common/Panel_Hint";
    import Text_Default from "@/ui/components/common/Text_Default";
    import Button_Default from "@/ui/components/common/Button_Default";
    import Header_Medium from "@/ui/components/common/Header_Medium";
    import Storage from "@/utils/local_storage/Storage";

    export default {
        name: "Layout_Tutorial",
        components: { Header_Medium, Button_Default, Text_Default, Panel_Hint },
        props: {
            type: String
        },
        data() {
            return {
                hidden : true,
                storageObject: Storage.Tutorials
            }
        },
        mounted() {
            if(!this.storageObject.exists(this.type)) {
                this.hidden = false;
            }
        },
        methods : {
            hide() {
                this.storageObject.set(this.type, true);
                this.hidden = true;
            }
        }
    }
</script>
