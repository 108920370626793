/** Abstract base class for accessing a single subdomain of the server. */
export default class ApiProxy {

    _baseUrl;

    _client;

    constructor(baseUrl, requestClient) {
        this._baseUrl = baseUrl;
        this._client = requestClient;
    }

    head(urlPath, ...params) {
        return this._handleResponseErrors(
            this._client.head(this._baseUrl + urlPath, ...params)
        );
    }

    get(urlPath, ...params) {
        return this._handleResponseErrors(
            this._client.get(this._baseUrl + urlPath, ...params)
        );
    }

    delete(urlPath, ...params) {
        return this._handleResponseErrors(
            this._client.delete(this._baseUrl + urlPath, ...params)
        );
    }

    post(urlPath, ...params) {
        return this._handleResponseErrors(
            this._client.post(this._baseUrl + urlPath, ...params)
        );
    }

    put(urlPath, ...params) {
        return this._handleResponseErrors(
            this._client.put(this._baseUrl + urlPath, ...params)
        );
    }

    async _handleResponseErrors(requestPromise) {
        try {
            return await requestPromise;
        }
        catch(exc) {
            return exc.response;
        }
    }
}
