<template>
    <Duel_Component class="loading-blur" :duel="fakeDuel"/>
</template>

<script>
    import LoremIpsum from "@/utils/LoremIpsum";
    import Duel from "@/server/structures/Duel";
    import Duel_Component from "@/ui/components/duels/Duel_Component";

    const FakeDuel = new Duel({
        challenger: {
            username: "Some Username"
        },
        challenger_post: {
            text: LoremIpsum.generateWords(4) + '\n' + LoremIpsum.generateSentences(7),
            type: "text",
            tags : [],
            user: {
                username: "Some Username"
            }
        },
        challenged: {
            username: "Some Username"
        },
        challenged_post: {
            text: LoremIpsum.generateWords(4) + '\n' + LoremIpsum.generateSentences(7),
            type: "text",
            tags : [],
            user: {
                username: "Some Username"
            }
        },
    });

    export default {
        name: "Duel_Loading",
        components: { Duel_Component },
        data() {
            return { fakeDuel : FakeDuel }
        }
    }
</script>
