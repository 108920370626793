import ModuleTemplate_Collection from "@/store/templates/ModuleTemplate_Collection";

export default function(postsApi) {

    return ModuleTemplate_Collection(async (context, page) => {

        const response = await postsApi.getFavorites(page);

        return {
            success : response.success,
            entries : response.posts,
            lastPage : response.lastPage
        }
    });
}
