<template>
    <Bar_Large mask-image="user/experience-bar-mask.png" :percentage-fill="percentageFill" class="experience-bar-large">
        
        <span class="first-split" />
        <span class="second-split" />
        <div class="bar-texts overlay-absolute">
            <p class="bar-text">{{$t("users.level-advanced")}}</p>
            <p class="bar-text">{{$t("users.level-expert")}}</p>
            <p class="bar-text">{{$t("users.level-pro")}}</p>
        </div>
    </Bar_Large>
</template>

<script>
    import Bar_Large from "@/ui/components/common/Bar_Large";
    export default {
        name: "Bar_Experience_Large",
        components: { Bar_Large },
        props : {
            percentageFill: Number
        }
    }
</script>

<style scoped>

    .experience-bar-large {
        position: relative;
        width: 390px;
        height: 20px;
    }

    .bar-texts {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-evenly;
    }

    .bar-text {
        width: 33%;
        height: 100%;
        text-align: center;
        color: white;
        text-transform: uppercase;
    }
    .first-split,
    .second-split {
        border-right: 2px solid #fff;
        width: 33%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

    }
    .second-split {
        width: 67%;
    }
</style>
