const MathEx = {};

MathEx.clamp = function (value, min, max) {
    if (value < min) { return min; }
    if (value > max) { return max;}
    return value;
};

MathEx.min = function(lhs, rhs) {
    return lhs < rhs ? lhs : rhs;
};

MathEx.max = function (lhs, rhs) {
    return lhs > rhs ? lhs : rhs;
};

export default MathEx;
