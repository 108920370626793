<template>
    <SubmenuNav :submenus="submenus" :generate-url="generateSubmenuPath" v-on:selected-clicked="selectedClicked"/>
</template>

<script>

    import SubmenuNav from "@/ui/components/submenu_nav/SubmenuNav";
    import SubmenuBuilder from "@/utils/SubmenuBuilder";
    import Icons from "@/utils/Icons";
    import FeedTypes from "@/store/types/FeedTypes";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import {mapGetters, mapActions} from "vuex";
    import RouterUtils from "@/routes/RouterUtils";
    import ContentTypes from "@/store/types/ContentTypes";
    import Actions from "@/store/Actions";
    import WindowEx from "@/utils/WindowEx";
    import StoreObjects from "@/store/StoreObjects";

    export default {
        name: "Feed_SubmenuNav",
        props: { contentType: String },
        components: { SubmenuNav },
        data() {
            return {
                submenus: (new SubmenuBuilder('general.'))
                    .add(FeedTypes.NEW, Icons.Feed.New)
                    .add(FeedTypes.HOT, Icons.Feed.Hot)
                    .add(FeedTypes.SHORT, Icons.Feed.Short)
                    .add(FeedTypes.NETWORK, Icons.Feed.Recommended, () => this.isAuthenticated)
                    .add(FeedTypes.DUELS, Icons.Feed.Duels)
                    .get()
            }
        },
        computed: {
            duelCollection() {
                return StoreObjects.DuelFeed;
            },
            ...mapGetters(Modules.AUTH, {
                isAuthenticated: Getters.Auth.IS_AUTHENTICATED
            })
        },
        methods: {
            generateSubmenuPath(submenuName) {
                if(submenuName === FeedTypes.SHORT) {
                    return RouterUtils.generateFeedUrl(ContentTypes.JOKES, submenuName);
                }

                return RouterUtils.generateFeedUrl(this.contentType, submenuName);
            },
            selectedClicked(type) {
                if(type.toString() === "duels") {
                    this.duelCollection[Actions.Collection.RELOAD]();
                } else {
                    WindowEx.scrollToTop();
                    this.reloadPosts();
                }
            },
            ...mapActions(Modules.POSTS, {
                reloadPosts: Actions.Posts.RELOAD
            }),
        }
    }
</script>
