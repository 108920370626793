<template>
    <Layout_ArrowScroll class="white" :scroll-step="120">
        <div class="scrollable-tag-list-container">
            <Post_Tag v-for="tag in tags" :key="tag.name" :tag="tag" />
        </div>
    </Layout_ArrowScroll>
</template>

<script>

    import Post_Tag from "@/ui/components/feed/post/Post_Tag";
    import Layout_ArrowScroll from "@/ui/layouts/Layout_ArrowScroll";

    export default {
        name: "List_Scrollable_HashTags",
        components: { Layout_ArrowScroll, Post_Tag},
        props: {tags: Array},
    }
</script>

<style>

    .scrollable-tag-list-container {
        padding-top: 8px;
        padding-bottom: 8px;
    }

</style>
