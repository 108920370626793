<template>
    <Layout_UserDetailsSection :info-text="showHint ? $t('users.hint-duels') : null">
        <div class="user-details-section-duels" :class="{ 'with-info' : showHint }">
            <UserDetails_Header_Small>
                <UserDetails_Icon_Small :icon="$icons.Users.Duels"/>
                {{ $t("users.duels") }}
            </UserDetails_Header_Small>

            <UserDetails_Text_Small>{{ $t("users.won") }}: {{ user.winsCount }}</UserDetails_Text_Small>

            <UserDetails_Text_Small>{{ $t("users.lost") }}: {{ user.lossesCount }}</UserDetails_Text_Small>
        </div>
    </Layout_UserDetailsSection>
</template>

<script>
    import Layout_UserDetailsSection from "@/ui/layouts/Layout_UserDetailsSection";
    import UserDetails_Header_Small from "@/ui/components/users/details/UserDetails_Header_Small";
    import User from "@/server/structures/User";
    import UserDetails_Icon_Small from "@/ui/components/users/details/UserDetails_Icon_Small";
    import UserDetails_Text_Small from "@/ui/components/users/details/UserDetails_Text_Small";
    export default {
        name: "UserDetails_Section_Duels",
        components: { UserDetails_Text_Small, UserDetails_Icon_Small, UserDetails_Header_Small, Layout_UserDetailsSection },
        props: { user: User, showHint: Boolean }
    }
</script>

<style scoped>

    .user-details-section-duels {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-around;
    }

    .user-details-section-duels.with-info {
        justify-content: space-between;
        padding-right: 70px;
    }
</style>
