<template>
    <div class="leaderboard-entry-section-custom">
        <template v-if="byActivity || myPosition">
            <Post_Text_Views>{{ $t('leaderboards.experience-points') }} {{ data.activityPoints }}</Post_Text_Views>
        </template>
        <template v-else-if="byInfluencers">
            <Post_Text_Views>{{ $t('leaderboards.reaction-points') }} {{ data.reactionPoints }}</Post_Text_Views>
        </template>
        <template v-else-if="byFollowers">
            <Post_Text_Views>{{ $t('leaderboards.fans') }} {{ data.fanCount }}</Post_Text_Views>
        </template>
    </div>
</template>

<script>
    import LeaderboardEntry from "@/server/structures/LeaderboardEntry";
    import Post_Text_Views from "@/ui/components/feed/post/Post_Text_Views";
    import LeaderboardDisplayTypes from "@/ui/types/LeaderboardDisplayTypes";

    export default {
        name: "Leaderboard_EntrySection_Custom",
        components: { Post_Text_Views },
        props: {
            displayType: String,
            data: LeaderboardEntry
        },
        computed: {
            byActivity() { return this.displayType === LeaderboardDisplayTypes.Activity; },
            byInfluencers() { return this.displayType === LeaderboardDisplayTypes.Influencers; },
            byFollowers() { return this.displayType === LeaderboardDisplayTypes.Followers; },
            myPosition() { return this.displayType === LeaderboardDisplayTypes.MyPosition; }
        }
    }
</script>

<style scoped>

    .leaderboard-entry-section-custom {
        flex-basis: 35%;
        padding-right: 0.8em;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
</style>
