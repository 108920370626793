import WindowEx from "@/utils/WindowEx";
import StoreUtils from "@/utils/StoreUtils";
import EventBus from "@/events/EventBus";
import Events from "@/events/Events";

export default {

    router : null,

    init(router) {
        this.router = router;
    },

    isOnDuelFeed(routePath) {

        const regex = /^\/j\/.*\/duels/g;

        return routePath.match(regex);
    },

    generateFeedUrl(category, feedType) {
        return `/j/${category}/${feedType}`;
    },

    generateUserUrl(userSlug, submenu) {
        return `/u/${userSlug}/${submenu}`;
    },

    goToUser(userSlug) {
        this.router.push(`/u/${userSlug}`).catch(this._handleException);
        WindowEx.scrollToTop();
        StoreUtils.clearTags();
    },

    goToUserFollowers(userSlug) {
        this.router.push(`/u/${userSlug}/followers`);
    },

    goToUserFollowings(userSlug) {
        this.router.push(`/u/${userSlug}/followings`);
    },

    goToFeed(category, feedType) {
        this.router.push(this.generateFeedUrl(category, feedType)).catch(this._handleException);
    },

    goBack() {
        this.router.go(-1);
    },

    goHome(clearTags) {
        this.router.push('/').catch((exc) => {
            this._handleException(exc);
        });

        if(clearTags) {
            StoreUtils.clearTags();
        }

        WindowEx.scrollToTop();
    },

    goToNotifications() {

        if(this.router.currentRoute.path.includes("notifications")) { return; }

        this.router.push("/notifications");
    },

    /** @param {string} code */
    goToDuelChallenge(code) {

        this.router.push(`/duel/${code}`);
    },

    /**
     * Opens the duel feed, placing a specific duel on top.
     * @param {string} code The code of the duel to share on top.
     */
    goToDuelFeed(code = null) {
        if(!code) {
            this.router.push(`/j/all/duels`);
        } else {
            this.router.push(`/j/all/duels?d=${code}`);
        }
    },

    _handleException(exc) {

        if(exc.name === "NavigationDuplicated") {
            EventBus.$emit(Events.DuplicateNavigation);
            return;
        }

        console.error(exc);
    }
}
