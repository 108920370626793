
export default class Notification {

    _data;

    /** @return {string} */
    get imageUrl() { return this._data.image; }

    /** @return {String} */
    get body() { return this._data.body; }

    /** @return {String} */
    get title() { return this._data.title; }

    /** @return {String} */
    get targetUrl() { return this._data.url; }

    /** @return {Boolean} */
    get isRead() { return this._data.read; }

    constructor(data) {
        this._data = data;
    }
}
