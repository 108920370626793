<template>
    <div class="modal-section-tags">
        <h5> {{ $t("posts.add-tags") }} </h5>

        <PostCreation_Button_Tag
                v-for="(tag, index) in tags"
                :key="tag"
                :name="tag"
                @click.native="removeTag(index)"
        />

        <div v-if="!tagsFull" class="tag-input-container">

            <Form_InputField
                    v-model="inputValue"
                    :debounce="200"
                    placeholder="Type tags here..."
                    class="gray mt-2 mb-3"
            />
            <img class="add-tag-button" :src="$icons.PostCreation.AddTag" alt="Add tag" @click="addCustomTag"/>

            <PostCreation_TagSuggestions
                    :search-text="inputValue"
                    :selected-tags="tags"
                    v-on:post-tag-selected="addTag"
            />
        </div>
    </div>
</template>

<script>
    import Form_InputField from "@/ui/components/common/Form_InputField";
    import PostCreation_Button_Tag from "@/ui/components/feed/post_creation/PostCreation_Button_Tag";
    import PostCreation_TagSuggestions from "@/ui/components/feed/post_creation/PostCreation_TagSuggestions";

    const MaxTagsAllowed = 8;

    export default {
        name: "PostCreation_TagSelection",
        components: { PostCreation_TagSuggestions, PostCreation_Button_Tag, Form_InputField },
        props: {
            tags: { type: Array, required: true }
        },
        data() {
            return {
                inputValue: null,
            }
        },
        computed: {
            tagsFull() {
                return this.tags.length >= MaxTagsAllowed;
            },
            canAddTag() {
                return this.inputValue && !this.tags.includes(this.inputValue) && !this.tagsFull;
            }
        },
        methods: {
            addCustomTag() {
                if (!this.canAddTag) return;

                this.tags.push(this.inputValue);

                this.inputValue = null;
            },
            addTag(tag) {
                this.tags.push(tag.name);

                this.inputValue = null;
            },
            removeTag(index) {
                this.tags.splice(index, 1);
            }
        }
    }
</script>

<style scoped>
    .modal-section-tags {
        border-top: 1px solid #ededed;
        margin-top: 15px;
        padding-top: 15px;
    }

    .modal-section-tags h5 {
        color: #6f6f6f;
    }

    .tag-input-container {
        position: relative;
    }

    .add-tag-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 2.4rem;
    }

</style>
