<template>
    <Layout_AuthSidebar :title="$t('auth.title-login')" nav-id="sidebar-login" v-on:close="reset">
        <template #default="{ hide }">

            <Auth_ErrorMessage :error-message="$t(errorMessage)"/>

            <Auth_Form id="login">

                <Auth_Form_InputField :label="$t('auth.email')"
                                      type="email"
                                      input-name="email"
                                      v-model="email"
                                      input-id="email-login"/>

                <Auth_Form_InputField :label="$t('auth.password')"
                                      type="password"
                                      input-name="password"
                                      input-id="password-login"
                                      v-model="password"
                                      autocomplete="current-password"
                                      required/>

                <Auth_Button_Black class="mt-4 mb-1" :disabled="continueDisabled" @click.native="attemptLogin">
                    {{ $t("auth.continue") }}
                </Auth_Button_Black>

            </Auth_Form>

            <div class="w-100 mt-3 pt-2">
                <Auth_PromptLabel class="mb-2" :label="$t('auth.dont-have-an-account-q')">
                    <Button_WhiteOutline class="px-4" @click.native="close" v-b-toggle.sidebar-register>
                        {{ $t("auth.register") }}
                    </Button_WhiteOutline>
                </Auth_PromptLabel>

                <Auth_Link @click.native="close" v-b-toggle.sidebar-forgot-password>
                    {{ $t('auth.forgot-your-password-q') }}
                </Auth_Link>
            </div>

            <button hidden ref="hideButton" @click="hide"></button>
        </template>
    </Layout_AuthSidebar>
</template>

<script>

    import {mapActions} from "vuex";
    import Auth_Form_InputField from "@/ui/components/auth/Auth_Form_InputField";
    import Auth_Button_Black from "@/ui/components/auth/Auth_Button_Black";
    import Auth_ErrorMessage from "@/ui/components/auth/Auth_ErrorMessage";
    import Auth_PromptLabel from "@/ui/components/auth/Auth_PromptLabel";
    import Button_WhiteOutline from "@/ui/components/common/Button_WhiteOutline";
    import Layout_AuthSidebar from "@/ui/layouts/Layout_AuthSidebar";
    import Auth_Form from "@/ui/components/auth/Auth_Form";
    import Actions from "@/store/Actions";
    import Modules from "@/store/Modules";
    import Auth_Link from "@/ui/components/auth/Auth_Link";

    export default {
        name: "Auth_Screen_EmailLogin",
        components: {
            Auth_Link,
            Auth_PromptLabel,
            Auth_Form,
            Layout_AuthSidebar,
            Auth_Form_InputField,
            Auth_Button_Black,
            Auth_ErrorMessage,
            Button_WhiteOutline
        },
        data() {
            return {
                email: null,
                password: null,
                errorMessage: null
            }
        },
        computed: {
            continueDisabled() {
                return (!this.email) || (!this.password);
            }
        },
        methods: {
            close() {
                this.$refs["hideButton"].click();
                this.reset();
            },
            reset() {
                this.email = this.password = this.errorMessage = null;
            },
            async attemptLogin() {

                this.errorMessage = null;

                const result = await this._login({
                    email: this.email,
                    password: this.password
                });

                if (result.success) {
                    this.close();
                }
                else {
                    this._handleFailedLogin(result.errorMessage);
                }
            },
            _handleFailedLogin(errorMessage) {
                this.email = this.password = null;
                this.errorMessage = errorMessage;
            },
            ...mapActions(Modules.AUTH, {
                _login: Actions.Auth.LOGIN
            }),
            ...mapActions(Modules.POSTS, {
                _reloadPosts: Actions.Posts.RELOAD
            })
        }
    }
</script>
