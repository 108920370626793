<!-- Manages loading and rendering of a given map resource. -->
<template>
    <div>
        <template v-if="statusIsReady">
            <Map_Renderer :map-data="data"/>
        </template>
        <template v-else-if="statusIsLoading">
            <Spinner class="center-absolute orange"/>
        </template>
        <template v-else-if="statusIsError">
            <Text_NoResults>{{ $t("errors.failed-map-load") }}</Text_NoResults>
        </template>
    </div>
</template>

<script>

import Map_Renderer from "@/ui/components/map/Map_Renderer";
import Spinner from "@/ui/components/common/Spinner";
import ResourceStatus from "@/store/types/ResourceStatus";
import Text_NoResults from "@/ui/components/common/Text_NoResults";

export default {
    name: "Map",
    components: { Text_NoResults, Spinner, Map_Renderer },
    props: {
        mapResource : Object
    },
    watch: {
        mapResource() {
            this.mapResource.load();
        }
    },
    computed: {
        statusIsReady() {
            return this.mapResource.getStatus === ResourceStatus.Ready;
        },
        statusIsLoading() {
            return this.mapResource.getStatus === ResourceStatus.Loading;
        },
        statusIsError() {
            return this.mapResource.getStatus === ResourceStatus.Error;
        },
        data() {
            return this.mapResource.getData;
        },
        errors() {
            return this.mapResource.getErrors;
        }
    },
    mounted() {
        this.mapResource.load();
    }
}
</script>

<style scoped>

</style>
