<template>
    <div class="info-question">
        <h2 class="info-question-text">
            <slot></slot>
        </h2>
    </div>
</template>

<script>

    export default {
        name: "Info_SubHeader"
    }
</script>

<style scoped>
    .info-question-text {
        color: #8a7d78;
        font-size: 1.25rem;
        margin-top: 1rem;
        margin-bottom: 0;
    }
</style>
