import CollectionStatus from "@/store/types/CollectionStatus";
import LeaderboardCategories from "@/server/types/LeaderboardCategories";
import Mutations from "@/store/Mutations";
import Getters from "@/store/Getters";
import Actions from "@/store/Actions";
import Modules from "@/store/Modules";

export default function(leaderboardsApi) {

    return {
        namespaced: true,
        state: () => ({
            category: LeaderboardCategories.Activity,
            showMyPosition : false,
            status: CollectionStatus.Ready,
            entries: []
        }),
        getters : {
            [Getters.Leaderboards.GET_STATUS](state) {
                return state.status;
            },
            [Getters.Leaderboards.GET_ENTRIES](state) {
                return state.entries;
            },
            [Getters.Leaderboards.SHOW_MY_POSITION](state) {
                return state.showMyPosition;
            },
            [Getters.Leaderboards.CATEGORY](state) {
                return state.category;
            }
        },
        mutations : {
            [Mutations.Leaderboards._SET_CATEGORY](state, value) {
                state.category = value;
            },
            [Mutations.Leaderboards._SET_SHOW_MY_POSITION](state, value) {
                state.showMyPosition = value;
            },
            [Mutations.Leaderboards._SET_ENTRIES](state, entries) {
                state.entries = entries;
            },
            [Mutations.Leaderboards._SET_STATUS](state, status) {
                state.status = status;
            }
        },
        actions : {
            async [Actions.Leaderboards.SET_CATEGORY]({state, commit, dispatch}, category) {

                if(state.category === category && !state.showMyPosition) return;

                commit(Mutations.Leaderboards._SET_SHOW_MY_POSITION, false);
                commit(Mutations.Leaderboards._SET_CATEGORY, category);

                await dispatch(Actions.Leaderboards.PULL);
            },
            async [Actions.Leaderboards.SHOW_MY_POSITION]({commit, dispatch}) {

                commit(Mutations.Leaderboards._SET_SHOW_MY_POSITION, true);

                await dispatch(Actions.Leaderboards.PULL);
            },
            async [Actions.Leaderboards.PULL]({state, commit, rootGetters}) {

                commit(Mutations.Leaderboards._SET_STATUS, CollectionStatus.Loading);

                let response = null;
                if(state.showMyPosition && isAuthenticated(rootGetters)) {
                    response = await leaderboardsApi.getMyPosition();
                }
                else {
                    response = await leaderboardsApi.getCategory(state.category);
                }

                if(response.success) {

                    commit(Mutations.Leaderboards._SET_STATUS, CollectionStatus.Ready);

                    commit(Mutations.Leaderboards._SET_ENTRIES, response.entries);
                }
            }
        }
    };

    function isAuthenticated(rootGetters) {
        return rootGetters[`${Modules.AUTH}/${Getters.Auth.IS_AUTHENTICATED}`];
    }
}
