<template>
    <Layout_DuelChallenge_Actions>

<!--    <DuelChallenge_Button_RemindLater/> : Disabled until feature is added -->

        <template v-if="!duelAccepted">

<!--        <DuelChallenge_Button_Refuse/> : Disabled until discussion -->

            <DuelChallenge_Button_Accept @click.native="onAcceptClick"/>

        </template>
        <template v-else>

            <PostCreation_Bar :mode="postModalMode" class="fade-in-fast" :text-prompt="$t('duels.challenge-text-prompt')" :duel-response-id="duel.id"/>

        </template>
    </Layout_DuelChallenge_Actions>
</template>

<script>

import Duel from "@/server/structures/Duel";
import Layout_DuelChallenge_Actions from "@/ui/components/duel_challenge/Layout_DuelChallenge_Actions";
import DuelChallenge_Button_Accept from "@/ui/components/duel_challenge/DuelChallenge_Button_Accept";
import PostCreation_Bar from "@/ui/components/feed/post_creation/PostCreation_Bar";
import PostModalModes from "@/utils/PostModalModes";

export default {
    name: "DuelChallenge_Actions_Challenged",
    components: {
        PostCreation_Bar,
        DuelChallenge_Button_Accept,
        Layout_DuelChallenge_Actions
    },
    props: {
        duel: Duel
    },
    data() {
        return { duelAccepted: false }
    },
    computed: {
        postModalMode() {
            return PostModalModes.DuelResponse;
        }
    },
    methods: {
        onAcceptClick() {
            this.duelAccepted = true;
        }
    }
}
</script>
