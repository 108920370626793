<template>
    <Layout_Master>
        <main class="content">
            <template v-if="statusIsLoading">
                <Spinner class="orange center-absolute"/>
            </template>
            <template v-else-if="statusIsReady">
                <Notifications_List :notifications="getNotifications"/>
            </template>
        </main>
    </Layout_Master>
</template>

<script>
import Layout_Master from "@/ui/layouts/Layout_Master";
import {mapActions, mapGetters} from "vuex";
import Modules from "@/store/Modules";
import Actions from "@/store/Actions";
import Getters from "@/store/Getters";
import CollectionStatus from "@/store/types/CollectionStatus";
import Spinner from "@/ui/components/common/Spinner";
import Notifications_List from "@/ui/components/notifications/Notifications_List";

export default {
    name: "View_Notifications",
    components: { Notifications_List, Spinner, Layout_Master },
    async mounted() {

        await this.pullNotifications();

        this.markAllAsRead();
    },
    computed: {
        statusIsLoading() { return this.getStatus === CollectionStatus.Loading; },
        statusIsReady() { return this.getStatus === CollectionStatus.Ready; },
        ...mapGetters(Modules.NOTIFICATIONS, {
            getStatus           : Getters.Notifications.GET_STATUS,
            getNotifications    : Getters.Notifications.GET_NOTIFICATIONS
        })
    },
    methods: {
        ...mapActions(Modules.NOTIFICATIONS, {
            pullNotifications   : Actions.Notifications.PULL,
            markAllAsRead       : Actions.Notifications.MARK_ALL_AS_READ
        })
    }
}
</script>

<style scoped>

.content {
    padding: 55px 3px 3px 3px;
    min-height: 100vh;
}

</style>
