<template>
    <b-collapse class="user-collapse-hint border-rounded" :visible="visible">
        <div class="padder"></div>
        <span>{{ text }}</span>
        <div class="padder"></div>
    </b-collapse>
</template>

<script>
    export default {
        name: "Help_Collapse",
        props: {
            text: String,
            visible: Boolean
        },
    }
</script>

<style scoped>

    .user-collapse-hint {
        width: 100%;
        background-color: #ffe0aa;
        line-height: 1.4em;
        font-size: 12pt;
        margin-bottom: 4px;
        padding-left: 12px;
        padding-right: 30px;
    }

    /* Hack to allow padding without ruining the smoothness of b-collapse's animation. */
    .user-collapse-hint .padder {
        width: 100%;
        height: 5px;
    }

</style>
