<!-- Used for the small icons that appear in the user details sections. -->
<!-- Classes : -->
<!-- 'light' : increases the icon's brightness. -->
<!-- 'dark'  : decreases the icon's brightness -->

<template>
    <img class="user-details-icon-small" :src="icon" alt="user-details-icon"/>
</template>

<script>
    export default {
        name: "UserDetails_Icon_Small",
        props: { icon: String }
    }
</script>

<style>

    .user-details-icon-small {
        width: 1.6em;
        height: 1.6em;
        filter: grayscale(1) brightness(1.2);
    }

    .user-details-icon-small.light {
        filter: brightness(1.55);
    }

    .user-details-icon-small.dark {
        filter: brightness(0.5);
    }
</style>
