import StorageVariable from "@/utils/local_storage/StorageVariable";
import StorageObject from "@/utils/local_storage/StorageObject";

export default {

    AuthToken : new StorageVariable("authToken"),

    User : new StorageVariable("user"),

    Language : new StorageVariable("language"),

    Region : new StorageVariable("region"),

    PrivacyPolicyAccepted : new StorageVariable("privacyPolicyAccepted"),

    Tutorials : new StorageObject("tutorials"),

    clear() {
        localStorage.clear();
    }
}
