<template>
    <Layout_Post
        :post="post"
        :disableTrackViews="disableTrackViews"
        :previewMode="previewMode"
        :isClosed="isClosed"
        :canBeClosed="canBeClosed"
        :readTime="readTime"
    >
        <template v-slot:header>
            <Post_Section_Header :rank="post.rank" :user="post.user" :timestamp="post.createdAt" :view-count="post.viewCount"/>
        </template>

    </Layout_Post>
</template>

<script>

    import Post from "@/server/structures/Post";
    import Layout_Post from "@/ui/components/common/Layout_Post";
    import Post_Section_Header from "@/ui/components/feed/post/Post_Section_Header";
    import ReadTime from "@/utils/ReadTime";

    const MinimumPostLength = 250;

    export default {
        name: "Post_Component",
        components: { Post_Section_Header, Layout_Post },
        props: {
            post: Post,
            disableTrackViews: Boolean,
            previewMode: {
                default: false
            }
        },
        computed: {
            isClosed() {
                return this.canBeClosed
            },
            canBeClosed() {
                return this.post.isText && this.post.isLong && this.post.length > MinimumPostLength;
            },
            readTime() {
                if(this.post.isText){
                    return ReadTime.calculate(this.post.text);
                }
                else {
                    return null
                }
            }
        }
    }
</script>

