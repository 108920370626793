<template>
    <div>
        <Auth_Screen_LoginSelect/>

        <Auth_Screen_EmailLogin/>

        <Auth_Screen_Registration/>

        <Auth_Screen_RegistrationEmailSent/>

        <Auth_Screen_ForgotPassword/>
    </div>
</template>

<script>

    import Auth_Screen_LoginSelect from "@/ui/components/auth/Auth_Screen_LoginSelect";
    import Auth_Screen_Registration from "@/ui/components/auth/Auth_Screen_Registration";
    import Auth_Screen_EmailLogin from "@/ui/components/auth/Auth_Screen_EmailLogin";
    import Auth_Screen_RegistrationEmailSent from "@/ui/components/auth/Auth_Screen_RegistrationEmailSent";
    import Auth_Screen_ForgotPassword from "@/ui/components/auth/Auth_Screen_ForgotPassword";

    export default {
        name: "Auth_Screens",
        components: {
            Auth_Screen_LoginSelect,
            Auth_Screen_Registration,
            Auth_Screen_EmailLogin,
            Auth_Screen_RegistrationEmailSent,
            Auth_Screen_ForgotPassword
        }
    }
</script>

<style scoped>

</style>
