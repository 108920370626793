<template>
    <div>
        <Help_Section v-if="category.description" :info-text="category.description"/>
        <Header_Large class="orange">
            {{ category.name }}
        </Header_Large>
        <Badges_Subcategory
            v-for="subcategory in category.subcategories"
            :key="subcategory.name"
            :subcategory="subcategory"
        />
    </div>
</template>

<script>
import BadgeCategory from "@/server/structures/BadgeCategory";
import Badges_Subcategory from "@/ui/components/badges/Badges_Subcategory";
import Header_Large from "@/ui/components/common/Header_Large";
import Help_Section from "@/ui/components/common/help/Help_Section";

export default {
    name: "Badges_Category",
    components: { Help_Section, Header_Large, Badges_Subcategory },
    props: {
        category: BadgeCategory
    }
}
</script>

<style scoped>

</style>
