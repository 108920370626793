<template>
    <div class="duel-close-section">
        <Button_Default class="duel-close-button" @click.native="$emit('close')">
            {{ $t("duels.close") }}
            <img :src="$icons.Common.ArrowDown" alt="Up Arrow" class="duel-close-icon" />
        </Button_Default>
    </div>
</template>

<script>
    import Button_Default from "@/ui/components/common/Button_Default";

    export default {
        name: "Duel_Button_Close",
        components: { Button_Default },
    }
</script>

<style>
    .duel-close-section {
        padding: 0 0 1em 0;
        margin-top: 1em;
        text-align: center;
        text-transform: lowercase;
    }
    .duel-close-section .duel-close-icon {
        height: 0.8rem;
        margin-right: -0.3rem;
        transform: rotate(180deg);
    }
    .duel-close-button:hover {
        cursor: pointer;
    }
</style>
