<template>
    <div class="sidebar-collapse">
        <b-collapse v-for="item in items"
                    class="sidebar-collapse-item"
                    :class="{selected:item.selected}"
                    :key="item.key"
                    :visible="visible"
                    @click.native="$emit('select', item)">
            {{ item.value }}
        </b-collapse>
    </div>
</template>

<script>
    export default {
        name: "Sidebar_Collapse",
        props: {
            items: Array,
            visible: Boolean
        }
    }
</script>

<style scoped>
    
    .sidebar-collapse .sidebar-collapse-item {
        padding-left: 63px;
        line-height: 2.0em;
        font-size: 0.9em;
        font-weight: 200;
        color: rgba(254, 253, 255, 0.71);
    }

    .sidebar-collapse .sidebar-collapse-item.selected {
        color: #ff6154;
        background-color: #372f43;
    }

</style>
