<template>
    <div class="container">
        <Info_Text class="header"> {{ $t("terms.important-header")}} </Info_Text>

        <Info_Text> {{ $t("terms.important-company")}} </Info_Text>
        <Info_Text> {{ $t("terms.important-adress")}} </Info_Text>

        <Info_Text> {{ $t("terms.important-contacts")}} </Info_Text>
        <Info_Text> {{ $t("terms.important-contact-email")}} </Info_Text>
        <Info_Text> {{ $t("terms.important-personal-data-email")}} </Info_Text>
        <Info_Text> {{ $t("terms.important-complaints-email")}} </Info_Text>

        <Info_Text> {{ $t("terms.important-use-conditions")}} </Info_Text>
        <Info_Text> {{ $t("terms.important-cancel")}} </Info_Text>

        <Info_Text> 
            {{ $t("terms.important-questions-first")}}
            <router-link to="/faq">https://joketrip.com/faq</router-link>
            {{ $t("terms.important-questions-second")}} 
        </Info_Text>
    </div>
</template>

<script>

    import Info_Text from "@/ui/components/info/Info_Text"; 
    
    export default {
        name: "Info_ImportantSection",
        components: { Info_Text },
    }
</script>

<style scoped>
    .container {
        border-style: solid;
        border-color:#d9d9d9;
        border-width: 2px;
        border-radius: 25px;
        margin-top: 2em;
        padding: 5px;
    }
    .header {
        font-weight: bold;
        margin-top: 0;
        text-align: center;
    }
</style>