<template>
    <Layout_DuelChallenge_Actions>
        <span class="mr-2 mb-2">{{ $t("duels.challenge-login-prompt") }}</span>
        <Button_Login/>
    </Layout_DuelChallenge_Actions>
</template>

<script>
import Layout_DuelChallenge_Actions from "@/ui/components/duel_challenge/Layout_DuelChallenge_Actions";
import Button_Login from "@/ui/components/common/Button_Login";
export default {
    name: "DuelChallenge_Actions_Unauthenticated",
    components: { Button_Login, Layout_DuelChallenge_Actions }
}
</script>
