import ApiResponse from "@/server/responses/ApiResponse";
import Notification from "@/server/structures/Notification";

export default class ApiResponse_GetNotifications extends ApiResponse {

    _notifications;

    get notifications() { return this._notifications; }

    constructor(rawResponse) {
        super(rawResponse);

        if(this.success) {
            this._notifications = rawResponse.data.map((rawNotification) => {
                return new Notification(rawNotification);
            });
        }
    }
}
