import EventBus from "@/events/EventBus";
import Events from "@/events/Events";
import Mutations from "@/store/Mutations";
import Modules from "@/store/Modules";
import Actions from "@/store/Actions";

function init(store) {

    EventBus.$on(Events.RegionChanged, () => {
        store.commit(`${Modules.TAGS}/${Mutations.Tags.CLEAR_SELECTED}`);
        store.dispatch(`${Modules.TAGS}/${Actions.Tags.PULL_POPULAR}`);
    });

    EventBus.$on(Events.ReceivedNotification, () => {
        store.dispatch(`${Modules.NOTIFICATIONS}/${Actions.Notifications.MARK_UNREAD}`)
    });
    EventBus.$on(Events.CheckForNewUnshownNotification, () => {
        store.dispatch(`${Modules.NOTIFICATIONS}/${Actions.Notifications.GET_LAST_UNSHOWN}`)
    });
    EventBus.$on(Events.PushForNewUnshownNotification, () => {
        store.dispatch(`${Modules.NOTIFICATIONS}/${Actions.Notifications.PUSH_LAST_UNSHOWN}`)
    });
}

export default {
    init : init
}
