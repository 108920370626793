<template>
    <b-button pill class="btn-dark btn-with-image">
        <img :src="icon" :alt="alt"/>
        <span>
            <slot></slot>
        </span>
        &rsaquo;
    </b-button>
</template>

<script>
    export default {
        name: "Auth_Button_Black",
        props: {
            icon : String,
            alt : String
        }
    }
</script>

<style scoped>

    .btn-dark {
        background: #292333;
        color: white;
        max-width: 25em;
    }

    .btn-with-image {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 2.7em;
        padding: 0 1em 0 0.4em;
    }
</style>
