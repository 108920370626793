<template>
    <div class="tag-search" v-bind:class="{hidden: !searchActive}">
        <img class="back-button" :src="$icons.Common.ArrowLeft" alt="Back" @click="onSearchCancelled"/>
        <b-form-input
                :value="value"
                ref="form-input"
                @input="updateValue"
                class="tag-search-field"
                v-bind:class="{ filled : active }"
                autocomplete="on">
        </b-form-input>
    </div>
</template>

<script>

    const TypeDelay = 350;

    export default {
        name: "FeedTags_Bar_Search",
        props: {
            value: String,
            onSearchCancelled: Function,
            searchActive: Boolean
        },
        data() {
            return {
                timeoutId: null
            }
        },
        watch: {
            searchActive(newValue) {
                if(newValue) {
                    this.$refs["form-input"].focus();
                }
            }
        },
        computed: {
            active() { return !!this.value || this.timeoutId; }
        },
        methods: {
            updateValue(value) {
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => this.doneTyping(value), TypeDelay);
            },
            doneTyping(value) {
                this.timeoutId = null;
                this.$emit('input', value)
            }
        }
    }
</script>

<style scoped>

    .tag-search {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition-duration: 0.5s;
        float: right;
    }

    .tag-search .search-results {
    }

    .tag-search.hidden {
        width: 0;
        overflow: hidden;
    }

    .tag-search .back-button {
        height: 1.8em;
        margin-top: 1px;
        margin-right: 0.5em;
        filter: brightness(50%);
        cursor: pointer;
    }

    .tag-search .back-button:hover,
    .tag-search .back-button:focus {
        background: none;
        border: none;
    }

    .tag-search .tag-search-field {
        border-radius: 1.2rem;
        border: none;
        height: 100%;
        background-color:#ffffff !important;
        opacity: 1.0;
        transition: 0.6s;
    }

    .tag-search.hidden .tag-search-field {
        opacity: 0.0;
    }

    .tag-search .tag-search-field.filled {
        box-shadow: none;
        border-color: white;
        background: linear-gradient(120deg, rgba(253, 120, 78, 1) 0%, rgba(253, 75, 95, 1) 100%);
    }
</style>
