import Vuex from 'vuex'
import StoreFactory_UserDuels from "@/store/factories/StoreFactory_UserDuels";
import StoreFactory_UserFollowers from "@/store/factories/StoreFactory_UserFollowers";
import StoreFactory_Maps from "@/store/factories/StoreFactory_Maps";

export default {

    init(server) {

        const dynamicStore = new Vuex.Store({});

        StoreFactory_UserDuels.init(dynamicStore, server.duels);

        StoreFactory_UserFollowers.init(dynamicStore, server.users);

        StoreFactory_Maps.init(dynamicStore, server.users, server.posts);

        // ... init any other factories here
    }
}
