<!-- Layout for defining lazy loading feeds. -->

<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Layout_LazyLoadFeed",
        props : {
            loadMarginPx : Number,          // The distance from the bottom at which we start loading more elements
            lastPageReached : Boolean,
            enableLoading : Boolean
        },
        mounted() {
            window.addEventListener('scroll', this._onScroll, false);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this._onScroll, false);
        },
        methods : {
            _onScroll() {
                if(!this.enableLoading || this.lastPageReached) return;

                const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
                const maxScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;

                if(maxScroll - currentScroll < this.loadMarginPx) {
                    this.$emit("loadMore");
                }
            }
        }
    }
</script>
