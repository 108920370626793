<template>
    <Layout_UserDetailsSection class="user-section-influencer-stats" :info-text="$t('users.hint-influencer-power')">
        <div class="user-details-section-duels">
            <UserDetails_Header_Small> {{ $t("users.influencer-stats") }}: </UserDetails_Header_Small>
            <UserDetails_Text_Small class="ml-4">{{ user.reactionPoints }} {{ $t("users.reaction-points") }}</UserDetails_Text_Small>
        </div>
    </Layout_UserDetailsSection>
</template>

<script>
    import Layout_UserDetailsSection from "@/ui/layouts/Layout_UserDetailsSection";
    import User from "@/server/structures/User";
    import UserDetails_Header_Small from "@/ui/components/users/details/UserDetails_Header_Small";
    import UserDetails_Text_Small from "@/ui/components/users/details/UserDetails_Text_Small";
    export default {
        name: "UserDetails_Section_InfluencerStats",
        components: { UserDetails_Text_Small, UserDetails_Header_Small, Layout_UserDetailsSection },
        props: { user: User }
    }
</script>

<style scoped>

    .user-section-influencer-stats {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        flex-wrap: wrap;
    }
    .user-details-section-duels {
        display: flex;
        justify-content: space-between;
    }

</style>
