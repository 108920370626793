<template>
    <Layout_AuthSidebar :title="$t('auth.title-registration')" nav-id="sidebar-register-email-sent" :hide-legal-disclaimer="true">
        <template #default="{ hide }">

            <Auth_Text class="my-5">
                {{ $t("auth.registration-email-sent") }}
            </Auth_Text>

            <Auth_Button_Black class="my-3" @click.native="close">
                {{ $t("auth.continue") }}
            </Auth_Button_Black>

            <button hidden ref="hide" @click="hide"></button>
        </template>
    </Layout_AuthSidebar>
</template>

<script>

    import Layout_AuthSidebar from "@/ui/layouts/Layout_AuthSidebar";
    import Auth_Button_Black from "@/ui/components/auth/Auth_Button_Black";
    import Auth_Text from "@/ui/components/auth/Auth_Text";

    export default {
        name: "Auth_Screen_RegistrationEmailSent",
        components: { Auth_Text, Layout_AuthSidebar, Auth_Button_Black },
        methods: {
            close() {
                this.$refs["hide"].click();
            }
        }
    }
</script>

<style scoped>

    .thank-you-message {
        font-size: 15pt;
    }
</style>
