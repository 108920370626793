<template>
    <Panel_White class="leaderboard-entry">
        <slot/>
    </Panel_White>
</template>

<script>
import Panel_White from "@/ui/components/common/Panel_White";
export default {
    name: "Layout_Leaderboard_Entry",
    components: { Panel_White }
}
</script>

<style>

    .leaderboard-entry {
        height: 75px;
        margin-bottom: 2px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .leaderboard-entry.highlighted {
        background-color: #ffe0aa;
    }

</style>
