export default {
    pad: pad
};

/** Add leading zeroes to the number up to the given digit count. Returns a string. */
function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}
