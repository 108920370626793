<template>
    <div class="duel-user-icons-row">
        <Duel_Header_UserIcons :user="duel.challenger" :isChallenger="true">
            <slot name="challengerIcon"> </slot>
        </Duel_Header_UserIcons>
        <Duel_Header_UserIconsText :duel="duel">
            <slot name="iconRowText"> </slot>
        </Duel_Header_UserIconsText>
        <Duel_Header_UserIcons :user="duel.challenged">
            <slot name="challengedIcon"> </slot>
        </Duel_Header_UserIcons>
    </div>
</template>

<script>

    import Duel from "@/server/structures/Duel";
    import Duel_Header_UserIcons from "@/ui/components/duels/Duel_Header_UserIcons";
    import Duel_Header_UserIconsText from "@/ui/components/duels/Duel_Header_UserIconsText";

    export default {
        name: "Duel_Header_UserIconsRow",
        components: { Duel_Header_UserIcons, Duel_Header_UserIconsText },
        props: { 
            duel: Duel
        }
    }
</script>

<style>
    .duel-user-icons-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .duel-user-icons-row .duel-user-icons {
        flex-basis: 30%;
    }
    .duel-user-icons-row .duel-user-icons-text {
        flex-basis: 40%;
        text-align: center;
    }
</style>