<template>
    <Layout_Master>

        <template v-if="userFound" v-slot:sticky-header>
            <Users_SubmenuNav :user-slug="userSlug"/>
        </template>

        <main class="content">
            <template v-if="loading">
                <Spinner class="orange center-absolute"/>
            </template>
            <template v-else-if="userFound">
                <router-view v-if="user" :user="user"></router-view>
            </template>
            <template v-else>
                <Text_NoResults>{{ $t("errors.user-not-found") }}</Text_NoResults>
            </template>
        </main>
    </Layout_Master>
</template>

<script>

    import Layout_Master from "@/ui/layouts/Layout_Master";
    import Users_SubmenuNav from "@/ui/components/users/Users_SubmenuNav";
    import {mapActions, mapGetters} from "vuex";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import EventBus from "@/events/EventBus";
    import Events from "@/events/Events";
    import Getters from "@/store/Getters";
    import Text_NoResults from "@/ui/components/common/Text_NoResults";
    import Spinner from "@/ui/components/common/Spinner";

    export default {
        name: "UserView",
        components : { Spinner, Text_NoResults, Users_SubmenuNav, Layout_Master },
        props: {
            userSlug: String // Passed by router
        },
        data() {
            return { loading : false }
        },
        async mounted() {

            this.updateUser();

            EventBus.$on(Events.LoginSuccessful, this.updateUser);
        },
        beforeDestroy() {

            EventBus.$off(Events.LoginSuccessful, this.updateUser);
        },
        watch: {
            userSlug() {
                this.updateUser();
            }
        },
        computed : {
            userFound() {
                return !this.loading && this.user;
            },
            user() {
                return this.getUserBySlug(this.userSlug);
            },
            ...mapGetters(Modules.USERS, {
                getUserBySlug: Getters.Users.GET_BY_SLUG
            })
        },
        methods: {
            async updateUser() {
                this.loading = true;

                await this.pullUser(this.userSlug);

                this.loading = false;
            },
            ...mapActions(Modules.USERS, {
                pullUser: Actions.Users.PULL
            })
        }
    }
</script>

<style scoped>

    .content {
        padding: 119px 0 0;
        min-height: 100vh;
    }
</style>
