<template>
    <div class="duel-middle-section">
        <div class="duel-middle-left-line" />
        <Button_Gradient v-if="duel.isAccepted" class="duel-middle-button" @click.native="$emit('open')">
            {{ duel.voted ? $t('duels.voted') : $t('duels.play') }}
        </Button_Gradient>
        <Button_Default v-else-if="duel.isFinished" class="duel-middle-button" @click.native="$emit('open')">
            {{ $t('duels.view') }}
        </Button_Default>
        <div class="duel-middle-right-line" />
    </div>
</template>

<script>
    import Duel from "@/server/structures/Duel";
    import Button_Default from "@/ui/components/common/Button_Default";
    import Button_Gradient from "@/ui/components/common/Button_Gradient"

    export default {
        name: "Duel_Button_Open",
        components: { Button_Gradient, Button_Default },
        props: {
            duel: Duel
        },
    }
</script>

<style>
    .duel-middle-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: -3em;
        z-index: 200;
        position: relative;
    }
    .duel-middle-button{
        width: 20%;
    }
    .duel-middle-left-line,
    .duel-middle-right-line {
        border-bottom: 1px solid rgba(198, 198, 198, 0.8);
        width: 40%;
        transform: translate(0, -50%);
    }
</style>
