<template>
    <b-button-group class="share-modal-options">

        <Share_Option_Copy
                :url="url"
                v-on:copy="onCopy"
                v-on:copyError="onError"
        />

        <Share_Option_Network v-for="network in networks"
                :key="network.network"
                :network="network"
                :url="url"
                :title="getTitle"
                :tags="getTags"
                v-on:networkSelected="onNetworkSelected"
        />
    </b-button-group>
</template>

<script>

    import Share_Option_Copy from "@/ui/components/feed/sharing/Share_Option_Copy";
    import Share_Option_Network from "@/ui/components/feed/sharing/Share_Option_Network";

    export default {
        name: "Post_Modal_ShareOptions",
        components: { Share_Option_Network, Share_Option_Copy },
        props: {
            post: Object,
            title: String,
            tags : Array,
            url: String
        },
        data() {
            return {
                networks: [
                    {network: "facebook", text: "Facebook", icon: this.$icons.SocialMedia.Facebook, alt: "Facebook Image"},
                    {network: "twitter", text: "Twitter", icon: this.$icons.SocialMedia.Twitter, alt: "Twitter Image"},
                    {network: "viber", text: "Viber", icon: this.$icons.SocialMedia.Viber, alt: "Viber Image"},
                    {network: "skype", text: "Skype", icon: this.$icons.SocialMedia.Skype, alt: "Skype Image"},
                    {network: 'vk', text: 'Vk', icon: this.$icons.SocialMedia.VK, alt: "VKontakte Image"}
                ]
            }
        },
        computed: {
            getTags() {
                return this.tags === undefined ? null : this.tags.map(tag => tag.name).join(",");
            },
            getTitle() {
                return this.title === undefined ? '' : this.title;
            }
        },
        methods: {
            onCopy() {
                this.$emit('copy');
            },
            onError() {
                this.$emit('copyError');
            },
            onNetworkSelected() {
                this.$emit('networkSelected')
            }
        }
    }
</script>

<style scoped>
    .share-modal-options {
        display: grid;
        grid-template-columns: repeat(4, 25% [col-start]);
        margin-top: 20px;
        justify-items: center;
    }

</style>
