import {registerAndGetStore} from "vuex-stores";
import Module_User_Followers from "@/store/dynamic_modules/Module_User_Followers";
import Module_User_Followings from "@/store/dynamic_modules/Module_User_Followings";

export default class StoreFactory_UserFollowers {

    static _store;
    static _usersApi;

    static init(store, usersApi) {
        this._store = store;
        this._usersApi = usersApi;
    }

    static createForUserFollowings(userSlug) {

        return registerAndGetStore(this._store,
            {
                namespace: `userFollowings_${userSlug}`,
                ...Module_User_Followings(userSlug, this._usersApi)
            }
        );
    }

    static createForUserFollowers(userSlug) {

        return registerAndGetStore(this._store,
            {
                namespace: `userFollowers_${userSlug}`,
                ...Module_User_Followers(userSlug, this._usersApi)
            }
        );
    }
}
