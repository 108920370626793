<template>
    <span class="auth-link mr-2">
        <u>
            <slot></slot>
        </u>
    </span>

</template>

<script>
    export default {
        name: "Auth_Link"
    }
</script>
