import AbstractMethodError from "@/errors/AbstractMethodError";

export default class APostCreationData {

    _tags;

    get requestParams() {
        throw new AbstractMethodError("requestParams");
    }

    get requestHeaders() {
        throw new AbstractMethodError("requestHeaders");
    }

    get postType() {
        throw new AbstractMethodError("requestHeaders");
    }

    setTags(tags) {
        this._tags = tags;
    }

    // eslint-disable-next-line no-unused-vars
    applyToPost(post) {
        post.tags = this._tags;
    }
}
