import APostCreationData from "@/server/post_creation/APostCreationData";
import PostType from "@/server/types/PostType";


export default class PostCreationData_Text extends APostCreationData {

    _text;

    get requestParams() {
        return {
            text : this._text,
            tags : this._tags
        };
    }

    get requestHeaders() {
        return undefined;
    }

    get postType() {
        return PostType.Text;
    }

    constructor(text) {
        super();
        this._text = text;
    }

    applyToPost(post) {
        super.applyToPost(post);
        post.fullText = this._text;
    }
}
