<template>
    <div v-if="comments">
        <Comment v-for="comment in comments" :key="comment.id" :comment="comment"/>
    </div>
</template>

<script>
    import Comment from "@/ui/components/feed/comments/Comment";
    export default {
        name: "Comment_List",
        components: { Comment },
        props: { comments: Array }
    }
</script>
