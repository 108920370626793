<!-- Component that displays a list of notifications. -->
<template>
    <div class="w-100">
        <Notifications_Component v-for="(notification, index) in notifications"
            :key="index"
            :notification="notification"
        />
    </div>
</template>

<script>
import Notifications_Component from "@/ui/components/notifications/Notifications_Component";
export default {
    name: "Notifications_List",
    components: { Notifications_Component },
    props: {
        notifications: Array
    }
}
</script>

<style scoped>

</style>
