<template>
    <div class="challenge-text">
        <template v-if="isChallenger">
            <p class="challenge-text-header"> {{ $t("duels.challenge-challenger-explanation-header") }} </p>
        </template>
        <template v-else>
            <p class="challenge-text-header"> {{ $t("duels.challenge-challenged-explanation-header") }} </p>
            <p class="challenge-text-content"> {{ $t("duels.challenge-challenged-explanation") }} </p>
        </template>
     </div>
</template>

<script>
    export default {
        name: "DuelChallenge_Section_Explanation",
        props : {
            isChallenger : Boolean
        }
    }
</script>

<style scoped>
    .challenge-text {
        color: #7c858b;
        border-bottom: 1px solid rgba(198, 198, 198, 0.8);
        margin-bottom: 1em;
    }
    .challenge-text .challenge-text-header {
        font-size: 20px;
    }
</style>
