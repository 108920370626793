<!-- Holds the title and text of a joke post. -->

<template>
    <div class="post-content-text">
        <h4 class="post-joke-title">{{ post.header }}</h4>

        <p class="post-joke-text">{{ post.text }}</p>
    </div>
</template>

<script>
    import Post from "@/server/structures/Post";

    export default {
        name: "Post_Section_Text",
        props: { post: Post }
    }
</script>

<style>
    .post-joke-title {
        font-size: 16pt;
        font-weight: 700;
        margin-bottom: 0.2em;
    }
    .post-joke-text {
        font-size: 1em;
        line-height: 1.5;
        white-space: pre-wrap;
        margin-bottom: 1rem;
    }
</style>
