<template>
    <div class="leaderboard-list">
        <Leaderboard_Entry v-for="entry in entries"
                           :key="entry.position"
                           :data="entry"
                           :displayType="displayType"
                           :highlighted="isMe(entry.slug)"/>
    </div>
</template>

<script>
    import Leaderboard_Entry from "@/ui/components/leaderboard/Leaderboard_Entry";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import {mapGetters} from "vuex";

    export default {
        name: "Leaderboard_List",
        components: { Leaderboard_Entry },
        props: { entries: Array, displayType: String },
        computed: {
            ...mapGetters(Modules.USERS, {
                isMe : Getters.Users.IS_ME
            })
        }
    }
</script>
