<template>
    <router-link class="user-name" :to="{ path: `/u/${user.slug}`}" @click.native="clearTags()" tag="span">
        {{ this.user.name }}
    </router-link>
</template>

<script>

    import User from "@/server/structures/User";
    import StoreUtils from "@/utils/StoreUtils";

    export default {
        name: "Post_Text_Username",
        props: {
            user: User,
        },
        methods: {
            clearTags() {
                StoreUtils.clearTags();
            }
        }
    }
</script>

<style scoped>

    .user-name {
        font-size: 0.88em;
        font-weight: 700;
        line-height: 2;
        width: 100%; 
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .user-name:hover {
        cursor: pointer;
    }
</style>
