<template>
    <ul class="info-list-tick">
        <slot></slot>
    </ul>
</template>

<script>
    export default {
        name: "Info_ListTick"
    }
</script>

<style>
    .info-list-tick {
        padding-inline-start: 20px; 
        list-style: none;
    }
    .info-list-tick li:before {
        content: '✓';
        margin-right: 0.2rem;
    }
</style>