import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
    breakpoints: {
        'mobile-s': 350,
        'mobile-m': 400,
        mobile: 575,
        tablet: 800,
        desktop: Infinity,
    },
});
