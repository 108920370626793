
export default class DuelResults {

    _data;

    get votesCount() { return this._data.votes_count; }

    get challengerVotesCount() { return this._data.challenger_votes_count; }

    get challengerVotesPercentage() { return this._data.challenger_votes_percentage; }

    get challengedVotesCount() { return this._data.challenged_votes_count; }

    get challengedVotesPercentage() { return this._data.challenged_votes_percentage; }

    constructor(data) {
        this._data = data;
    }
}
