<template>
    <p class="auth-text">
        <slot/>
    </p>
</template>

<script>
    export default {
        name: "Auth_Text",
    }
</script>

<style scoped>
    .auth-text {
        color: white;
        font-size: 16pt;
    }
</style>
