<!-- The submenu_nav that appears in some views. Example : the New, Hot, Short menu in the main feed. -->

<template>
    <nav class="left-side-nav background-color-gray">
        <b-nav class="justify-content-center">
            <SubmenuNav_Button v-for="submenu in visibleSubmenus"
                               :key="submenu.name"
                               :submenu="submenu"
                               :selected="isSelected(submenu.name)"
                               :url="generateUrl(submenu.name)"
                               v-on:selected-clicked="selectedClicked">
            </SubmenuNav_Button>
        </b-nav>
    </nav>
</template>

<script>
    import SubmenuNav_Button from "@/ui/components/submenu_nav/SubmenuNav_Button";

    export default {
        name: "SubmenuNav",
        components: { SubmenuNav_Button },
        props : {
            submenus: Array,
            generateUrl: Function
        },
        computed : {
            visibleSubmenus() {
                return this.submenus.filter((submenu) => {

                    if(submenu.renderCondition === undefined) { return true; }

                    if(submenu.renderCondition instanceof Function) { return submenu.renderCondition(); }

                    return !!submenu.renderCondition;
                });
            }
        },
        methods : {
            isSelected(submenu) {
                return this.$route.fullPath.includes(this.generateUrl(submenu));
            },
            selectedClicked(type) {
                this.$emit('selected-clicked', type)
            },
        }
    }
</script>

<style scoped>

    .left-side-nav {
        width: 100%;
    }
    .left-side-nav ul {
        width: 100%;
        display:flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-around;
    }
</style>
