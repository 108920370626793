<template>
    <Panel_Folding
        border-variant="light"
        class="duel-post-waiting background-color-white border-rounded"
        folded-max-height="280px"
        :is-closed="false"
    >
        <Post_Section_Header :user="duel.challenger" :timestamp="duel.createdAt" :view-count="duel.challengerPost.viewCount"/>

        <Post_Section_Content :post="duel.challengerPost" v-observe-visibility="visibilityChanged"/>
        
        <div class="button-container border-top">
            <Button_Share class="share-button" @click.native="onShareClick"/>
        </div>
    </Panel_Folding>
</template>

<script>
    import Duel from "@/server/structures/Duel";
    import Post_Section_Header from "@/ui/components/feed/post/Post_Section_Header";
    import Panel_Folding from "@/ui/components/common/Panel_Folding";
    import {mapActions} from "vuex";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import Post_Section_Content from "@/ui/components/feed/post/Post_Section_Content";
    import Button_Share from "@/ui/components/common/Button_Share";
    import RouterUtils from "@/routes/RouterUtils";

    const ViewTrackTimeMs = 2900;   // How long the post has to stay visible to be counted as a view.

    export default {
        name: "Duel_PostWaiting",
        components: { Post_Section_Content, Post_Section_Header, Panel_Folding , Button_Share },
        props: {
            duel: Duel,
            disableTrackViews: Boolean,
        },
        data() {
            return {
                viewTimeout: null
            }
        },
        methods: {
            visibilityChanged(isVisible) {
                if(this.disableTrackViews || this.isClosed) return;

                if(isVisible) {
                    this.viewTimeout = setTimeout(this.addView, ViewTrackTimeMs);
                }
                else {
                    clearTimeout(this.viewTimeout);
                }
            },
            async onShareClick() {
                RouterUtils.goToDuelChallenge(this.duel.code);
            },
            async addView() {
                this.addViewForPost(this.duel.challengerPost.id);
            },
            ...mapActions(Modules.POSTS, {
                addViewForPost : Actions.Posts.VIEW,
            }),
            ...mapActions(Modules.DUEL_ACTIONS, {
                getShareLink : Actions.Duels.SHARE
            })
        }
    }
</script>

<style>
    .duel-post-waiting {
        margin-bottom: 0.6em;
        padding-bottom: 0;
    }

    .duel-post-waiting .button-container {
        padding-top: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
