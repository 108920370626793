import firebase from "firebase/app";
import "firebase/messaging";
import FirebaseConfig from "@/notifications/FirebaseConfig";
import INotificationsManager from "@/notifications/INotificationsManager";

export default class WebNotificationsManager extends INotificationsManager {

    constructor() {
        super();

        firebase.initializeApp(FirebaseConfig);
    }

    async getToken() {

            return firebase.messaging().getToken().catch(function (){
                return null;
            });

    }

    onMessage(callback) {

            return firebase.messaging().onMessage(callback);
    }
}
