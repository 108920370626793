<template>
    <div class="page-section-header separator-bottom">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "Layout_Section_PageHeader"
}
</script>

<style>

    .page-section-header {
        padding: 1.2em 1.1em 0.5em;
        margin-bottom: 5px;
        position: relative;
    }
</style>
