import ApiResponse from "@/server/responses/ApiResponse";


export default class ApiResponse_GetLocalization extends ApiResponse {

    get language() {
        return this._rawResponse.data.lang.key;
    }

    get region() {
        return this._rawResponse.data.region.key;
    }
}
