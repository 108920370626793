    <template>
    <Layout_UserDetailsSection class="profile-section-header" :show-help-button="false">
        <Avatar_Large :src="user.avatarUrl"/>

        <div class="right-side">
            <h4 class="heading-name">{{ user.name }}</h4>

            <div class="d-flex flex-column">
                <UserDetails_Button_Fans class="mb-2" @click.native="goToFollowers" :text="$t('users.fans')" :value="user.fanCount"/>
                <UserDetails_Button_Fans @click.native="goToFollowings" :text="$t('users.fanOf')" :value="user.fanOfCount"/>
                <UserDetails_Button_Follow class="mt-2" :user="user"/>
            </div>

            <p class="description">{{ user.description }}</p>
        </div>

    </Layout_UserDetailsSection>
</template>

<script>
    import User from "@/server/structures/User";
    import Avatar_Large from "@/ui/components/common/Avatar_Large";
    import Layout_UserDetailsSection from "@/ui/layouts/Layout_UserDetailsSection";
    import UserDetails_Button_Follow from "@/ui/components/users/details/UserDetails_Button_Follow";
    import UserDetails_Button_Fans from "@/ui/components/users/details/UserDetail_Button_Fans";
    import RouterUtils from "@/routes/RouterUtils";

    export default {
        name: "UserDetails_Section_Header",
        components: { UserDetails_Button_Fans, UserDetails_Button_Follow, Layout_UserDetailsSection, Avatar_Large },
        props: { user: User },
        methods: {
            goToFollowers() { RouterUtils.goToUserFollowers(this.user.slug); },
            goToFollowings() { RouterUtils.goToUserFollowings(this.user.slug); }
        }
    }
</script>

<style scoped>

    .profile-section-header {
        display: flex;
        position: relative;
        flex-direction: row;
        padding-bottom: 1.2em;
        width: 100%;
    }
    .profile-section-header .right-side {
        display: flex;
        flex-grow: 2;
        flex-direction: column;
        margin-left: 15px;
    }
    .profile-section-header .heading-name {
        margin-bottom: 12px;
        color: #2c2c2c;
    }
    .profile-section-header .description {
        overflow-wrap: break-spaces;
        word-wrap: break-word;
        white-space: normal;
        margin-top: 1.4em;
    }
</style>
