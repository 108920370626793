import BadgeSubcategory from "@/server/structures/BadgeSubcategory";

export default class BadgeCategory {

    _data;

    /** @returns {string} */
    get name() {
        return this._data.name;
    }

    /** @returns {string} */
    get description() {
        return this._data.description;
    }

    /** @returns {BadgeSubcategory[]} */
    get subcategories() {
        return this._data.sub_categories.map((rawSubcategory) => {
            return new BadgeSubcategory(rawSubcategory);
        });
    }

    constructor(data) {
        this._data = data;
    }
}
