<template>
    <b-form-input
            :value="value"
            @input="updateValue"
            @focus="onFocus"
            :placeholder="placeholder"
            class="input-field"
            autocomplete="on"
            :disabled="disabled"
    >
    </b-form-input>
</template>

<script>
    export default {
        name: "Form_InputField",
        props : {
            value: String,
            placeholder: String,
            debounce : [String, Number],
            disabled : Boolean,
            autoBlur : Boolean
        },
        data() {
            return {
                timeoutId: null
            }
        },
        methods: {
            onFocus() {
                if(this.autoBlur) {
                    this.blur();
                }
            },
            updateValue(value) {
                if(!this.debounce) {
                    this.doneTyping(value);
                }
                else {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = setTimeout(() => this.doneTyping(value), this.debounce);
                }
            },
            doneTyping(value) {
                this.timeoutId = null;
                this.$emit('input', value)
            },
            blur() {
                this.$el.blur();
            }
        }
    }
</script>

<style scoped>
    .input-field {
        border-radius: 1.2rem;
        border: none;
        height: 2.4rem;
    }

    .input-field.gray {
        background-color: #ededed;
    }

    .input-field.black {
        background-color: black;
    }

</style>
