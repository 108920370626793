<!-- Template for horizontal bars that you can scroll via arrows. -->

<template>
    <div class="scroll-arrow-layout">
        <div class="scroll-arrow-layout-content" :class="$mq" ref="content">
            <div class="scroll-arrow left" :hidden="leftArrowHidden">
                <img :src="$icons.Common.ArrowLeft" alt="arrow left" @click="scrollLeft"/>
            </div>

            <slot></slot>

            <div class="scroll-arrow right" :hidden="rightArrowHidden">
                <b-img :src="$icons.Common.ArrowRight" alt="arrow right" @click="scrollRight"></b-img>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/events/EventBus";
    import Events from "@/events/Events";

    const ArrowMargin = 4;

    export default {
        name: "Layout_ArrowScroll",
        props : {
            scrollStep : { type: Number, default: 120 }
        },
        data() {
            return {
                leftArrowHidden : false,
                rightArrowHidden : false
            }
        },
        mounted() {
            setTimeout(this.updateArrowVisibility, 100);

            EventBus.$on(Events.TagSelected, this.scrollToBegining);
            this.content.addEventListener('scroll', this.onScroll);
        },
        beforeDestroy () {
            EventBus.$off(Events.TagSelected, this.scrollToBegining);
            this.content.removeEventListener('scroll', this.onScroll)
        },
        computed : {
            content() {
                return this.$refs.content;
            }
        },
        methods: {
            onScroll() {
                this.updateArrowVisibility();
            },
            updateArrowVisibility() {
                this.leftArrowHidden = this.content.scrollLeft <= ArrowMargin;
                this.rightArrowHidden = this.content.scrollLeft >= this.content.scrollWidth - this.content.clientWidth - ArrowMargin;
            },
            scrollLeft() {
                this.content.scrollBy({  left: -this.scrollStep,  behavior: 'smooth' });
            },
            scrollRight() {
                this.content.scrollBy({  left: this.scrollStep,  behavior: 'smooth' });
            },
            scrollToBegining() {
                this.content.scrollTo({ left: 0, behavior: 'smooth'});
            }
        }
    }
</script>


<style>

    .scroll-arrow-layout {
        position: relative;
        overflow: hidden;
    }

    .scroll-arrow-layout-content {
        white-space: nowrap;
        overflow: auto;
    }

    .scroll-arrow-layout-content.desktop {
        overflow: hidden;
    }

    .scroll-arrow-layout .scroll-arrow {
        position: absolute;
        top: 0;
        padding-top: 0;
        height: 100%;
        width: 2.2em;
        white-space: nowrap;
        cursor: pointer;
        z-index: 100;
    }

    .scroll-arrow-layout .scroll-arrow img {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 1.6em;
    }

    .scroll-arrow-layout.gray .scroll-arrow.left {
        background: rgb(237,237,237);
        background: linear-gradient(90deg, rgba(237,237,237,1) 0%, rgba(237,237,237,1) 70%, rgba(237,237,237,0) 100%);
    }
    .scroll-arrow-layout.gray .scroll-arrow.right {
        background: rgb(237,237,237);
        background: linear-gradient(90deg, rgba(237,237,237,0) 0%, rgba(237,237,237,1) 30%, rgba(237,237,237,1) 100%);
    }
    .scroll-arrow-layout.white .scroll-arrow.left {
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);
    }
    .scroll-arrow-layout.white .scroll-arrow.right {
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%);
    }


    .scroll-arrow-layout .scroll-arrow.left { left: -5px; }
    .scroll-arrow-layout .scroll-arrow.left img { float: left; }

    .scroll-arrow-layout .scroll-arrow.right { right: -5px; }
    .scroll-arrow-layout .scroll-arrow.right img { float: right; }
</style>
