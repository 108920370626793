import ApiResponse_GetPosts from "@/server/responses/ApiResponse_GetPosts";
import ApiResponse_GetPost from "@/server/responses/ApiResponse_GetPost";
import FeedTypes from "@/store/types/FeedTypes";
import ApiProxy from "@/server/proxies/ApiProxy";
import ContentTypes from "@/store/types/ContentTypes";
import PostType from "@/server/types/PostType";
import LengthType from "@/server/types/LengthType";
import ApiResponse from "@/server/responses/ApiResponse";
import ApiResponse_GetShareLink from "@/server/responses/ApiResponse_GetShareLink";
import ApiResponse_CreatePost from "@/server/responses/ApiResponse_CreatePost";
import ApiResponse_GetMap from "@/server/responses/ApiResponse_GetMap";

const ContentTypeToPostType = {
    [ContentTypes.IMAGES] : PostType.Image,
    [ContentTypes.JOKES]  : PostType.Text,
    [ContentTypes.ALL]    : null
};

export default class ApiProxy_Posts extends ApiProxy {

    async viewPost(id) {
        return new ApiResponse(await this.put(`/${id}/view`));
    }

    async setLiked(id, value) {
        return new ApiResponse(await this.put(value ? `/${id}/like` : `/${id}/like/remove`));
    }

    async setDisliked(id, value) {
        return new ApiResponse(await this.put(value ? `/${id}/dislike` : `/${id}/dislike/remove`));
    }

    async setFavorited(id, value) {
        return new ApiResponse(await this.put(value ? `/${id}/favorite` : `/${id}/favorite/remove`));
    }

    async reportPost(id, text) {
        return new ApiResponse(await this.post(`/${id}/report`, { text }));
    }

    async getShareLink(id) {
        return new ApiResponse_GetShareLink(await this.post(`/${id}/share`));
    }

    /**
     * @param {APostCreationData} creationData
     * @returns {Promise<ApiResponse_CreatePost>}
     */
    async createPost(creationData) {

        const rawResponse = await this.post('/', creationData.requestParams, creationData.requestHeaders);

        return new ApiResponse_CreatePost(rawResponse, creationData.postType);
    }

    /**
     * @param {number} id
     * @param {APostCreationData} editData
     * @returns {Promise<ApiResponse>}
     */
    async editPost(id, editData) {

        const rawResponse = await this.post(`/${id}`, editData.requestParams, editData.requestHeaders);

        return new ApiResponse(rawResponse);
    }

    /**
     * @param {Number} userId
     * @param {Number} limit
     * @returns {Promise<ApiResponse_GetPosts>}
     */
    async getBestForUser(userId, limit) {

        const rawResponse = await this.get('/', {
            params : {
                user_id: userId,
                per_page : limit,
                order: 'likes'
            }
        });

        return new ApiResponse_GetPosts(rawResponse)
    }

    /**
     * @param {Number} userId
     * @param {Number} limit
     * @returns {Promise<ApiResponse_GetPosts>}
     */
    async getNewestForUser(userId, limit) {

        const rawResponse = await this.get('/', {
            params : {
                user_id: userId,
                per_page: limit
            }
        });

        return new ApiResponse_GetPosts(rawResponse);
    }

    async getByShareCode(shareCode) {
        const rawResponse = await this.get(`/${shareCode}/by-share`);

        return new ApiResponse_GetPost(rawResponse)
    }

    async getPosts(page, tags, contentType, feedType) {

        const rawResponse = await this.get(this._generateUrl(feedType), {
                params: {
                    tags : tags.map((tag) => tag.name),
                    page : page,
                    length_type : this._generateLengthTypeParam(feedType),
                    type: this._generateTypeParam(contentType)
                }
        });

        return new ApiResponse_GetPosts(rawResponse);
    }

    async getFavorites(page) {
        const rawResponse = await this.get('/favorites', {
            params: { page : page }
        });

        return new ApiResponse_GetPosts(rawResponse);
    }

    /**
     * Retrieves the map data for the specified post.
     * @param {string} id The id of the post.
     * @returns {Promise<ApiResponse_GetMap>}
     */
    async getMap(id) {
        return new ApiResponse_GetMap(await this.get(`/${id}/map`));
    }

    _generateUrl(feedType) {
        if(feedType === FeedTypes.HOT) { return '/hot'; }
        else if(feedType === FeedTypes.NETWORK) { return '/networking'; }
        return '/';
    }

    _generateTypeParam(contentType) {
        return ContentTypeToPostType[contentType];
    }

    _generateLengthTypeParam(feedType) {
        return feedType === FeedTypes.SHORT ? LengthType.Short : null;
    }
}
