<template>
    <Layout_LazyLoadFeed
        :load-margin-px="500"
        :last-page-reached="lastPageReached"
        :enable-loading="statusIsReady"
        v-on:loadMore="loadMorePosts"
    >
        <Post_Component v-for="post in posts" :key="post.id" :post="post"/>

        <template v-if="statusIsLoading">
            <Post_List_Loading :count="10"/>
        </template>

        <Text_NoResults v-if="noResultsFound">
            {{ $t("posts.no-posts-found-favorites") }}
        </Text_NoResults>
    </Layout_LazyLoadFeed>
</template>

<script>
    import Modules from "@/store/Modules";
    import {mapActions, mapGetters} from "vuex";
    import Layout_LazyLoadFeed from "@/ui/layouts/Layout_LazyLoadFeed";
    import Actions from "@/store/Actions";
    import Getters from "@/store/Getters";
    import Post_Component from "@/ui/components/feed/post/Post_Component";
    import Text_NoResults from "@/ui/components/common/Text_NoResults";
    import WindowEx from "@/utils/WindowEx";
    import Post_List_Loading from "@/ui/components/feed/post/Post_List_Loading";

    export default {
        name: "Favorites_PostList",
        components: { Post_List_Loading, Text_NoResults, Post_Component, Layout_LazyLoadFeed },
        mounted() {
            WindowEx.scrollToTop();
            this.reload();
        },
        computed : {
            noResultsFound() {
                return this.statusIsReady && this.posts && this.posts.length === 0;
            },
            ...mapGetters(Modules.FAVORITES, {
                lastPageReached: Getters.Collection.GET_LAST_PAGE_REACHED,
                statusIsReady:   Getters.Collection.STATUS_IS_READY,
                statusIsLoading: Getters.Collection.STATUS_IS_LOADING,
                posts:           Getters.Collection.GET_ENTRIES
            })
        },
        methods : {
            ...mapActions(Modules.FAVORITES, {
                reload: Actions.Collection.RELOAD,
                loadMorePosts: Actions.Collection.LOAD_MORE
            })
        }
    }
</script>

<style scoped>

</style>
