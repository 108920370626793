<template>
    <div class="custom-formgroup" role="group">
        <label :for="inputId">{{ label }}</label>
        <b-form-input
                :id="inputId"
                :value="value"
                @input="updateValue"
                :type="type"
                :name="inputName"
                placeholder=""
                class="auth-form-input"
                :autocomplete="autocomplete"
                :required="required"
        >
        </b-form-input>
    </div>
</template>

<script>
    export default {
        name: "Auth_Form_InputField",
        props: {
            label: String,
            type: {type: String, required: true},
            value: String,
            inputId: String,
            inputName: String,
            autocomplete: {type: String, default: "on"},
            required: Boolean
        },
        methods: {
            updateValue(value) {
                this.$emit('input', value)
            }
        }
    }
</script>

<style scoped>

    .custom-formgroup {
        position: relative;
        margin: 0 auto 0.7rem auto;
        color: white;
        width: 100%;
        max-width: 25em;
    }

    .auth-form-input {
        border-radius: 1.2rem;
        border: none;
        line-height: 1em;
        height: 2.5em;
    }

    .auth-form-input:focus {
        box-shadow: none;
        border-color: white;
    }

</style>
