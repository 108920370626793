<template>
    <Layout_Master>
        <main class="content">

            <Leaderboard_Section_Header/>

            <Leaderboard_Section_Category/>

            <Leaderboard_List v-if="resultsAreReady" :entries="entries" :displayType="displayType"/>
            <Spinner v-else class="orange center-absolute"/>
        </main>
    </Layout_Master>
</template>

<script>
    import CollectionStatus from "@/store/types/CollectionStatus";
    import Layout_Master from "@/ui/layouts/Layout_Master";
    import Leaderboard_List from "@/ui/components/leaderboard/Leaderboard_List";
    import Leaderboard_Section_Header from "@/ui/components/leaderboard/Leaderboard_Section_Header";
    import Leaderboard_Section_Category from "@/ui/components/leaderboard/Leaderboard_Section_Category";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import {mapActions, mapGetters} from "vuex";
    import Actions from "@/store/Actions";
    import Spinner from "@/ui/components/common/Spinner";
    import LeaderboardDisplayTypes from "@/ui/types/LeaderboardDisplayTypes";

    export default {
        name: "View_Leaderboard",
        components: { Spinner, Leaderboard_Section_Category, Leaderboard_Section_Header, Leaderboard_List, Layout_Master },
        mounted() {
            this.pull();
        },
        computed: {
            resultsAreReady() {
                return this.status === CollectionStatus.Ready;
            },
            displayType() {

                if(this.showMyPosition) { return LeaderboardDisplayTypes.MyPosition; }

                return LeaderboardDisplayTypes.fromLeaderboardCategory(this.category);
            },
            ...mapGetters(Modules.LEADERBOARDS, {
                status : Getters.Leaderboards.GET_STATUS,
                entries : Getters.Leaderboards.GET_ENTRIES,
                category : Getters.Leaderboards.CATEGORY,
                showMyPosition : Getters.Leaderboards.SHOW_MY_POSITION
            })
        },
        methods: {
            ...mapActions(Modules.LEADERBOARDS, {
                pull : Actions.Leaderboards.PULL
            })
        }
    }
</script>

<style scoped>

    .content {
        padding: 55px 3px 3px 3px;
        min-height: 100vh;
    }

</style>
