<template>
    <b-sidebar id="sidebar-map" right class="sidebar-map" :class="$mq" no-header @shown="onShow">

        <template #default="{ hide }">

            <div class="header">
                <b-button variant="link" @click="close" class="close">
                    <b-img left :src="$icons.Common.Close" alt="arrow"></b-img>
                </b-button>
            </div>

          <Layout_Section_PageHeader class="separator-bottom-light separator-top-light">
            <Help_Section :info-text="$t('map.help-in-post')"/>
            <Header_Medium>{{ $t("map.page-title") }}</Header_Medium>
          </Layout_Section_PageHeader>
            <Map v-if="mapResource" :map-resource="mapResource"/>

            <button hidden ref="hideButton" @click="hide"></button>
        </template>
    </b-sidebar>
</template>

<script>
    import WindowEx from "@/utils/WindowEx";
    import Events from "@/events/Events";
    import EventBus from "@/events/EventBus";
    import Map from "@/ui/components/map/Map";
    import Layout_Section_PageHeader from "@/ui/layouts/Layout_Section_PageHeader";
    import Header_Medium from "@/ui/components/common/Header_Medium";
    import Help_Section from "@/ui/components/common/help/Help_Section";
    import StoreFactory_Maps from "@/store/factories/StoreFactory_Maps";

    export default {
        name: "Map_Sidebar",
        components: { Map, Layout_Section_PageHeader, Header_Medium, Help_Section },
        data() {
            return {
                mapResource: null,
                postId: null,
                savedScrollPos: null,
            }
        },
        mounted() {
            EventBus.$on(Events.PostJokeTripClicked, this.setMap);
        },
        beforeDestroy() {
            EventBus.$off(Events.PostJokeTripClicked, this.setMap);
        },
        methods: {
            async setMap(postId) {

                this.$root.$emit('bv::toggle::collapse', 'sidebar-map')

                this.postId = postId;
            },
            onShow() {

                this.savedScrollPos = WindowEx.saveScrollPos();

                this.mapResource = StoreFactory_Maps.getForPost(this.postId);
            },
            close() {

                WindowEx.restoreScrollPos(this.savedScrollPos);

                this.$refs["hideButton"].click();

                this.mapResource.unregisterModule();

                this.mapResource = this.postId = null;
            },
        }
    }
</script>

<style>

    /* Layout Map */

    .sidebar-map .b-sidebar {
        width: 100%;
    }

    .sidebar-map .b-sidebar .b-sidebar-body {
        height: 100%;
        width: 100vw;
        margin-top: 3em;
        padding: 0 0.5em 0 0.5em;
    }

    .sidebar-map.desktop .b-sidebar .b-sidebar-body {
        max-width: 800px;
        margin: 3em auto 0 auto;
    }

    .sidebar-map .close {
        position: absolute;
        top: 17px;
        right: 17px;
        width: 1em;
    }

    .sidebar-map .close img {
        width: 1em;
    }

    .sidebar-map .header {
        margin-top: 1.2em;
    }
</style>
