
const Random = {};

Random.bool = function() {
    return Math.random() > 0.5;
};

Random.range = function (min, max) {
    const diff = max - min;
    return Math.floor(min) + Math.round(Math.random() * Math.floor(diff));
};

export default Random;
