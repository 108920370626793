<template>
    <div class="separator-bottom pt-3 pb-4">
        <Select_Horizontal
            class="font-weight-normal"
            :options="options"
            :selected="currentCategory"
            v-on:select="selectCategory"
        />
    </div>
</template>

<script>
import Select_Horizontal from "@/ui/components/common/Select_Horizontal";
import {mapActions, mapGetters} from "vuex";
import Modules from "@/store/Modules";
import Getters from "@/store/Getters";
import RankingCategories from "@/server/types/RankingCategories";
import Actions from "@/store/Actions";
export default {
    name: "Rankings_Select_Category",
    components: { Select_Horizontal },
    computed: {
        options() {
            return Object.values(RankingCategories).map((category) => {
                return {
                    id: category,
                    key: category,
                    value: this.$t("rankings." + category)
                }
            })
        },
        ...mapGetters(Modules.RANKINGS, {
            currentCategory : Getters.Rankings.GET_CATEGORY
        })
    },
    methods: {
        ...mapActions(Modules.RANKINGS, {
            selectCategory : Actions.Rankings.SET_CATEGORY
        })
    }
}
</script>

<style scoped>

</style>
