<template>
    <div class="leaderboard-entry-position">
        {{ formattedValue }}
    </div>
</template>

<script>
    import NumberEx from "@/utils/NumberEx";

    export default {
        name: "Number_Rank",
        props: { value: Number },
        computed: {
            formattedValue() {
                return NumberEx.pad(this.value, 2);
            }
        }
    }
</script>

<style scoped>

    .leaderboard-entry-position {
        font-size: 22pt;
        font-weight: 200;
        flex-basis: 15%;
        color: #666262;
    }

    .leaderboard-entry-position.align-left {
        text-align: left;
    }

    .leaderboard-entry-position.align-center {
        text-align: center;
    }

    .leaderboard-entry-position.align-right {
        text-align: right;
    }

</style>
