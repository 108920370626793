<!-- Contains various elements whose single instances are reused throughout the entire app, such as modals and popovers. -->

<template>
    <div>
        <Popover_Login
                name="popover-login-favorite"
                :title="$t('posts.like-this-joke-q')"
                :text="$t('posts.sign-in-to-favorite')"
        />

        <Popover_Login
                name="popover-login-report"
                :title="$t('posts.want-to-report-q')"
                :text="$t('posts.sign-in-to-report')"
        />

        <Popover_Login
                name="popover-login-post"
                :title="$t('posts.want-to-post-q')"
                :text="$t('posts.sign-in-to-post')"
        />

        <Popover_Login
                name="popover-login-comment"
                :title="$t('posts.want-to-comment-q')"
                :text="$t('posts.sign-in-to-comment')"
        />

        <Popover_Login
            name="popover-login-follow"
            :title="$t('users.want-to-follow-q')"
            :text="$t('users.sign-in-to-follow')"
        />

        <Popover_Login
            name="popover-login-duel-vote"
            :title="$t('duels.want-to-vote')"
            :text="$t('duels.sign-in-to-vote')"
        />

        <Post_Modal_Report/>

        <Post_Modal_Edit/>

        <Post_Modal_Share />

        <Duel_Modal_Statistics />

        <Badges_Modal_Info/>

        <Notifications_Realtime_Popup/>

    </div>
</template>

<script>

    import Popover_Login from "@/ui/components/common/Popover_Login";
    import Post_Modal_Report from "@/ui/components/feed/post/Post_Modal_Report";
    import Post_Modal_Share from "@/ui/components/feed/post/Post_Modal_Share";
    import Duel_Modal_Statistics from "@/ui/components/duels/Duel_Modal_Statistics";
    import Post_Modal_Edit from "@/ui/components/feed/post/Post_Modal_Edit";
    import Badges_Modal_Info from "@/ui/components/badges/Badges_Modal_Info";
    import Notifications_Realtime_Popup from "@/ui/components/notifications/Notifications_Realtime_Popup";

    export default {
        name: "Globals",
        components: { Notifications_Realtime_Popup, Post_Modal_Edit, Badges_Modal_Info, Post_Modal_Report, Post_Modal_Share, Popover_Login, Duel_Modal_Statistics }
    }
</script>
