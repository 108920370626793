<template>
    <Button_Gradient v-b-toggle.sidebar-login-select>{{ $t("auth.log-in")}}</Button_Gradient>
</template>

<script>
import Button_Gradient from "@/ui/components/common/Button_Gradient";
export default {
    name: "Button_Login",
    components: { Button_Gradient }
}
</script>
