<template>
    <b-form-textarea
            class="text-area"
            :class="{'one-line': isOneLine}"
            :value="value"
            @input="updateValue"
            :placeholder="placeholder"
            :rows="rows"
            :max-rows="maxRows"
            :no-resize="true"
            @focusin="onFocused"
            @focusout="onLostFocus"
            ref="textArea"
    ></b-form-textarea>
</template>

<script>
    export default {
        name: "Form_TextArea",
        props : {
            value: String,
            placeholder: String,
            rows: Number,
            maxRows: Number,
            clickOnly: Boolean
        },
        data() {
            return {
                isOneLine: false
            }
        },
        mounted() {
            this.isOneLine = this.rows === 1;
        },
        methods : {
            onFocused() {
                if(this.clickOnly) {
                    this.blur();
                    return;
                }

                this.isOneLine = false;
            },
            onLostFocus() {
                this.isOneLine = this.rows === 1;
            },
            focus() {
                this.$el.focus();
            },
            blur() {
                this.$el.blur();
            },
            updateValue(value) {
                this.$emit('input', value)
            }
        }
    }
</script>

<style scoped>
    .text-area {
        border-radius: 1.2rem;
        border: none;
    }

    /* Hack. Necessary due to limitations on certain browsers related to single line text areas.*/
    .text-area.one-line {
        max-height: 2.5em;
        line-height: 1.8em;
    }

    .text-area.gray {
        background-color: #ededed;
    }

    .text-area.black {
        background-color: black;
    }
</style>
