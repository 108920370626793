import Mutations from "@/store/Mutations";
import Actions from "@/store/Actions";
import Getters from "@/store/Getters";
import ArrayEx from "@/utils/ArrayEx";
import Events from "@/events/Events";
import EventBus from "@/events/EventBus";

const PopularTagCount = 12;

export default function (tagsApi) {
    return {
        namespaced: true,
        state: () => ({
            popular: [],
            latest: [],
            selected: []
        }),
        getters: {
            [Getters.Tags.POPULAR]: (state) => {
                return state.popular;
            },
            [Getters.Tags.PREVIEWED]: (state) => { // Tags that the profile sees above the feed
                return state.selected.concat(ArrayEx.difference(state.popular, state.selected));
            },
            [Getters.Tags.IS_SELECTED]: (state) => (tag) => {
                return ArrayEx.containsValue(state.selected, tag);
            },
            [Getters.Tags.GET_SELECTED]: (state) => {
                return state.selected;
            }
        },
        mutations: {
            [Mutations.Tags.TOGGLE_SELECT](state, tag) {

                if (ArrayEx.containsValue(state.selected, tag)) {
                    state.selected = ArrayEx.filterValue(state.selected, tag);
                } else {
                    state.selected.push(tag);
                    EventBus.$emit(Events.TagSelected);
                }
            },
            [Mutations.Tags.SET_SELECTED](state, tag) {
                if (!ArrayEx.containsValue(state.selected, tag)) {
                    state.selected.push(tag);
                    EventBus.$emit(Events.TagSelected);
                }
            },
            [Mutations.Tags.CLEAR_SELECTED](state) {
                state.selected = [];
            },
            [Mutations.Tags._SET_POPULAR](state, tags) {
                state.popular = tags;
            },
            [Mutations.Tags._SET_LATEST](state, tags) {
                state.latest = tags;
            }
        },
        actions: {
            async [Actions.Tags.PULL_POPULAR]({commit}) {

                const response = await tagsApi.getPopularTags(PopularTagCount);

                if(response.success) { commit(Mutations.Tags._SET_POPULAR, response.matches); }
            },
            async [Actions.Tags.PULL_LATEST]({commit}) {

                commit(Mutations.Tags._SET_LATEST, []);
            },
            async [Actions.Tags.SEARCH](context, {searchText, resultLimit}) {

                return await tagsApi.searchTag(searchText, resultLimit);
            }
        }
    };
}
