<template>
    <Layout_Info>
        <template v-slot:title>
            {{ $t("aboutUs.title") }}
        </template>

        <template v-slot:content>
            <Info_Text>
                {{ $t("aboutUs.0") }}
            </Info_Text>

            <Info_Header>{{ $t("aboutUs.subheader-1") }}</Info_Header>
            <Info_Text>{{ $t("aboutUs.1-1") }}</Info_Text>
            <Info_AboutUsImage :src="$icons.AboutUs.Nikolai" alt="Nikolai Image"/>
            <Info_Text>{{ $t("aboutUs.1-2") }}</Info_Text>
            <Info_Text>{{ $t("aboutUs.1-3") }}</Info_Text>
            <Info_Text>{{ $t("aboutUs.1-4") }}</Info_Text>
            <Info_Text>{{ $t("aboutUs.1-5") }}</Info_Text>

            <Info_Header>{{ $t("aboutUs.subheader-3") }}</Info_Header>
            <Info_SubHeader>{{ $t("aboutUs.consultant-1") }}</Info_SubHeader>
            <Info_AboutUsImage :src="$icons.AboutUs.Veselin" alt="Veselin Image"/>
            <b-link class="about-us-link" href="https://www.linkedin.com/in/vpgeorgiev/">
                https://www.linkedin.com/in/vpgeorgiev/
            </b-link>
            <Info_Text>{{ $t("aboutUs.consultant-1-text") }}</Info_Text>

            <Info_SubHeader>{{ $t("aboutUs.consultant-2") }}</Info_SubHeader>
            <Info_AboutUsImage :src="$icons.AboutUs.Teodor" alt="Teodor Image"/>
            <b-link class="about-us-link" href="https://www.linkedin.com/in/teodor-iliev/">
                https://www.linkedin.com/in/teodor-iliev/
            </b-link>
            <Info_Text>{{ $t("aboutUs.consultant-2-text") }}</Info_Text>

            <Info_Text>{{ $t("aboutUs.other-thanks") }}</Info_Text>

            <Info_Text>{{ $t("aboutUs.contact-us") }}</Info_Text>

            <Info_Header>{{ $t("aboutUs.subheader-2") }}</Info_Header>
            <Info_Text>{{ $t("aboutUs.text-2-1") }}</Info_Text>
            <Info_Text>{{ $t("aboutUs.text-2-2") }}</Info_Text>
            <Info_Text>{{ $t("aboutUs.text-2-3") }}</Info_Text>
            <Info_Text>{{ $t("aboutUs.text-2-4") }}</Info_Text>
            <Info_Text>{{ $t("aboutUs.text-2-5") }}</Info_Text>
            <Info_Text>{{ $t("aboutUs.text-2-6") }}</Info_Text>
            <Info_Text>{{ $t("aboutUs.text-2-7") }}</Info_Text>
            <Info_Text>{{ $t("aboutUs.text-2-8") }}</Info_Text>

            <Info_List>
                <Info_ListBullet>{{ $t("aboutUs.2-bullet-1") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("aboutUs.2-bullet-2") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("aboutUs.2-bullet-3") }}</Info_ListBullet>
            </Info_List>
            <Info_Text> {{ $t("aboutUs.2-end") }}</Info_Text>
        </template>
    </Layout_Info>
</template>

<script>
import Layout_Info from "@/ui/layouts/Layout_Info";
import Info_Header from "@/ui/components/info/Info_Header";
import Info_Text from "@/ui/components/info/Info_Text";
import Info_List from "@/ui/components/info/Info_List";
import Info_ListBullet from "@/ui/components/info/Info_ListBullet";
import Info_SubHeader from "@/ui/components/info/Info_SubHeader";
import Info_AboutUsImage from "@/ui/components/info/Info_AboutUsImage";

export default {
    name: "InfoView_AboutUs",
    components: {Info_SubHeader, Info_ListBullet, Info_List, Layout_Info, Info_Header, Info_Text, Info_AboutUsImage}
}
</script>

<style>
.about-us-image,
.about-us-link {
    width: 100%;
}
</style>