<template>
    <div class="leaderboard-entry-user">

        <Bar_Experience_Small :status="user.activityStatus"/>

        <Avatar_Small :user="user"/>

        <Post_Text_Username class="leaderboard-entry-user-name" :user="user" />
    </div>
</template>

<script>
    import User from "@/server/structures/User";
    import Avatar_Small from "@/ui/components/common/Avatar_Small";
    import Bar_Experience_Small from "@/ui/components/common/Bar_Experience_Small";
    import Post_Text_Username from "@/ui/components/feed/post/Post_Text_Username";

    export default {
        name: "Leaderboard_EntrySection_User",
        components: { Post_Text_Username, Bar_Experience_Small, Avatar_Small },
        props: { user: User }
    }
</script>

<style>

    .leaderboard-entry .leaderboard-entry-user {
        max-width: 50%;
        flex-grow: 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.6em;
    }

    .leaderboard-entry .leaderboard-entry-user-name {
        padding-top: 6px;
        font-weight: 600;
        line-height: 1.4;
        color: #6f6f6f;
        width: auto;
    }

    .leaderboard-entry.highlighted .leaderboard-entry-user-name {
        font-weight: bold;
        color: #2d2736;
    }
</style>
