import { render, staticRenderFns } from "./UserView_Settings.vue?vue&type=template&id=a7f56b3e&"
import script from "./UserView_Settings.vue?vue&type=script&lang=js&"
export * from "./UserView_Settings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports