import Module_Duel_Feed from "@/store/modules/Module_Duel_Feed";
import {registerAndGetStore} from "vuex-stores";

const StoreObjects = {};

StoreObjects.init = function (store, server) {

    StoreObjects.DuelFeed = registerAndGetStore(store, { namespace: "duelFeed", ...Module_Duel_Feed(server.duels)} );
}

export default StoreObjects;
