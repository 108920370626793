<template>
    <div>
        <Select_Horizontal
                class="my-3"
                :options="horizontalOptionList"
                :selected="filterMyPosition"
                v-on:select="onHorizontalSelect"
        />

        <Leaderboard_Popover_Category
                target="leaderboard-category-button"
                :show.sync="showPopover"
                :options="popoverOptionList"
                :selected="currentCategory"
                v-on:select="onSelectCategory"
        />
    </div>
</template>

<script>
    import Select_Horizontal from "@/ui/components/common/Select_Horizontal";
    import Leaderboard_Popover_Category from "@/ui/components/leaderboard/Leaderboard_Popover_Category";
    import LeaderboardCategories from "@/server/types/LeaderboardCategories";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import {mapActions, mapGetters} from "vuex";
    import Getters from "@/store/Getters";
    import Icons from "@/utils/Icons";

    const LeaderboardCategoryButtonId = "leaderboard-category-button";

    export default {
        name: "Leaderboard_Section_Category",
        components: { Leaderboard_Popover_Category, Select_Horizontal },
        data() {
            return {
                showPopover : false,
            }
        },
        computed: {
            popoverOptionList() {
                return Object.values(LeaderboardCategories).map((key) => {
                    return {
                        key: key,
                        value: this.localizeCategory(key)
                    }
                })
            },
            horizontalOptionList() {
                return [{
                    id: LeaderboardCategoryButtonId,
                    key: false,
                    value: this.localizeCategory(this.currentCategory),
                    icon: Icons.Common.Sort
                }];
            },
            ...mapGetters(Modules.LEADERBOARDS, {
                currentCategory : Getters.Leaderboards.CATEGORY,
                filterMyPosition : Getters.Leaderboards.SHOW_MY_POSITION
            }),
            ...mapGetters(Modules.AUTH, {
                isAuthenticated : Getters.Auth.IS_AUTHENTICATED
            })
        },
        methods: {
            localizeCategory(category) {
                return this.$t("leaderboards.category-" + category);
            },
            onSelectCategory(category) {
                this.setCategory(category);
                this.showPopover = false;
            },
            onHorizontalSelect(option) {
                if(option === true) {
                    this.setShowMyPosition();
                }
                else {
                    this.showPopover = true;
                }
            },
            ...mapActions(Modules.LEADERBOARDS, {
                setCategory: Actions.Leaderboards.SET_CATEGORY,
                setShowMyPosition: Actions.Leaderboards.SHOW_MY_POSITION
            })
        }
    }
</script>
