import Actions from "@/store/Actions";
import DuelVoteType from "@/server/types/DuelVoteType";
import DuelStatusType from "@/server/types/DuelStatusType";

/**
 * @param {ApiProxy_Duels} duelsApi
 */
export default function Module_Duel_Actions(duelsApi) {

    return {
        namespaced: true,
        state: () => ({}),
        getters: {},
        mutations: {},
        actions: {
            async [Actions.Duels.CREATE] (context, postCreationData) {

                return await duelsApi.createDuel(postCreationData);
            },
            async [Actions.Duels.RESPOND] (context, { duelId, postCreationData }) {

                return await duelsApi.respondToDuel(duelId, postCreationData);
            },
            async [Actions.Duels.SHARE] (context, duelId) {

                return await duelsApi.share(duelId);
            },
            async [Actions.Duels.GET_BY_CODE] (context, code) {

                return await duelsApi.getByCode(code);
            },
            async [Actions.Duels.GET_BY_SHARE_CODE] (context, shareCode) {

                return await duelsApi.getByShareCode(shareCode);
            },
            async [Actions.Duels.VOTE_CHALLENGER] (context, duel) {

                await voteFor(context, duel, DuelVoteType.Challenger);
            },
            async [Actions.Duels.VOTE_CHALLENGED] (context, duel) {

                await voteFor(context, duel, DuelVoteType.Challenged);
            },
            async [Actions.Duels.GET_STATS] (context, duelId) {

                return await duelsApi.getStats(duelId);
            },
            async [Actions.Duels.GET_RESULTS] (context, duelId) {

                return await duelsApi.getResults(duelId);
            },
            async [Actions.Duels.SET_FINISHED] (context, duel) {
                duel.status = DuelStatusType.Finished;
            },
        }
    }

    async function voteFor(context, duel, voteType) {

        await duel.mutate(async () => {

            duel.votedFor = voteType;

            const voteResponse = await duelsApi.vote(duel.id, voteType);
            if(!voteResponse.success) { return false; }

            await updateDuelResults(duel);

            return voteResponse;
        })
    }

    async function updateDuelResults(duel) {

        const resultResponse = await duelsApi.getResults(duel.id);
        if(resultResponse.success) { duel.updateResults(resultResponse.results); }
    }
}
