<template>
    <Panel_White class="px-4 py-4">
        <UserSettings_ProfileEdit class="pb-4 separator-bottom" :user="user"/>
        <UserSettings_PasswordEdit class="mt-4"/>
    </Panel_White>
</template>

<script>
    import UserSettings_ProfileEdit from "@/ui/components/users/user_settings/UserSettings_ProfileEdit";
    import User from "@/server/structures/User";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import {mapGetters} from "vuex";
    import RouterUtils from "@/routes/RouterUtils";
    import Panel_White from "@/ui/components/common/Panel_White";
    import UserSettings_PasswordEdit from "@/ui/components/users/user_settings/UserSettings_PasswordEdit";

    export default {
        name: "UserView_Settings",
        components: { UserSettings_PasswordEdit, Panel_White, UserSettings_ProfileEdit },
        props: { user: User },
        mounted() {
            if(!this.isMe(this.user.slug)) { RouterUtils.goHome(); }
        },
        computed: {
            ...mapGetters(Modules.USERS, {
                isMe: Getters.Users.IS_ME
            })
        }
    }
</script>
