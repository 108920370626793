

export default class MapCity {

    _id;
    _data;

    get id() {
        return this._id;
    }

    get name() {
        return this._data.name;
    }

    get views() {
        return this._data.count;
    }

    get position() {
        return this._data.position;
    }

    constructor(id, data) {
        this._id = id;
        this._data = data;
    }
}
