<template>
    <div class="auth-view">

        <div class="text-center my-5">
            <b-img class="logo" :src="$icons.WhiteLogo" alt="JokeTrip"></b-img>
        </div>

        <div v-if="loading" class="spinner-container">
            <b-spinner variant="light" class="spinner"></b-spinner>
        </div>
        <template v-else class="content">

            <div class="centered-children content">
                <slot></slot>
            </div>

            <div class="centered-children footer">
                <slot name="footer"></slot>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: "Layout_AuthView",
        props: {
            loading: Boolean
        }
    }
</script>

<style scoped>
    .auth-view {
        background: linear-gradient(120deg, rgba(253, 120, 78, 1) 0%, rgba(253, 75, 95, 1) 100%);
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
    }
    .auth-view .logo {
        width: 80%;
    }
    .auth-view .centered-children {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .auth-view .content {
        height: 80%;
        width: 80%;
    }
    .auth-view .footer {
        height: 20%;
        width: 80%;
        margin-bottom: 15%;
    }
    .auth-view .spinner-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .auth-view .spinner {
        width: 8rem;
        height: 8rem;
    }

</style>
