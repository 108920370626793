import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_GetLeaderboard from "@/server/responses/ApiResponse_GetLeaderboard";
import LeaderboardCategories from "@/server/types/LeaderboardCategories";

export default class ApiProxy_Leaderboards extends ApiProxy {

    async getCategory(leaderboardCategory) {

        const rawResponse = await this.get(`/${leaderboardCategory}`);

        return new ApiResponse_GetLeaderboard(rawResponse);
    }

    async getMyPosition() {

        const rawResponse = await this.get(`/${LeaderboardCategories.Activity}/me`);

        return new ApiResponse_GetLeaderboard(rawResponse);
    }
}
