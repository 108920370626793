<!-- Component commonly used for showing a message when no responses are found after a search. -->

<template>
    <h4 class="no-results-text">
        <slot></slot>
    </h4>
</template>

<script>
    export default {
        name: "Text_NoResults"
    }
</script>

<style scoped>
    .no-results-text {
        text-align: center;
        padding-left: 7%;
        padding-right: 7%;
        margin-top: 40%;
        color: #8b8b8b;
    }
</style>
