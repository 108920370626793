<template>
    <img class="search-button" :src="$icons.Feed.Search" alt="Search"/>
</template>

<script>
    export default {
        name: "FeedTags_Icon_Search",
    }
</script>

<style scoped>

    .tag-bar .search-button {
        position: absolute;
        right: 22px;
        height: 1.7em;
        margin-top: 2px;
        cursor: pointer;
    }

    .tag-bar .search-button.bright {
        position: absolute;
        filter: brightness(200%);
    }
</style>
