<template>
    <img v-if="status" class="level-indicator" :src='generateIcon(status)' />
</template>

<script>

    import UserStatusTypes from "@/store/types/UserStatusTypes";
    import Icons from "@/utils/Icons";

    const StatusIcons = {
        [UserStatusTypes.BASIC]: Icons.ExperienceBarSmall.Basic,
        [UserStatusTypes.ADVANCED]: Icons.ExperienceBarSmall.Advanced,
        [UserStatusTypes.EXPERT]: Icons.ExperienceBarSmall.Expert,
        [UserStatusTypes.PRO]: Icons.ExperienceBarSmall.Pro
    };

    export default {
        name: "Bar_Experience_Small",
        props: {
            status: String
        },
        methods: {
           generateIcon(status) {
               return StatusIcons[status]
           }
        }
    }
</script>

<style scoped>
    .level-indicator {
        width: 5px;
        height: 26px;
        display: inline-block;
    }
</style>
