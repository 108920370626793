<template>
    <div class="about-us-image-container">
        <b-img class="about-us-image" :src="src" :alt="alt" />
    </div>
</template>

<script>
    export default {
        name: "Info_AboutUsImage",
        props: {
            src: String,
            alt: String
        }
    }
</script>

<style>
    .about-us-image-container {
        margin: 1em 0 1em 0;
    }
</style>