import Post from "@/server/structures/Post";
import User from "@/server/structures/User";
import ProxyObject from "@/server/structures/ProxyObject";
import DuelStatusType from "@/server/types/DuelStatusType";
import DuelVoteType from "@/server/types/DuelVoteType";
import InvalidArgumentError from "@/errors/InvalidArgumentError";

export default class Duel extends ProxyObject {

    _data;

    get id() { return this._data.id; }

    get code() { return this._data.code; }

    get status() { return this._data.status; }

    get isAccepted() { return this.status === DuelStatusType.Accepted; }

    get isFinished() { return this.status === DuelStatusType.Finished; }

    get isRefused() { return this.status === DuelStatusType.Refused; }

    get isWaiting() { return this.status === DuelStatusType.Waiting; }

    get challenger() { return new User(this._data.challenger); }

    get challenged() { return new User(this._data.challenged); }

    get challengerPost() { return new Post(this._data.challenger_post); }

    get challengedPost() { return new Post(this._data.challenged_post); }

    get endDate() { return new Date(this._data.end_date); }

    get acceptedAt() { return new Date(this._data.accepted_at); }

    get createdAt() { return new Date(this._data.created_at); }

    get challengerVotesCount() { return this._data.challenger_votes_count; }

    get challengedVotesCount() { return this._data.challenged_votes_count; }

    get votesCount() { return this._data.votes_count; }

    get challengerVotePercent() { return this._data.challenger_votes_percentage; }

    get challengedVotePercent() { return this._data.challenged_votes_percentage; }

    get isChallenger() { return this._data.is_challenger; }

    get isChallenged() { return this._data.is_challenged; }

    get voted() { return this._data.is_voted; }

    get isParticipant() { return this._data.is_participant; }

    get votedFor() { return this._data.voted_for; }

    set status(value) {
        this._data.status = value;
    }

    set votedFor(value) {

        if(value !== DuelVoteType.Challenger && value !== DuelVoteType.Challenged) {
            throw new InvalidArgumentError("Invalid vote type argument for duel.");
        }

        this._data.voted_for = value;
        this._data.is_voted = (value != null);

        this._data.votes_count++;

        if(value === DuelVoteType.Challenger) { this._data.challenger_votes_count++; }
        else { this._data.challenged_votes_count++; }

        this._data.challenger_votes_percentage = Math.round(this._data.challenger_votes_count / this._data.votes_count * 100);
        this._data.challenged_votes_percentage = Math.round(this._data.challenged_votes_count / this._data.votes_count * 100);
    }

    constructor(data) {
        super();
        this._data = data;
    }

    /** @param {DuelResults} results */
    updateResults(results) {
        this._data.votes_count = results.votesCount;
        this._data.challenger_votes_count = results.challengerVotesCount;
        this._data.challenger_votes_percentage = results.challengerVotesPercentage;
        this._data.challenged_votes_count = results.challengedVotesCount;
        this._data.challenged_votes_percentage = results.challengedVotesPercentage;
    }

    copy() {
        return new Duel({ ...this._data });
    }

    apply(copy) {
        this._data = copy._data;
    }

    equals(other) {

        if(!(other instanceof Duel)) return false;

        if(this === other) return true;

        return this.id === other.id;
    }
}
