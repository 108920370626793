<template>
    <b-button pill class="button-gradient orange-gradient">
        <img v-if="leftIcon" :src="leftIcon" alt="left icon"/>
        <slot></slot>
        <img v-if="rightIcon" :src="rightIcon" alt="right icon"/>
    </b-button>
</template>

<script>
    export default {
        name: "Button_Gradient",
        props: {
            rightIcon: String,
            leftIcon: String
        }
    }
</script>

<style scoped>

    .button-gradient {
        font-weight: 500;
        border: none;
        min-width: 100px;
    }
    .button-gradient img {
        height: 1.2rem;
        margin-left: 4px;
        filter: invert(61%) sepia(100%) saturate(2289%) hue-rotate(329deg) brightness(300%) contrast(98%);
    }
    .button-gradient.icon-small img {
        height: 0.8rem;
    }
</style>
