<template>
    <Button_Gradient class="icon-small" :rightIcon="$icons.DuelsIcons.Accept">
        {{ $t("duels.accept") }}
    </Button_Gradient>
</template>

<script>
    import Button_Gradient from "@/ui/components/common/Button_Gradient";
    export default {
        name: "DuelChallenge_Button_Accept",
        components: { Button_Gradient }
    }
</script>
