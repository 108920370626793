import MapArea from "@/server/structures/MapArea";
import MapCity from "@/server/structures/MapCity";

export default class JokeTripMap {
    
    _areaLookup;

    _cities;

    get cities() { return this._cities; }

    getArea(code) {
        const formattedCode = code.toLowerCase();
        
        if(formattedCode in this._areaLookup) {
            return this._areaLookup[formattedCode];
        }

        return MapArea.createEmpty();
    }
    
    constructor(data) {
        this._areaLookup = {};

        data.countries.forEach((rawCountry) => {
            this._areaLookup[rawCountry.code] = new MapArea(rawCountry);
        })

        data.states.forEach((rawState) => {
            this._areaLookup[rawState.code] = new MapArea(rawState);
        })
        
        this._cities = data.cities.map((rawCity, index) => {
            return new MapCity(index, rawCity);
        });
    }
}
