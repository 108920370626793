<template>
    <Layout_Master>
        <main class="content">
            <div class="section-header separator-bottom">
                <Header_Large>{{ $t("general.favorites") }}</Header_Large>
            </div>
            <Favorites_PostList/>
        </main>
    </Layout_Master>
</template>

<script>
    import Layout_Master from "@/ui/layouts/Layout_Master";
    import Header_Large from "@/ui/components/common/Header_Large";
    import Favorites_PostList from "@/ui/components/favorites/Favorites_PostList";
    export default {
        name: "View_Favorites",
        components: { Favorites_PostList, Header_Large, Layout_Master },
    }
</script>

<style scoped>

    .content {
        padding: 55px 3px 3px 3px;
        min-height: 100vh;
    }

    .section-header {
        padding: 1.2em 1.1em 0.5em;
        margin-bottom: 5px;
        position: relative;
    }
</style>
