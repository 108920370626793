<template>
    <Layout_Master>
        <main class="content">

            <Rankings_Section_Header/>

            <Rankings_Select_Category/>

            <Rankings_List_Posts class="mt-3"/>
        </main>
    </Layout_Master>
</template>

<script>
import Layout_Master from "@/ui/layouts/Layout_Master";
import Rankings_Section_Header from "@/ui/components/rankings/Rankings_Section_Header";
import Rankings_Select_Category from "@/ui/components/rankings/Rankings_Select_Category";
import Rankings_List_Posts from "@/ui/components/rankings/Rankings_List_Posts";

export default {
    name: "View_WeekRankings",
    components: { Rankings_List_Posts, Rankings_Select_Category, Rankings_Section_Header, Layout_Master }
}
</script>

<style scoped>

.content {
    padding: 55px 3px 3px 3px;
    min-height: 100vh;
}

</style>
