<template>
    <div>
        <template v-if="!isAuthenticated">
            <DuelChallenge_Actions_Unauthenticated/>
        </template>
        <template v-else-if="isChallenger">
            <DuelChallenge_Actions_Challenger :duel="duel"/>
        </template>
        <template v-else>
            <DuelChallenge_Actions_Challenged :duel="duel"/>
        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Duel from "@/server/structures/Duel";
import Modules from "@/store/Modules";
import Getters from "@/store/Getters";
import DuelChallenge_Actions_Challenger from "@/ui/components/duel_challenge/DuelChallenge_Actions_Challenger";
import DuelChallenge_Actions_Challenged from "@/ui/components/duel_challenge/DuelChallenge_Actions_Challenged";
import DuelChallenge_Actions_Unauthenticated from "@/ui/components/duel_challenge/DuelChallenge_Actions_Unauthenticated";

export default {
    name: "DuelChallenge_Section_Actions",
    components: {
        DuelChallenge_Actions_Unauthenticated,
        DuelChallenge_Actions_Challenged,
        DuelChallenge_Actions_Challenger
    },
    props: {
        isChallenger: Boolean,
        duel: Duel
    },
    computed: {
        ...mapGetters(Modules.AUTH, { isAuthenticated: Getters.Auth.IS_AUTHENTICATED })
    }
}
</script>
