<template>
    <Panel_White border-variant="light" class="folding-card" v-bind:class="{closed: isClosed}">
        <div class="folding-card-body">
            <slot></slot>
        </div>
    </Panel_White>
</template>

<script>
    import Panel_White from "@/ui/components/common/Panel_White";
    export default {
        name: "Panel_Folding",
        components: { Panel_White },
        props: {
            isClosed: { type: Boolean, required: true },
            foldedMaxHeight: { type: String, required: true }
        },
        mounted() {

            if (this.isClosed) {
                this._setClosedStyles();
            }
        },
        watch: {
            isClosed() {
                this._animateFolding();
            }
        },
        methods: {
            _animateFolding() {

                const prevHeight = this._getHeight();

                // Set the height that we want to end up at after the animation is complete.
                this._setDesiredStyles();

                const newHeight = this._getHeight();

                // That temporary height is never rendered in the browser, since we reset it here.
                this._reset(prevHeight);

                // Start Animation in next Animation frame from original height to new height
                requestAnimationFrame(() => {
                    this.$el.style.height = newHeight;

                    if(!this.isClosed) {
                        setTimeout(() => {
                            this.$el.style.height = 'auto';
                        }, 350);
                    }
                });
            },
            _getHeight() {
                return this.$el.getBoundingClientRect().height + 'px';
            },
            _setDesiredStyles() {
                this.isClosed ? this._setClosedStyles() : this._setOpenStyles();
            },
            _setClosedStyles() {
                this.$el.style.maxHeight = this.foldedMaxHeight;
                this.$el.style.height = 'min-content';
            },
            _setOpenStyles() {
                this.$el.style.maxHeight = 'initial';
                this.$el.style.height = 'auto';
            },
            _reset(oldHeight) {
                this.$el.style.maxHeight = null;
                this.$el.style.height = oldHeight;
                this.$el.getBoundingClientRect();   // If we forget calling this the resize is never applied for some reason.
            }
        }
    }
</script>

<style>

    .folding-card {
        transition: height 0.35s;

        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .folding-card.closed {
        overflow: hidden;
    }

    .folding-card .folding-card-body {
        padding: 0.5rem 1.25rem 1.25rem;
    }
</style>
