<!-- The generic button that is used for toggling tags. -->

<template>
    <div>
        <b-button class="tag" :class="computedClass" @click="toggleTag(tag)">
            #{{ tag.name }}
        </b-button>
    </div>
</template>

<script>

    import {mapGetters, mapMutations} from "vuex";
    import Tag from "@/server/structures/Tag";
    import Getters from "@/store/Getters";
    import Mutations from "@/store/Mutations";
    import Modules from "@/store/Modules";
    import MathEx from "@/utils/MathEx";

    const IndexToClass = [
        'grey-tag',
        'midgrey-tag',
        'lightgrey-tag'
    ];

    export default {
        name: "FeedTags_Button",
        props: {
            tag: Tag,
            index: { type: Number, default: 0 }
        },
        computed: {
            computedClass() {
                if(this.isSelected(this.tag)) { return 'color-tag'; }

                return IndexToClass[MathEx.clamp(this.index, 0, IndexToClass.length - 1)];
            },
            ...mapGetters(Modules.TAGS, { isSelected: Getters.Tags.IS_SELECTED })
        },
        methods: {
            ...mapMutations(Modules.TAGS, { toggleTag: Mutations.Tags.TOGGLE_SELECT }),
        }
    }
</script>

<style scoped>

    .tag {
        font-size: 0.875em;
        color: #575756;
        border: none;
        margin-bottom: 1.1em;
    }
    .tag.grey-tag {background-color: #C6C6C6}
    .tag.midgrey-tag {background-color: #DADADA}
    .tag.lightgrey-tag {background-color: #EDEDED}
    .tag.color-tag {background: linear-gradient(90deg, rgba(254,114,78,1) 0%, rgba(255,81,94,1) 100%);color:#fff}
</style>
