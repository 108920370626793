<template>
    <Post_Component class="loading-blur" :disable-track-views="true" :post="fakePost"/>
</template>

<script>
    import Post_Component from "@/ui/components/feed/post/Post_Component";
    import LoremIpsum from "@/utils/LoremIpsum";
    import Post from "@/server/structures/Post";

    const FakePost = new Post({

        text: LoremIpsum.generateWords(4) + '\n' + LoremIpsum.generateSentences(7),
        type: "text",
        created_at: new Date(),
        tags : [],
        user : {
            username: "Some Username"
        }
    });

    export default {
        name: "Post_Loading",
        components: { Post_Component },
        data() {
            return { fakePost : FakePost }
        }
    }
</script>
