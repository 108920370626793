<template>
    <Layout_ArrowScroll class="initial-tag-select gray" v-bind:class="{ hidden: searchActive }" :scroll-step="120">
        <b-button
                v-for="tag in previewed"
                :key="tag.name"
                v-bind:class="{ active : isSelected(tag) }"
                v-on:click="toggleSelect(tag)">
            {{ tag.name }}
        </b-button>
    </Layout_ArrowScroll>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import Actions from "@/store/Actions";
    import Mutations from "@/store/Mutations";
    import Layout_ArrowScroll from "@/ui/layouts/Layout_ArrowScroll";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";

    export default {
        name: "FeedTags_Bar_InitialSelect",
        components: { Layout_ArrowScroll },
        props: {
            searchActive: Boolean
        },
        mounted() {
            this.pull();
        },
        computed: {
            ...mapGetters(Modules.TAGS, {
                previewed: Getters.Tags.PREVIEWED,
                isSelected: Getters.Tags.IS_SELECTED
            }),
        },
        methods: {
            ...mapActions(Modules.TAGS, {
               pull : Actions.Tags.PULL_POPULAR
            }),
            ...mapMutations(Modules.TAGS, {
                toggleSelect : Mutations.Tags.TOGGLE_SELECT
            })
        }
    }
</script>

<style scoped>

    .initial-tag-select {
        width: 100%;
        margin-right: 2.4em;
        transition: 0.5s;
        opacity: 1.0;
    }

    .initial-tag-select.hidden {
        overflow: hidden;
        margin-right: 0;
        width: 0;
        opacity: 0.0;
    }

    .initial-tag-select .btn {
        border: 1px solid #d9d9d9;
        color: #2a2333;
        background-color: #ededed;
        padding: 0.4em 0.9em;
        font-size: 0.85em;
        font-weight: 500;
        margin-right: 3px;
        display: inline-block;
    }

    .initial-tag-select .btn.active {
        color: #ffffff;
        background: linear-gradient(90deg, rgba(254, 114, 78, 1) 0%, rgba(255, 81, 94, 1) 100%);
        border: 1px solid white;
    }
</style>
