<template>
    <div class="avatar float-right mr-0">
        <img left :src="icon ? icon : $icons.TopNav.GuestAvatar" alt="Avatar" @click="navigateToUser"/>
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "TopNav_Avatar",
        props: {
            icon: String,
        },
        methods: {
            navigateToUser() {
                this.$emit('navigate');
            }
        }
    }
</script>

<style scoped>
    .avatar {
        margin: 0 1em 0 0;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
    }
    .avatar img {
        width: 2.6em;
        border-radius: 100%;
    }
</style>
