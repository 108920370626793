<!-- Component for the portion of the post that shows the Comment and Joke Trip stats. -->

<template>
    <div class="post-statistics border-bottom py-3">
        <div class="post-section-comment-stats comment" v-bind:class="{ open: commentsAreOpen }" @click="$emit('toggleComments')">
            <b-img class="" :src="$icons.Post.Comments" alt=""></b-img>
            {{ $t('general.comments') }} {{ post.commentCount }}
        </div>
        <div v-if="post.isDuelPost && !isInDuelFeed" class="post-section-comment-stats duel-icon">
            <b-img :src="$icons.Duels" alt="Duels" :style="{visibility: !isInDuelFeed ? 'visible' : 'hidden'}" @click="duelIconClicked()" />
        </div>
        <div class="post-section-comment-stats joke-trip" v-on:click="toggleSidebar()">
            <b-img class="" :src="$icons.Post.Places" alt=""></b-img>
            Joke Trip {{ post.tripCount }}
        </div>
    </div>
</template>

<script>
    import Post from "@/server/structures/Post";
    import RouterUtils from "@/routes/RouterUtils";
    import Events from "@/events/Events";
    import EventBus from "@/events/EventBus";

    export default {
        name: "Post_Section_CommentStats",
        props : {
            post: Post,
            commentsAreOpen: Boolean,
            isInDuelFeed: Boolean
        },
        methods: {
            duelIconClicked() {
                RouterUtils.goToDuelFeed(this.post.duelCode);
            },
            toggleSidebar() {
                //send the post id to load the post's map
                EventBus.$emit(Events.PostJokeTripClicked, this.post.id)
            }
        },
    }
</script>

<style>

    .post-statistics {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

    .post-section-comment-stats {
        color: #6f6f6f;
        font-size: 0.9em;
        cursor: pointer;
    }

    .post-section-comment-stats.duel-icon {
        justify-self: center;
    }

    .post-section-comment-stats.joke-trip {
        justify-self: flex-end;
        font-weight: 700;
        /*text-decoration: underline;*/
    }

    .post-section-comment-stats img {
        width: 1.5em;
    }

    .post-section-comment-stats.open img {
        filter: invert(49%) sepia(33%) saturate(5047%) hue-rotate(332deg) brightness(114%) contrast(100%);
    }

</style>
