<template>
    <b-button variant="link" class="button-hamburger float-left" v-b-toggle.sidebar>
        <img class="icon-hamburger" :src="$icons.TopNav.Hamburger" alt="icon"/>
    </b-button>
</template>

<script>
    export default {
        name: "TopNav_Button_Hamburger"
    }
</script>

<style scoped>

    .icon-hamburger {
        width: 1.8em;
        filter: invert(1);
    }
</style>
