<!-- A small round question mark button that appears in the corner of sections.  -->
<!-- Used to expand help sections. -->
<!-- Classes : -->
<!-- 'light' : increases the icon's brightness. -->
<!-- 'dark'  : decreases the icon's brightness -->

<template>
    <img
            class="common-button-help"
            :src="$icons.Common.Help1"
            alt="help-button"
    />
</template>

<script>
    export default {
        name: "Help_Button"
    }
</script>

<style scoped>

    .common-button-help {
        width: 1.55em;
        height: 1.55em;
        position: absolute;
        top: 3px;
        right: 3px;
        filter: grayscale(1) brightness(1.2);
    }

    .common-button-help:hover {
        cursor: pointer;
    }

    .common-button-help.light {
        filter: brightness(1.0);
    }

    .common-button-help.dark {
        filter: brightness(0.5);
    }
</style>
