<template>
    <div class="top-nav-notifications-icon" @click="navigate">
        <img :src="$icons.Notifications" />
        <span v-if="hasUnreadMessages" class="notification-dot"/>
    </div>
</template>

<script>
import RouterUtils from "@/routes/RouterUtils";
import {mapGetters} from "vuex";
import Modules from "@/store/Modules";
import Getters from "@/store/Getters";

export default {
    name: "TopNav_NotificationsBell",
    computed: {
        ...mapGetters(Modules.NOTIFICATIONS, {
            hasUnreadMessages : Getters.Notifications.HAS_UNREAD
        })
    },
    methods: {
        navigate() {
            RouterUtils.goToNotifications();
        }
    }
}
</script>

<style>

    .top-nav-notifications-icon {
        width: 2em;
        height: 2em;
        position: absolute;
        right: 3.0em;
    }

    .top-nav-notifications-icon img {
        width: 100%;
        height: 100%;
        filter: invert();
    }

    .top-nav-notifications-icon .notification-dot {
        height: 9px;
        width: 9px;
        right: 6px;
        top: 2px;
        background-color: #FF6255;
        border-radius: 50%;
        position: absolute;
    }
</style>
