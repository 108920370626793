
export default class SubmenuBuilder {

    _localePrefix;

    _submenus;

    constructor(localePrefix) {
        this._localePrefix = localePrefix;
        this._submenus = [];
    }

    add(name, icon, renderCondition) {
        this._submenus.push({
            name: name,
            icon: icon,
            localeKey: this._localePrefix + name,
            renderCondition : renderCondition
        });

        return this;
    }

    get() {
        return this._submenus;
    }
}
