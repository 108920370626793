<template>
    <Layout_LazyLoadFeed
        :load-margin-px="400"
        :last-page-reached="lastPageReached"
        :enable-loading="resultsAreReady"
        v-on:loadMore="loadMore"
    >
        <template v-if="isWaitingSelected">
            <Duel_PostWaiting
                v-for="duel in duels"
                :key="duel.id"
                :duel="duel"
            />
         </template>

        <Duel_Component
            v-else
            v-for="duel in duels"
            :key="duel.id"
            :duel="duel"
            :highlighted="isHighlighted(duel.id)"
            v-on:open="highlight(duel.id)"
        />
        <template v-if="resultsAreLoading">
            <Duel_Loading v-for="index in 10" :key="-index"/>
        </template>

        <Text_NoResults v-if="resultsAreReady && noDuelsFound">{{ $t("duels.no-duels-found") }}</Text_NoResults>

    </Layout_LazyLoadFeed>
</template>

<script>
import {mapGetters} from "vuex";
import WindowEx from "@/utils/WindowEx";
import Getters from "@/store/Getters";
import Modules from "@/store/Modules";
import Actions from "@/store/Actions";
import Layout_LazyLoadFeed from "@/ui/layouts/Layout_LazyLoadFeed";
import Duel_Component from "@/ui/components/duels/Duel_Component";
import Duel_PostWaiting from "@/ui/components/duels/Duel_PostWaiting";
import Duel_Loading from "@/ui/components/duels/Duel_Loading";
import Text_NoResults from "@/ui/components/common/Text_NoResults";

export default {
    name: "Duel_Feed",
    components: { Text_NoResults, Duel_Loading, Duel_Component, Duel_PostWaiting, Layout_LazyLoadFeed },
    props: {
        duelCollection : Object,
        isWaitingSelected: Boolean
    },
    data() {
        return {
            highlightedId: null
        }
    },
    mounted() {
        this._reload();
    },
    watch: {
        selectedTags() { this._reload(); },
        selectedRegion() { this._reload(); },
    },
    computed: {
        duels() {
            return this.duelCollection[Getters.Collection.GET_ENTRIES];
        },
        resultsAreLoading() {
            return this.duelCollection[Getters.Collection.STATUS_IS_LOADING];
        },
        resultsAreReady() {
            return this.duelCollection[Getters.Collection.STATUS_IS_READY];
        },
        lastPageReached() {
            return this.duelCollection[Getters.Collection.GET_LAST_PAGE_REACHED];
        },
        noDuelsFound() {
            return this.duels.length === 0;
        },
        ...mapGetters(Modules.TAGS, {
            selectedTags : Getters.Tags.GET_SELECTED
        }),
        ...mapGetters(Modules.LOCALIZATION, {
            selectedRegion : Getters.Localization.GET_REGION
        })
    },
    methods: {
        highlight(duelId) {
            this.highlightedId = duelId;
        },
        isHighlighted(duelId) {
            return duelId === this.highlightedId;
        },
        loadMore() {
            this.duelCollection[Actions.Collection.LOAD_MORE]();
        },
        _reload() {

            this.highlightedId = null;

            WindowEx.scrollToTop();

            this.duelCollection[Actions.Collection.RELOAD]();
        }
    }
}
</script>
