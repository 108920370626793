<template>
    <Layout_AuthView :loading="loading">

        <template v-slot:default>

            <template v-if="result === null">
                <Auth_Text class="mb-5">{{ $t("auth.choose-new-password") }}</Auth_Text>

                <Auth_Form_InputField
                    type="password"
                    input-name="new-password"
                    v-model="newPassword"
                />
            </template>
            <template v-else-if="result.success">
                <Auth_Text class="mb-5">{{ $t("auth.password-reset-success") }}</Auth_Text>
            </template>
            <template v-else>
                <Auth_Text class="mb-5">{{ $t("auth.password-reset-fail") }}</Auth_Text>
            </template>
        </template>

        <template v-slot:footer>

            <Auth_Button_Black v-if="result === null" @click.native="submit">
                {{ $t("auth.continue") }}
            </Auth_Button_Black>
        </template>
    </Layout_AuthView>
</template>

<script>

    import Layout_AuthView from "@/ui/layouts/Layout_AuthView";
    import Auth_Form_InputField from "@/ui/components/auth/Auth_Form_InputField";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import {mapActions} from "vuex";
    import Auth_Text from "@/ui/components/auth/Auth_Text";
    import Auth_Button_Black from "@/ui/components/auth/Auth_Button_Black";
    import RouterUtils from "@/routes/RouterUtils";

    export default {
        name: "AuthView_ResetPassword",
        components: { Auth_Button_Black, Auth_Text, Auth_Form_InputField, Layout_AuthView },
        data() {
            return {
                newPassword : null,
                loading : false,
                result : null
            }
        },
        methods : {
            async submit() {

                const { email, token } = this.$route.query;

                this.loading = true;

                this.result = await this.resetPassword({ email, token, password : this.newPassword });

                this.loading = false;

                setTimeout(() => {
                    RouterUtils.goHome();
                }, 2900);
            },
            ...mapActions(Modules.AUTH, {
                resetPassword : Actions.Auth.RESET_PASSWORD
            })
        }
    }
</script>
