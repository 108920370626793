<template>
    <b-nav-item class="sidebar-button" :disabled="disabled" v-bind:class="{small: small}" href="#">
        <div class="icon-holder">
            <img class="nav-image-icon" :src="icon" alt="Left image"/>
        </div>
        <slot></slot>
        <RouterLink_Overlay :to="url"/>
    </b-nav-item>
</template>

<script>
    import RouterLink_Overlay from "@/ui/components/common/RouterLink_Overlay";
    export default {
        name: "Sidebar_Button",
        components: { RouterLink_Overlay },
        props: {
            small: Boolean,
            disabled: Boolean,
            icon: String,
            url: [String, null]
        }
    }
</script>

<style scoped>

    .sidebar-button {
        border-bottom: 1px solid #373040;
        padding: 0.3em 0.3em;
        position: relative;
    }
    .sidebar-button .nav-image-icon {
        color: #ffffff;
        width: 1.3em;
    }
    .sidebar-button .icon-holder {
        display: inline-block;
        position: relative;
        margin-right: 1em;
    }
    .sidebar-button img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(346deg) brightness(108%) contrast(101%);
    }
    .sidebar-button.small {
        font-size: 0.8em;
        font-weight: 200;
        border-bottom: none;
        padding-bottom: 0;
    }
    .sidebar-button.small a {
        color: rgba(254, 253, 255, 0.71);
    }
    .sidebar-button.small .icon-holder {
        margin: 0 1.4em 0 0.2em;
    }
    .sidebar-button.small a.disabled {
        color: rgba(226, 225, 227, 0.51);
    }

    /** Hovering */

    .sidebar-button:hover img {
        filter: invert(58%) sepia(82%) saturate(3869%) hue-rotate(329deg) brightness(106%) contrast(100%);
    }
</style>
