<!-- Used for showing the users's users image in its small version. -->
<template>
    <img class="avatar" :src="userIcon" @click="navigateToUser" alt=""/>
</template>

<script>
    import User from "@/server/structures/User";
    import RouterUtils from "@/routes/RouterUtils";
    import Icons from "@/utils/Icons";

    export default {
        name: "Avatar_Small",
        props : { user: User, disableNavigation: Boolean },
        methods : {
            navigateToUser() {
                if(!this.user || this.disableNavigation) return;

                RouterUtils.goToUser(this.user.slug);
            }
        },
        computed : {
            userIcon() {
                if(!this.user) { return Icons._Placeholder.Profile; }

                if(!this.user.avatarUrl) { return Icons._Placeholder.Profile; }

                return this.user.avatarUrl;
            }
        }
    }
</script>

<style scoped>

    .avatar {
        width: 2.2em;
        height: 2.2em;
        margin: 0.2em;
        border-radius: 100%;
    }

    .avatar:hover {
        cursor: pointer;
    }
</style>
