<template>
    <div class="renderless-facebook-login" @click="login">
        <slot/>
    </div>
</template>

<script>
import EventBus from "@/events/EventBus";
import Events from "@/events/Events";
    /*global FB*/

    const FacebookStatus = {
        Connected : "connected",
        NotAuthorized : "not_authorized",
        Unknown : "unknown"
    };

    export default {
        name: "Renderless_FacebookLogin",
        data(){
            return {
                is_mobile_app: false
            }
        },
        mounted() {
            this.is_mobile_app = localStorage.getItem('is-mobile-app')
            let obj = this;
            EventBus.$on(Events.LoginWithFacebook, function (e){
                obj.emitLogin(e);
            })
        },
        methods: {
            login() {
                if(this.is_mobile_app) {
                    EventBus.$emit(Events.AddMobileAppEvent, {'event': 'facebook-login', 'data': {}})
                } else {
                    this.checkStatus();
                }
            },
            checkStatus() {
                FB.getLoginStatus((response) => {
                    if(this.responseStatusIsSuccess(response)) {
                        this.emitLogin(response);
                    }
                    else {
                        this.attemptLogin();
                    }
                });
            },
            attemptLogin() {
                FB.login((response) => {
                    if(this.responseStatusIsSuccess(response)) {
                        this.emitLogin(response);
                    }
                }, {scope: 'public_profile,email'});
            },
            responseStatusIsSuccess(response) {
                return response.status === FacebookStatus.Connected;
            },
            emitLogin(response) {
                this.$emit("login", response.authResponse.accessToken);
            }
        }
    }
</script>

<style>

    .renderless-facebook-login {
        width: 100%;
        height: 100%;
    }
</style>
