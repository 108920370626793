import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_GetNotifications from "@/server/responses/ApiResponse_GetNotifications";
import ApiResponse from "@/server/responses/ApiResponse";

export default class ApiProxy_Notifications extends ApiProxy {

    /**
     * Retrieves all notifications for the current user.
     * @return {Promise<ApiResponse_GetNotifications>}
     */
    async getNotifications() {

        return new ApiResponse_GetNotifications(await this.get('/'));
    }

    /**
     * Marks all notifications for the current user as read.
     * @return {Promise<ApiResponse>}
     */
    async markAllAsRead() {

        return new ApiResponse(await this.post('/mark-as-read'));
    }

    /**
     * Get Last unshown notification
     * @return {Promise<ApiResponse>}
     */
    async getLastUnshown() {

        return new ApiResponse(await this.get('/last-unshown'));
    }

    /**
     * Sends a firebase notification token to the back-end, to be used for targeting the device with notifications.
     * @param {String} token
     * @return {Promise<ApiResponse>}
     */
    async submitNotificationToken(token) {

        return new ApiResponse(await this.post('/notification-token', {token}))
    }
}
