<!-- Represents a post when seen inside a duel. -->

<template>
    <Layout_Post
        :post="post"
        :disableTrackViews="disableTrackViews"
        :previewMode="previewMode"
        class="duel-post"
        :class="{ challenged: !isChallenger }"
        :isClosed="isClosed"
        :isInDuelFeed="true"
        :show-actions="showActions"
        :show-comments="false"
    >
        <template v-slot:header>
            <Duel_Post_Header :isChallenger="isChallenger" :viewCount="post.viewCount" >
                {{ headerText }}
            </Duel_Post_Header>
        </template>
    </Layout_Post>
</template>

<script>

    import Post from "@/server/structures/Post";
    import Layout_Post from "@/ui/components/common/Layout_Post";
    import Duel_Post_Header from "@/ui/components/duels/Duel_Post_Header";

    export default {
        name: "Duel_Post",
        components: { Duel_Post_Header, Layout_Post },
        props: {
            headerText: String,
            isChallenger: Boolean,
            post: Post,
            disableTrackViews: Boolean,
            previewMode: {
                default: false
            },
            isClosed: Boolean,
            showActions: Boolean
        }
    }
</script>

<style>
    .duel-post {
        border: none;
    }
    .duel-post .folding-card-body{
        padding: 0;
    }
</style>
