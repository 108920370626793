import Vuex from 'vuex'
import Modules from "@/store/Modules";
import Actions from "@/store/Actions";
import Storage from "@/utils/local_storage/Storage";
import EventBus from "@/events/EventBus";
import Module_Posts from "@/store/modules/Module_Posts";
import Module_Auth from "@/store/modules/Module_Auth";
import Module_Tags from "@/store/modules/Module_Tags";
import Module_Users from "@/store/modules/Module_Users";
import Module_Comments from "@/store/modules/Module_Comments";
import Module_Localization from "@/store/modules/Module_Localization";
import Module_Leaderboards from "@/store/modules/Module_Leaderboards";
import Module_Favorites from "@/store/modules/Module_Favorites";
import Module_Duel_Actions from "@/store/modules/Module_Duel_Actions";
import StoreObjects from "@/store/StoreObjects";
import StoreFactories from "@/store/StoreFactories";
import StoreUtils from "@/utils/StoreUtils";
import Module_Badges from "@/store/modules/Module_Badges";
import Module_Rankings from "@/store/modules/Module_Rankings";
import Module_Notifications from "@/store/modules/Module_Notifications";

export default {
    init: init
};

function init(server, localizationClient) {
    const store = new Vuex.Store({
        modules: {
            [Modules.AUTH]:          Module_Auth(server.auth, server.social, server.apiClient, Storage, EventBus),
            [Modules.USERS]:         Module_Users(server.users),
            [Modules.POSTS]:         Module_Posts(server.posts),
            [Modules.TAGS]:          Module_Tags(server.tags),
            [Modules.COMMENTS]:      Module_Comments(server.comments),
            [Modules.LOCALIZATION]:  Module_Localization(server.localization, server.apiClient, localizationClient),
            [Modules.LEADERBOARDS]:  Module_Leaderboards(server.leaderboards),
            [Modules.FAVORITES]:     Module_Favorites(server.posts),
            [Modules.DUEL_ACTIONS]:  Module_Duel_Actions(server.duels),
            [Modules.BADGES]:        Module_Badges(server.badges),
            [Modules.RANKINGS]:      Module_Rankings(server.rankings),
            [Modules.NOTIFICATIONS]: Module_Notifications(server.notifications)
        },
        actions: {
            async [Actions.INITIALIZE]({dispatch}) {
                await dispatch(`${Modules.USERS}/${Actions.Users.INITIALIZE}`);
                dispatch(`${Modules.AUTH}/${Actions.Auth.INITIALIZE}`);
                dispatch(`${Modules.LOCALIZATION}/${Actions.Localization.INITIALIZE}`);
            }
        }
    });

    StoreObjects.init(store, server)

    StoreFactories.init(server);

    StoreUtils.init(store);

    return store;
}
