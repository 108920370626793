<template>
    <li class="info-answer-text ordered-item">
        <span class="text">
            <slot></slot>
        </span>
    </li>
</template>

<script>
    export default {
        name: "Info_ListItemOrdered"
    }
</script>

<style scoped>
    .ordered-item { 
        display: block;
        font-weight: 700;
    }
    .text {
        font-weight: 400;
    }
    .ordered-item::before { 
        counter-increment: item; 
        content: counters(item, ".") ".";
    }
</style>