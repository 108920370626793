import ModuleTemplate_Resource from "@/store/templates/ModuleTemplate_Resource";

/**
 * @param {string} userSlug
 * @param {ApiProxy_Users} usersApi
 */
export default function (userSlug, usersApi) {

    return ModuleTemplate_Resource(async () => {

        const result = await usersApi.getMap(userSlug);

        if(result.success) {
            return { success : true, data : result.map }
        }
        else {
            return { success : false, errors : result.errors }
        }
    });
}
