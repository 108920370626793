import ApiResponse from "@/server/responses/ApiResponse";
import User from "@/server/structures/User";


export default class ApiResponse_VerifyEmail extends ApiResponse {

    get errorMessage() {
        return 'errors.expired-verification-token';
    }

    get token() {
        return "Bearer " + this._rawResponse.headers.authorization;
    }

    get userData() {
        return new User(this._rawResponse.data);
    }
}
