<template>
    <Layout_AuthView class="verification-view" :loading="!verificationComplete">

        <template v-slot:default>
            <Auth_Text v-if="success" class="my-5">
                {{ $t('auth.email-verification-success') }}
            </Auth_Text>
            <Auth_Text v-else class="error text-center">
                {{ $t(errorMessage) }}
            </Auth_Text>
        </template>

        <template v-slot:footer>
            <Auth_Button_Black @click.native="redirect">
                {{ $t("auth.continue") }}
            </Auth_Button_Black>
        </template>
    </Layout_AuthView>
</template>

<script>
    import Actions from "@/store/Actions";
    import {mapActions} from "vuex";
    import Auth_Button_Black from "@/ui/components/auth/Auth_Button_Black";
    import Auth_Text from "@/ui/components/auth/Auth_Text";
    import Modules from "@/store/Modules";
    import RouterUtils from "@/routes/RouterUtils";
    import Layout_AuthView from "@/ui/layouts/Layout_AuthView";

    export default {
        name: "AuthView_VerifyEmail",
        components: { Layout_AuthView, Auth_Text, Auth_Button_Black },
        data() {
            return {
                verificationComplete: false,
                success: false,
                errorMessage: null
            }
        },
        mounted() {
            this.attemptVerify();
        },
        methods: {
            redirect() {
                RouterUtils.goHome();
            },
            async attemptVerify() {

                const { expires, signature, id, userId, hash } = this.$route.query;

                const result = await this.verifyEmail({ expires, signature, id, userId, hash });

                this.verificationComplete = true;
                this.success = result.success;
                if(!result.success) {
                    this.errorMessage = result.errorMessage;
                }
            },
            ...mapActions(Modules.AUTH, {
                verifyEmail : Actions.Auth.VERIFY_EMAIL
            })
        }
    }
</script>
