
export default {
    apiKey: "AIzaSyC3X3u-fTOodYcPvWSaC-lNYuXyC2UVZ6w",
    authDomain: "joketrip-ece99.firebaseapp.com",
    projectId: "joketrip-ece99",
    storageBucket: "joketrip-ece99.appspot.com",
    messagingSenderId: "1058884318936",
    appId: "1:1058884318936:web:b367f01c4b73f0323ac9ad",
    measurementId: "G-YZC61VTE43"
}
