<!-- The section of the component holding all of the social icons - like, favourite etc. -->

<template>
    <b-collapse ref="collapse" :visible="expanded">
        <div class="post-media-actions border-bottom py-2" :class="[!isInDuelFeed ? 'share-button-visible' : '', $mq]">
                <Post_ActionIcon
                    :icon="$icons.Post.Like"
                    :count="post.likeCount"
                    :is-selected="post.isLiked"
                    v-on:wasClicked="like(post)"
                />

                <Post_ActionIcon
                    :icon="$icons.Post.Dislike"
                    :count="post.dislikeCount"
                    :is-selected="post.isDisliked"
                    v-on:wasClicked="dislike(post)"
                />

                <Post_ActionIcon
                    :icon="$icons.Post.Favourite"
                    :is-selected="post.isFavorited"
                    v-popover="{ name : (authenticated ? '' : 'popover-login-favorite')}"
                    v-on:wasClicked="authenticated ? favorite(post) : ''"
                />

                <Post_ActionDropdown
                    :icon="$icons.Post.Report"
                    :post="post"
                    :isDuel="isInDuelFeed"
                />

                <Button_Share v-if="!isInDuelFeed" class="ml-auto" @click.native="onShareClick"/>
        </div>
    </b-collapse>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import Modules from "@/store/Modules";
    import Post_ActionIcon from "@/ui/components/feed/post/Post_ActionIcon";
    import Post_ActionDropdown from "@/ui/components/feed/post/Post_ActionDropdown";
    import Button_Share from "@/ui/components/common/Button_Share";
    import Actions from "@/store/Actions";
    import Post from "@/server/structures/Post";
    import ShareUtils from "@/utils/ShareUtils";

    export default {
        name: "Post_Section_Actions",
        components: { Post_ActionIcon, Button_Share, Post_ActionDropdown },
        props: {
            post: Post,
            isInDuelFeed: Boolean,
            expanded : { type: Boolean, default: true }
        },
        computed: {
            ...mapState(Modules.AUTH, { authenticated: "authenticated" })
        },
        methods: {
            async onShareClick() {

                const shareUrl = await this.share(this.post.id);

                const shareTitle = this.post.isText ? this.post.header + '...' : this.$t('posts.post-share-text');

                ShareUtils.share(shareTitle, shareUrl, this.post.tags);
            },
            ...mapActions(Modules.POSTS, {
                like: Actions.Posts.LIKE,
                dislike: Actions.Posts.DISLIKE,
                favorite: Actions.Posts.FAVORITE,
                share: Actions.Posts.SHARE
            })
        }
    }
</script>

<style>
.post-media-actions {
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
}

.post-media-actions.mobile-s .social-item-holder,
.post-media-actions.mobile-s .btn {
    transform: scale(0.8);
}
.post-media-actions.mobile-s .social-item-holder,
.post-media-actions.mobile-s.share-button-visible .btn {
    margin-right: auto;
}

</style>
