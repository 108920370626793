
export default {
    Auth : {
        IS_AUTHENTICATED : "isAuthorized"
    },
    Tags : {
        POPULAR: "getPopular",
        IS_SELECTED : "isSelected",
        PREVIEWED : "getPreviewed",
        GET_SELECTED : "getSelected"
    },
    Posts : {
        STATUS_IS : "statusIs",
        STATUS_IS_READY : "statusIsReady",
        STATUS_IS_LOADING : "statusIsLoading",
        STATUS_IS_ERROR : "statusIsError",
        GET_ERROR_MESSAGE : "getErrorMessage",
        GET_LAST_PAGE_REACHED : "getLastPageReached",
        GET_POSTS : "getPosts",
    },
    Comments : {
        GET_FOR_POST : "getForPost",
        HAS_MORE_COMMENTS : "hasMoreComments",
        COMMENTS_ARE_LOADING : "commentsAreLoading",
        COMMENTS_PER_PAGE : "commentsPerPage"
    },
    Users : {
        IS_FOLLOWING : "isFollowing",
        GET_ME : "getMe",
        GET_BY_SLUG : "getBySlug",
        IS_ME : "isMe"
    },
    Localization : {
        GET_REGION : "getRegion",
        GET_LANGUAGE : "getLanguage"
    },
    Leaderboards : {
        GET_STATUS : "getStatus",
        GET_ENTRIES : "getEntries",
        CATEGORY : "getCurrentCategory",
        SHOW_MY_POSITION : "showMyPosition"
    },
    Badges : {
        GET_STATUS : "getStatus",
        GET_CATEGORIES : "getCategories"
    },
    Notifications : {
        GET_STATUS : "getStatus",
        GET_NOTIFICATIONS : "getNotifications",
        HAS_UNREAD : "getHasUnreadNotifications"
    },
    Rankings : {
        GET_POSTS       : "getPosts",
        GET_STATUS      : "getStatus",
        GET_CATEGORY    : "getCategory"
    },
    Collection : {
        STATUS_IS : "statusIs",
        STATUS_IS_READY : "statusIsReady",
        STATUS_IS_LOADING : "statusIsLoading",
        STATUS_IS_ERROR : "statusIsError",
        GET_ERROR_MESSAGE : "getErrorMessage",
        GET_LAST_PAGE_REACHED : "getLastPageReached",
        GET_ENTRIES : "getEntries",
    }
}

