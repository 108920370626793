<template>
    <div>
        <Sidebar_Button class="sidebar-collapse-button" :icon="$icons.Language" @click.native="toggleCollapse">
            {{ $t("general.region") + " : " }} <span class="text-capitalize text-region-name">{{ this.textFor(currentRegion) }}</span>
        </Sidebar_Button>

        <Sidebar_Collapse
                :visible="collapseVisible"
                :items="regions"
                v-on:select="selectRegion"
        />
    </div>
</template>

<script>
    import Sidebar_Button from "@/ui/components/sidebar/Sidebar_Button";
    import Sidebar_Collapse from "@/ui/components/sidebar/Sidebar_Collapse";
    import RegionValues from "@/store/values/RegionValues";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import {mapGetters, mapMutations} from "vuex";
    import Mutations from "@/store/Mutations";

    export default {
        name: "Sidebar_Button_Region",
        components: { Sidebar_Collapse, Sidebar_Button },
        data() {
            return {
                collapseVisible : false
            }
        },
        computed: {
            regions() {
                return RegionValues.map((regionKey) => {
                    return {
                        key: regionKey,
                        value: this.textFor(regionKey),
                        selected: regionKey === this.currentRegion
                    }
                });
            },
            ...mapGetters(Modules.LOCALIZATION, {
                currentRegion: Getters.Localization.GET_REGION
            })
        },
        methods: {
            textFor(region) {
               return this.$t(`general.region-${region}`);
            },
            selectRegion(region) {
                this.setRegion(region.key);
                this.collapseVisible = false;
                this.$emit("selected");
            },
            toggleCollapse() {
                this.collapseVisible = !this.collapseVisible;
            },
            ...mapMutations(Modules.LOCALIZATION, {
                setRegion : Mutations.Localization.SET_REGION
            }),
            hide() {
                this.collapseVisible = false;
            }
        }
    }
</script>

<style scoped>
    .sidebar-collapse-button /deep/ .icon-holder {
        margin-right: 0.75em;
    }

    .text-region-name {
        color: #ff6154;
    }
</style>
