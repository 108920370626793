<template>
    <span
        class="post-clickable-hashtag"
        v-on:click="handleClick()"
        :class="{ active : isSelected(tag) }"
    >
        #{{ tag.name }}
    </span>
</template>

<script>

    import { mapGetters, mapMutations } from 'vuex';
    import Modules from "@/store/Modules";
    import Mutations from "@/store/Mutations";
    import Getters from "@/store/Getters";

    export default {
        name: "Post_Tag",
        props: {
            tag: Object
        },
        computed: {
            ...mapGetters(Modules.TAGS, {
                isSelected : Getters.Tags.IS_SELECTED
            })
        },
        methods: {
            ...mapMutations(Modules.TAGS, {
                setSelected : Mutations.Tags.SET_SELECTED
            }),
            handleClick() {
                this.setSelected(this.tag);
            }
        },

    }
</script>

<style>

    .post-clickable-hashtag {
        text-decoration: underline;
        margin-right: 1em;
        color: #6f6f6f;
        font-size: 0.9em;
        display: inline-block;
    }

    .post-clickable-hashtag:hover {
        cursor: pointer;
        color: #ff6154;
    }

    .post-clickable-hashtag.active {
        color: #ff6154;
    }
</style>
