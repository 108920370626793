export default {

    Logo : require('../assets/icons/logo.svg'),

    WhiteLogo : require('../assets/icons/logo-white.svg'),

    Home : require('../assets/icons/JT_icons01_64px_Home.svg'),

    TopTen : require('../assets/icons/JT_icons01_64px_Cup.svg'),

    Badges : require('../assets/icons/JT_icons01_64px_Badge.svg'),

    Leaderboard : require('../assets/icons/JT_icons01_64px_Leaderboard.svg'),

    Favorites : require('../assets/icons/JT_icons01_64px_Favorites.svg'),

    Notifications : require('../assets/icons/JT_icons01_64px_Notification.svg'),

    Language : require('../assets/icons/JT_icons01_64px_Language.svg'),

    Faq : require('../assets/icons/JT_icons01_64px_FAQ.svg'),

    EliteUser : require('../assets/icons/JT_icons01_64px_EliteUser.svg'),

    LogOut : require('../assets/icons/JT_icons01_64px_LogOut.svg'),

    Duels : require('../assets/icons/JT_icons01_64px_Duels.svg'),

    Rocket : require('../assets/icons/JT_icons01_64px_JokeTrip.svg'),

    TopNav : {

        GuestAvatar : require('../assets/icons/JT_icons01_64px_Join.svg'),

        Hamburger : require('../assets/icons/hamburger.svg')
    },

    Auth : {

        FacebookLogo : require('../assets/logo-fb.png'),

        GoogleLogo : require('../assets/logo-g.png'),

        Help : require('../assets/icons/JT_icons01_64px_Help1.svg')
    },

    Common : {

        ArrowLeft : require('../assets/icons/JT_icons01_64px_Left.svg'),

        ArrowRight : require('../assets/icons/JT_icons01_64px_Right.svg'),

        ArrowDown : require('../assets/icons/arrow-down.svg'),

        Close : require('../assets/icons/close.svg'),

        Plus : require('../assets/icons/plus.svg'),

        Post : require('../assets/icons/JT_icons01_64px_Post.svg'),

        Help1 : require('../assets/icons/JT_icons01_64px_Help1.svg'),

        Help2 : require('../assets/icons/JT_icons01_64px_Help2.svg'),

        Sort : require('../assets/icons/JT_icons01_64px_Sort.svg')
    },

    Feed : {

        New : require('../assets/icons/JT_icons01_64px_New.svg'),

        Hot : require('../assets/icons/JT_icons01_64px_Hot.svg'),

        Short : require('../assets/icons/JT_icons01_64px_Shorts.svg'),

        Recommended : require('../assets/icons/JT_icons01_64px_Recommended.svg'),

        Duels : require('../assets/icons/JT_icons01_64px_Duels.svg'),

        SeenOn : require('../assets/icons/JT_icons01_64px_SeenOn.svg'),

        SeenOff : require('../assets/icons/JT_icons01_64px_SeenOff.svg'),

        Search : require('../assets/icons/JT_icons01_64px_Search.svg')
    },

    PostCreation : {

        UploadImage : require('../assets/icons/JT_icons01_64px_Photo.svg'),

        AddTag : require('../assets/icons/JT_icons01_64px_Add.svg')
    },

    Post : {

        Like : require('../assets/icons/JT_icons01_64px_Like.svg'),

        Dislike : require('../assets/icons/JT_icons01_64px_Dislike.svg'),

        Favourite : require('../assets/icons/JT_icons01_64px_AddFavorites.svg'),

        Report : require('../assets/icons/JT_icons01_64px_More.svg'),

        Comments : require('../assets/icons/JT_icons01_64px_Comments.svg'),

        Places : require('../assets/icons/JT_icons01_64px_Places.svg'),

        Share : require('../assets/icons/JT_icons01_64px_Share.svg')
    },

    Users : {

        Details : require('../assets/icons/JT_icons01_64px_EliteUser.svg'),

        JokeTrip : require('../assets/icons/JT_icons01_64px_JokeTrip.svg'),

        Duels : require('../assets/icons/JT_icons01_64px_Duels.svg'),

        Settings : require('../assets/icons/JT_icons01_64px_Settings.svg'),

        FollowOff : require('../assets/icons/JT_icons01_64px_Follow.svg'),

        FollowOn : require('../assets/icons/JT_icons01_64px_Following.svg'),
    },

    SocialMedia : {

        Copy : require('../assets/icons/copy_icon.svg'),

        Facebook : require('../assets/icons/facebook_colored.svg'),

        Twitter : require('../assets/icons/twitter_colored.svg'),

        Viber : require('../assets/icons/viber_colored.svg'),

        Skype : require('../assets/icons/skype_colored.svg'),

        VK: require('../assets/icons/vkontake_colored.svg'),
    },

    ExperienceBarSmall : {

        Basic : require('../assets/icons/xp_bar_small/xp_bar_basic.svg'),

        Advanced : require('../assets/icons/xp_bar_small/xp_bar_advanced.svg'),

        Expert : require('../assets/icons/xp_bar_small/xp_bar_expert.svg'),

        Pro : require('../assets/icons/xp_bar_small/xp_bar_pro.svg'),
    },

    DuelsIcons : {
        Accept : require('../assets/icons/tick.svg'),
        Refuse : require('../assets/icons/cross.svg'),
        Remind : require('../assets/icons/clock.svg'),
        UserChallenger : require('../assets/icons/JT_icons01_64px_ChallengerOrange.svg'),
        UserChallenged : require('../assets/icons/JT_icons01_64px_ChallengerYellow.svg'),
        PostChallenger : require('../assets/icons/JT_icons01_64px_Challenger.svg'),
        PostChallenged : require('../assets/icons/JT_icons01_64px_Challenged.svg')
    },

    AboutUs : {
        Nikolai : require('../assets/info/Nikolai.png'),
        Veselin : require('../assets/info/Veselin.png'),
        Teodor : require('../assets/info/Teodor.png')
    },

    Map: {
        Marker : require('../assets/icons/JT_icons01_64px_Pin.svg')
    },

    _Placeholder : {

        Profile : require('../assets/icons/JT_icons01_64px_Join.svg')
    }
};
