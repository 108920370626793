<template>
    <div class="post-creation-modal-header">
        <div>
            <h4 class="modal-title"> {{ titleText }} </h4>
            <span class="modal-subtitle">
                {{ $t('general.in-region')  }}
                <span class="modal-region-name">{{ $t(`general.region-${this.currentRegion}`) }}</span>
            </span>
        </div>
        <img class="close-icon" :src="$icons.Common.Close" alt="X" @click="$emit('close-click')"/>
    </div>
</template>

<script>
import RouterUtils from "@/routes/RouterUtils";

export default {
    name: "PostCreation_ModalSection_Header",
    props: {
        currentRegion: String
    },
    computed : {
        titleText() {
            if(RouterUtils.isOnDuelFeed(this.$route.path)) {
                return this.$t("duels.create-a-duel");
            }
            else {
                return this.$t("posts.create-a-joke");
            }
        }
    }
}
</script>

<style scoped>

.post-creation-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.modal-title,
.modal-subtitle {
    color: #6f6f6f;
}

.modal-region-name {
    color: #5b5b5b;
    font-weight: 800;
}

.close-icon {
    height: 20px;
    filter: invert(1) brightness(50%);
}

</style>
