<template>
    <div class="spinner-container">
        <b-spinner variant="light" class="spinner"></b-spinner>
    </div>
</template>

<script>
    export default {
        name: "Spinner"
    }
</script>

<style scoped>
    .spinner-container {
    }
    .spinner-container .spinner {
        width: 8rem;
        height: 8rem;
    }
    .spinner-container.small .spinner {
        width: 4rem;
        height: 4rem;
    }
    .spinner-container.orange .spinner {
        filter: invert(56%) sepia(34%) saturate(3193%) hue-rotate(329deg) brightness(105%) contrast(99%);
    }
    .spinner-container.center-absolute {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
</style>
