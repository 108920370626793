<template>
    <div class="py-3 pr-1 sidebar-content-select">
        <Select_Horizontal
            class="bright font-size-small text-uppercase font-weight-normal"
            :selected="currentContentType"
            :options="contentTypeOptions"
            v-on:select="selectContentType"
        />
    </div>
</template>

<script>
import Select_Horizontal from "@/ui/components/common/Select_Horizontal";
import ContentTypeValues from "@/store/values/ContentTypeValues";
import RouterUtils from "@/routes/RouterUtils";

export default {
    name: "Sidebar_Select_Content",
    components: { Select_Horizontal },
    computed: {
        currentContentType() {
            return this.$route.params.contentType;
        },
        filter() {
            return this.$route.params.filter;
        },
        contentTypeOptions() {
            return ContentTypeValues.map(this.generateItem);
        }
    },
    methods: {
        selectContentType(contentType) {
            if (this.currentContentType === contentType) { return; }

            RouterUtils.goToFeed(contentType, this.filter ? this.filter : '');
        },
        generateItem(key) {
            return {
                id: key,
                key: key,
                value: this.$t("general." + (key ? key : "browse"))
            }
        }
    }
}
</script>

<style scoped>

.sidebar-content-select {
    border-bottom: 1px solid #373040;
}

</style>
