<template>
    <Text_NoResults v-if="postCollection.isEmpty && !postCollection.loading">
        {{ $t("errors.no-posts") }}
    </Text_NoResults>
    <div v-else>
        <Select_Horizontal class="mb-4" :options="userPostTypes" :selected="userPostType" v-on:select="selectPostType"/>

        <Post_Component v-for="post in postCollection.posts"
                        v-bind:key="post.id"
                        :post="post"
        />

        <template v-if="postCollection.loading">
            <Post_List_Loading :count="3"/>
        </template>

    </div>
</template>

<script>
    import User from "@/server/structures/User";
    import UserPostTypes from "@/store/types/UserPostTypes";
    import UserPostCollection from "@/store/structures/UserPostCollection";
    import {mapActions} from "vuex";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import Post_Component from "@/ui/components/feed/post/Post_Component";
    import Select_Horizontal from "@/ui/components/common/Select_Horizontal";
    import Text_NoResults from "@/ui/components/common/Text_NoResults";
    import Post_List_Loading from "@/ui/components/feed/post/Post_List_Loading";

    export default {
        name: "UserPosts",
        components: { Post_List_Loading, Select_Horizontal, Post_Component, Text_NoResults },
        props : { user: { type: User, required: true } },
        data() {
            return {
                userPostType : UserPostTypes.Best,
                postCollection : UserPostCollection.empty(),
            }
        },
        watch : {
            userPostType() {
                this.pull();
            },
            user() {
                this.pull();
            }
        },
        mounted() {
            this.pull();
        },
        computed : {
            userPostTypes() {
                return Object.values(UserPostTypes).map((postType) => {
                    return { key: postType, value: this.$t('users.' + postType) }
                });
            }
        },
        methods : {
            async pull() {
                this.postCollection = await this.getPostsForUser({
                    userId : this.user.id,
                    userPostType : this.userPostType,
                    limit : 100
                });
            },
            selectPostType(type) {
                this.userPostType = type;
            },
            ...mapActions(Modules.POSTS, {
                getPostsForUser : Actions.Posts.GET_FOR_USER
            })
        }
    }
</script>

<style scoped>

</style>
