<template>
    <b-modal ref="statistics-modal" id="statistics-modal">
        <template #modal-header>
            <div class="header-close">
                <img class="header-close-icon" :src="$icons.DuelsIcons.Refuse" alt="Close" @click="hide()" />
            </div>
            <Duel_Statistics_Header :duel="duel" />
        </template>
        <template #default>
            <Duel_Statistics :duel="duel" />
        </template>
        <template #modal-footer>
            <span @click="hide()"> 
                {{ $t("duels.close") }} 
                <img class="close-icon" :src="$icons.DuelsIcons.Refuse" alt="Close" />
            </span>
        </template>
    </b-modal>
</template>

<script>
    
    import Duel_Statistics_Header from "@/ui/components/duels/Duel_Statistics_Header";
    import Duel_Statistics from "@/ui/components/duels/Duel_Statistics";
    
    import Events from "@/events/Events";
    import EventBus from "@/events/EventBus";

    export default {
        name: "Duel_Modal_Statistics",
        components: { Duel_Statistics, Duel_Statistics_Header },
        data: function() {
            return {
                duel: null     
            }
        },
        watch: {
            $route(to, from) {
                if(from != to) {
                    this.hide()
                }
            }
        },
        mounted() {
            EventBus.$on(Events.VoteBarClicked, this.show);
        },
        beforeDestroy() {
            EventBus.$off(Events.VoteBarClicked, this.show);
        },
        methods: {
            show(duel) {
                this.duel = duel;
                this.$refs['statistics-modal'].show();
            },
            hide() {
                this.$refs['statistics-modal'].hide();
            },
        }

    }
</script>

<style scoped>
    * /deep/ .modal-content {
        padding-top: 1em;
        padding-bottom: 1em;
        transform: translate(0%, 5%);
    }
    * /deep/ .modal-header {
        border: none;
        display: contents;
    }
    .header-close {
        height: 1em;
        display: flex;
        justify-content: flex-end;
    }
    * /deep/ .header-close-icon {
        height: 0.8rem;
        filter: invert(58%) sepia(10%) saturate(8%) hue-rotate(319deg) brightness(90%) contrast(90%);
    }
    * /deep/ .modal-footer {
        padding: 0 0 0 1em;
        border: none;
        justify-content: center;
        text-transform: lowercase;
    }
    * /deep/ .modal-footer .close-icon {
        height: 0.8rem;
        margin-left: 0.3rem;
    }
    * /deep/ .modal-footer .close-icon:hover {
        cursor: pointer;
    }
</style>