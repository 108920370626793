<template>
    <div class="position-relative">
        <template v-if="statusIsReady">
            <Post_Component v-for="post in posts" :key="post.id" :post="post"/>
        </template>
        <template v-else-if="statusIsLoading">
            <Post_List_Loading :count="10"/>
        </template>
        <template v-else-if="statusIsError">
            <Text_NoResults>{{ errorMessage }}</Text_NoResults>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Modules from "@/store/Modules";
import Getters from "@/store/Getters";
import Actions from "@/store/Actions";
import Text_NoResults from "@/ui/components/common/Text_NoResults";
import Post_List_Loading from "@/ui/components/feed/post/Post_List_Loading";
import Post_Component from "@/ui/components/feed/post/Post_Component";

export default {
    name: "Rankings_List_Posts",
    components: { Post_Component, Post_List_Loading, Text_NoResults },
    mounted() {
        this.reload();
    },
    computed: {
        ...mapGetters(Modules.RANKINGS, {
            statusIsReady   : Getters.Collection.STATUS_IS_READY,
            statusIsLoading : Getters.Collection.STATUS_IS_LOADING,
            statusIsError   : Getters.Collection.STATUS_IS_ERROR,
            posts           : Getters.Rankings.GET_POSTS,
            errorMessage    : Getters.Collection.GET_ERROR_MESSAGE
        })
    },
    methods: {
        ...mapActions(Modules.RANKINGS, {
            reload          : Actions.Rankings.RELOAD
        })
    }
}
</script>
