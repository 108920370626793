<template>
    <b-popover
            id="popover-leaderboard"
            :target="target"
            :show="show"
            triggers="hover"
            placement="bottom"
            custom-class="popover-leaderboard"
    >
        <div v-for="option in options"
             :key="option.key"
             :class="{selected: isSelected(option)}"
             class="option"
             @click="$emit('select', option.key)"
        >
            {{ option.value }}
        </div>
    </b-popover>
</template>

<script>
    export default {
        name: "Leaderboard_Popover_Category",
        props: {
            target: String,
            options : Array,
            show: Boolean,
            selected: null
        },
        methods: {
            isSelected(option) {
                return option.key === this.selected;
            },
        }
    }
</script>

<style>
    .popover-leaderboard .option {
        color: #f3f3f3;
        padding-top: 8px;
        padding-bottom: 12px;
        border-bottom: 1px solid #493f56;
        font-size: 1.02rem;
        font-weight: 400;
    }
    .popover-leaderboard .option:hover {
        cursor: pointer;
    }
    .popover-leaderboard .option.selected {
        color: #fc7e54;
    }
    .popover-leaderboard .option:last-of-type {
        border-bottom: none;
    }
    .popover-leaderboard .popover-body {
        z-index: 300;
        padding-left : 1.6em;
        padding-right: 1.6em;
        padding-bottom: 0.4em;
        border-radius: 7px;
        border: none;
        background-color: #2a2333;
    }
    .popover-leaderboard .arrow {
        filter: invert(12%) sepia(24%) saturate(719%) hue-rotate(224deg) brightness(4%) contrast(91%);;
    }
</style>
