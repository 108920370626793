<template>
    <expandable-image v-if="isDesktop" class="post-image" :src="imageUrl" alt="Post Image"/>
    <img v-else class="post-image" :src="imageUrl" alt="Post Image"/>
</template>

<script>
    export default {
        name: "Post_Section_Image",
        props: {
            imageUrl: String
        },
        computed: {
            isDesktop() {
                return this.$mq === "desktop"
            }
        }
    }
</script>

<style scoped>

    .post-image {
        width: 100%;
    }
</style>
