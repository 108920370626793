<template>
    <h6 class="common-header-small"><slot/></h6>
</template>

<script>
    export default { name: "Header_Small" }
</script>

<style>
    .common-header-small {
        font-size: 13pt;
        font-weight: 400;
        color: #565654;
    }
</style>
