<template>
    <Layout_LazyLoadFeed
        :load-margin-px="400"
        :last-page-reached="lastPageReached"
        :enable-loading="resultsAreReady"
        v-on:loadMore="loadMore"
    >
        <Users_Follower v-for="user in entries" :key="user.id" :user="user"/>

        <template v-if="resultsAreLoading">
            <Users_Follower_Loading v-for="index in 5" :key="-index"/>
        </template>

        <Text_NoResults v-if="resultsAreReady && collectionEmpty">{{ noResultsMessage }}</Text_NoResults>

    </Layout_LazyLoadFeed>
</template>

<script>
import Mixin_CollectionComponent from "@/ui/mixins/Mixin_CollectionComponent";
import Layout_LazyLoadFeed from "@/ui/layouts/Layout_LazyLoadFeed";
import Users_Follower from "@/ui/components/users/Users_Follower";
import Users_Follower_Loading from "@/ui/components/users/Users_Follower_Loading";
import Text_NoResults from "@/ui/components/common/Text_NoResults";

export default {
    name: "Users_Feed",
    components: { Text_NoResults, Users_Follower_Loading, Users_Follower, Layout_LazyLoadFeed },
    mixins: [Mixin_CollectionComponent],
    props: { noResultsMessage: String }
}
</script>
