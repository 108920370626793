import Actions from "@/store/Actions";
import Modules from "@/store/Modules";
import Mutations from "@/store/Mutations";
import Getters from "@/store/Getters";
import Events from "@/events/Events";

/**
 * @param {ApiProxy_Auth} authApi
 * @param {ApiProxy_SocialProvider} socialApi
 * @param apiClient
 * @param localStorage
 * @param eventBus
 */
export default function (authApi, socialApi, apiClient, localStorage, eventBus) {
    return {
        namespaced: true,
        state: () => ({
            authenticated: false,
        }),
        getters: {
            [Getters.Auth.IS_AUTHENTICATED](state) {
                return state.authenticated;
            }
        },
        mutations: {
            [Mutations.Auth._SET_SUCCESSFUL_LOGIN](state, token) {
                state.authenticated = true;
                localStorage.AuthToken.value = token;
                apiClient.setAuthToken(token);
            },
            [Mutations.Auth._LOGOUT](state) {
                state.authenticated = false;
                localStorage.AuthToken.remove();
                apiClient.removeAuthToken();
            }
        },
        actions: {
            async [Actions.Auth.INITIALIZE]({commit, dispatch}) {

                if (!localStorage.AuthToken.exists) {
                    return;
                }

                apiClient.setAuthToken(localStorage.AuthToken.value);

                const currentUserResponse = await dispatch(`${Modules.USERS}/${Actions.Users.PULL_ME}`, null, {root: true});

                if (currentUserResponse.success) {
                    commit(Mutations.Auth._SET_SUCCESSFUL_LOGIN, localStorage.AuthToken.value);

                    sendNotificationToken(dispatch);
                } else {
                    await dispatch(Actions.Auth.LOGOUT);
                }
            },
            async [Actions.Auth.REGISTER](context, {name, email, password}) {

                return await authApi.register(name, email, password);
            },
            async [Actions.Auth.VERIFY_EMAIL](context, {expires, hash, id, userId, signature}) {

                const response = await authApi.verifyEmail(expires, hash, id, userId, signature);

                if (response.success) {

                    setSuccessfulLogin(context, response.token, response.userData);
                }

                return response;
            },
            async [Actions.Auth.LOGIN](context, {email, password}) {

                return handleLoginResponse(context, await authApi.login(email, password));
            },
            async [Actions.Auth.LOGIN_FACEBOOK](context, {token}) {

                return handleLoginResponse(context, await socialApi.facebookLogin(token));
            },
            async [Actions.Auth.LOGIN_GOOGLE](context, {token}) {

                return handleLoginResponse(context, await socialApi.googleLogin(token));
            },
            async [Actions.Auth.FORGOT_PASSWORD](context, {email}) {

                return await authApi.forgotPassword(email);
            },
            async [Actions.Auth.RESET_PASSWORD](context, {email, token, password}) {

                return await authApi.resetPassword(email, token, password);
            },
            async [Actions.Auth.CHANGE_PASSWORD](context, {password, passwordConfirmation}) {

                return await authApi.changePassword(password, passwordConfirmation);
            },
            async [Actions.Auth.LOGOUT]({commit}) {

                commit(Mutations.Auth._LOGOUT);

                commit(`${Modules.USERS}/${Mutations.Users.REMOVE_SELF}`, null, {root: true});
            }
        }
    };

    function handleLoginResponse(context, response) {

        if (response.success) {
            setSuccessfulLogin(context, response.token, response.userData);
        }

        return response;
    }

    function setSuccessfulLogin({commit, dispatch}, token, userData) {
        commit(Mutations.Auth._SET_SUCCESSFUL_LOGIN, token);
        commit(`${Modules.USERS}/${Mutations.Users.SET_SELF}`, userData, {root: true});

        sendNotificationToken(dispatch);

        eventBus.$emit(Events.LoginSuccessful);
    }

    function sendNotificationToken(dispatch) {
        eventBus.$emit(Events.InitNotification);
        setTimeout(function () {

            dispatch(`${Modules.NOTIFICATIONS}/${Actions.Notifications.SUBMIT_TOKEN}`, null, {root: true});
        }, 3000);
    }
}
