<template>
    <Info_NestedList>
        <Info_Header> {{ $t("terms.1-intro") }} </Info_Header>
        <Info_ListItemOrdered>
            {{ $t("terms.1-1-first")}}
            <router-link to="/">www.joketrip.com.</router-link>
            {{ $t("terms.1-1-second") }}
        </Info_ListItemOrdered>
            
        <Info_Header> {{ $t("terms.2-definitions") }} </Info_Header>
        <Info_ListOrderedAlpha style="padding-inline-start: 0px;">
            <Info_ListBullet>{{ $t("terms.2-1") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-2") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-3") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-4") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-5") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-6") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-7") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-8") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-9") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-10") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-11") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-12") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-13") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-14") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("terms.2-15") }}</Info_ListBullet>
        </Info_ListOrderedAlpha>

        <Info_Header> {{ $t("terms.3-description") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.3-1") }} </Info_ListItemOrdered>
        
        <Info_Header> {{ $t("terms.4-conditions") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.4-1") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.5-usage") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.5-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.5-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.5-3") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.5-4") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.6-registration") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.6-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.6-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.6-3") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.7-profile") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.7-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.7-2") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.8-feed") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.8-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.8-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.8-3") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.8-4") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.8-5") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.9-joke-trip") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.9-1") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.10-duels") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.10-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.10-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.10-3") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.10-4") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.11-badges") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.11-1") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.12-leaderboards") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.12-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.12-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.12-3") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.12-4") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.12-5") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.13-your-content") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.13-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.13-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.13-3") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.13-4") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.13-5") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.13-6") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.13-7") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.14-third-party") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.14-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.14-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.14-3") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.14-4") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.14-5") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.15-intelectual-property") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.15-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.15-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.15-3") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.16-usage-rules") }} </Info_Header>
        <Info_ListItemOrdered>
            {{ $t("terms.16-1") }}
            <Info_NestedList>
                <Info_ListItemOrdered> {{ $t("terms.16-1-1") }} </Info_ListItemOrdered>
                <Info_ListItemOrdered> {{ $t("terms.16-1-2") }} </Info_ListItemOrdered>
                <Info_ListItemOrdered> {{ $t("terms.16-1-3") }} </Info_ListItemOrdered>
            </Info_NestedList>
        </Info_ListItemOrdered>
        <Info_ListItemOrdered>
            {{ $t("terms.16-2") }}
            <Info_NestedList>
                <Info_ListItemOrdered> {{ $t("terms.16-2-1") }} </Info_ListItemOrdered>
                <Info_ListItemOrdered> {{ $t("terms.16-2-2") }} </Info_ListItemOrdered>
                <Info_ListItemOrdered>
                    {{ $t("terms.16-2-3") }} 
                    <Info_ListOrderedAlpha>
                        <Info_ListBullet>{{ $t("terms.16-2-3-1") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-2-3-2") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-2-3-3") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-2-3-4") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-2-3-5") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-2-3-6") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-2-3-7") }}</Info_ListBullet>
                    </Info_ListOrderedAlpha>
                </Info_ListItemOrdered>
            </Info_NestedList>
        </Info_ListItemOrdered>
        <Info_ListItemOrdered>
            {{ $t("terms.16-3") }}
            <Info_NestedList>
                <Info_ListItemOrdered>
                    {{ $t("terms.16-3-1") }}
                    <Info_ListOrderedAlpha>
                        <Info_ListBullet>{{ $t("terms.16-3-1-1") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-3-1-2") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-3-1-3") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-3-1-4") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-3-1-5") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-3-1-6") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-3-1-7") }}</Info_ListBullet>
                        <Info_ListBullet>{{ $t("terms.16-3-1-8") }}</Info_ListBullet>
                    </Info_ListOrderedAlpha>
                </Info_ListItemOrdered>
            </Info_NestedList>
        </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.16-4") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.17-usage-standards") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.17-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.17-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.17-3") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.17-4") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.17-5") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.18-personal-safety") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.18-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.18-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.18-3") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.18-4") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.18-5") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.19-information-security") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.19-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.19-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.19-3") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.20-greater-force") }} </Info_Header>
        <Info_ListItemOrdered>
            {{ $t("terms.20-1") }}
            <Info_ListOrderedAlpha>
                <Info_ListBullet>{{ $t("terms.20-1-1") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.20-1-2") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.20-1-3") }}</Info_ListBullet>
            </Info_ListOrderedAlpha>
        </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.21-responsibilities") }} </Info_Header>
        <Info_ListItemOrdered>
            {{ $t("terms.21-1") }}
            <Info_ListOrderedAlpha>
                <Info_ListBullet>{{ $t("terms.21-1-1") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.21-1-2") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.21-1-3") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.21-1-4") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.21-1-5") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.21-1-6") }}</Info_ListBullet>
            </Info_ListOrderedAlpha>
        </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.22-usage-termination") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.22-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered>
            {{ $t("terms.22-2") }}
            <Info_ListOrderedAlpha>
                <Info_ListBullet>{{ $t("terms.22-2-1") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.22-2-2") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.22-2-3") }}</Info_ListBullet>
            </Info_ListOrderedAlpha>
        </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.22-3")}} </Info_ListItemOrdered>
        <Info_ListItemOrdered>
            {{ $t("terms.22-4") }}
            <Info_ListOrderedAlpha>
                <Info_ListBullet>{{ $t("terms.22-4-1") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.22-4-2") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.22-4-3") }}</Info_ListBullet>
            </Info_ListOrderedAlpha>
        </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.23-account-disable") }} </Info_Header>
        <Info_ListItemOrdered>
            {{ $t("terms.23-1") }}
            <Info_ListOrderedAlpha>
                <Info_ListBullet>{{ $t("terms.23-1-1") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.23-1-2") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.23-1-3") }}</Info_ListBullet>
            </Info_ListOrderedAlpha>
        </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.23-2") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.23-3") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.23-4") }} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.24-condition-termination") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.24-1")}} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.25-condition-change") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.25-1") }} </Info_ListItemOrdered>
        
        <Info_Header> {{ $t("terms.26-applicable-law") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.26-1")}} </Info_ListItemOrdered>

        <Info_Header> {{ $t("terms.27-consumer-protection") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.27-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered>
            {{ $t("terms.27-2") }}
            <Info_ListOrderedAlpha>
                <Info_ListBullet>{{ $t("terms.27-2-1") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("terms.27-2-2") }}</Info_ListBullet>
            </Info_ListOrderedAlpha>
        </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.27-3") }} </Info_ListItemOrdered>
        
        <Info_Header> {{ $t("terms.28-contact") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.28-1") }} </Info_ListItemOrdered>
        
        <Info_Header> {{ $t("terms.29-others") }} </Info_Header>
        <Info_ListItemOrdered> {{ $t("terms.29-1") }} </Info_ListItemOrdered>
        <Info_ListItemOrdered> {{ $t("terms.29-2") }} </Info_ListItemOrdered>

    </Info_NestedList>
</template>

<script>

    import Info_ListOrderedAlpha from "@/ui/components/info/Info_ListOrderedAlpha";
    import Info_ListBullet from "@/ui/components/info/Info_ListBullet";
    import Info_ListItemOrdered from "@/ui/components/info/Info_ListItemOrdered";
    import Info_NestedList from "@/ui/components/info/Info_NestedList";
    import Info_Header from "@/ui/components/info/Info_Header";
    
    export default {
        name: "InfoView_TermsAndConditions",
        components: { Info_Header, Info_NestedList, Info_ListOrderedAlpha, Info_ListBullet, Info_ListItemOrdered},
    }
</script>