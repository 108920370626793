<template>
    <Panel_White class="px-4 py-3">

        <UserDetails_Section_Header :user="user"/>

        <UserDetails_Section_Experience :user="user"/>

        <UserDetails_Section_AuthorStats :user="user"/>

        <UserDetails_Section_Duels :user="user" :show-hint="true"/>

        <UserDetails_Section_InfluencerStats :user="user"/>

        <UserDetails_Section_Badges :user="user"/>

        <UserDetails_Section_TopRankedTags/>
    </Panel_White>
</template>

<script>
    import User from "@/server/structures/User";
    import UserDetails_Section_Header from "@/ui/components/users/details/UserDetails_Section_Header";
    import UserDetails_Section_Experience from "@/ui/components/users/details/UserDetails_Section_Experience";
    import UserDetails_Section_AuthorStats from "@/ui/components/users/details/UserDetails_Section_AuthorStats";
    import UserDetails_Section_InfluencerStats from "@/ui/components/users/details/UserDetails_Section_InfluencerStats";
    import UserDetails_Section_TopRankedTags from "@/ui/components/users/details/UserDetails_Section_TopRankedTags";
    import Panel_White from "@/ui/components/common/Panel_White";
    import UserDetails_Section_Duels from "@/ui/components/users/details/UserDetails_Section_Duels";
    import UserDetails_Section_Badges from "@/ui/components/users/details/UserDetails_Section_Badges";

    export default {
        name: "UserDetails",
        components: { UserDetails_Section_Badges, UserDetails_Section_Duels, Panel_White, UserDetails_Section_TopRankedTags, UserDetails_Section_InfluencerStats, UserDetails_Section_AuthorStats, UserDetails_Section_Experience, UserDetails_Section_Header },
        props: { user: User }
    }
</script>
