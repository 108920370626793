<template>
    <Layout_UserDetailsSection>

        <UserDetails_Header_Small>{{ $t("users.top-ranked-tags") }}</UserDetails_Header_Small>

        <List_Scrollable_HashTags class="my-1" :tags="popular"/>

    </Layout_UserDetailsSection>
</template>

<script>
    import Layout_UserDetailsSection from "@/ui/layouts/Layout_UserDetailsSection";
    import UserDetails_Header_Small from "@/ui/components/users/details/UserDetails_Header_Small";
    import List_Scrollable_HashTags from "@/ui/components/common/List_Srollable_HashTags";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import RouterUtils from "@/routes/RouterUtils";
    import {mapActions, mapGetters} from "vuex";
    import Actions from "@/store/Actions";
    import EventBus from "@/events/EventBus";
    import Events from "@/events/Events";

    export default {
        name: "UserDetails_Section_TopRankedTags",
        components: { List_Scrollable_HashTags, UserDetails_Header_Small, Layout_UserDetailsSection },
        computed: {
            ...mapGetters(Modules.TAGS, {
                popular : Getters.Tags.POPULAR
            })
        },
        mounted() {
            this.pullPopular();
            EventBus.$on(Events.TagSelected, this.onTagSelected);
        },
        beforeDestroy () {
            EventBus.$off(Events.TagSelected, this.onTagSelected);
        },
        methods: {
            ...mapActions(Modules.TAGS, {
                pullPopular: Actions.Tags.PULL_POPULAR
            }),
            onTagSelected() {
                RouterUtils.goHome();
            }
        }
    }
</script>
