<template>
    <div class="duel-username-row">
        <Post_Text_Username :user="duel.challenger" />
        <Duel_Header_UsernameVSText>
            <slot> </slot>
        </Duel_Header_UsernameVSText>
        <Post_Text_Username class="challenged" :user="duel.challenged" /> 
    </div>
</template>

<script>

    import Duel from "@/server/structures/Duel";
    import Post_Text_Username from "@/ui/components/feed/post/Post_Text_Username";
    import Duel_Header_UsernameVSText from "@/ui/components/duels/Duel_Header_UsernameVSText";

    export default {
        name: "Duel_Header_UsernameRow",
        components: { Post_Text_Username, Duel_Header_UsernameVSText },
        props: {
            duel: Duel
        },
        
    }
</script>

<style>
    .duel-username-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #7c858b;
    }
    .duel-username-row .user-name {
        flex-basis: 40%;
        margin: 0;
    }
    .duel-username-row .user-name.challenged {
        text-align: end;
    }
    .duel-username-middle-text {
        flex-basis: 20%;
        text-align: center;
    }
    .duel-header.mobile-s .user-name,
    .duel-header.mobile-m .user-name {
        flex-basis: 36%;
    }
    .duel-header.mobile-s .duel-username-middle-text,
    .duel-header.mobile-m .duel-username-middle-text {
        flex-basis: 28%;
    }
    .duel-header.mobile-s .user-name,
    .duel-header.mobile-s .duel-username-middle-text {
        font-size: 13px;
    }
</style>