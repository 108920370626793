<!-- Element used for a single leaderboard entry. -->

<template>
    <Layout_Leaderboard_Entry :class="{highlighted: highlighted}">

        <Number_Rank class="leaderboard-entry-rank-number align-right" :value="data.rank"/>

        <Leaderboard_EntrySection_User :user="data"/>

        <Leaderboard_EntrySection_Custom :data="data" :displayType="displayType"/>
    </Layout_Leaderboard_Entry>
</template>

<script>
    import Number_Rank from "@/ui/components/common/Number_Rank";
    import Leaderboard_EntrySection_User from "@/ui/components/leaderboard/Leaderboard_EntrySection_User";
    import Leaderboard_EntrySection_Custom from "@/ui/components/leaderboard/Leaderboard_EntrySection_Custom";
    import Layout_Leaderboard_Entry from "@/ui/components/leaderboard/Layout_Leaderboard_Entry";

    export default {
        name: "Leaderboard_Entry_User",
        components: { Layout_Leaderboard_Entry, Leaderboard_EntrySection_Custom, Leaderboard_EntrySection_User, Number_Rank },
        props: {
            data: Object,
            displayType: String,
            highlighted: Boolean
        }
    }
</script>

<style>

    .leaderboard-entry-rank-number {
        line-height: 75px;
    }
</style>
