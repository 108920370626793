<template>
        <div class="w-100">
            <Layout_ArrowScroll :class="scrollClass">
                <div class="scrollable-badges-container w-100">
                    <Badges_Component v-for="badge in badges" :key="badge.name" :badge="badge"/>
                </div>
            </Layout_ArrowScroll>
        </div>
</template>

<script>

import Layout_ArrowScroll from "@/ui/layouts/Layout_ArrowScroll";
import Badges_Component from "@/ui/components/badges/Badges_Component";

export default {
    name: "Badges_ArrowScroll",
    components: { Badges_Component, Layout_ArrowScroll },
    props: {
        badges: Array,
        scrollClass: String
    }
}
</script>

<style scoped>

    .scrollable-badges-container {
        padding: 12px 32px 12px 12px;
    }
</style>
