<template>
    <Panel_Folding
        border-variant="light"
        class="joke-card background-color-white border-rounded"
        :class="{'post-preview-mode': previewMode}"
        folded-max-height="280px"
        :is-closed="closed">

        <slot name="header"> </slot>

        <Post_Section_Content :post="post" v-observe-visibility="visibilityChanged"/>

        <Post_Section_Actions :post="post" :expanded="showActions" :isInDuelFeed="isInDuelFeed"/>

        <List_Scrollable_HashTags class="border-top border-bottom" :tags="post.tags"/>

        <template v-if="showComments">

            <Post_Section_CommentStats :post="post" :commentsAreOpen="commentsAreOpen" v-on:toggleComments="toggleComments" :isInDuelFeed="isInDuelFeed" />

            <Post_Section_Comments :post="post" :open="commentsAreOpen" />
        </template>

        <template v-if="closed">

            <div class="post-closed-overlay"></div>

            <Post_Button_Expand v-if="canBeClosed" @click.native="open" class="button-read" :is-open-button="true" :readTime="readTime" >{{ $t('general.read') }}</Post_Button_Expand>

        </template>

    </Panel_Folding>
</template>

<script>
    import Post from "@/server/structures/Post";
    import List_Scrollable_HashTags from "@/ui/components/common/List_Srollable_HashTags";
    import Post_Section_CommentStats from "@/ui/components/feed/post/Post_Section_CommentStats";
    import Post_Button_Expand from "@/ui/components/feed/post/Post_Button_Expand";
    import Panel_Folding from "@/ui/components/common/Panel_Folding";
    import {mapActions} from "vuex";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import Post_Section_Comments from "@/ui/components/feed/post/Post_Section_Comments";
    import Post_Section_Actions from "@/ui/components/feed/post/Post_Section_Actions";
    import Post_Section_Content from "@/ui/components/feed/post/Post_Section_Content";

    const ViewTrackTimeMs = 2900;   // How long the post has to stay visible to be counted as a view.

    export default {
        name: "Layout_Post",
        components: { Post_Section_Content, Post_Section_Actions, Post_Section_Comments, Panel_Folding, Post_Button_Expand, Post_Section_CommentStats, List_Scrollable_HashTags },
        props: {
            post: Post,
            disableTrackViews: Boolean,
            previewMode: {
                default: false
            },
            isClosed: Boolean,
            readTime: Object,
            canBeClosed: Boolean,
            isInDuelFeed: { type: Boolean, default: false },
            showActions: { type: Boolean, default: true },
            showComments : { type: Boolean, default: true }
        },
        data() {
            return {
                closed: this.isClosed,
                commentsAreOpen: false,
                viewTimeout: null
            }
        },
        mounted() {
            if(this.previewMode){
                this.open();
            }
        },
        watch: {
            isClosed: function() {
                this.closed = this.isClosed
            }
        },
        methods: {
            visibilityChanged(isVisible) {
                if(this.disableTrackViews || this.isClosed) return;

                if(isVisible) {
                    this.viewTimeout = setTimeout(this.addView, ViewTrackTimeMs);
                }
                else {
                    clearTimeout(this.viewTimeout);
                }
            },
            toggleComments() {
                this.commentsAreOpen = !this.commentsAreOpen;
            },
            open() {
                this.closed = false;
                this.addView();
            },
            close() {
                this.closed = true;
                setTimeout(() => { this.commentsAreOpen = false }, 350);
            },
            async addView() {
                this.addViewForPost(this.post.id);
            },
            ...mapActions(Modules.POSTS, {
                addViewForPost : Actions.Posts.VIEW,
            })
        }
    }
</script>

<style>

    /* Joke Card */
    .joke-card {
        margin-bottom: 0.6em;
    }
    .joke-card.post-preview-mode {
        box-shadow: 0 2px 23px 2px grey;
    }

    .joke-card .post-closed-overlay {
        display: none;
    }

    .joke-card .button-read {
        position: absolute;
        bottom: 14px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 101;
    }

    .joke-card.closed .post-closed-overlay {
        display: inline;
        position: absolute;
        top: 15%;
        left: 0;
        width: 100%;
        height: 90%;
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
        z-index: 100;
    }

</style>
