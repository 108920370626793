<template>
    <div class="bar-large" :style="maskStyle">

        <div class="bar-background"></div>

        <slot name="fill">
            <div class="bar-fill" :style="fillStyle"></div>
        </slot>

        <slot/>
    </div>
</template>

<script>
    export default {
        name: "Bar_Large",
        props : {
            maskImage : String,
            percentageFill : Number
        },
        computed: {
            maskStyle() {
                return {
                    '--mask-src' : "url(" + require('@/assets/' + this.maskImage) + ")"
                };
            },
            fillStyle() {
                return {
                    width: `${this.percentageFill}%`
                }
            }
        }
    }
</script>

<style scoped>

    .bar-large {
    }

    .bar-large .bar-fill {
        border-radius: 0.5em;
        transform: translateX(0);
        background-color: #ff6154;
        height: 100%;
        background: linear-gradient(90deg, rgb(254, 201, 27) 0%, rgba(255,81,94,1) 100%) !important;
        position: relative;
    }

    .bar-large .bar-background {
        position: absolute;
        left: 0;
        top: 0;
        background-color: gray;
        width: 100%;
        height: 100%;
        z-index: 0;
        border-radius: 0.5em;
    }
    .first-split {
        border-right: 2px solid #fff;
        width: 33%;
        height: 100%;
        position: absolute;
    }
    .second-split {
        border-right: 2px solid #fff;
        width: 67%;
        height: 100%;
        position: absolute;
    }
</style>
