import CollectionStatus from "@/store/types/CollectionStatus";
import Getters from "@/store/Getters";
import Mutations from "@/store/Mutations";
import Actions from "@/store/Actions";
import RankingCategories from "@/server/types/RankingCategories";

/**
 * @param {ApiProxy_Rankings} rankingsApi
 */
export default function (rankingsApi) {
    return {
        namespaced: true,
        state: () => ({
            posts: [],
            category: RankingCategories.Liked,
            status: CollectionStatus.Ready,
            errorMessage: null
        }),
        getters: {
            [Getters.Rankings.GET_POSTS](state) {
                return state.posts;
            },
            [Getters.Rankings.GET_CATEGORY](state) {
                return state.category;
            },
            [Getters.Collection.STATUS_IS_READY](state) {
                return state.status === CollectionStatus.Ready;
            },
            [Getters.Collection.STATUS_IS_LOADING](state) {
                return state.status === CollectionStatus.Loading;
            },
            [Getters.Collection.STATUS_IS_ERROR](state) {
                return state.status === CollectionStatus.Error;
            },
            [Getters.Collection.GET_ERROR_MESSAGE](state) {
                return state.errorMessage;
            }
        },
        mutations: {
            [Mutations.Rankings._SET_POSTS](state, posts) {
                state.posts = posts;
            },
            [Mutations.Rankings._SET_CATEGORY](state, category) {
                state.category = category;
            },
            [Mutations.Collection._SET_STATUS](state, status) {
                state.status = status;
            },
            [Mutations.Collection._SET_ERROR_MESSAGE](state, errorMessage) {
                state.errorMessage = errorMessage;
            }
        },
        actions: {
            async [Actions.Rankings.SET_CATEGORY]({ state, commit, dispatch }, category) {

                if(state.category === category) { return; }

                commit(Mutations.Rankings._SET_CATEGORY, category);

                dispatch(Actions.Collection.RELOAD);
            },
            async [Actions.Rankings.RELOAD]({ state, commit }) {

                commit(Mutations.Collection._SET_STATUS, CollectionStatus.Loading);

                const response = await rankingsApi.getRankedPosts(state.category);

                if(response.success) {

                    commit(Mutations.Rankings._SET_POSTS, response.posts);

                    commit(Mutations.Collection._SET_STATUS, CollectionStatus.Ready);
                }
                else {
                    commit(Mutations.Collection._SET_ERROR_MESSAGE, response.errors.getFirstError());

                    commit(Mutations.Collection._SET_STATUS, CollectionStatus.Error);
                }
            }
        }
    };
}
