<template>
    <b-modal
        ref="share-modal"
        id="modal-share-post"
        :title="$t('posts.share')"
        hide-header-close
    >
        <b-input
            readonly
            :value="url"
            :state="inputState"
        />

        <Post_Modal_ShareOptions
            :title="title"
            :tags="tags"
            :url="url"
            v-on:copy="copy"
            v-on:copyError="copyError"
            v-on:networkSelected="hide()"
        />

        <template #modal-footer>
            <Button_Default
                @click.native="hide"
            >
                {{ $t("general.close")}}
            </Button_Default>
        </template>
    </b-modal>
</template>

<script>
    import EventBus from "@/events/EventBus";
    import Events from "@/events/Events";
    import Button_Default from "@/ui/components/common/Button_Default";
    import Post_Modal_ShareOptions from "@/ui/components/feed/post/Post_Modal_ShareOptions";

    export default {
        name: "Post_Modal_Share",
        components: { Button_Default, Post_Modal_ShareOptions },
        data() {
            return {
                title: null,
                tags : null,
                url: null,
                inputState: null
            }
        },
        mounted() {
            EventBus.$on(Events.ShareClicked, this.show);
        },
        beforeDestroy() {
            EventBus.$off(Events.ShareClicked, this.show);
        },
        methods: {
            show({ title, tags, url }) {
                this.title = title;
                this.tags = tags;
                this.url = url;
                this.inputState = null;
                this.$refs['share-modal'].show();
            },
            hide() {
                this.$refs['share-modal'].hide();
            },
            copy() {
                this.inputState = true;
            },
            copyError() {
                this.inputState = false;
            }
        }
    }
</script>

<style scoped>
    .form-control {
        width: 94%;
        margin-left: 3%;
    }
</style>
