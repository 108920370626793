<template>
    <div>
        <Post_Loading v-for="index in count" :key="index"/>
    </div>
</template>

<script>
import Post_Loading from "@/ui/components/feed/post/Post_Loading";

export default {
    name: "Post_List_Loading",
    components: { Post_Loading },
    props: {
        count: Number
    }
}
</script>
