import { LoremIpsum } from "lorem-ipsum";

const LoremInstance = new LoremIpsum({
    sentencesPerParagraph: {
        max: 8,
        min: 4
    },
    wordsPerSentence: {
        max: 16,
        min: 4
    }
});


export default {

    generateWords(num) {
        return LoremInstance.generateWords(num)
    },
    generateSentences(num) {
        return LoremInstance.generateSentences(num);
    },
    generateParagraphs(num) {
        return LoremInstance.generateParagraphs(num);
    }
}
