<template>
    <Layout_StickyHeader class="top-nav">

        <div class="top-nav-container desktop-shrink background-color-dark py-3 pr-3 pl-1" :class="$mq">

            <TopNav_Button_Hamburger/>

            <TopNav_Logo class="top-nav-logo" @click.native="navigateToHome"/>

            <template v-if="authenticated">

                <TopNav_Avatar :icon="user.avatarUrl" v-on:navigate="navigateToUser">

                    <TopNav_NotificationsBell/>

                </TopNav_Avatar>

            </template>

            <Button_WhiteOutline v-if="!authenticated" v-b-toggle.sidebar-login-select>{{ $t("general.join") }} &rsaquo;</Button_WhiteOutline>

            <div class="clearfix"></div>

            <Sidebar v-on:shown="onSidebarShown" v-on:hidden="onSidebarHidden"/>
        </div>

        <div class="desktop-shrink" :class="$mq">
            <slot></slot>
        </div>

    </Layout_StickyHeader>
</template>

<script>

    import Sidebar from "@/ui/components/sidebar/Sidebar";
    import {mapGetters, mapState, mapActions} from "vuex";
    import TopNav_Avatar from "@/ui/components/top_nav/TopNav_Avatar";
    import Button_WhiteOutline from "@/ui/components/common/Button_WhiteOutline";
    import Layout_StickyHeader from "@/ui/layouts/Layout_StickyHeader";
    import Modules from "@/store/Modules";
    import RouterUtils from "@/routes/RouterUtils";
    import TopNav_Logo from "@/ui/components/top_nav/TopNav_Logo";
    import Getters from "@/store/Getters";
    import Actions from "@/store/Actions";
    import WindowEx from "@/utils/WindowEx";
    import TopNav_Button_Hamburger from "@/ui/components/top_nav/TopNav_Button_Hamburger";
    import ScrollLock from "@/utils/ScrollLock";
    import TopNav_NotificationsBell from "@/ui/components/top_nav/TopNav_NotificationsBell";

    export default {
        name: "TopNav",
        components : {
            TopNav_NotificationsBell,
            TopNav_Button_Hamburger,
            TopNav_Logo,
            Layout_StickyHeader,
            Button_WhiteOutline, TopNav_Avatar, Sidebar
        },
        watch: {
            $route() {
                this.isDuelsFeed
            }
        },
        computed : {
            ...mapGetters(Modules.USERS, { user : Getters.Users.GET_ME }),
            ...mapState(Modules.AUTH, [ "authenticated" ]),
            isHomePage() {
                const regex = /(\/j\/.*)/;
                return regex.test(this.$route.path);
            },
            isDuelsFeed() {
                const regex = /(\/j\/.*\/duels.*)/;
                return regex.test(this.$route.path)
            }
        },
        methods : {
            onSidebarShown() {
                ScrollLock.lock();
            },
            onSidebarHidden() {
                ScrollLock.unlock();
            },
            navigateToUser() {
                RouterUtils.goToUser(this.user.slug);
            },
            navigateToHome() {
                RouterUtils.goHome();
                WindowEx.scrollToTop();
                this._reloadPosts();
            },
            contentType() {
                return this.$route.params.contentType;
            },
            filter() {
                return this.$route.params.filter;
            },
            ...mapActions(Modules.POSTS, {
                _reloadPosts : Actions.Posts.RELOAD
            })
        },

    }
</script>

<style>

    /* Login/Reg */
    .btn-outline-white,
    .btn-outline-white:hover,
    .btn-outline-white.btn-secondary:not(:disabled):not(.disabled).active,
    .btn-outline-white.btn-secondary:not(:disabled):not(.disabled):active,
    .btn-outline-white.btn-secondary.focus, .btn-secondary:focus {
        background: transparent;
        border-color: white;
    }

    /* Top Navigation */
    .top-nav {
        color: #ffffff;
        position: fixed;
        top: 0;
        width: 100%;
        z-index:1000;
    }
    .top-nav .top-nav-container {
        height: 55px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .desktop-shrink.desktop {
        margin: auto;
        min-width: 500px;
        max-width: 800px;
    }

    .top-nav .logo img {
        width: 9em;
    }

    .top-nav .icon-holder img {
        filter: invert(100);
        margin-top: -0.2em;
    }
    .top-nav .dropdown .btn {
        background: none;
        border: none;
        color: #fd7a4c;
        text-transform: uppercase;
    }
    .top-nav .dropdown .dropdown-toggle::after {
        margin: 0.6em 0 0 0.7em;
    }

    .top-nav .back-button {
        color: #fd7a4c;
        background: none;
        border: none;
    }

    .top-nav .back-button:hover {
        color: #fd7a4c;
        background-color: #3d3842;
    }

    .top-nav .top-nav-logo {
        flex-grow: 2;
    }
</style>
