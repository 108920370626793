import ResourceStatus from "@/store/types/ResourceStatus";
import Mutations from "@/store/Mutations";
import Getters from "@/store/Getters";
import Actions from "@/store/Actions";
import Notifications from "@/notifications/Notifications";
import EventBus from "@/events/EventBus";
import Events from "@/events/Events";
import Notification from "@/server/structures/Notification";

/**£
 * @param {ApiProxy_Notifications} notificationsApi
 */
export default function (notificationsApi) {

    return {
        namespaced: true,
        state: () => ({
            notifications: null,
            status: ResourceStatus.Null,
            hasUnread: false
        }),
        mutations: {
            [Mutations.Notifications._SET_STATUS](state, status) {
                state.status = status;
            },
            [Mutations.Notifications._SET_NOTIFICATIONS](state, notifications) {
                state.notifications = notifications;
            },
            [Mutations.Notifications._SET_HAS_UNREAD](state, value) {
                state.hasUnread = value;
            }
        },
        getters: {
            [Getters.Notifications.GET_STATUS](state) {
                return state.status;
            },
            [Getters.Notifications.GET_NOTIFICATIONS](state) {
                return state.notifications;
            },
            [Getters.Notifications.HAS_UNREAD](state) {
                return state.hasUnread;
            }
        },
        actions: {
            async [Actions.Notifications.PULL]({commit}) {

                commit(Mutations.Notifications._SET_STATUS, ResourceStatus.Loading);

                const response = await notificationsApi.getNotifications();

                if (response.success) {

                    commit(Mutations.Notifications._SET_NOTIFICATIONS, response.notifications);

                    commit(Mutations.Notifications._SET_STATUS, ResourceStatus.Ready);
                } else {

                    commit(Mutations.Notifications._SET_STATUS, ResourceStatus.Error)
                }
            },
            async [Actions.Notifications.MARK_ALL_AS_READ]({commit}) {

                commit(Mutations.Notifications._SET_HAS_UNREAD, false);

                await notificationsApi.markAllAsRead();
            },
            async [Actions.Notifications.GET_LAST_UNSHOWN]({commit}) {

                let notifications = await notificationsApi.getLastUnshown();

                if (notifications._rawResponse.status === 200
                    && notifications._rawResponse.data.length > 0) {
                    let notification = notifications._rawResponse.data[0];
                    EventBus.$emit(Events.ReceivedNotification, new Notification({
                        title: notification.title,
                        body: notification.body,
                        url: notification.url,
                        image: notification.image,
                        read: false
                    }));

                    commit(Mutations.Notifications._SET_HAS_UNREAD, true);
                }
            },
            async [Actions.Notifications.PUSH_LAST_UNSHOWN]() {

                let notifications = await notificationsApi.getLastUnshown();

                if (notifications._rawResponse.status === 200
                    && notifications._rawResponse.data.length > 0) {
                    let notification = notifications._rawResponse.data[0];
                    window.location.href = notification.url
                }
            },
            async [Actions.Notifications.MARK_UNREAD]({commit}) {

                commit(Mutations.Notifications._SET_HAS_UNREAD, true);
            },
            async [Actions.Notifications.SUBMIT_TOKEN]() {

                let token = await Notifications.getToken();

                if (token) {
                    await notificationsApi.submitNotificationToken(token)
                }
            }
        }

    }
}
