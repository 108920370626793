import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_TagSearch from "@/server/responses/ApiResponse_TagSearch";

export default class ApiProxy_Tags extends ApiProxy {

    async getPopularTags(resultLimit) {

        const rawResponse = await this.get('/', { params: { per_page: resultLimit } });

        return new ApiResponse_TagSearch(rawResponse);
    }

    // eslint-disable-next-line no-unused-vars
    async searchTag(searchText, resultLimit) {

        const rawResponse = await this.get('/', {
            params: {
                search: searchText,
                per_page: resultLimit
            }
        });

        return new ApiResponse_TagSearch(rawResponse);
    }
}
