<template>
    <div>
        <Layout_Section_PageHeader class="separator-bottom-light separator-top-light">
          <Help_Section v-if="authenticated && isMe(user.slug)" :info-text="$t('map.help-in-my-user')"/>
          <Help_Section v-else :info-text="$t('map.help-in-user', {userName: user.name})"/>
          <Header_Medium>{{ $t("map.page-title") }}
            </Header_Medium>
        </Layout_Section_PageHeader>
        <Map v-if="mapResource" :map-resource="mapResource"/>
    </div>
</template>

<script>

    import User from "@/server/structures/User";
    import Map from "@/ui/components/map/Map";
    import StoreFactory_Maps from "@/store/factories/StoreFactory_Maps";
    import Layout_Section_PageHeader from "@/ui/layouts/Layout_Section_PageHeader";
    import Header_Medium from "@/ui/components/common/Header_Medium";
    import Help_Section from "@/ui/components/common/help/Help_Section";
    import {mapGetters, mapState} from "vuex";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";

    export default {
        name: "UserView_Map",
        components: { Header_Medium, Layout_Section_PageHeader, Map , Help_Section},
        props: {
            user: User
        },
        data() {
            return { mapResource: null }
        },
        computed: {
            ...mapState(Modules.AUTH, {authenticated: "authenticated"}),
            ...mapGetters(Modules.USERS, {
                isMe: Getters.Users.IS_ME,
            })
        },
        mounted() {
            this.mapResource = StoreFactory_Maps.getForUser(this.user.slug);
        },
        beforeDestroy() {
            this.mapResource.unregisterModule();
        }
    }
</script>
