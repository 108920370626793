<template>
    <div class="sticky-header" :class="{ 'hidden': !showNavbar }">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Layout_StickyHeader",
        data() {
          return {
              showNavbar: true,
              lastScrollPosition: 0
          }
        },
        mounted () {
            window.addEventListener('scroll', this._onScroll)
        },
        beforeDestroy () {
            window.removeEventListener('scroll', this._onScroll)
        },
        methods: {
            _onScroll() {
                const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
                if (currentScrollPosition < 0) { return }
                // Stop executing this function if the difference between
                // current scroll position and last scroll position is less than some offset
                if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 50) {
                    return
                }
                this.showNavbar = currentScrollPosition < this.lastScrollPosition;
                this.lastScrollPosition = currentScrollPosition
            }
        }
    }
</script>

<style scoped>
    .sticky-header {
        position: fixed;
        transition: 0.2s all ease-out;
    }
    .sticky-header.hidden {
        transform: translate3d(0, -100%, 0);
    }
</style>
