import LeaderboardCategories from "@/server/types/LeaderboardCategories";

export default {
    Activity: LeaderboardCategories.Activity,

    Influencers : LeaderboardCategories.Influencers,

    Followers: LeaderboardCategories.Followers,

    MyPosition : "my-position",

    fromLeaderboardCategory(category) {
        return category;
    }
}
