<template>
    <Panel_White class="users-follower-container">
        <Bar_Experience_Small :status="user.activityStatus"/>

        <Avatar_Small :user="user"/>

        <Post_Text_Username class="users-follower-username" :user="user" />

        <UserDetails_Button_Follow class="users-follower-button" :user="user"/>
    </Panel_White>
</template>

<script>

import User from "@/server/structures/User";
import Bar_Experience_Small from "@/ui/components/common/Bar_Experience_Small";
import Avatar_Small from "@/ui/components/common/Avatar_Small";
import Post_Text_Username from "@/ui/components/feed/post/Post_Text_Username";
import Panel_White from "@/ui/components/common/Panel_White";
import UserDetails_Button_Follow from "@/ui/components/users/details/UserDetails_Button_Follow";

export default {
    name: "Users_Follower",
    components: { UserDetails_Button_Follow, Panel_White, Post_Text_Username, Avatar_Small, Bar_Experience_Small },
    props: { user: User }
}
</script>

<style>

    .users-follower-container {
        margin-bottom: 5px;
        width: 100%;
        height: 75px;
        display: flex;
        flex-direction: row;
        padding-left: 15px;
        padding-right: 10px;
        align-items: center;
    }

    .users-follower-container .users-follower-username {
        color: #6f6f6f;
        margin-left: 3px;
        margin-right: 3px;
        width: auto;
    }

    .users-follower-container .users-follower-button {
        max-width: 110px;
        margin-left: auto;
        flex-grow: 1;
    }
</style>
