import ModuleTemplate_Collection from "@/store/templates/ModuleTemplate_Collection";
import Actions from "@/store/Actions";

export default function(userId, duelsApi) {

    const module = ModuleTemplate_Collection(async({ state }, page) => {
        const response = await duelsApi.getUserDuels(page, userId, state.requestedStatus);

        return {
            success : response.success,
            entries : response.duels,
            lastPage : response.lastPage
        }
    }, { requestedStatus: "accepted" });

    module.actions.setRequestedStatus = function ({ dispatch, state }, value) {
        state.requestedStatus = value;

        dispatch(Actions.Collection.RELOAD);
    }

    return module;
}
