<template>
    <div class="duel-vote-row">
        <Button_Gradient
            class="challenger"
            v-popover.right="{ name : (isAuthenticated ? '' : 'popover-login-duel-vote')}"
            @click.native="isAuthenticated ? handleChallengerClick() : ''"
        >
            {{ $t("duels.vote") }}
        </Button_Gradient>

        <Button_Gradient
            class="challenged"
            v-popover.left="{ name : (isAuthenticated ? '' : 'popover-login-duel-vote')}"
            @click.native="isAuthenticated ? handleChallengedClick() : ''"
        >
            {{ $t("duels.vote") }}
        </Button_Gradient>
    </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';
    import Button_Gradient from "@/ui/components/common/Button_Gradient";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import Getters from "@/store/Getters";
    import Duel from "@/server/structures/Duel";

    export default {
        name: "Duel_Voting_Vote",
        components: { Button_Gradient },
        props: { duel: Duel },
        computed: {
            ...mapGetters(Modules.AUTH, { isAuthenticated : Getters.Auth.IS_AUTHENTICATED }),
        },
        methods: {
            async handleChallengerClick() {
                if(!this.isAuthenticated) return;

                this.voteChallenger(this.duel);
            },
            handleChallengedClick() {
                if(!this.isAuthenticated) return;

                this.voteChallenged(this.duel);
            },
            ...mapActions(Modules.DUEL_ACTIONS, {
                voteChallenger: Actions.Duels.VOTE_CHALLENGER,
                voteChallenged: Actions.Duels.VOTE_CHALLENGED,
                getResults : Actions.Duels.GET_RESULTS
            })
        }
    }
</script>

<style>
    .duel-vote-row {
        display: flex;
        justify-content: space-between;
    }
    .duel-vote-row .challenger,
    .duel-vote-row .challenged {
        min-width: 0;
        width: 6em;
    }
    .duel-vote-row .challenger {
        background:#FF6255 !important;
    }
    .duel-vote-row .challenged {
        background:#F9B233 !important;
    }
    .duel-header.mobile-s .duel-header-voting-section .duel-vote-row .btn {
        font-size: small;
    }
</style>
