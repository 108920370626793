/**
 * Interface for defining notification handling implementations.
 * These are usually per platform.
 */
import AbstractMethodError from "@/errors/AbstractMethodError";

export default class INotificationsManager {

    /**
     * Returns and FCM token that can be used by the server to target the device for specific notifications.
     * @return {Promise<string>}
     */
    async getToken() {
        throw new AbstractMethodError("getToken");
    }

    /**
     * Subscribes a callback to handle any foreground messages. Returns an unsubscriber function.
     * @param callback
     * @return firebase.Unsubscribe
     */
    // eslint-disable-next-line no-unused-vars
    onMessage(callback) {
        throw new AbstractMethodError("onMessage");
    }
}
