<template>
    <Layout_Duel_Header :duel="duel">
        <template v-slot:iconRowText>
            <span class="statistics-text">
                <slot name="iconRowText"> {{ $t("duels.statistics") }} </slot>
            </span>
        </template>
        <template v-slot:versusText>
            <span class="versus-text"> {{ $t("duels.versus") }} </span>
        </template>
    </Layout_Duel_Header>
</template>

<script>

    import Duel from "@/server/structures/Duel";
    import Layout_Duel_Header from "@/ui/components/common/Layout_Duel_Header";

    export default {
        name: "Duel_Statistics_Header",
        components: { Layout_Duel_Header },
        props: {
            duel: Duel
        }
    }
</script>

<style scoped>
    .statistics-text {
        text-transform: uppercase;
    }
</style>
