<template>
    <label :for="id" class="container">
        <input :id="id" type="checkbox" class="checkbox" ref="checkbox" :value="false" :checked="false" @click="onToggle"/>
        <span class="checkmark"></span>
        <div class="description-container">
            <span class="description"><slot/></span>
        </div>
    </label>
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        id: String,
        value: Boolean
    },
    watch: {
        value() {
            this.$refs["checkbox"].checked = this.value;
        }
    },
    methods: {
        onToggle() {
            this.$refs["checkbox"].checked = this.value;
            this.$emit("input", !this.value);
        }
    }
}
</script>

<style scoped>

.container {
    position: relative;
    padding-top: 2px;
    padding-right: 0;
}
/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 15%;
    background-color: #f1f1f1;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #f1f1f1;;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #fcfcfc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 10px;
    top: 6px;
    width: 6px;
    height: 11px;
    border: solid rgba(253, 120, 78, 1);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container .description-container {
    margin-left: 16px;
    height: 100%;
    width: 100%;
}
.container .description {
    font-size: 12pt;
}
</style>
