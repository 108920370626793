<!-- Renders a map view based on provided map data. -->
<template>
    <div class="joketrip-map">
        <LMap
            v-if="!loading"
            :zoom="zoom"
            :center="center"
            @update:zoom="zoomUpdated"
            @update:center="centerUpdated"
            @update:bounds="boundsUpdated"
            class="border-rounded"
            :style="{background: '#FFFFFF'}"
        >
            <!-- Cities -->
            <LMarker v-for="city in mapData.cities" :key="city.id" :lat-lng="city.position" :icon="markerIcon()">
                <LTooltip>{{ city.name }} : {{ city.views }}</LTooltip>
            </LMarker>

            <!-- Countries -->
            <LGeoJson :geojson="worldGeojson" :options="options" :options-style="style" />

            <!-- States -->
            <LGeoJson :geojson="statesGeojson" :options="options" :options-style="style"/>
        </LMap>
    </div>
</template>

<script>

    import { LMap, LMarker, LTooltip, LGeoJson } from "vue2-leaflet"
    import JokeTripMap from "@/server/structures/JokeTripMap";
    import Icons from "@/utils/Icons";

    import GeojsonMap from '@/utils/geojson/GeojsonMap';

    // eslint-disable-next-line no-undef
    const MarkerIcon = L.icon({
        iconUrl: Icons.Map.Marker,
        iconSize: [32, 32],
        iconAnchor: [16, 28]
    });

    const Colors = {
        Default : "#c6c6c6",
        Viewed : "#ed4b6d"
    };

    export default {
        name: "Map_Renderer",
        components: { LMap, LMarker, LTooltip, LGeoJson },
        props: {
            mapData : JokeTripMap
        },
        data() {
            return {
                zoom : 1,
                center: [47.413220, -1.219482],
                bounds: null,
                worldGeojson: null,
                statesGeojson: null,
                loading: false,
            }
        },
        async mounted() {
            // Hides the little Leaflet logo
            document.getElementsByClassName("leaflet-control-attribution")[0].style.display = 'none';

            this.worldGeojson = await GeojsonMap.World.get();
            this.statesGeojson = await GeojsonMap.States.get();
        },
        computed: {
            options() {
                return {
                    onEachFeature: this.onEachFeature
                };
            },
            onEachFeature() {
                const mapData = this.mapData;
                return (feature, layer) => {
                    const data = mapData.getArea(this.isState(feature) ? feature.properties["postal"] : feature.properties["ISO_A3"])

                    layer.bindTooltip(
                        (this.isState(feature) ? feature.properties.name : feature.properties.ADMIN) +
                        ": " +
                        data.views,
                        { permanent: false, sticky: true }
                    );
                };
            },
        },
        methods: {
            zoomUpdated (zoom) {
                this.zoom = zoom;
            },
            centerUpdated (center) {
                this.center = center;
            },
            boundsUpdated (bounds) {
                this.bounds = bounds;
            },
            markerIcon() {
                return MarkerIcon;
            },
            style(feature) {
                return {
                    fillColor: this._getColor(this.isState(feature) ? feature.properties["postal"] : feature.properties["ISO_A3"]),
                    weight: 0.7,
                    opacity: 0.5,
                    fillOpacity: 1,
                    color: 'white',
                };
            },
            _getColor(code) {

                const data = this.mapData.getArea(code);

                return data.views === 0 ? Colors.Default : Colors.Viewed;
            },
            isState(feature) {
                return Object.prototype.hasOwnProperty.call(feature.properties, 'postal');
            },
        }
}

</script>

<style>

    .joketrip-map {
        width: 100%;
        height: 400px;
    }

    .joketrip-map .vue2leaflet-map {
        z-index: 10;
    }

</style>
