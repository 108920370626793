<template>
    <Layout_DuelChallenge_Actions>
        <Button_Share @click.native="onShareClick" :text="$t('general.share-with-friend')"/>
    </Layout_DuelChallenge_Actions>
</template>

<script>

import Duel from "@/server/structures/Duel";
import Layout_DuelChallenge_Actions from "@/ui/components/duel_challenge/Layout_DuelChallenge_Actions";
import Button_Share from "@/ui/components/common/Button_Share";
import {mapActions} from "vuex";
import Actions from "@/store/Actions";
import Modules from "@/store/Modules";
import ShareUtils from "@/utils/ShareUtils";

export default {
    name: "DuelChallenge_Actions_Challenger",
    components: { Button_Share, Layout_DuelChallenge_Actions },
    props: {
        duel: Duel
    },
    methods: {
        async onShareClick() {

            const result = await this.getShareLink(this.duel.id);

            if(!result.success) { return; }

            ShareUtils.share(
                this.$t("duels.challenge-share-title"),
                result.url,
                this.duel.challengerPost.tags
            );
        },
        ...mapActions(Modules.DUEL_ACTIONS, {
            getShareLink : Actions.Duels.SHARE
        })
    }
}
</script>
