import en from './en';
import bg from './bg';
import VueI18n from 'vue-i18n'

let instance = null;

export default {
    init: init,
    translate: translate
};

function init() {
    instance = new VueI18n({
        locale: 'en',
        fallbackLocale: 'en',
        messages: {
            en,
            bg
        },
    });

    return instance;
}

function translate(...params) {
    return instance.t(...params);
}
