<template>
    <b-button pill variant="outline-secondary" class="button-default">
        <slot></slot>
    </b-button>
</template>

<script>
    export default {
        name: "Button_Default"
    }
</script>

<style scoped>

    .button-default:focus,
    .button-default:hover,
    .button-default:active{
        color: black !important;
        background-color: white !important;
    }
    .button-default {
        background-color: white;
    }

</style>
