import ApiResponse from "@/server/responses/ApiResponse";
import PostComment from "@/server/structures/PostComment";

export default class ApiResponse_PostComment extends ApiResponse {

    _comment;

    get comment() {
        return this._comment;
    }

    constructor(rawResponse) {
        super(rawResponse);

        if(this.success) {
            this._comment = new PostComment(rawResponse.data);
        }
    }
}
