<template>
    <div class="position-relative">
        <Help_Collapse
            :text="infoText"
            :visible="hintVisible"
        />
        <Help_Button
            :class="hintVisible ? 'dark' : 'light'"
            @click.native="toggleHint"
        />
    </div>
</template>

<script>
    import Help_Collapse from "@/ui/components/common/help/Help_Collapse";
    import Help_Button from "@/ui/components/common/help/Help_Button";
    export default {
        name: "Help_Section",
        components: { Help_Button, Help_Collapse },
        props: {
            infoText: String
        },
        data() {
            return {
                hintVisible: false
            }
        },
        methods: {
            toggleHint() {
                this.hintVisible = !this.hintVisible;
            }
        }
    }
</script>
