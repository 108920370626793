export default class StorageVariable {

    _key;

    set value(val) {
        return localStorage.setItem(this._key, val);
    }

    get value() {
        return localStorage.getItem(this._key);
    }

    get exists() {
        return this.value !== null && this.value !== "null";
    }

    constructor(key) {
        this._key = key;
    }

    remove() {
        localStorage.removeItem(this._key);
    }
}
