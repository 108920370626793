<template>

    <div id="app">
        <Globals/>
        <router-view v-if="initialized"/>
        <div id="outgoing-event-app-container" ref="outgoingEvent"></div>
        <div id="incoming-event-app-container" ref="incomingEvent"></div>
        <div id="pathname-app-container">{{ pathname }}</div>
    </div>

</template>

<script>

import Globals from "@/ui/components/common/Globals";
import {mapActions} from "vuex";
import Actions from "@/store/Actions";
import ShareUtils from "@/utils/ShareUtils";
import EventBus from "@/events/EventBus";
import Events from "@/events/Events";
import Notification from "@/server/structures/Notification";

export default {
    name: "App",
    components: {Globals},
    props: ['serverApi'],
    watch: {
        $route() {
            this.pathname = window.location.pathname;
        },
        incomingEvent: function (val) {
            console.log('incomingEvent');
            console.log(val);
        }

    },
    data() {
        return {
            initialized: false,
            pathname: '',
        }
    },
    async mounted() {

        ShareUtils.init(this.$isMobile());

        await this.initialize();

        this.initialized = true;
        let obj = this;

        setInterval(function () {
            let dataStr = String(obj.$refs.incomingEvent.innerHTML);

            if (dataStr.trim().length > 0) {

                let data = JSON.parse(dataStr);

                console.log('-----------dataStr-----------')
                console.log(dataStr)

                obj.$refs.incomingEvent.innerHTML = '';
                if (data.event === 'login-with-facebook') {
                    obj.facebookLoginEvent(data.data);
                } else if (data.event === 'received-notification') {
                    obj.receivedNotificationEvent(data.data)
                } else if (data.event === 'run-last-notification') {
                    obj.runLastNotificationEvent()
                }

            }
        }, 200);

        EventBus.$on(Events.AddMobileAppEvent, function (e) {

            obj.$refs.outgoingEvent.innerHTML = JSON.stringify(e);
        })
    },
    methods: {
        ...mapActions({initialize: Actions.INITIALIZE}),
        facebookLoginEvent(data) {

            EventBus.$emit(Events.LoginWithFacebook, data);
        },
        receivedNotificationEvent(data) {

            let url = new URL(data.imageUrl);
            let searchParams = new URLSearchParams(url.search);
            let action = searchParams.get('url');

            EventBus.$emit(Events.ReceivedNotification, new Notification({
                title: data.title,
                body: data.body,
                url: action,
                image: data.imageUrl
            }));

        },
        runLastNotificationEvent() {

            EventBus.$emit(Events.PushForNewUnshownNotification);
        }
    }
}
</script>

<style>

#pathname-app-container, #outgoing-event-app-container, #incoming-event-app-container {
    display: none;
}

#app {
    font-size: 16px;
    line-height: 1.2;
}

.custom-icon.white {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(346deg) brightness(108%) contrast(101%);
}

.orange-gradient {
    background: linear-gradient(90deg, rgba(254, 114, 78, 1) 0%, rgba(255, 81, 94, 1) 100%) !important;
}

.clickable {
    cursor: pointer;
}

.color-orange {
    color: #fd7a4c;
}

.background-color-white {
    background-color: #ffffff;
}

.background-color-gray {
    background-color: #ededed;
}

.background-color-dark {
    background-color: #2a2333;
}

.loading-blur {
    filter: blur(8px);
}

.border-rounded {
    border-radius: 7px;
}

.no-border {
    border: none !important;
}

.separator-top-light {
    border-top: 1px solid #d9d9d9;
}

.separator-bottom-light {
    border-bottom: 1px solid #d9d9d9;
}

.separator-top {
    border-top: 2px solid #d9d9d9;
}

.separator-bottom {
    border-bottom: 2px solid #d9d9d9;
}

.overlay-absolute {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}


/* Buttons */
.btn-white {
    background-color: #ffffff;
    color: #292232;
}

.btn:focus, .btn.focus {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0) !important;
    -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0) !important;
    -o-box-shadow: 0 5px 15px rgba(0, 0, 0, 0) !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0) !important;
}

.mobile {
    display: none;
}

.desktop {
    display: block;
}

.desktop-inline {
    display: inline-block;
}

@media screen and (max-width: 575px) {
    .mobile {
        display: block;
    }

    .desktop,
    .desktop-inline {
        display: none;
    }

    .mobile-bottom-btns {
        position: fixed;
        bottom: 0;

    }
}

.fade-in-fast {

    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
    -ms-animation: fadein 0.5s; /* Internet Explorer */
    -o-animation: fadein 0.5s; /* Opera < 12.1 */
    animation: fadein 0.5s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
</style>
