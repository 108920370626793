<!-- Pass down type to store in localStorage object -->
<template>
    <Layout_Tutorial type="duelsTutorialHidden"> 
        <template v-slot:header> {{ $t('duels.tutorial-header') }} </template>

        <template v-slot:explanation> {{ $t('duels.tutorial-explanation') }} </template>
    </Layout_Tutorial>
</template>

<script>
    import Layout_Tutorial from "@/ui/components/common/Layout_Tutorial";

    export default {
        name: "Duel_Tutorial",
        components: { Layout_Tutorial },
    }
</script>