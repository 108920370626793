import Modules from "@/store/Modules";
import Mutations from "@/store/Mutations";

const StoreUtils = {
    instance: null
};

StoreUtils.init = function (store) {
    StoreUtils.instance = store;
};

StoreUtils.clearTags = function() {
    StoreUtils.instance.commit(`${Modules.TAGS}/${Mutations.Tags.CLEAR_SELECTED}`);
};

export default StoreUtils;
