import Badge from "@/server/structures/Badge";

export default class BadgeSubcategory {

    _data;

    /** @returns {string} */
    get name() {
        return this._data.name;
    }

    /** @returns {string} */
    get description() {
        return this._data.description;
    }

    /** @returns {Badge[]} */
    get badges() {
        return this._data.badges.map((rawBadge) => {
            return new Badge(rawBadge);
        });
    }

    constructor(data) {
        this._data = data;
    }
}
