import ApiResponse from "@/server/responses/ApiResponse";
import Post from "@/server/structures/Post";


export default class ApiResponse_GetPost extends ApiResponse {

    _post;

    get post() { return this._post; }

    constructor(rawResponse) {

        super(rawResponse);

        if(this.success) {
            this._post = new Post(rawResponse.data);
        }
    }
}
