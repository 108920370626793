<!-- A sharing option that copies the share link to the user's clipboard. -->

<template>
    <Share_Option
            v-clipboard="url"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            :icon="$icons.SocialMedia.Copy"
            alt="Copy Text Image"
    >
        {{$t("general.copy")}}
    </Share_Option>
</template>

<script>
    import Share_Option from "@/ui/components/feed/sharing/Share_Option";
    export default {
        name: "Share_Option_Copy",
        components: { Share_Option },
        props: { url : String },
        methods: {
            onCopy(param) {
                this.$emit('copy', param);
            },
            onError(param) {
                this.$emit('copyError', param);
            }
        }
    }
</script>
