<script>
import Getters from "@/store/Getters";
import Actions from "@/store/Actions";

export default {
    name: "Mixin_CollectionComponent",
    props: {
        module: null
    },
    mounted() {
        this.reload();
    },
    computed: {
        entries() {
            return this.module[Getters.Collection.GET_ENTRIES];
        },
        resultsAreLoading() {
            return this.module[Getters.Collection.STATUS_IS_LOADING];
        },
        resultsAreReady() {
            return this.module[Getters.Collection.STATUS_IS_READY];
        },
        lastPageReached() {
            return this.module[Getters.Collection.GET_LAST_PAGE_REACHED];
        },
        collectionEmpty() {
            return this.entries.length === 0;
        }
    },
    methods: {
        loadMore() {
            this.module[Actions.Collection.LOAD_MORE]();
        },
        reload() {
            this.module[Actions.Collection.RELOAD]();
        }
    }
}
</script>
