<template>
    <div class="auth-prompt-label">
        <span class="auth-prompt-label-text mr-2">{{ label }}</span>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Auth_PromptLabel",
        props : {
            label: String
        }
    }
</script>

<style scoped>

    .auth-prompt-label {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
</style>
