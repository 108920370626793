<template>
    <Comment class="loading-blur" :comment="fakeComment" />
</template>

<script>

    import PostComment from "@/server/structures/PostComment";
    import Comment from "@/ui/components/feed/comments/Comment";
    import Icons from "@/utils/Icons";
    import LoremIpsum from "@/utils/LoremIpsum";
    import Random from "@/utils/Random";

    export default {
        name: "Comment_Loading",
        components: { Comment },
        data() {
            return {
                fakeComment : PostComment.createTemporary("Some Username", Icons.TopNav.GuestAvatar, LoremIpsum.generateWords(Random.range(6, 20)))
            }
        }
    }
</script>
