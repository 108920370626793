<!-- Logo that appears next to the sidebar button. Only visible on desktop. -->

<template>
    <div class="logo clickable">
        <img class="px-2 py-1 d-block m-auto" :src="$icons.Logo" alt="JokeTrip"/>
    </div>
</template>

<script>
    export default {
        name: "TopNav_Logo"
    }
</script>
