import ApiResponse from "@/server/responses/ApiResponse";
import Duel from "@/server/structures/Duel";

export default class ApiResponse_GetDuels extends ApiResponse {

    _duels;

    get duels() { return this._duels; }

    get lastPage() { return this._rawResponse.data.last_page; }

    constructor(rawResponse) {
        super(rawResponse);

        if(this.success) {
            this._duels = rawResponse.data.data.map((rawDuel) => { return new Duel(rawDuel); });
        }
    }
}

