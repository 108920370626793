
<!-- Higher order component for managing tag selection and searching. -->

<template>
    <div class="pt-2 feed-tags" v-bind:class="{ searching: searching }">
        <div class="tag-bar px-3" :class="$mq">

            <FeedTags_Bar_InitialSelect :search-active="searching"/>

            <FeedTags_Bar_Search v-model="searchText" :search-active="searching" :on-search-cancelled="disableSearch"/>

            <FeedTags_Icon_Search v-bind:class="{ bright: searching }" @click.native="enableSearch"/>
        </div>

        <template v-if="searching">

            <FeedTags_SearchResults v-if="searchText" :search-text="searchText" />

            <FeedTags_SearchPreview v-else/>
        </template>
    </div>
</template>

<script>
    import FeedTags_Bar_InitialSelect from "@/ui/components/feed/tags/FeedTags_Bar_InitialSelect";
    import FeedTags_Bar_Search from "@/ui/components/feed/tags/FeedTags_Bar_Search";
    import FeedTags_Icon_Search from "@/ui/components/feed/tags/FeedTags_Icon_Search";
    import FeedTags_SearchPreview from "@/ui/components/feed/tags/FeedTags_SearchPreview";
    import FeedTags_SearchResults from "@/ui/components/feed/tags/FeedTags_SearchResults";
    import EventBus from "@/events/EventBus";
    import Events from "@/events/Events";

    export default {
        name: "FeedTags",
        components: { FeedTags_SearchResults, FeedTags_SearchPreview, FeedTags_Icon_Search, FeedTags_Bar_Search, FeedTags_Bar_InitialSelect },
        model: {
            prop: 'searching',
            event: 'searchToggled'
        },
        props: {
            searching: Boolean
        },
        data() {
            return {
                searchText : null
            }
        },
        mounted() {
            EventBus.$on(Events.TagSelected, this.disableSearch);
            EventBus.$on(Events.DuplicateNavigation, this.disableSearch);
        },
        beforeDestroy () {
            EventBus.$off(Events.TagSelected, this.disableSearch);
            EventBus.$off(Events.DuplicateNavigation, this.disableSearch);
        },
        watch: {
            filter() {
                // Close the tag search if we navigate to another feed type
                this.disableSearch();
            }
        },
        computed: {
            filter() {
                return this.$route.params.filter;
            }
        },
        methods: {
            enableSearch() {
                this.$emit('searchToggled', true);
            },
            disableSearch() {
                this.searchText = null;
                this.$emit('searchToggled', false);
            }
        }
    }
</script>

<style scoped>

    .feed-tags {
        border-top: 1px solid #cfcfcf;
        background-color: #ededed;
        transition: background-color 0.6s;
    }

    .feed-tags.searching {
        background-color: #f6f6f6;
    }

    .feed-tags .tag-bar {
        position: relative;
        padding-bottom: 0.42em;
        display: flex;
    }
</style>
