export default {

    calculate(text) {
        const characters = text.replace(' ', '');
        const characterCount = characters.length;
        const charactersPerSecond =  18;        

        const readTime = characterCount / charactersPerSecond;

        if(readTime > 60) {
            const readTimeInMinutes = Math.floor(readTime / 60)
            return { 
                time: readTimeInMinutes,
                type: 'minutes'
            }
        }
        
        return {
            time: Math.round(readTime),
            type: 'seconds'
        }
    }
}