import ApiResponse from "@/server/responses/ApiResponse";
import PostComment from "@/server/structures/PostComment";

export default class ApiResponse_GetComments extends ApiResponse {

    _comments;

    get lastPage() { return this._rawResponse.data.last_page; }

    get comments() { return this._comments; }

    constructor(rawResponse) {

        super(rawResponse);

        this._comments = rawResponse.data.data.map((rawComment) => {
            return new PostComment(rawComment);
        });
    }
}
