
export default class UserPostCollection {

    _posts;
    _loading;

    get loading() {
        return this._loading;
    }

    get posts() {
        return this._posts;
    }

    get isEmpty() {
        return this._posts.length === 0;
    }

    static empty() {

        return new UserPostCollection();
    }

    static fromPromise(promise) {

        const collection = new UserPostCollection();

        promise.then((response) => {

            collection._loading = false;

            if(response.success) {
                collection._posts = response.posts;
            }
        });

        return collection;
    }

    constructor() {
        this._posts = [];
        this._loading = true;
    }
}
