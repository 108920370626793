import Actions from "@/store/Actions";
import Mutations from "@/store/Mutations";
import ContentTypes from "@/store/types/ContentTypes";
import FeedTypes from "@/store/types/FeedTypes";
import Modules from "@/store/Modules";
import ArrayEx from "@/utils/ArrayEx";
import Getters from "../Getters";
import UserPostTypes from "@/store/types/UserPostTypes";
import UserPostCollection from "@/store/structures/UserPostCollection";
import CollectionStatus from "@/store/types/CollectionStatus";

/**
 * @param {ApiProxy_Posts} postsApi
 */
export default function (postsApi) {
    return {
        namespaced: true,
        state: () => ({
            status : CollectionStatus.Ready,
            posts: [],
            page: 0,
            lastPageReached: false,
            contentType: ContentTypes.ALL,
            feedType: FeedTypes.NEW
        }),
        getters: {
            [Getters.Posts.STATUS_IS] : (state) => (status) => {
                return state.status === status;
            },
            [Getters.Posts.STATUS_IS_READY] : (state) => {
                return state.status === CollectionStatus.Ready;
            },
            [Getters.Posts.STATUS_IS_LOADING] : (state) => {
                return state.status === CollectionStatus.Loading;
            },
            [Getters.Posts.STATUS_IS_ERROR] : (state) => {
                return state.status === CollectionStatus.Error;
            },
            [Getters.Posts.GET_LAST_PAGE_REACHED](state) {
                return state.lastPageReached;
            },
        },
        mutations: {
            [Mutations.Posts._RESET](state) {
                state.posts = [];
                state.page = 0;
                state.lastPageReached = false;
            },
            [Mutations.Posts.SET_FILTERS](state, { contentType, feedType }) {
                state.contentType = contentType;
                state.feedType = feedType;
            },
            [Mutations.Posts._SET_STATUS](state, status) {
                state.status = status;
            },
            [Mutations.Posts._INCREMENT_PAGE](state) {
                state.page++;
            },
            [Mutations.Posts._ADD_POST_TO_TOP](state, post) {
                  state.posts.unshift(post);
            },
            [Mutations.Posts._ADD_NEW_POSTS](state, { posts, lastPageReached }) {
                state.lastPageReached = lastPageReached;
                state.posts = ArrayEx.unique(state.posts.concat(posts));
            },
            [Mutations.Posts.INCREMENT_COMMENTS](state, postId) {
                state.posts.find((post) => post.id === postId)?.incrementComments();
            }
        },
        actions: {
            async [Actions.Posts.RELOAD]({ commit, dispatch }) {

                commit(Mutations.Posts._RESET);

                dispatch(Actions.Posts.LOAD_MORE);
            },
            async [Actions.Posts.LOAD_MORE]({ commit, state, rootState }) {

                commit(Mutations.Posts._INCREMENT_PAGE);

                commit(Mutations.Posts._SET_STATUS, CollectionStatus.Loading);

                const response = await postsApi.getPosts(
                    state.page,
                    rootState[Modules.TAGS].selected,
                    state.contentType,
                    state.feedType);

                if(response.success) {

                    commit(Mutations.Posts._SET_STATUS, CollectionStatus.Ready);

                    commit(Mutations.Posts._ADD_NEW_POSTS, {
                        posts: response.posts,
                        lastPageReached: response.lastPage === state.page
                    });
                }
                else {

                    commit(Mutations.Posts._SET_STATUS, CollectionStatus.Error);
                }
            },
            async [Actions.Posts.CREATE](context, postCreationData) {

                const response = await postsApi.createPost(postCreationData);

                if(response.success) { addNewPost(context, response.post); }

                return response;
            },
            async [Actions.Posts.EDIT](context, { post, data }) {

                return post.mutate(async () => {

                    data.applyToPost(post);

                    return (await postsApi.editPost(post.id, data));
                });
            },
            async [Actions.Posts.VIEW](context, id) {
                await postsApi.viewPost(id);
            },
            async [Actions.Posts.LIKE](context, post) {

                await post.mutate(async (post) => {

                    post.isLiked = !post.isLiked;

                    return (await postsApi.setLiked(post.id, post.isLiked));
                });
            },
            async [Actions.Posts.DISLIKE](context, post) {

                await post.mutate(async (post) => {

                    post.isDisliked = !post.isDisliked;

                    return (await postsApi.setDisliked(post.id, post.isDisliked));
                });
            },
            async [Actions.Posts.FAVORITE](context, post) {

                await post.mutate(async (post) => {

                    post.isFavorited = !post.isFavorited;

                    return (await postsApi.setFavorited(post.id, post.isFavorited));
                });
            },
            async [Actions.Posts.REPORT](context, { id, text }) {
                await postsApi.reportPost(id, text);
            },
            async [Actions.Posts.GET_FOR_USER](context, { userId, userPostType, limit }) {

                if(userPostType === UserPostTypes.Best) {
                    return UserPostCollection.fromPromise(postsApi.getBestForUser(userId, limit));
                }
                else {
                    return UserPostCollection.fromPromise(postsApi.getNewestForUser(userId, limit));
                }
            },
            async [Actions.Posts.SHARE](context, id) {

                const response = await postsApi.getShareLink(id);

                return response.url;
            },
            async [Actions.Posts.GET_BY_SHARE_CODE](context , shareCode) {

                return await postsApi.getByShareCode(shareCode);
            },
            async [Actions.Posts.GET_MAP](context, postId) {
                const result = await postsApi.getMap(postId);

                return result.map;
            }
        }
    };

    function addNewPost({commit}, post) {

        commit(Mutations.Posts._ADD_POST_TO_TOP, post);
    }
}
