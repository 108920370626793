<template>
    <div v-if="usersFound" class="duel-statistics" :class="$mq">
        <Duel_Statistics_Row :stats="getStats('posts', 'posts')" />
        <Duel_Statistics_Row :stats="getStats('fanCount', 'fans')" />
        <!-- <Duel_Statistics_Row :stats='experiencePoints', 'experience-points' /> -->

        <Duel_Statistics_List >
            <template v-slot:listHeader>
                <Duel_Statistics_Row :stats="getStats(null, 'duels')" :icon="$icons.Users.Duels" />
            </template>
            <template v-slot:listRows>
                <Duel_Statistics_Row :stats="getStats('won', 'won')" />
                <Duel_Statistics_Row :stats="getStats('lost', 'lost')" />
                <Duel_Statistics_Row :stats="getStats('refused', 'refused')" />
            </template>
        </Duel_Statistics_List>

         <Duel_Statistics_List >
            <template v-slot:listHeader>
                <Duel_Statistics_Row :stats="getStats(null, 'author-stats')" />
            </template>
            <template v-slot:listRows>
                <Duel_Statistics_Row :stats="getStats('likesCount', 'likes')" :icon="$icons.Post.Like" />
                <Duel_Statistics_Row :stats="getStats('dislikesCount', 'dislikes')" :icon="$icons.Post.Dislike" />
                <Duel_Statistics_Row :stats="getStats('sharesCount', 'shares')" />
                <Duel_Statistics_Row :stats="getStats('favoritesCount', 'favorites')" :icon="$icons.Post.Favourite" />
                <Duel_Statistics_Row :stats="getStats('commentsCount', 'comments')" :icon="$icons.Post.Comments" />
            </template>
        </Duel_Statistics_List>

         <Duel_Statistics_Row :stats="getStats('reactionPoints', 'influencer-stats')" />
        <!-- <Duel_Statistics_Row :stats="getStats('badges', 'badges')" /> -->
    </div>
    <Text_NoResults v-else>
        {{ $t('errors.user-statistics-not-found') }}
    </Text_NoResults>
</template>

<script>
    import Duel from "@/server/structures/Duel";
    import Duel_Statistics_Row from "@/ui/components/duels/Duel_Statistics_Row";
    import Duel_Statistics_List from "@/ui/components/duels/Duel_Statistics_List";
    import {mapGetters} from "vuex";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import Text_NoResults from "@/ui/components/common/Text_NoResults";

    export default {
        name: "Duel_Statistics",
        components: { Duel_Statistics_Row, Duel_Statistics_List, Text_NoResults },
        props: { 
            duel: Duel 
        },
        computed: {
            challenger() {
                return this.duel.challenger;
            },
            challenged() {
                return this.duel.challenged;
            },
            usersFound() {
                return this.challenger !== undefined && this.challenged !== undefined
            },
            ...mapGetters(Modules.USERS, {
                getUserBySlug: Getters.Users.GET_BY_SLUG
            })
        },
        methods: {
            getStats(type, text) {
                return {
                    challenger: this.challenger.type,
                    text: this.$t(`users.${text}`),
                    challenged: this.challenged.type
                }
            },
        }
    }
</script>

<style scoped>

</style>