<template>
    <router-link v-if="to" class="overlay-absolute" :to="to"/>
</template>

<script>
    export default {
        name: "RouterLink_Overlay",
        props: { to : [String, null] }
    }
</script>
