<template>
    <Duel_Bar_VotingResult v-if="!ableToVote" :duel="duel" />
    <b-collapse class="duel-header-voting-section" v-else :visible="!this.isClosed">
        <Duel_Voting_VoteButtons :duel="duel" />
    </b-collapse>
</template>

<script>

    import Duel from "@/server/structures/Duel";
    import Duel_Bar_VotingResult from "@/ui/components/duels/Duel_Bar_VotingResults";
    import Duel_Voting_VoteButtons from "@/ui/components/duels/Duel_Voting_VoteButtons";
    import { mapGetters } from 'vuex';
    import Getters from "@/store/Getters";
    import Modules from "@/store/Modules";

    export default {
        name: "Duel_Section_Voting",
        components: { Duel_Bar_VotingResult, Duel_Voting_VoteButtons },
        props: {
            duel: Duel,
            isClosed: Boolean
        },
        computed: {
            ableToVote() {
                return (!this.duel.voted && !this.isParticipant) && this.duel.isAccepted;
            },
            isParticipant() {
                return this.isMe(this.duel.challenger.slug) || this.isMe(this.duel.challenged.slug);
            },
            ...mapGetters(Modules.USERS, {
                isMe : Getters.Users.IS_ME,
            })
        },
    }
</script>

<style>
    .duel-header-voting-section {
        margin-bottom: 0.5em;
    }
</style>
