import AbstractMethodError from "@/errors/AbstractMethodError";
import ValidationErrorMap from "@/server/validation/ValidationErrorMap";
import ResponseStatus from "@/server/types/ResponseStatus";
import Locale from "@/locale/Locale";


export default class ApiResponse {

    _rawResponse;

    get success() {
        return this.statusCode === ResponseStatus.Success;
    }

    /** @deprecated use get errors instead for a more complete error summary. */
    get errorMessage() {
        throw new AbstractMethodError("errorMessage");
    }

    get errors() {
        try {
            return ValidationErrorMap.fromServerResponse(this._rawResponse.data);
        }
        catch {
            return ValidationErrorMap.general(Locale.translate("errors.unknown"));
        }
    }

    get statusCode() {
        return this._rawResponse.status;
    }

    constructor(rawResponse) {
        this._rawResponse = rawResponse;
    }
}
