import PostType from "@/server/types/PostType";
import LengthType from "@/server/types/LengthType";
import ProxyObject from "@/server/structures/ProxyObject";
import User from "@/server/structures/User";
import Tag from "@/server/structures/Tag";
import PostText from "@/utils/structures/PostText";

/** Wrapper class for Post objects returned from the server. */
export default class Post extends ProxyObject {

    _text;
    _data;

    get id() {
        return this._data.id;
    }

    get fullText() {
        return this._text.full;
    }

    set fullText(value) {
        this._data.text = value;
        this._text = new PostText(value);
    }

    get header() {
        return this._text.header;
    }

    get text() {
        return this._text.body;
    }

    get imageUrl() {
        return this._data.image.url;
    }

    set imageUrl(value) {
        this._data.image = { url : value };
    }

    get rank() {
        return this._data.rank;
    }

    //TODO : Remove second check after back-end is fixed.
    get isText() {
        return this._data.type === PostType.Text && !!this._data.text;
    }

    get isImage() {
        return this._data.type === PostType.Image;
    }

    get isLong() {
        return this._data.length_type === LengthType.Long;
    }

    get length() {
        return this._data.length;
    }

    get user() {
        return new User(this._data.user);
    }

    /** @deprecated Use user.name instead */
    get username() {
        return this._data.user.name;
    }

    /** @deprecated Use user.avatarUrl instead */
    get userAvatarUrl() {
        return this._data.user.avatar?.url;
    }

    /** @deprecated Use user.slug instead */
    get userSlug() {
        return this._data.user.slug;
    }

    get createdAt() {
        return new Date(this._data.created_at);
    }

    get viewCount() {
        return this._data.views_count;
    }

    get likeCount() {
        return this._data.likes_count;
    }

    get dislikeCount() {
        return this._data.dislikes_count;
    }

    get commentCount() {
        return this._data.comments_count;
    }

    get tripCount() {
        return this._data.joke_trips;
    }

    get tags() {

        if(this._data.tags === undefined){
            return [];
        }

        return this._data.tags.map((tagObj) => {
            return new Tag(tagObj);
        });
    }

    set tags(tagNames) {

        this._data.tags = tagNames.map((tagName) => {
            return { text: tagName, posts_count: 0 };
        });
    }

    get tagNames() {
        return this._data.tags.map((tag) => {
            return tag.text;
        });
    }

    get isLiked() {
        return this._data.is_like === 1;
    }

    get isDisliked() {
        return this._data.is_dislike === 1;
    }

    get isFavorited() {
        return this._data.is_favorite === 1;
    }

    get isDuelPost() {
        return !!this._data.duel_code;
    }

    get duelCode() {
        return this._data.duel_code;
    }

    set isLiked(value) {
        if (this.isLiked === value) return;

        if(value) this.isDisliked = false;

        this._data.is_like = value ? 1 : null;
        this._data.likes_count += value ? 1 : -1;
    }

    set isDisliked(value) {
        if (this.isDisliked === value) return;

        if(value) this.isLiked = false;

        this._data.is_dislike = value ? 1 : null;
        this._data.dislikes_count += value ? 1 : -1;
    }

    set isFavorited(value) {
        if (this.isFavorited === value) return;

        this._data.is_favorite = value ? 1 : null;
    }

    constructor(data) {
        super();

        this._setData(data);
    }

    incrementComments() {
        this._data.comments_count++;
    }

    copy() {
        return new Post({ ...this._data });
    }

    apply(copy) {
        this._setData(copy._data);
    }

    _setData(data) {

        this._data = data;

        if (!this._data.is_favorite) { this._data.is_favorite = null; }

        if (this.isText) { this._text = new PostText(this._data.text); }
    }

    equals(other) {

        if(!(other instanceof Post)) return false;

        if(this === other) return true;

        return this.id === other.id;
    }
}
