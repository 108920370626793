<template>
    <Layout_AuthSidebar :title="$t('auth.title-password-reset')" nav-id="sidebar-forgot-password" :hide-legal-disclaimer="true" v-on:close="reset">
        <template #default="{ hide }">

            <template v-if="!submitted">

                <Auth_Form_InputField
                        class="mb-5"
                        :label="$t('auth.password-reset-what-email-q')"
                        type="email"
                        input-name="email"
                        v-model="email"
                />

                <Auth_Button_Black :disabled="continueDisabled" @click.native="submit">
                    {{ $t("auth.continue") }}
                </Auth_Button_Black>

            </template>
            <template v-else>

                <Auth_Text class="mb-5 text-center"> {{ $t("auth.password-reset-check-your-email") }} </Auth_Text>

                <Auth_Button_Black :disabled="continueDisabled" @click.native="close" v-b-toggle.sidebar-login>
                    {{ $t("auth.continue") }}
                </Auth_Button_Black>
            </template>

            <button hidden ref="hideButton" @click="hide"></button>
        </template>
    </Layout_AuthSidebar>
</template>

<script>

    import Auth_Form_InputField from "@/ui/components/auth/Auth_Form_InputField";
    import Layout_AuthSidebar from "@/ui/layouts/Layout_AuthSidebar";
    import Auth_Button_Black from "@/ui/components/auth/Auth_Button_Black";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import {mapActions} from "vuex";
    import Auth_Text from "@/ui/components/auth/Auth_Text";

    export default {
        name: "Auth_Screen_ForgotPassword",
        components: { Auth_Text, Auth_Button_Black, Layout_AuthSidebar, Auth_Form_InputField},
        data() {
            return {
                email: null,
                submitted: false
            }
        },
        computed: {
            continueDisabled() {
                return !this.email;
            }
        },
        methods: {
            close() {
                this.$refs["hideButton"].click();
                this.reset();
            },
            reset() {
                this.email = null;
                this.submitted = false;
            },
            submit() {
                this.submitted = true;
                this.requestPasswordReset({ email : this.email });
            },
            ...mapActions(Modules.AUTH, {
                requestPasswordReset : Actions.Auth.FORGOT_PASSWORD
            })
        }
    }
</script>
