<template>
    <div class="info-question">
        <h2 class="info-question-text color-orange">
            <slot></slot>
        </h2>
    </div>
</template>

<script>

    export default {
        name: "Info_Header"
    }
</script>

<style scoped>
    .info-question {
        border-bottom: 1px solid rgba(198, 198, 198, 0.8);
        padding-bottom: .3rem;
    }
    .info-question-text {
        font-size: 1.5rem;
        margin-top: 1rem;
    }
</style>
