import AbstractMethodError from "@/errors/AbstractMethodError";

export default class ProxyObject {

    async mutate(callback) {

        const copy = this.copy();

        const result = await callback(this);

        if (!result.success) { this.apply(copy); }

        return result;
    }

    copy() {
        throw new AbstractMethodError("copy");
    }

    // eslint-disable-next-line no-unused-vars
    apply(copy) {
        throw new AbstractMethodError("apply");
    }
}
