<template>
    <Layout_UserDetailsSection class="user-details-section-experience" :info-text="$t('users.hint-experience')">

        <UserDetails_Header_Small>{{ $t("users.experience-points") }}:</UserDetails_Header_Small>

        <UserDetails_Text_Small class="ml-3">{{ user.activityPoints }}</UserDetails_Text_Small>

        <Bar_Experience_Large class="mt-2 mb-2 experience-bar" :percentage-fill="user.activityPercentage"/>

    </Layout_UserDetailsSection>
</template>

<script>
    import User from "@/server/structures/User";
    import Layout_UserDetailsSection from "@/ui/layouts/Layout_UserDetailsSection";
    import UserDetails_Header_Small from "@/ui/components/users/details/UserDetails_Header_Small";
    import UserDetails_Text_Small from "@/ui/components/users/details/UserDetails_Text_Small";
    import Bar_Experience_Large from "@/ui/components/common/Bar_Experience_Large";

    export default {
        name: "UserDetails_Section_Experience",
        components: { Bar_Experience_Large, UserDetails_Text_Small, UserDetails_Header_Small, Layout_UserDetailsSection },
        props: { user: User }
    }
</script>

<style scoped>

    .user-details-section-experience {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .user-details-section-experience .experience-bar {
        margin-left: auto;
        margin-right: auto;
    }

</style>
