<template>
    <div class="common-panel-hint border-rounded">
        <slot/>
    </div>
</template>

<script>
export default { name: "Panel_Hint" }
</script>

<style>

.common-panel-hint {
    background-color: #ffe0aa;
}
</style>
