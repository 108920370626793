import ApiResponse from "@/server/responses/ApiResponse";
import User from "@/server/structures/User";

export default class ApiResponse_Login extends ApiResponse{

    get errorMessage() {
        return "errors.invalid-login-credentials";
    }

    get token() {
        return "Bearer " + this._rawResponse.headers.authorization;
    }

    get userData() {
        return new User(this._rawResponse.data);
    }
}
