<template>
    <div>
        <Duel_Tutorial v-if="!isSharing"/>
        <Duel_Feed ref="feed" :duel-collection="duelCollection"/>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import StoreObjects from "@/store/StoreObjects";
    import Duel_Feed from "@/ui/components/duels/Duel_Feed";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import Duel_Tutorial from "@/ui/components/duels/Duel_Tutorial";
    import WindowEx from "@/utils/WindowEx";

    /** An offset that is added when sharing a duel to focus on it. */
    const ShareInitialScroll = 235;

    export default {
        name: "FeedView_Duels",
        components: { Duel_Tutorial, Duel_Feed },
        watch: {
            '$route.query.d': () => { this.checkForLocalRedirect(); },
            '$route.query.s': () => { this.checkForSharedDuel(); }
        },
        mounted() {
            this.checkForSharedDuel();
            this.checkForLocalRedirect();
        },
        computed: {
            isSharing() {
                return this.$route.query.d || this.$route.query.s;
            },
            duelCollection() {
                return StoreObjects.DuelFeed;
            }
        },
        methods: {
            async checkForLocalRedirect() {
                if(!this.$route.query.d) { return; }

                const response = await this.loadFromDuelCode(this.$route.query.d)

                if(response.success) this.showSharedDuel(response.duel);
            },
            async checkForSharedDuel() {
                if(!this.$route.query.s) { return; }

                const response = await this.loadFromShareCode(this.$route.query.s)

                if(response.success) this.showSharedDuel(response.duel);
            },
            async showSharedDuel(duel) {

                await StoreObjects.DuelFeed[Actions.Collection.PUSH_TO_TOP](duel);

                this.$refs['feed'].highlight(duel.id);

                WindowEx.smoothScrollY(ShareInitialScroll);
            },
            ...mapActions(Modules.DUEL_ACTIONS, {
                loadFromShareCode: Actions.Duels.GET_BY_SHARE_CODE,
                loadFromDuelCode : Actions.Duels.GET_BY_CODE
            })
        }
    }
</script>
