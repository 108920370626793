<template>
    <div class="user-settings-password-edit">
        <Header_Large>{{ $t("users.password-change") }}</Header_Large>

        <template v-if="statusIsReady || statusIsError">
            <Text_Error v-if="errorMsg">{{ this.errorMsg }}</Text_Error>

            <Form_Label class="mt-3" for="user-settings-password">{{ $t("users.password-new") }}</Form_Label>
            <Form_InputField type="password" id="user-settings-password" class="gray" v-model="password"/>

            <Form_Label class="mt-3" for="user-settings-password-confirm">{{ $t("users.password-confirm") }}</Form_Label>
            <Form_InputField type="password" id="user-settings-password-confirm" class="gray" v-model="confirmPassword"/>

            <Button_Gradient class="mt-3 mx-auto w-100" :disabled="!passwordsFilled" @click.native="confirm">
                {{ $t("general.save") }}
            </Button_Gradient>
        </template>
        <template v-else-if="statusIsLoading">
            <Spinner class="orange center-absolute"/>
        </template>
        <template v-else-if="statusIsSuccess">
            <Text_NoResults class="mt-5 pt-3">Password changed successfully</Text_NoResults>
        </template>
        <template v-else>

        </template>
    </div>
</template>

<script>
import Form_Label from "@/ui/components/common/Form_Label";
import Form_InputField from "@/ui/components/common/Form_InputField";
import Button_Gradient from "@/ui/components/common/Button_Gradient";
import Header_Large from "@/ui/components/common/Header_Large";
import Text_Error from "@/ui/components/common/Text_Error";
import {mapActions} from "vuex";
import Modules from "@/store/Modules";
import Actions from "@/store/Actions";
import Spinner from "@/ui/components/common/Spinner";
import Text_NoResults from "@/ui/components/common/Text_NoResults";
import FormStatus from "@/store/types/FormStatus";
import Mixin_Form from "@/ui/mixins/Mixin_Form";

export default {
    name: "UserSettings_PasswordEdit",
    components: { Text_NoResults, Spinner, Text_Error, Header_Large, Button_Gradient, Form_InputField, Form_Label },
    mixins: [Mixin_Form],
    data() {
        return {
            password: null,
            confirmPassword: null,
            errorMsg: null
        }
    },
    mounted() {
        this._clearFields();

        this.status = FormStatus.Ready;
    },
    computed: {
        passwordsFilled() {
            return !!this.password && !!this.confirmPassword;
        },
        passwordsMatch() {
            return this.password === this.confirmPassword;
        }
    },
    methods: {
        async confirm() {
            this.errorMsg = null;

            if(!this.passwordsMatch) {
                this.errorMsg = this.$t("errors.password-mismatch");
                return;
            }

            this.setStatus(FormStatus.Loading);

            const result = await this.changePassword({
                password: this.password,
                passwordConfirmation: this.confirmPassword
            })

            this._clearFields();

            if(result.success) {
                this.setStatus(FormStatus.Success);
            }
            else {
                this.errorMsg = result.errors.getFirstError();
                this.setStatus(FormStatus.Error);
            }
        },
        _clearFields() {
            this.password = this.confirmPassword = this.errorMsg = null;
        },
        ...mapActions(Modules.AUTH, {
            changePassword : Actions.Auth.CHANGE_PASSWORD
        })
    }
}
</script>

<style>

    .user-settings-password-edit {
        min-height: 15em;
        position: relative;
    }

</style>
