<template>
    <div class="no-results-text-small-container">
        <h5 class="no-results-text-small">
            <slot></slot>
        </h5>
    </div>
</template>

<script>
    export default {
        name: "Text_NoResults_Small"
    }
</script>

<style>

    .no-results-text-small-container {
        text-align: center;
        padding-left: 7%;
        padding-right: 7%;
        min-height: 5em;
        position: relative;
        color: #8b8b8b;
    }

    .no-results-text-small {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

</style>
