import ModuleTemplate_Collection from "@/store/templates/ModuleTemplate_Collection";

/**
 * @param {String} userSlug
 * @param {ApiProxy_Users} usersApi
 */
export default function (userSlug, usersApi) {

    return ModuleTemplate_Collection(async(context, page) => {

        const response = await usersApi.getFollowings(userSlug, page);

        return {
            success : response.success,
            entries : response.users,
            lastPage : response.lastPage
        }
    });
}
