<template>
    <Layout_Section_PageHeader>

        <Help_Section :info-text="$t('leaderboards.help-section')" />

        <Header_Large class="text-uppercase">{{ $t("leaderboards.title") }}</Header_Large>

        <Header_Small>{{ $t("leaderboards.subtitle") }}</Header_Small>

    </Layout_Section_PageHeader>
</template>

<script>
    import Header_Large from "@/ui/components/common/Header_Large";
    import Header_Small from "@/ui/components/common/Header_Small";
    import Help_Section from "@/ui/components/common/help/Help_Section";
    import Layout_Section_PageHeader from "@/ui/layouts/Layout_Section_PageHeader";
    export default {
        name: "Leaderboard_Section_Header",
        components: { Layout_Section_PageHeader, Help_Section, Header_Small, Header_Large }
    }
</script>
