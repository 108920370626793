<template>
    <b-button pill variant="outline-secondary" class="d-block mt-4 mx-auto pl-3 pr-2 post-expand-button">
        <slot></slot>
        <img :src="$icons.Common.ArrowDown" alt="Down Arrow" class="arrow-icon" :class="{inverted: !isOpenButton}"/>
        <span class="read-time" v-if="isOpenButton">
            {{ readTime.time }} {{ readTime.type === 'seconds' ? $t("general.second-short") : $t("general.minute-short") }}
        </span>
    </b-button>
</template>

<script>
    export default {
        name: "Post_Button_Expand",
        props: {
            isOpenButton: Boolean,
            readTime: Object
        }
    }
</script>

<style scoped>

    .post-expand-button:focus {
        color: black;
    }

    .post-expand-button:hover {
        color: black;
    }

    .post-expand-button .arrow-icon {
        height: 0.9em;
        margin-left: 4px;
        filter: opacity(45%);
    }

    .post-expand-button .arrow-icon.inverted {
        transform: rotate(180deg);
    }

    .read-time {
        color: #b7b7b7;
        margin-left: 0.2rem;
        margin-right: 0.3rem;
    }
</style>
