<template>
    <div class="duel-user-icons-text">
        <slot> </slot>
    </div>
</template>

<script>

    export default {
        name: "Duel_Header_UserIconsText",
    }
</script>

<style>
    .duel-user-icons-text {
        font-weight: 700;
        color: #7c858b;
    }
</style>