<!-- Element used for a single leaderboard entry. -->

<template>
    <div>

        <Leaderboard_Entry_User v-if="isUserEntry" :data="data" :display-type="displayType" :highlighted="highlighted"/>

        <Leaderboard_Entry_Separator v-else-if="isSeparatorEntry"/>

    </div>
</template>

<script>

    import Leaderboard_Entry_User from "@/ui/components/leaderboard/Leaderboard_Entry_User";
    import Leaderboard_Entry_Separator from "@/ui/components/leaderboard/Leaderboard_Entry_Separator";

    export default {
        name: "Leaderboard_Entry",
        components: { Leaderboard_Entry_Separator, Leaderboard_Entry_User },
        props: {
            data: Object,
            displayType: String,
            highlighted: Boolean
        },
        computed: {
            isUserEntry() {
                return this.data.isUser;
            },
            isSeparatorEntry() {
                return this.data.isSeparator;
            }
        }
    }
</script>
