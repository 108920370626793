<template>
    <Layout_Section_PageHeader>

        <Help_Section :info-text="$t('rankings.help-section')"/>

        <Header_Large class="text-uppercase">{{ $t("rankings.title") }}</Header_Large>

    </Layout_Section_PageHeader>
</template>

<script>
import Layout_Section_PageHeader from "@/ui/layouts/Layout_Section_PageHeader";
import Help_Section from "@/ui/components/common/help/Help_Section";
import Header_Large from "@/ui/components/common/Header_Large";
export default {
name: "Rankings_Section_Header",
    components: { Header_Large, Help_Section, Layout_Section_PageHeader }
}
</script>
