import APostCreationData from "@/server/post_creation/APostCreationData";
import PostType from "@/server/types/PostType";

export default class PostCreationData_Image extends APostCreationData {

    _imageFile;

    get requestParams() {

        const formData = new FormData();

        if(this._imageFile) {
            formData.append('image', this._imageFile.rawFile);
        }

        this._tags.forEach((tag) => { formData.append('tags[]', tag); });

        return formData;
    }

    get requestHeaders() {
        return { headers: { 'Content-Type' : 'multipart/form-data' } };
    }

    get postType() {
        return PostType.Image;
    }

    constructor(imageFile) {
        super();
        this._imageFile = imageFile;
    }

    applyToPost(post) {
        super.applyToPost(post);

        if(this._imageFile) {
            post.imageUrl = this._imageFile.base64;
        }
    }
}
