<template>
    <span class="user-fans-counter">
        {{ text }}: {{ value }} <span class="see-button"> {{ $t('users.see') }} </span>
    </span>
</template>

<script>

export default {
    name: "UserDetails_Button_Fans",
    props: {
        text: String,
        value: Number
    },
}
</script>

<style>
    .user-fans-counter {
        height: 20px;
        line-height: 20px;
        max-width: 220px;
        color: #3b3b3b;
        padding-left: 6px;
        padding-right: 12px;
        background-color: #efefef;
        border-radius: 10px;
    }
    .user-fans-counter:hover {
        cursor: pointer;
    }
    .user-fans-counter .see-button {
        float: right;
        text-decoration: underline;
    }
</style>
