<template>
    <div class="duel-challenge-actions" :class="$mq">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "Layout_DuelChallenge_Actions"
}
</script>

<style>
    .duel-challenge-actions {
        padding: 1em 0 1em 0;
        border-top: 1px solid rgba(198, 198, 198, 0.8);
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .duel-challenge-actions.mobile-m,
    .duel-challenge-actions.mobile-s {
        flex-flow: row wrap;
    }
</style>
