<template>
    <div>
        <small>
            <i18n path="auth.legal-disclaimer">
                <template #privacy>
                    <RouterLink_Default to="/privacy" target="_blank">{{ $t("auth.legal-disclaimer-privacy") }}</RouterLink_Default>
                </template>
                <template #terms>
                    <RouterLink_Default to="/terms" target="_blank">{{ $t("auth.legal-disclaimer-terms") }}</RouterLink_Default>
                </template>
            </i18n>
        </small>
    </div>
</template>

<script>
    import RouterLink_Default from "@/ui/components/common/RouterLink_Default";
    export default {
        name: "Auth_LegalDisclaimer",
        components: { RouterLink_Default },
    }
</script>
