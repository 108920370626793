import general from './general.json';
import auth from './auth.json';
import errors from './errors.json';
import posts from './posts.json';
import users from './users.json';
import faq from './faq.json';
import leaderboards from './leaderboards.json';
import aboutUs from './aboutUs.json';
import terms from './terms.json';
import duels from './duels.json';
import privacyPolicy from './privacyPolicy.json';
import map from './map.json';
import badges from './badges.json';
import rankings from './rankings.json';

export default {
    general,
    auth,
    errors,
    posts,
    users,
    faq,
    leaderboards,
    aboutUs,
    terms,
    duels,
    privacyPolicy,
    map,
    badges,
    rankings
};
