// Store stringified Objects in localStorage
export default class StorageObject {

    _key;

    set(key, val) {
        let storageString = localStorage.getItem(this._key);
        const parsedObject = JSON.parse(storageString);

        const newObject = {
            ...parsedObject, 
            [key]: val
        }
        
        storageString = JSON.stringify(newObject);
        return localStorage.setItem(this._key, storageString);
    }

    get(key) {
        let storageString = localStorage.getItem(this._key);
        const parsedObject = JSON.parse(storageString);

        return parsedObject[key];
    }

    exists(key) {
        let storageString = localStorage.getItem(this._key);
        const parsedObject = JSON.parse(storageString);

        return parsedObject !== null && (key in parsedObject);
    }

    constructor(key) {
        this._key = key;
    }

    remove() {
        localStorage.removeItem(this._key);
    }
}