
export default {
    INITIALIZE : "initialize",
    Auth : {
        INITIALIZE : "initialize",
        REGISTER : "register",
        LOGIN : "login",
        LOGIN_FACEBOOK : "facebookLogin",
        LOGIN_GOOGLE : "googleLogin",
        LOGOUT : "logout",
        VERIFY_EMAIL : "verifyEmail",
        FORGOT_PASSWORD : "forgotPassword",
        RESET_PASSWORD : "resetPassword",
        CHANGE_PASSWORD : "changePassword"
    },
    Posts : {
        RELOAD : "reload",
        LOAD_MORE : "loadMore",
        CREATE : "createPost",
        EDIT : "editPost",
        VIEW : "view",
        LIKE : "like",
        DISLIKE : "dislike",
        FAVORITE : "favorite",
        REPORT : "report",
        GET_FOR_USER : "getForUser",
        SHARE : "getShareLink",
        GET_BY_SHARE_CODE : "getByShareCode",
        GET_MAP : "getMap"
    },
    Tags : {
        PULL_POPULAR : "pullPopular",
        PULL_LATEST : "pullLatest",
        SEARCH : "search"
    },
    Comments : {
        RELOAD : "reaload",
        LOAD_MORE : "loadMore",
        POST : "post"
    },
    Users : {
        INITIALIZE : "initialize",
        PULL : "get",
        PULL_ME : "getMe",
        TOGGLE_FOLLOW : "toggleFollow",
        UPDATE_AVATAR : "updateAvatar",
        UPDATE_PROFILE_TEXTS : "updateProfileTexts",
        GET_MAP : "getMap",
        GET_BADGES : "getBadges"
    },
    Localization : {
        INITIALIZE : "initialize"
    },
    Leaderboards : {
        SET_CATEGORY : "setCategory",
        SHOW_MY_POSITION : "setShowMyPosition",
        PULL : "pullLeaderboards"
    },
    Duels : {
        CREATE : "createDuel",
        RESPOND : "respondToDuel",
        SHARE : "shareDuel",
        GET_BY_CODE : "getDuelByCode",
        GET_BY_SHARE_CODE : "getByShareCode",
        GET_TEST_DUEL : "getTestDuel",
        VOTE_CHALLENGER : "voteForChallenger",
        VOTE_CHALLENGED : "voteForChallenged",
        GET_STATS: "getStats",
        GET_RESULTS: "getResults",
        SET_FINISHED: "setFinished"
    },
    Badges : {
        PULL : "pull"
    },
    Notifications : {
        PULL : "pull",
        MARK_ALL_AS_READ : "markAllAsRead",
        SUBMIT_TOKEN : "submitToken",
        MARK_UNREAD : "markUnread",
        GET_LAST_UNSHOWN : "getLastUnshown",
        PUSH_LAST_UNSHOWN : "pushLastUnshown"
    },
    Rankings: {
        SET_CATEGORY: "setCategory",
        RELOAD: "reload"
    },
    Collection : {
        PUSH_TO_TOP : "pusToTop",
        RELOAD: "reload",
        LOAD_MORE: "loadMore"
    }
}
