
export default {
    Auth : {
        _SET_SUCCESSFUL_LOGIN : "_setSuccessfulLogin",
        _LOGOUT : "_logout"
    },
    Tags : {
        TOGGLE_SELECT : "toggleSelect",
        SET_SELECTED : "setSelected",
        CLEAR_SELECTED : "clearSelected",
        _SET_POPULAR : "_setPopular",
        _SET_LATEST : "_setLatest"
    },
    Posts : {
        SET_FILTERS : "setFilters",
        _RESET : "_reset",
        _BEGIN_LOAD : "_beginLoad",
        _END_LOAD : "_endLoad",
        _INCREMENT_PAGE : "_incrementPage",
        _ADD_NEW_POSTS : "_addNewPosts",
        _ADD_POST_TO_TOP : "_addPostToTop",
        _SET_STATUS : "_setStatus",
        INCREMENT_COMMENTS : "incrementComments"
    },
    Users : {
        SET_SELF : "setSelf",
        REMOVE_SELF : "removeSelf",
        _SET_USER : "_setUser"
    },
    Comments : {
        _BEGIN_LOAD_MORE : "_setNewPage",
        _END_LOAD_MORE : "_addNewComments",
        _RESET_FOR_POST : "_resetForPost",
        _ADD_COMMENT : "_addComment",
        _REMOVE_COMMENT : "_removeComment",
    },
    Localization : {
        SET_REGION : "setRegion",
        SET_LANGUAGE : "setLanguage"
    },
    Leaderboards : {
        _SET_FILTERS : "_setFilters",
        _SET_CATEGORY : "_setCategory",
        _SET_SHOW_MY_POSITION : "_setShowMyPosition",
        _SET_ENTRIES : "_setEntries",
        _SET_STATUS : "_setStatus"
    },
    Badges : {
        _SET_STATUS : "_setStatus",
        _SET_CATEGORIES : "_setCategories"
    },
    Notifications : {
        _SET_STATUS : "_setStatus",
        _SET_NOTIFICATIONS: "_setNotifications",
        _SET_HAS_UNREAD : "_setHasUnread"
    },
    Rankings: {
        _SET_POSTS : "_setPosts",
        _SET_CATEGORY : "_setCategory"
    },
    Collection : {
        _RESET : "_reset",
        _INCREMENT_PAGE : "_incrementPage",
        _ADD_NEW_ENTRIES : "_addNewEntries",
        _SET_STATUS : "_setStatus",
        _SET_ERROR_MESSAGE : "_setErrorMessage"
    }
}
