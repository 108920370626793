<template>
    <b-button class="post-added-tag" :class="{ 'add-button': isAddButton}">
        {{ name }}
        <img :src="isAddButton ? $icons.Common.Plus : $icons.Common.Close" />
    </b-button>
</template>

<script>
    export default {
        name: "PostCreation_Button_Tag",
        props : {
            name: String,
            isAddButton: Boolean
        }
    }
</script>

<style scoped>
    .post-added-tag {
        border: 1px solid #b8b6b7;
        border-radius: 5px;
        color: #2a2333;
        padding: 0.22em 0.7em;
        font-size: 0.88em;
        font-weight: 500;
        margin-right: 8px;
        margin-bottom: 8px;
        background-color: #f6f6f4;
        display: inline-block;
    }

    .post-added-tag:hover {
        color: #2a2333;
        background-color: #f6f6f4;
    }

    .post-added-tag.add-button {
        background-color: #f6f6f4;
    }

    .post-added-tag img {
        margin-left: 9px;
        height: 0.7rem;
        filter: invert(54%) sepia(59%) saturate(3754%) hue-rotate(329deg) brightness(105%) contrast(98%);
    }
</style>
