
export default {
    LoginSuccessful : "event-login",
    ReportClicked : "event-report-clicked",
    ShareClicked : "event-share-clicked",
    TextCopy : "event-text-copied",
    TextCopyError : "event-text-copy-error",
    TagSelected : "tag-selected",
    DuplicateNavigation : "duplicate-navigation",
    RegionChanged: "region-changed",
    VoteBarClicked : "event-vote-bar-clicked",
    PostEdit: "post-edit",
    PostJokeTripClicked : "post-joketrip-clicked",
    BadgeClicked : "badge-clicked",
    InitNotification : "init-notification",
    ReceivedNotification : "received-notification",
    CheckForNewUnshownNotification : "check-for-new-unshown-notification",
    PushForNewUnshownNotification : "push-for-new-unshown-notification",
    AddMobileAppEvent : "add-mobile-app-event",
    LoginWithFacebook : "login-with-facebook",
}
