<template>
    <div v-if="tags.length !== 0" class="tag-search-results-section">
        <h6 v-if="name">{{ name }}</h6>
        <FeedTags_Button
                v-for="(tag, index) in tags"
                :key="tag.name"
                :index="index"
                :tag="tag"
        />
    </div>
</template>

<script>
    import FeedTags_Button from "@/ui/components/feed/tags/FeedTags_Button";
    export default {
        name: "FeedTags_Search_ResultsSection",
        components: { FeedTags_Button },
        props: {
            name: String,
            tags: Array
        }
    }
</script>

<style scoped>
    .tag-search-results-section {
        border-bottom: 1px solid #d4d4d4;
        padding: 1rem 2rem;
    }
</style>
