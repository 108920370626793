<template>
    <b-collapse v-if="open" :visible="open">

        <template v-if="!loading && commentsAreEmpty">
            <Text_NoResults_Small>{{ $t("posts.no-comments-found") }}</Text_NoResults_Small>
        </template>

        <Comment_List :comments="comments" />

        <template v-if="loading">
            <Comment_Loading v-for="index in this.loadingCommentsCount" :key="-index"/>
        </template>

        <div v-if="showLoadMore" @click="loadMore(post.id)" class="post-comments-button-load-more">
            {{ $t('posts.view-more-comments') }}
        </div>

        <Comment_Field :post-id="post.id"/>
    </b-collapse>
</template>

<script>
    import Post from "@/server/structures/Post";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import {mapActions, mapGetters, mapState} from "vuex";
    import Getters from "@/store/Getters";
    import Comment_List from "@/ui/components/feed/comments/Comment_List";
    import Comment_Field from "@/ui/components/feed/comments/Comment_Field";
    import Comment_Loading from "@/ui/components/feed/comments/Comment_Loading";
    import Text_NoResults_Small from "@/ui/components/common/Text_NoResults_Small";
    import MathEx from "@/utils/MathEx";

    export default {
        name: "Post_Section_Comments",
        components: { Text_NoResults_Small, Comment_Loading, Comment_Field, Comment_List },
        props: {
            post: Post,
            open: Boolean
        },
        watch: {
            open(value) {
                if(value) { this.reload(this.post.id); }
            }
        },
        computed: {
            comments() { return this.getCommentsForPost(this.post.id); },
            loadingCommentsCount() { return MathEx.min(this.post.commentCount - this.comments.length, this.commentsPerPage); },
            commentsAreEmpty() { return this.comments.length === 0; },
            loading() { return this.post.commentCount !== 0 && this.commentsAreLoading(this.post.id); },
            showLoadMore() { return !this.loading && this.hasMoreComments(this.post.id); },
            ...mapState(Modules.AUTH, {
                authenticated : auth => auth.authenticated
            }),
            ...mapGetters(Modules.COMMENTS, {
                getCommentsForPost : Getters.Comments.GET_FOR_POST,
                hasMoreComments : Getters.Comments.HAS_MORE_COMMENTS,
                commentsAreLoading : Getters.Comments.COMMENTS_ARE_LOADING,
                commentsPerPage : Getters.Comments.COMMENTS_PER_PAGE
            })
        },
        methods : {
            ...mapActions(Modules.COMMENTS, {
                loadMore: Actions.Comments.LOAD_MORE,
                reload: Actions.Comments.RELOAD
            })
        }
    }
</script>

<style>

    .post-comments-button-load-more {
        width: 100%;
        text-align: center;
        font-size: 10pt;
        padding-top: 0.8em;
        padding-bottom: 0.5em;
    }
</style>
