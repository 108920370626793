<template>
    <Layout_Info>
         <template v-slot:title>
            {{ $t("privacyPolicy.title") }}
        </template>

        <template v-slot:content>  
            <Info_SubHeader> {{ $t("privacyPolicy.last-edit") }} </Info_SubHeader>

            <Info_Header>{{ $t("privacyPolicy.1-intro") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.1-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.1-2")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.1-3")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.2-definitions") }}</Info_Header>
            <Info_ListOrderedAlpha class="privacy-list-alpha">
                <Info_ListBullet>{{ $t("privacyPolicy.2-1") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-2") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-3") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-4") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-5") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-6") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-7") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-8") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-9") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-10") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-11") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-12") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-13") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-14") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-15") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-16") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-17") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-18") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-19") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-20") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-21") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-22") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.2-23") }}</Info_ListBullet>
            </Info_ListOrderedAlpha>

            <Info_Header>{{ $t("privacyPolicy.3-personal-data-type") }}</Info_Header>
            <Info_NestedList>
                <Info_ListItemOrdered> {{ $t("privacyPolicy.3-1") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.3-1-1")}}</Info_Text>
                <Info_ListItemOrdered> {{ $t("privacyPolicy.3-2") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.3-2-1")}}</Info_Text>
                <Info_ListItemOrdered> {{ $t("privacyPolicy.3-3") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.3-3-1")}}</Info_Text>
                <Info_Text>{{ $t("privacyPolicy.3-3-2")}}</Info_Text>
                <Info_ListItemOrdered> {{ $t("privacyPolicy.3-4") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.3-4-1")}}</Info_Text>
                <Info_Text>{{ $t("privacyPolicy.3-4-2")}}</Info_Text>
                <Info_Text>{{ $t("privacyPolicy.3-4-3")}}</Info_Text>
                <Info_ListItemOrdered> {{ $t("privacyPolicy.3-5") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.3-5-1")}}</Info_Text>
            </Info_NestedList>
            
            <Info_Header>{{ $t("privacyPolicy.4-personal-data-gather") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.4-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.4-1-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.4-2")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.4-2-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.4-3")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.4-3-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.4-4")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.4-4-1")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.5-personal-data-processing") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.5-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.5-2")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.5-3")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.6-cookies") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.6-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.6-2")}}</Info_Text>
            <Info_ListOrderedAlpha class="privacy-list-alpha">
                <Info_ListBullet>{{ $t("privacyPolicy.6-2-1") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.6-2-2") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.6-2-3") }}</Info_ListBullet>
                <Info_ListBullet>{{ $t("privacyPolicy.6-2-4") }}</Info_ListBullet>
            </Info_ListOrderedAlpha>
            
            <Info_BiscuitType type="language"/>
            <Info_BiscuitType type="user"/>
            <Info_BiscuitType type="auth-token"/>
            <Info_BiscuitType type="region"/>
            <Info_BiscuitType type="privacy-policy-accepted"/>

            <Info_Text>{{ $t("privacyPolicy.6-3")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.7-personal-data-purpose") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.7-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.7-2")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.7-3")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.8-legality") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.8-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.8-2")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.8-3")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.9-personal-data-storage-time") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.9-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.9-2")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.10-personal-data-share-partners") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.10-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.10-2")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.10-3")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.11-personal-data-share-users") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.11-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.11-2")}}</Info_Text>
            
            <Info_Header>{{ $t("privacyPolicy.12-personal-data-transfer") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.12-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.12-2")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.13-security") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.13-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.13-2")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.14-gdpr") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.14-1")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.14-2")}}</Info_Text>
            <Info_Text>{{ $t("privacyPolicy.14-3")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.15-your-rights") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.15-subtext")}}</Info_Text>
            <Info_NestedList>
                <Info_ListItemOrdered> {{ $t("privacyPolicy.15-1") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.15-1-1")}}</Info_Text>
                <Info_Text>{{ $t("privacyPolicy.15-1-2")}}</Info_Text>

                <Info_ListItemOrdered> {{ $t("privacyPolicy.15-2") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.15-2-1")}}</Info_Text>

                <Info_ListItemOrdered> {{ $t("privacyPolicy.15-3") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.15-3-1")}}</Info_Text>
                <Info_ListTick>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-3-1-1") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-3-1-2") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-3-1-3") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-3-1-4") }}</Info_ListBullet>
                </Info_ListTick>
                <Info_Text>{{ $t("privacyPolicy.15-3-2")}}</Info_Text>
                <Info_ListTick>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-3-2-1") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-3-2-2") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-3-2-3") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-3-2-4") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-3-2-5") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-3-2-6") }}</Info_ListBullet>
                </Info_ListTick>
                <Info_Text>{{ $t("privacyPolicy.15-3-3")}}</Info_Text>

                <Info_ListItemOrdered> {{ $t("privacyPolicy.15-4") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.15-4-1")}}</Info_Text>
                <Info_ListTick>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-4-1-1") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-4-1-2") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-4-1-3") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-4-1-4") }}</Info_ListBullet>
                </Info_ListTick>

                <Info_ListItemOrdered> {{ $t("privacyPolicy.15-5") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.15-5-1")}}</Info_Text>

                <Info_ListItemOrdered> {{ $t("privacyPolicy.15-6") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.15-6-1")}}</Info_Text>
                <Info_ListTick>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-6-1-1") }}</Info_ListBullet>
                    <Info_ListBullet>{{ $t("privacyPolicy.15-6-1-2") }}</Info_ListBullet>
                </Info_ListTick>
                <Info_Text>{{ $t("privacyPolicy.15-6-2")}}</Info_Text>
                <Info_Text>{{ $t("privacyPolicy.15-6-3")}}</Info_Text>
                <Info_Text>{{ $t("privacyPolicy.15-6-4")}}</Info_Text>

                <Info_ListItemOrdered> {{ $t("privacyPolicy.15-7") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.15-7-1")}}</Info_Text>
                <Info_Text>{{ $t("privacyPolicy.15-7-2")}}</Info_Text>

                <Info_ListItemOrdered> {{ $t("privacyPolicy.15-8") }} </Info_ListItemOrdered>
                <Info_Text>{{ $t("privacyPolicy.15-8-1")}}</Info_Text>
                <Info_Text>{{ $t("privacyPolicy.15-8-2")}}</Info_Text>
            </Info_NestedList>
            
            <Info_Header>{{ $t("privacyPolicy.16-contact-us") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.16-1")}}</Info_Text>

            <Info_Header>{{ $t("privacyPolicy.17-policy-updates") }}</Info_Header>
            <Info_Text>{{ $t("privacyPolicy.17-1")}}</Info_Text>
        </template>
    </Layout_Info>
</template>

<script>
    import Layout_Info from "@/ui/layouts/Layout_Info";
    import Info_SubHeader from "@/ui/components/info/Info_SubHeader";
    import Info_Header from "@/ui/components/info/Info_Header";
    import Info_Text from "@/ui/components/info/Info_Text";
    import Info_ListOrderedAlpha from "@/ui/components/info/Info_ListOrderedAlpha";
    import Info_ListTick from "@/ui/components/info/Info_ListTick";
    import Info_ListBullet from "@/ui/components/info/Info_ListBullet";
    import Info_ListItemOrdered from "@/ui/components/info/Info_ListItemOrdered";
    import Info_NestedList from "@/ui/components/info/Info_NestedList";
    import Info_BiscuitType from "@/ui/components/info/Info_BiscuitType";

    export default {
        name: "InfoView_TermsAndConditions",
        components: { Layout_Info, Info_SubHeader, Info_Header, Info_Text, Info_ListOrderedAlpha, Info_ListBullet, Info_ListItemOrdered, Info_NestedList, Info_ListTick, Info_BiscuitType },
    }
</script>

<style>
    .privacy-list-alpha {
        padding-inline-start: 0px; 
        list-style-position: inside;
    }
</style>
