<template>
    <p class="text-error"><slot/></p>
</template>

<script>
    export default {
        name: "Text_Error"
    }
</script>

<style scoped>

    .text-error {
        color: red;
    }
</style>
