<!-- Component for displaying the individual comments. -->

<template>
    <div class="comment">
        <div class="group-avatar">
            <Avatar_Small :user="comment.user"/>
        </div>

        <div class="group-text">
            <Post_Text_Username :user="comment.user" />
            <br/>
            {{ comment.text }}
        </div>
    </div>
</template>

<script>
    import Avatar_Small from "@/ui/components/common/Avatar_Small";
    import PostComment from "@/server/structures/PostComment";
    import Post_Text_Username from "@/ui/components/feed/post/Post_Text_Username";

    export default {
        name: "Comment",
        components: { Avatar_Small, Post_Text_Username },
        props: { comment: PostComment },
    }
</script>

<style scoped>

    .comment {
        margin-top: 0.5em;
        display: flex;
        flex-direction: row;
    }

    .comment .group-avatar {
        float: left;
        height: 100%;
    }

    .comment .group-text {
        flex-grow: 2;
        background-color: #ededed;
        border-radius: 18px;
        padding: 0.6em;
    }

    .comment .user-name {
        font-size: 10pt;
        font-weight: bold;
        width: auto;
    }
</style>
