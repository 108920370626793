import ApiResponse from "@/server/responses/ApiResponse";

export default class ApiResponse_ShareDuel extends ApiResponse {

    get url() {
        return this._rawResponse.data.url;
    }

    get code() {
        return this._rawResponse.data.code;
    }
}
