<template>
    <Layout_AuthSidebar :title="$t('auth.title-registration')" nav-id="sidebar-register" v-on:close="reset">
        <template #default="{ hide }">

            <template v-if="!loading">
                <Auth_Form id="registration">

                    <Auth_ErrorMessage :error-message="errors.getFirstErrorFor('name')"/>
                    <Auth_Form_InputField v-model="name" :label="$t('auth.name')" type="text" input-name="name" input-id="name-registration"/>

                    <Auth_ErrorMessage :error-message="errors.getFirstErrorFor('email')"/>
                    <Auth_Form_InputField v-model="email" :label="$t('auth.email')" type="email" input-name="email" input-id="email-registration"/>

                    <Auth_ErrorMessage :error-message="errors.getFirstErrorFor('password')"/>
                    <Auth_Form_InputField class="mb-3" v-model="password" :label="$t('auth.password')" type="password" input-name="password" input-id="password-registration" autocomplete="new-password"/>

                    <Auth_ErrorMessage :error-message="errors.getFirstErrorFor('ageVerification')"/>
                    <Checkbox class="ml-2" v-model="ageConfirmed">{{ $t("auth.confirmation-age") }}</Checkbox>

                    <Auth_Button_Black class="my-3" :disabled="!continueEnabled" @click.native="attemptRegister">{{ $t('auth.continue') }}</Auth_Button_Black>

                </Auth_Form>

                <Auth_PromptLabel :label="$t('auth.have-an-account-q')">
                    <Button_WhiteOutline class="px-4" @click.native="close" v-b-toggle.sidebar-login-select>
                        {{ $t('auth.log-in') }}
                    </Button_WhiteOutline>
                </Auth_PromptLabel>
            </template>
            <Spinner v-else class="center-absolute"/>

            <button hidden ref="showSuccessButton" @click="hide" v-b-toggle.sidebar-register-email-sent></button>
            <button hidden ref="hideButton" @click="hide"></button>
        </template>
    </Layout_AuthSidebar>
</template>

<script>
    import {mapActions} from "vuex";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import Button_WhiteOutline from "@/ui/components/common/Button_WhiteOutline";
    import Layout_AuthSidebar from "@/ui/layouts/Layout_AuthSidebar";
    import Auth_Form_InputField from "@/ui/components/auth/Auth_Form_InputField";
    import Auth_Button_Black from "@/ui/components/auth/Auth_Button_Black";
    import Auth_Form from "@/ui/components/auth/Auth_Form";
    import Auth_PromptLabel from "@/ui/components/auth/Auth_PromptLabel";
    import Auth_ErrorMessage from "@/ui/components/auth/Auth_ErrorMessage";
    import ValidationErrorMap from "@/server/validation/ValidationErrorMap";
    import Spinner from "@/ui/components/common/Spinner";
    import Checkbox from "@/ui/components/common/Checkbox";

    export default {
        name: "Auth_Screen_Registration",
        components: {
            Checkbox,
            Spinner,
            Auth_ErrorMessage,
            Auth_PromptLabel,
            Auth_Form, Auth_Button_Black, Auth_Form_InputField, Layout_AuthSidebar, Button_WhiteOutline},
        data() {
            return {
                name: null,
                email: null,
                password: null,
                errors: ValidationErrorMap.empty(),
                loading: false,
                ageConfirmed: false
            }
        },
        computed: {
            continueEnabled() {
                return this.name && this.email && this.password;
            }
        },
        methods: {
            close() {
                this.$refs["hideButton"].click();
                this.reset();
            },
            reset() {
                this.name = this.email = this.password = null;
                this.ageConfirmed = false;
                this.errors = ValidationErrorMap.empty();
            },
            async attemptRegister() {

                if(!this.ageConfirmed) {
                    this.errors = ValidationErrorMap.empty();
                    this.errors.addError("ageVerification", this.$t("errors.age-confirmation"))
                    return;
                }

                this.loading = true;

                const result = await this._register({
                    name: this.name,
                    email: this.email,
                    password: this.password
                });

                if(result.success) {
                    this._handleSuccess();
                }
                else {
                    this.errors = result.errors;
                }

                this.loading = false;
            },
            _handleSuccess() {
                this.$refs["showSuccessButton"].click();
                this.reset();
            },
            ...mapActions(Modules.AUTH, {
                _register: Actions.Auth.REGISTER
            })
        }
    }
</script>
