<!-- Represents a list of horizontally aligned texts that can be selected. -->

<template>
    <div class="horizontal-option-selection">
        <span v-for="option in options"
              :id="option.id"
              :key="option.key"
              :hidden="option.hidden"
              @click="select(option)"
              class="horizontal-option"
              :class="{ selected : isSelected(option) }">
            {{ option.value }}
            <img v-if="option.icon" :src="option.icon" alt="option.key"/>
        </span>
    </div>
</template>

<script>
    export default {
        name: "Select_Horizontal",
        props : {
            options : Array,
            selected : null
        },
        methods : {
            isSelected(option) {
                return option.key === this.selected;
            },
            select(option) {
                this.$emit('select', option.key);
            }
        }
    }
</script>

<style scoped>

    .horizontal-option-selection {
        width: 100%;
        text-align: center;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .horizontal-option-selection.font-size-small {
        font-size: 11pt;
    }

    .horizontal-option {
        color: #5a5a58;
        margin-left: 1.2em;
        margin-right: 1.2em;
    }
    .horizontal-option.selected {
        color: #ff6154;
    }
    .horizontal-option:hover {
        cursor: pointer;
    }

    .horizontal-option-selection.bright .horizontal-option {
        color: #e9e8e8;
    }
    .horizontal-option-selection.bright .horizontal-option.selected {
        color: #ff6154;
    }

    .horizontal-option img {
        width: 1.9rem;
    }
</style>
