import ApiResponse from "@/server/responses/ApiResponse";
import Post from "@/server/structures/Post";


export default class ApiResponse_GetPosts extends ApiResponse {

    _posts;

    get posts() { return this._posts; }

    get lastPage() { return this._rawResponse.data.last_page; }

    constructor(rawResponse) {

        super(rawResponse);

        if(this.success) {
            this._posts = rawResponse.data.data.map((rawPost) => new Post(rawPost));
        }
    }
}
