<template>
    <div>
        <PostCreation_Modal_Image :mode="postModalMode" ref="modal-image"/>

        <PostCreation_Modal_Text :mode="postModalMode" ref="modal-text"/>
    </div>
</template>

<script>
import PostCreation_Modal_Image from "@/ui/components/feed/post_creation/PostCreation_Modal_Image";
import PostCreation_Modal_Text from "@/ui/components/feed/post_creation/PostCreation_Modal_Text";
import EventBus from "@/events/EventBus";
import Events from "@/events/Events";
import PostModalModes from "@/utils/PostModalModes";

export default {
    name: "Post_Modal_Edit",
    components: { PostCreation_Modal_Text, PostCreation_Modal_Image },
    mounted() {
        EventBus.$on(Events.PostEdit, this.show);
    },
    beforeDestroy() {
        EventBus.$off(Events.PostEdit, this.show)
    },
    computed: {
        postModalMode() {
            return PostModalModes.Edit;
        }
    },
    methods: {
        show(post) {
            if(post.isImage) {
                this.$refs["modal-image"].edit(post);
            }
            else {
                this.$refs["modal-text"].edit(post);
            }
        }
    }
}
</script>
