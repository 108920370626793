<template>
    <span class="duel-challenge-title" :class="$mq">
        {{
            isChallenger ?
            $t("duels.challenge-issued") :
            $t("duels.challenge")
        }}
    </span>
</template>

<script>
    export default {
        name: "DuelChallenge_Section_Title",
        props: { isChallenger: Boolean }
    }
</script>

<style>
    .duel-challenge-title {
        color: #747474;
        font-weight: 600;
    }
    .duel-challenge-title.mobile,
    .duel-challenge-title.mobile-m {
        font-size: 1.6rem;
    }
    .duel-challenge-title.mobile-s {
        font-size: 1.4rem;
    }
</style>
