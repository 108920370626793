import Actions from "@/store/Actions";
import ResourceStatus from "@/store/types/ResourceStatus";
import Mutations from "@/store/Mutations";
import Getters from "@/store/Getters";

/**
 * @param {ApiProxy_Badges} badgesApi
 */
export default function (badgesApi) {

    return {
        namespaced: true,
        state: () => ({
            categories : null,
            status: ResourceStatus.Null
        }),
        mutations : {
            [Mutations.Badges._SET_STATUS](state, status) {
                state.status = status;
            },
            [Mutations.Badges._SET_CATEGORIES](state, categories) {
                state.categories = categories;
            }
        },
        getters : {
            [Getters.Badges.GET_STATUS](state) {
                return state.status;
            },
            [Getters.Badges.GET_CATEGORIES](state) {
                return state.categories;
            }
        },
        actions : {
            async [Actions.Badges.PULL]({commit}) {

                commit(Mutations.Badges._SET_STATUS, ResourceStatus.Loading);

                const response = await badgesApi.getBadgeList();

                if(response.success) {

                    commit(Mutations.Badges._SET_CATEGORIES, response.categories);

                    commit(Mutations.Badges._SET_STATUS, ResourceStatus.Ready);
                }
                else {

                    commit(Mutations.Badges._SET_STATUS, ResourceStatus.Error)
                }
            }
        }
    };
}
