import Actions from "@/store/Actions";
import Mutations from "@/store/Mutations";
import Storage from "@/utils/local_storage/Storage";
import Getters from "@/store/Getters";
import EventBus from "@/events/EventBus";
import Events from "@/events/Events";

export default function (localizationApi, apiClient, localizationClient) {
    return {
        namespaced: true,
        state: () => ({
            language : null,
            region : null
        }),
        getters: {
            [Getters.Localization.GET_LANGUAGE](state) {
                return state.language;
            },
            [Getters.Localization.GET_REGION](state) {
                return state.region;
            }
        },
        mutations: {
            [Mutations.Localization.SET_LANGUAGE](state, language) {
                state.language = language;
                Storage.Language.value = language;
                apiClient.setLanguage(language);
                localizationClient.locale = language;
            },
            [Mutations.Localization.SET_REGION](state, region) {
                state.region = region;
                Storage.Region.value = region;
                apiClient.setRegion(region);

                EventBus.$emit(Events.RegionChanged);
            }
        },
        actions: {
            async [Actions.Localization.INITIALIZE](context) {

                if(Storage.Region.exists) {
                    setInitialLocaleData(context, Storage.Language.value, Storage.Region.value);
                }
                else {
                    await retrieveDefaultsFromServer(context);
                }
            }
        }
    };

    async function retrieveDefaultsFromServer(context) {
        const response = await localizationApi.getDefaults();

        if(response.success) {
            setInitialLocaleData(context, response.language, response.region);
        }
        else {
            console.error("Failed to retrieve localization data from server");
        }
    }

    function setInitialLocaleData({commit}, language, region) {
        commit(Mutations.Localization.SET_REGION, region);
        commit(Mutations.Localization.SET_LANGUAGE, language);
    }
}
