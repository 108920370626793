import ModuleTemplate_Collection from "@/store/templates/ModuleTemplate_Collection";
import Modules from "@/store/Modules";

/**
 * @param {ApiProxy_Duels} duelsApi
 */
export default function(duelsApi) {

    return ModuleTemplate_Collection(async ({ rootState }, page) => {

        const response = await duelsApi.getFeed(page, rootState[Modules.TAGS].selected);

        return {
            success : response.success,
            entries : response.duels,
            lastPage : response.lastPage
        }
    });
}
