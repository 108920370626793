<template>
    <div>
        <UserDetails :user="user"/>

        <UserPosts class="mt-3" :user="user"/>
    </div>
</template>

<script>

    // TODO : Retrieve what user we're displaying for from the url and show them, rather than always showing the current user.

    import UserDetails from "@/ui/components/users/details/UserDetails";
    import User from "@/server/structures/User";
    import UserPosts from "@/ui/components/users/user_posts/UserPosts";

    export default {
        name: "UserView_Details",
        components: { UserPosts, UserDetails },
        props: { user: User }
    }
</script>

<style scoped>

</style>
