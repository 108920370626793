import INotificationsManager from "@/notifications/INotificationsManager";
import Events from "@/events/Events";
import EventBus from "@/events/EventBus";



/**
 * A dummy notifications manager, to be used when notifications are not supported or fail during initialization.
 */
export default class AjaxNotificationsManager extends INotificationsManager {


    constructor() {
        super();

        let obj = this;
        setTimeout(function (){
            obj.checkForNew();
        }, 1000);
        setInterval(function () {

            obj.checkForNew();
        }, 30000);
    }

    async getToken() {

        return null;
    }

    checkForNew() {

        EventBus.$emit(Events.CheckForNewUnshownNotification);

    }

    // eslint-disable-next-line no-unused-vars
    onMessage(callback) {
        return () => {
        };
    }
}
