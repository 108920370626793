import User from "@/server/structures/User";

export default class LeaderboardEntry extends User {

    get rank() { 
        return this._data.user_rank;
    }
    
    get isUser() {
        return this._data.object_name === "User";
    }

    get isSeparator() {
        return this._data.object_name === "Separator";
    }
}
