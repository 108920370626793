import Mutations from "@/store/Mutations";
import Actions from "@/store/Actions";
import Getters from "@/store/Getters";
import Vue from 'vue'
import Storage from "@/utils/local_storage/Storage";
import User from "@/server/structures/User";

/**
 *  @param {ApiProxy_Users} userApi
 */
export default function(userApi) {
    return {
        namespaced: true,
        state: () => ({
            selfSlug: null,
            users: {}
        }),
        getters: {
            [Getters.Users.GET_ME]: (state) => {
                return state.users[state.selfSlug];
            },
            [Getters.Users.IS_ME]: (state) => (userSlug) => {
                return state.selfSlug && state.selfSlug === userSlug;
            },
            [Getters.Users.GET_BY_SLUG]: (state) => (userSlug) => {
                return state.users[userSlug];
            }
        },
        mutations: {
            [Mutations.Users._SET_USER](state, user) {
                Vue.set(state.users, user.slug, user);
            },
            [Mutations.Users.SET_SELF](state, user) {
                state.selfSlug = user.slug;
                Vue.set(state.users, user.slug, user);
                Storage.User.value = user.serialize();
            },
            [Mutations.Users.REMOVE_SELF](state) {
                state.selfSlug = null;
                Storage.User.remove();
            }
        },
        actions: {
            async [Actions.Users.INITIALIZE]({commit}) {

                if(Storage.User.exists) {
                    commit(Mutations.Users.SET_SELF, User.deserialize(Storage.User.value));
                }
            },
            async [Actions.Users.PULL_ME]({commit}) {

                const result = await userApi.getMe();

                if(result.success) { commit(Mutations.Users.SET_SELF, result.user); }

                return result;
            },
            async [Actions.Users.PULL]({commit}, userSlug) {

                const result = await userApi.getUserBySlug(userSlug);

                if(result.success) { commit(Mutations.Users._SET_USER, result.user); }

                return result;
            },
            async [Actions.Users.TOGGLE_FOLLOW](context, user) {

                await user.mutate(async (user) => {

                    user.isFollowing = !user.isFollowing;

                    return (await userApi.setFollowUser(user.id, user.isFollowing));
                });
            },
            async [Actions.Users.GET_MAP](context, userSlug) {
                const result = await userApi.getMap(userSlug);

                return result.success ? result.map : null;
            },
            async [Actions.Users.UPDATE_AVATAR]({commit}, imageFile) {
                const response = await userApi.setProfileAvatar(imageFile);

                handleSelfUpdate(commit, response);
            },
            async [Actions.Users.UPDATE_PROFILE_TEXTS]({commit}, { name, description }) {
                const response = await userApi.updateProfileTexts(name, description);

                handleSelfUpdate(commit, response);
            },
            async [Actions.Users.GET_BADGES](context, userSlug) {

                return await userApi.getBadges(userSlug);
            }
        }
    }

    function handleSelfUpdate(commit, response) {
        if(response.success) {
            commit(Mutations.Users.SET_SELF, response.user);
        }
    }
}
