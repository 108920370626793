<template>
    <ol class="list-ordered-alpha">
        <slot></slot>
    </ol>
</template>

<script>
    export default {
        name: "ListOrderedAlpha"
    }
</script>

<style>
    .list-ordered-alpha { 
        list-style-type: lower-alpha;
        padding-inline-start: 20px;
    }
</style>
