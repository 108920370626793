import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_GetBadges from "@/server/responses/ApiResponse_GetBadges";

export default class ApiProxy_Badges extends ApiProxy {

    async getBadgeList() {

        return new ApiResponse_GetBadges(await this.get('/'))
    }
}
