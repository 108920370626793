export default class ValidationErrorMap {

    _errorSubjectMap;
    _errorList;

    get empty() {
        return this._errorList.length === 0;
    }

    static general(error) {

        const result = new ValidationErrorMap();

        result.addError("general", error);

        return result;
    }

    static empty() {
        return new ValidationErrorMap();
    }

    static fromServerResponse(responseData) {
        const result = new ValidationErrorMap;

        if(!responseData) { return result; }

        for (const [subject, errorMessages] of Object.entries(responseData.errors)) {
            errorMessages.forEach((errorMsg) => {
                result.addError(subject, errorMsg)
            });
        }

        return result;
    }

    constructor() {
        this._errorSubjectMap = {};
        this._errorList = [];
    }

    hasErrors() {
        return Object.keys(this._errorSubjectMap).length !== 0;
    }

    addError(subject, text) {

        if(!this._errorSubjectMap[subject]) {
            this._errorSubjectMap[subject] = [];
        }

        this._errorSubjectMap[subject].push(text);
        this._errorList.push(text);
    }

    hasErrorFor(subject) {
        return !!this._errorSubjectMap[subject];
    }

    getFirstError() {
        return this._errorList[0];
    }

    getFirstErrorFor(subject) {
        if(!this.hasErrorFor(subject)) return null;

        return this._errorSubjectMap[subject][0];
    }

    getAllErrorsFor(subject) {
        return this._errorSubjectMap[subject];
    }
}
