
const DefaultDeviceName = "web";

export default {

    getName() {

        try {
            return extractDeviceName();
        }
        catch {
            return DefaultDeviceName;
        }
    }
}

function extractDeviceName() {

    let result = navigator.userAgent.toLowerCase();

    const openingBracketIndex = result.indexOf('(');
    const closingBracketIndex = result.indexOf(')');

    result = result.substr(openingBracketIndex + 1, closingBracketIndex - openingBracketIndex - 1);

    return result;
}
