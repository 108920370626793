<!-- Component for letting the users submit his own comment. -->

<template>
    <div class="comment-field">

        <Avatar_Small class="avatar" :user="user"/>

        <Form_TextArea
                v-model="comment"
                :rows="1"
                :max-rows="3"
                class="gray"
                :placeholder="$t('posts.comment-placeholder')"
                :click-only="!isAuthenticated"
                v-popover="{ name : (isAuthenticated ? '' : 'popover-login-comment')}"
        />

        <Icon_Circle
                @click.native="submit"
                class="submit-button"
                :src="$icons.Common.Post"
                alt="Post Comment"
                v-popover.left="{ name : (isAuthenticated ? '' : 'popover-login-comment')}"
        />
    </div>
</template>

<script>
    import Modules from "@/store/Modules";
    import {mapActions, mapGetters} from "vuex";
    import Avatar_Small from "@/ui/components/common/Avatar_Small";
    import Icon_Circle from "@/ui/components/common/Icon_Circle";
    import Actions from "@/store/Actions";
    import Form_TextArea from "@/ui/components/common/Form_TextArea";
    import Getters from "@/store/Getters";

    export default {
        name: "Comment_Field",
        components: { Form_TextArea, Icon_Circle, Avatar_Small },
        props: {
            postId: Number
        },
        data() {
            return {
                comment: null
            }
        },
        computed: {
            ...mapGetters(Modules.AUTH, { isAuthenticated : Getters.Auth.IS_AUTHENTICATED }),
            ...mapGetters(Modules.USERS, { user: Getters.Users.GET_ME }),
        },
        methods: {
            submit() {
                if(!this.isAuthenticated) return;

                this.postComment({
                    postId: this.postId,
                    comment: this.comment
                });

                this.comment = null;
            },
            ...mapActions(Modules.COMMENTS, {
                postComment: Actions.Comments.POST
            })
        }
    }
</script>

<style scoped>

    .comment-field {
        display: flex;
        flex-direction: row;
        margin-top: 0.6em;
    }

    .comment-field .avatar {
        flex-shrink: 0;
    }

    .comment-field .submit-button {
        margin-left: 4px;
        flex-grow: 2;
        flex-shrink: 0;
    }
</style>
