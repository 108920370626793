<template>
    <h6 class="user-details-header-small my-1">
        <slot></slot>
    </h6>
</template>

<script>
    export default {
        name: "UserDetails_Header_Small"
    }
</script>

<style scoped>

    .user-details-header-small {
        font-size: 10pt;
    }
</style>
