<template>
    <div>
        <template v-if="isAuthenticated">
            <PostCreation_ImageUpload v-on:file-selected="openImageForm"/>
        </template>
        <template v-else>
            <PostCreation_ImageUpload disabled v-popover:popover-login-post.left/>
        </template>

        <PostCreation_Modal_Image :mode="mode" :duel-response-id="duelResponseId" ref="modal-create-image"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import PostCreation_Modal_Image from "@/ui/components/feed/post_creation/PostCreation_Modal_Image";
import PostCreation_ImageUpload from "@/ui/components/feed/post_creation/PostCreation_ImageUpload";
import Modules from "@/store/Modules";
import Getters from "@/store/Getters";

export default {
    name: "PostCreation_Image",
    components: { PostCreation_ImageUpload, PostCreation_Modal_Image },
    props: {
        mode: Number,
        duelResponseId: [Number, null]
    },
    computed: {
        ...mapGetters(Modules.AUTH, { isAuthenticated : Getters.Auth.IS_AUTHENTICATED })
    },
    methods: {
        openImageForm(file) {
            this.$refs['modal-create-image'].show(file);
        }
    }
}
</script>
