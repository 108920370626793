<!-- Base component for visualizing a single notification. -->
<template>
    <Panel_White class="notification-component" :class="{isRead : notification.isRead}" @click.native="navigate">
        <img class="notification-image" :src="notification.imageUrl"/>
      <div class="notification-text">
        <b class="notification-title">{{ notification.title }}</b>
        <p class="notification-body">{{ notification.body }}</p>
      </div>
    </Panel_White>
</template>

<script>

import Panel_White from "@/ui/components/common/Panel_White";
import Notification from "@/server/structures/Notification";

export default {
    name: "Notifications_Component",
    components: { Panel_White },
    props: {
        notification : Notification
    },
    methods: {
        navigate() {
            this.$router.push(this.notification.targetUrl);
        }
    }
}
</script>

<style>

    .notification-component {
        min-height: 4.2em;
        margin-top: 0.25em;
        margin-bottom: 0.25em;
        padding: 0.6em;
        display: flex;
        max-width: 100em;
        text-align: left;
    }

    .notification-component.isRead {
        filter: brightness(96%);
    }

    .notification-component .notification-image {
      width: 3em;
      height: 3em;
      margin-right: 0.5em;
    }

    .notification-component .notification-text {
      flex-grow: 2;
      margin: 0;
      text-align: left;
    }
    .notification-component .notification-title {
        /*flex-grow: 2;*/
        margin: 0;
      font-weight: bold;
    }
    .notification-component .notification-body {
        /*flex-grow: 2;*/
        margin: 0;
    }

</style>
