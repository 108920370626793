<template>
    <div>
        <Sidebar_Button :small="true" class="sidebar-collapse-button" :icon="$icons.Language" @click.native="toggleCollapse">
            {{ $t("general.language") }}
        </Sidebar_Button>

        <Sidebar_Collapse
                :visible="collapseVisible"
                :items="languages"
                v-on:select="selectLanguage"
        />
    </div>
</template>

<script>
    import Sidebar_Button from "@/ui/components/sidebar/Sidebar_Button";
    import Sidebar_Collapse from "@/ui/components/sidebar/Sidebar_Collapse";
    import LanguageValues from "@/store/values/LanguageValues";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import {mapGetters, mapMutations} from "vuex";
    import Mutations from "@/store/Mutations";
    export default {
        name: "Sidebar_Button_Language",
        components: { Sidebar_Collapse, Sidebar_Button },
        data() {
            return {
                collapseVisible : false
            }
        },
        computed: {
            languages() {
                return LanguageValues.map((languageKey) => {
                    return {
                        key: languageKey,
                        value: this.$t(`general.language-${languageKey}`),
                        selected: languageKey === this.currentLanguage
                    }
                });
            },
            ...mapGetters(Modules.LOCALIZATION, {
                currentLanguage: Getters.Localization.GET_LANGUAGE
            })
        },
        methods: {
            selectLanguage(language) {
                this.setLanguage(language.key);
                this.collapseVisible = false;
                this.$emit("selected");
            },
            toggleCollapse() {
                this.collapseVisible = !this.collapseVisible;
            },
            ...mapMutations(Modules.LOCALIZATION, {
                setLanguage : Mutations.Localization.SET_LANGUAGE
            }),
            hide() {
                this.collapseVisible = false;
            }
        }
    }
</script>

<style scoped>
    .sidebar-button.small /deep/ .icon-holder {
        margin-right: 1.2em;
    }
</style>
