<template>
    <div class="challenge-post" v-bind:class="{ 'post-image': !post.isText}">
        <Post_Section_Content :post="post"/>
    </div>
</template>

<script>

    import Post_Section_Content from "@/ui/components/feed/post/Post_Section_Content";

    export default {
        name: "Duels_Challenge_Post",
        components: { Post_Section_Content },
        props: {
            post: Object
        }
    }
</script>

<style scoped>
    .post-image {
        margin-bottom: 0.5em;
    }
</style>
