<template>
    <ShareNetwork
            :network="network.network"
            :title="title"
            :url="url"
            :hashtags="tags"
            v-on:click.native="networkSelected"
    >
        <Share_Option
                :icon="network.icon"
                :alt="network.alt"
        >
            {{ network.text }}
        </Share_Option>
    </ShareNetwork>
</template>

<script>
    import Post from "@/server/structures/Post";
    import Share_Option from "@/ui/components/feed/sharing/Share_Option";

    export default {
        name: "Share_Option_Network",
        components: { Share_Option },
        props : {
            network: Object,
            post: Post,
            title: String,
            url: String,
            tags: String
        },
        methods: {
            networkSelected() {
                this.$emit('networkSelected')
            }
        }
    }
</script>

<style scoped>

</style>
