<template>
    <Layout_PostCreation_Modal
            ref="post-modal"
            :mode="mode"
            :duel-response-id="duelResponseId"
            :content-ready="readyForSubmission"
            :post-creation-data="postCreationData"
            v-on:hide="reset">

        <Input_ImageUpload v-if="isInEditMode" v-on:file-selected="onNewImageSelected" ref="file-upload"/>

        <div class="post-modal-image-wrapper" :class="{'edit-mode' : isInEditMode}">
            <img
                :src="imageSource"
                class="post-modal-preview-image"
                alt="Image preview"
                @click="openFileUpload"
            />

            <img v-if="isInEditMode"
                 :src="$icons.PostCreation.UploadImage"
                 class="post-modal-image-overlay"
                 alt="Image overlay"
                 @click="openFileUpload"
            />
        </div>

    </Layout_PostCreation_Modal>
</template>

-<script>

    import PostCreationData_Image from "@/server/post_creation/PostCreationData_Image";
    import Layout_PostCreation_Modal from "@/ui/layouts/Layout_PostCreation_Modal";
    import Input_ImageUpload from "@/ui/components/common/Input_ImageUpload";
    import PostModalModes from "@/utils/PostModalModes";

    export default {
        name: "PostCreation_Modal_Image",
        components: { Input_ImageUpload, Layout_PostCreation_Modal },
        props: {
            mode: Number,
            duelResponseId : [Number, null]
        },
        data() {
            return {
                editTargetPost: null,
                imageFile: null
            }
        },
        computed: {
            isInEditMode() {
                return this.mode === PostModalModes.Edit;
            },
            imageSource() {
                if(this.imageFile) { return this.imageFile.base64; }

                if(this.editTargetPost) { return this.editTargetPost.imageUrl; }

                return null;
            },
            readyForSubmission() {
                return this.imageFile != null || this.editTargetPost != null;
            },
            postCreationData() {
                return new PostCreationData_Image(this.imageFile);
            },
            modal() {
                return this.$refs['post-modal'];
            }
        },
        methods: {
            show(imageFile) {
                this.imageFile = imageFile;
                this.modal.show();
            },
            edit(post) {
                this.editTargetPost = post;
                this.imageFile = null;
                this.modal.edit(post);
            },
            hide() {
                this.modal.hide();
            },
            reset() {
                this.editTargetPost = null;
                this.imageFile = null;
            },
            openFileUpload() {
                if(this.isInEditMode) {
                    this.$refs["file-upload"].open();
                }
            },
            onNewImageSelected(newFile) {
                this.imageFile = newFile;
            },
        }
    }
</script>

<style scoped>

    .post-modal-preview-image {
        width: 100%;
    }

    .post-modal-image-wrapper {
    }

    .post-modal-image-wrapper.edit-mode .post-modal-preview-image {
        filter: brightness(0.5);
    }

    .post-modal-image-overlay {
        filter: brightness(0) invert(1) opacity(0.55);
        width: 8em;
        height: 8em;
        position: absolute;
        left: 50%;
        transform: translate(-50%,50%);
    }

</style>
