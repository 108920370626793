<template>
    <Layout_Info>
         <template v-slot:title>
            {{ $t("terms.title") }}
        </template>

        <template v-slot:content>  
            <Info_SubHeader> {{ $t("terms.last-edit") }} </Info_SubHeader>

            <Info_ImportantSection></Info_ImportantSection>

            <Info_Terms_Content></Info_Terms_Content>
        </template>
    </Layout_Info>
</template>

<script>
    import Layout_Info from "@/ui/layouts/Layout_Info";
    import Info_SubHeader from "@/ui/components/info/Info_SubHeader";
    import Info_ImportantSection from "@/ui/components/info/Info_ImportantSection";
    import Info_Terms_Content from "@/ui/components/info/Info_Terms_Content";
    
    export default {
        name: "InfoView_TermsAndConditions",
        components: { Layout_Info, Info_SubHeader, Info_ImportantSection, Info_Terms_Content },
    }
</script>
