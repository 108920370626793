import Vue from "vue";
import VueGtm from "vue-gtm";

export default { init }

function init(router) {

    try {
        Vue.use(VueGtm, {
            id: process.env.VUE_APP_GTM_ID,
            enabled: true,
            vueRouter: router
        });
    }
    catch(exc) {
        console.warn("Failed to initialize GTM :", exc)
    }
}
