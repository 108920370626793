

export default class FileReaderAsync {

    static async imageFileToBase64(file) {

        return new Promise(resolve => {

            const reader = new FileReader();

            reader.onload = function (e) { resolve(e.target.result); };

            reader.readAsDataURL(file);
        });
    }
}
