import {registerAndGetStore} from "vuex-stores";
import Module_User_Duels from "@/store/dynamic_modules/Module_User_Duels";

/**
 * Creates dynamic stores for managing retrieving the duels of a single user.
 */
export default class StoreFactory_UserDuels {

    static _store;
    static _duelsApi;

    static init(store, duelsApi) {
        this._store = store;
        this._duelsApi = duelsApi;
    }

    static createForUser(userId) {

        return registerAndGetStore(this._store, { namespace: `userDuels_${userId}`, ...Module_User_Duels(userId, this._duelsApi)} );
    }
}
