import ApiResponse from "@/server/responses/ApiResponse";
import Post from "@/server/structures/Post";

export default class ApiResponse_GetRankings extends ApiResponse {

    _posts;

    /** @return {Post[]} */
    get posts() {
        return this._posts;
    }

    constructor(rawResponse) {
        super(rawResponse);

        if(this.success) {
            this._posts = this._rawResponse.data.map((rawPost) => {
                return new Post(rawPost);
            })
        }
    }

}
