import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_GetUser from "@/server/responses/ApiResponse_GetUser";
import ApiResponse from "@/server/responses/ApiResponse";
import ApiResponse_GetUsers from "@/server/responses/ApiResponse_GetUsers";
import ApiResponse_GetMap from "@/server/responses/ApiResponse_GetMap";
import ApiResponse_GetUserBadges from "@/server/responses/ApiResponse_GetUserBadges";

export default class ApiProxy_Users extends ApiProxy {

    /**
     * Retrieves the user data for the currently logged in user.
     * @returns {Promise<ApiResponse_GetUser>}
     */
    async getMe() {
        return new ApiResponse_GetUser(await this.get(`/me`));
    }

    /**
     * Retrieves a user by their user slug.
     * @param {string} slug
     * @returns {Promise<ApiResponse_GetUser>}
     */
    async getUserBySlug(slug) {
        return new ApiResponse_GetUser(await this.get(`/${slug}`));
    }

    /**
     * Sets whether the currently logged in user follows the specified user..
     * @param {string} userId
     * @param {boolean} value
     * @returns {Promise<ApiResponse>}
     */
    async setFollowUser(userId, value) {
        return new ApiResponse(await this.put(`/${userId}/${value ? 'follow' : 'unfollow'}`));
    }

    /**
     * Returns a list of a followers for a given user.
     * @param {String} slug
     * @param {Number} page
     * @returns {Promise<ApiResponse_GetUsers>}
     */
    async getFollowers(slug, page) {
        return new ApiResponse_GetUsers(await this.get(`/${slug}/followers`, {
            params : {
                page : page
            }
        }));
    }

    /**
     * Returns a list of poeple that a given user follows.
     * @param {String} slug
     * @param {Number} page
     * @returns {Promise<ApiResponse_GetUsers>}
     */
    async getFollowings(slug, page) {
        return new ApiResponse_GetUsers(await this.get(`/${slug}/followings`, {
            params : {
                page : page
            }
        }));
    }

    /**
     * Retrieves the map data for the specified user.
     * @param {string} slug
     * @returns {Promise<ApiResponse_GetMap>}
     */
    async getMap(slug) {
        return new ApiResponse_GetMap(await this.get(`/${slug}/map`));
    }

    /**
     * Retrieves the badges earned by the specified user.
     * @param {string} slug
     * @return {Promise<ApiResponse_GetUserBadges>}
     */
    async getBadges(slug) {
        return new ApiResponse_GetUserBadges(await this.get(`/${slug}/badges`));
    }

    /**
     * Updates the current user's avatar picture.
     * @param {ImageFile} imageFile
     * @returns {Promise<ApiResponse_GetUser>}
     */
    async setProfileAvatar(imageFile) {

        const formData = new FormData();
        formData.append('image', imageFile.rawFile);

        const rawResponse = await this.post('/image', formData, {
            headers: { 'Content-Type' : 'multipart/form-data' }
        });

        return new ApiResponse_GetUser(rawResponse);
    }

    /**
     * Updates the current user's name and description.
     * @param {string} name
     * @param {string} description
     * @returns {Promise<ApiResponse_GetUser>}
     */
    async updateProfileTexts(name, description) {

        const rawResponse = await this.put('/update', {
            name,
            description
        });

        return new ApiResponse_GetUser(rawResponse);
    }

    /**
     * Sets a new password for the current user.
     * @param {string} newPassword
     * @returns {Promise<ApiResponse_GetUser>}
     */
    async updateUserPassword(newPassword) {

        const rawResponse = await this.put('/password', {
            password : newPassword
        })

        return new ApiResponse_GetUser(rawResponse);
    }
}
