<!-- A utility component for handling image file upload. -->
<!-- Call the .open() method to  -->
<!-- Emits the '@file-selected'. -->

<template>
    <input ref="fileUploadInput" type="file" alt="File Upload Input" accept="image/*" hidden/>
</template>

<script>
    import ImageFile from "@/utils/ImageFile";

    export default {
        name: "Input_ImageUpload",
        computed : {
            fileUploadInput() {
                return this.$refs['fileUploadInput'];
            }
        },
        mounted() {
            this.fileUploadInput.addEventListener('change', this._onDialogClosed);
        },
        beforeDestroy() {
            this.fileUploadInput.removeEventListener('change', this._onDialogClosed);
        },
        methods : {
            open() {

                this.fileUploadInput.click();
            },
            async _onDialogClosed() {

                const selectedFile = this.fileUploadInput.files.item(0);

                if(selectedFile) {

                    const wrappedFile = await ImageFile.wrap(selectedFile);

                    this.fileUploadInput.value = '';

                    this.$emit('file-selected', wrappedFile);
                }
            }
        }
    }
</script>
