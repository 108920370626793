<template>
    <Notifications_Component v-if="isActive"
                 :notification="notification"
                 class="fade-in-fast realtime-notifications-popup"
                 :class="{hidden: fadingOut, visible: !fadingOut}"
                 @click.native="onClick"
    />
</template>

<script>
import Events from "@/events/Events";
import EventBus from "@/events/EventBus";
import Notifications_Component from "@/ui/components/notifications/Notifications_Component";

const MessageDisplayDuration = 6000;
const FadeOutDuration = 900;

export default {
    name: "Notifications_Realtime_Popup",
    components: { Notifications_Component },
    data() {
        return {
            notification: null,
            hideTimeoutHandle: null,
            fadingOut: false
        }
    },
    mounted() {

        EventBus.$on(Events.ReceivedNotification, this.onRealtimeNotification);
    },
    beforeDestroy() {

        EventBus.$off(Events.ReceivedNotification, this.onRealtimeNotification);
    },
    computed: {
        isActive() {
            return !!this.notification;
        }
    },
    methods: {
        onRealtimeNotification(notification) {

            clearTimeout(this.hideTimeoutHandle);

            this.notification = notification;

            this.hideTimeoutHandle = setTimeout(this.hide, MessageDisplayDuration)
        },
        hide() {

            this.fadingOut = true;

            setTimeout(() => {
                this.fadingOut = false;
                this.notification = null;
            }, FadeOutDuration)
        },
        onClick() {

            clearTimeout(this.hideTimeoutHandle);

            this.hide();
        }
    }
}
</script>

<style scoped>

    .realtime-notifications-popup {
        width: 94%;
        left : 3%;
        text-align: center;
        z-index: 5000;
        position: fixed;
        top: 12px;
        box-shadow: 0 2px 23px 2px grey;
        transition: all 0.9s;
        max-width: 25em;
    }

    .realtime-notifications-popup.visible {
        opacity: 1.0;
    }

    .realtime-notifications-popup.hidden {
        transform: translateX(20px);
        opacity: 0.0;
    }
</style>
