<template>

    <div class="profile-details-section">

        <Help_Section class="w-100" v-if="infoText" :info-text="infoText"/>

        <slot></slot>

    </div>

</template>

<script>

    import Help_Section from "@/ui/components/common/help/Help_Section";

    export default {
        name: "Layout_UserDetailsSection",
        components: { Help_Section },
        props: {
            name: String,
            infoText: String
        }
    }
</script>

<style scoped>

    .profile-details-section {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: 1px solid rgba(198, 198, 198, 0.8);
        font-size: 11pt;
        position: relative;
    }

</style>
