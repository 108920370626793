<!-- Appears bellow the search bar, displaying responses from the search. -->

<template>
    <div class="tag-search-results">

        <Spinner v-if="loading || searchResult == null" class="spinner orange"/>

        <Text_NoResults v-else-if="noMatchesFound"> {{ $t("errors.matching-tags") }}</Text_NoResults>

        <template v-else>

            <FeedTags_Search_ResultsSection :tags="searchResult.matches"/>

            <FeedTags_Search_ResultsSection :tags="searchResult.related" :name="$t('posts.related-tags')" />

            <FeedTags_Search_ResultsSection :tags="searchResult.latestSearches" :name="$t('posts.latest-searches')" />
        </template>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import Actions from "@/store/Actions";
    import Spinner from "@/ui/components/common/Spinner";
    import Modules from "@/store/Modules";
    import Text_NoResults from "@/ui/components/common/Text_NoResults";
    import FeedTags_Search_ResultsSection from "@/ui/components/feed/tags/FeedTags_SearchResultsSection";

    export default {
        name: "FeedTags_SearchResults",
        components: { FeedTags_Search_ResultsSection, Text_NoResults, Spinner },
        props: {
            searchText: String
        },
        data() {
            return {
                loading: false,
                searchResult: null
            }
        },
        mounted() {
            this.search();
        },
        watch: {
            searchText() {
                this.search();
            }
        },
        computed: {
            noMatchesFound() {
                return this.searchResult && this.searchResult.noMatchesFound;
            }
        },
        methods: {
            async search() {
                this.loading = true;
                this.searchResult = await this.searchTags({
                    searchText: this.searchText,
                    resultLimit: 3
                });
                this.loading = false;
            },
            ...mapActions(Modules.TAGS, { searchTags: Actions.Tags.SEARCH })
        }
    }
</script>

<style scoped>

    .tag-search-results {
        border-top: 1px solid #c9c9c9;
        min-height: 100vh;
    }

    .tag-search-results .spinner {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -40%);
    }
</style>
