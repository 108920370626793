<template>
    <small class="post-text-views"><slot/></small>
</template>

<script>
    export default {
        name: "Post_Text_Views"
    }
</script>

<style>
    .post-text-views {
        font-size: 10.5pt;
        margin-left: 0.4em;
        margin-top: 2px;
        margin-bottom: 2px;
        color: #5d5d5d;
    }
</style>
