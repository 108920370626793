<template>
    <div class="button-post-image-container">
        <img class="button-post-image" :src="$icons.PostCreation.UploadImage" @click="openFileSelect" alt="image" />

        <Input_ImageUpload ref="fileUploadInput" v-on:file-selected="onFileSelected"/>
    </div>
</template>

<script>
    import Input_ImageUpload from "@/ui/components/common/Input_ImageUpload";

    export default {
        name: "PostCreation_ImageUpload",
        components: { Input_ImageUpload },
        props: { disabled: Boolean },
        computed : {
            fileUploadInput() {
                return this.$refs['fileUploadInput'];
            }
        },
        methods : {
            openFileSelect() {
                if(this.disabled) { return; }

                this.fileUploadInput.open();
            },
            onFileSelected(file) {
                this.$emit('file-selected', file);
            }
        }

    }
</script>

<style scoped>

    .button-post-image-container {
        background-color: #ff6154;
        border-radius: 100%;
        padding: 3px;
        cursor: pointer;
    }

    .button-post-image {
        filter: brightness(1000%);
        width: 1.5rem;
    }

</style>
