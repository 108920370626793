<template>
    <div class="duel-user-icons" :class="{ challenged: !isChallenger }">
        <Bar_Experience_Small :status="user.activityStatus" />
        <Avatar_Small :user="user" />
        <div class="duel-user-icon">
            <slot></slot>
        </div>
    </div>
</template>

<script>

    import Avatar_Small from "@/ui/components/common/Avatar_Small";
    import Bar_Experience_Small from "@/ui/components/common/Bar_Experience_Small";
    import User from "@/server/structures/User";

    export default {
        name: "Duel_Header_User",
        components: { Avatar_Small, Bar_Experience_Small },
        props: {
            user: User,
            isChallenger: Boolean
        }
    }
</script>

<style>
    .duel-user-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    .duel-user-icons.challenged {
        flex-direction: row-reverse;
    }
    .duel-user-icon img {
        width: 2.2em;
        height: 2.2em;
    }
</style>