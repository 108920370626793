<template>
    <div class="stats-list">
        <span class="stats-list-header">
            <slot name="listHeader"> </slot>
        </span>
        <div class="stats-list-row"> 
            <slot name="listRows"> </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Duel_Statistics_List",
    }
</script>

<style scoped>
    .stats-list {
        border-bottom: 1px solid rgba(198, 198, 198, 0.8);
    }
    .stats-list-header .stats-row {
        border: none;
    }
    .stats-list-row .stats-row {
        font-weight: 400;
        border: none;
    }
</style>