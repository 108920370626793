import Post from "@/server/structures/Post";
import ApiResponse from "@/server/responses/ApiResponse";

export default class ApiResponse_CreatePost extends ApiResponse {

    get post() {
        return new Post(this._rawResponse.data);
    }

    // HACK : The type here is because the backend doesn't seem to return it as part of the data when a post is created.
    constructor(rawResponse, type) {

        rawResponse.data.type = type;

        super(rawResponse);
    }
}
