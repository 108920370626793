<!-- A button used for selecting popular tags when a search is started. -->

<template>
    <div class="tag-preview-toggle d-flex pb-2 mb-1" :class="isSelected(tag) ? 'positive-row' : ''" @click="toggleTag(tag)">
        <span>{{ isSelected(tag) ? '+' : '-' }}</span>
        <span class="text-capitalize">{{ tag.name }}</span>
        <small class="ml-auto">{{ tag.count }}</small>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    import Tag from "@/server/structures/Tag";
    import Getters from "@/store/Getters";
    import Mutations from "@/store/Mutations";
    import Modules from "@/store/Modules";

    export default {
        name: "FeedTags_Button_Popular",
        props : {
            tag: Tag
        },
        computed: {
            ...mapGetters(Modules.TAGS, { isSelected: Getters.Tags.IS_SELECTED })
        },
        methods: {
            ...mapMutations(Modules.TAGS, { toggleTag: Mutations.Tags.TOGGLE_SELECT }),
        }
    }
</script>

<style scoped>

    .tag-preview-toggle {
        font-weight: bold;
        color: #5c5c5c;
    }

    .tag-preview-toggle:hover {
        cursor: pointer;
    }

    .tag-preview-toggle span:first-child {
        width: 2em;
        display: inline-block;
    }
    .positive-row span {
        color: #FD7B4D;
    }
</style>
