<template>
    <Layout_UserFollowerView :header-text="$t('users.title-followers')">
        <Users_Feed v-if="module" :module="module" :no-results-message="$t('users.no-followers')"/>
    </Layout_UserFollowerView>
</template>

<script>
import StoreFactory_UserFollowers from "@/store/factories/StoreFactory_UserFollowers";
import Users_Feed from "@/ui/components/users/Users_Feed";
import Layout_UserFollowerView from "@/ui/layouts/Layout_UserFollowerView";

export default {
    name: "UserView_Followers",
    components: { Layout_UserFollowerView, Users_Feed },
    props: {
        userSlug: String,
    },
    data() {
        return { module: null }
    },
    mounted() {
        this.module = StoreFactory_UserFollowers.createForUserFollowers(this.userSlug);
    },
    beforeDestroy() {
        this.module.unregisterModule();
        this.module = null;
    }
}
</script>
