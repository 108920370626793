<template>
    <div>

        <UserDetails_Section_Duels class="separator-top-light" :user="user"/>

        <Select_Horizontal class="duel-filter" :options="filterOptionList" :selected="activeOption" v-on:select="setSelectedDuelsStatus"/>

        <Duel_Feed v-if="userDuelStore" :isWaitingSelected="activeOption === `waiting`" :duel-collection="userDuelStore"/>
    </div>
</template>

<script>
    import User from "@/server/structures/User";
    import StoreFactory_UserDuels from "@/store/factories/StoreFactory_UserDuels";
    import Duel_Feed from "@/ui/components/duels/Duel_Feed";
    import Select_Horizontal from "@/ui/components/common/Select_Horizontal";
    import UserDetails_Section_Duels from "@/ui/components/users/details/UserDetails_Section_Duels";
    import { mapGetters } from "vuex";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";

    export default {
        name: "UserView_Duels",
        components: { UserDetails_Section_Duels, Select_Horizontal, Duel_Feed },
        props: { user: User },
        data() {
            return {
                userDuelStore: null,
                activeOption: "active"
            }
        },
        watch: {
            activeOption(value) {
                this.userDuelStore.setRequestedStatus(value);
            }
        },
        mounted() {
            this.userDuelStore = StoreFactory_UserDuels.createForUser(this.user.id)
        },
        beforeDestroy() {
            this.userDuelStore.unregisterModule();
            this.userDuelStore = null;
        },
        computed: {
            filterOptionList() {
                let filterOptions = [
                    {
                        key: "active",
                        value: this.$t("users.duels-ongoing"),
                    },
                    {
                        key: "finished",
                        value: this.$t("users.duels-finished"),
                    },
                ];
                if(this.isMe(this.user.slug)) {
                    filterOptions.push({
                        key: "waiting",
                        value: this.$t("users.duels-waiting"),
                    });
                }
                return filterOptions;
            },
            ...mapGetters(Modules.USERS, {
                isMe : Getters.Users.IS_ME,
            })
        },
        methods: {
            setSelectedDuelsStatus(value) {
                this.activeOption = value;
            }
        }
    }
</script>

<style scoped>

    .duel-filter {
        height: 2.9rem;
    }
</style>
