<template>
    <b-modal ref="modal" class="create-joke-modal" modal-class="create-joke-modal">

        <template v-slot:modal-header>
            <PostCreation_ModalSection_Header :current-region="currentRegion" v-on:close-click="hide"/>
        </template>

        <Text_Error v-if="errorMessage">{{ errorMessage }}</Text_Error>

        <template v-if="!loading">
            <slot></slot>

            <PostCreation_TagSelection :tags="tags"/>

        </template>
        <template v-else>
            <Spinner class="center-absolute orange"/>
        </template>

        <template v-slot:modal-footer>
            <template v-if="modeIsCreation">
                <Button_Gradient class="float-left" :disabled="!readyForSubmission" @click.native="createDuel" :right-icon="$icons.Duels">
                    {{ $t("general.post-duel") }}
                </Button_Gradient>
                <Button_Gradient v-if="notInDuelFeed" class="float-right" :disabled="!readyForSubmission" @click.native="createPost">
                    {{ $t("general.post") }}
                </Button_Gradient>
            </template>
            <template v-else-if="modeIsResponse">
                <Button_Gradient class="float-right" :disabled="!readyForSubmission" @click.native="respondToDuel" :right-icon="$icons.Duels">
                    {{ $t("general.post-response") }}
                </Button_Gradient>
            </template>
            <template v-else-if="modeIsEdit">
                <Button_Gradient class="float-right" :disabled="!readyForSubmission" @click.native="editPost">
                    {{ $t("general.post-edit") }}
                </Button_Gradient>
            </template>
        </template>
    </b-modal>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex';
import PostCreation_TagSelection from "@/ui/components/feed/post_creation/PostCreation_TagSelection";
import Button_Gradient from "@/ui/components/common/Button_Gradient";
import Text_Error from "@/ui/components/common/Text_Error";
import Modules from "@/store/Modules";
import Getters from "@/store/Getters";
import Spinner from "@/ui/components/common/Spinner";
import RouterUtils from "@/routes/RouterUtils";
import ContentTypes from "@/store/types/ContentTypes";
import FeedTypes from "@/store/types/FeedTypes";
import APostCreationData from "@/server/post_creation/APostCreationData";
import Actions from "@/store/Actions";
import Mutations from "@/store/Mutations";
import PostCreation_ModalSection_Header from "@/ui/components/feed/post_creation/PostCreation_ModalSection_Header";
import PostModalModes from "@/utils/PostModalModes";

export default {
    name: "Layout_PostCreation_Modal",
    components: { PostCreation_ModalSection_Header, Spinner, Text_Error, Button_Gradient, PostCreation_TagSelection },
    props: {
        mode: Number,
        contentReady: Boolean,
        duelResponseId: [Number, null],
        postCreationData: APostCreationData,
    },
    data() {
        return {
            editTargetPost: null,
            tags: [],
            loading: false,
            errorMessage: null
        }
    },
    computed: {
        modeIsCreation() {
            return this.mode === PostModalModes.Create;
        },
        modeIsEdit() {
            return this.mode === PostModalModes.Edit;
        },
        modeIsResponse() {
            return this.mode === PostModalModes.DuelResponse;
        },
        notInDuelFeed() {
            return this.$route.params.filter !== undefined;
        },
        readyForSubmission() {
            return !this.loading && this.contentReady && this.tags && this.tags.length !== 0;
        },
        ...mapGetters(Modules.LOCALIZATION, {
            currentRegion: Getters.Localization.GET_REGION
        })
    },
    methods: {
        async createPost() {

            this.clearSelectedTags();
            RouterUtils.goToFeed(ContentTypes.ALL, FeedTypes.NEW);

            this.beginLoad();

            this.postCreationData.setTags(this.tags);
            const result = await this.postJoke(this.postCreationData);

            this.handleResult(result);
        },
        async createDuel() {

            this.beginLoad();

            this.postCreationData.setTags(this.tags);
            const result = await this.postDuel(this.postCreationData);

            if (result.success) {
                RouterUtils.goToDuelChallenge(result.duel.code);
            }
        },
        async respondToDuel() {

            this.beginLoad();

            this.postCreationData.setTags(this.tags);
            const result = await this.postDuelResponse({
                duelId: this.duelResponseId,
                postCreationData: this.postCreationData
            });

            if (result.success) {
                RouterUtils.goToDuelFeed(result.duel.code);
            }
        },
        async editPost() {

            this.beginLoad();

            this.postCreationData.setTags(this.tags);

            const result = await this.editJoke({
                post: this.editTargetPost,
                data: this.postCreationData
            });

            this.handleResult(result);
        },
        beginLoad() {
            this.loading = true;
        },
        handleResult(result) {
            this.loading = false;
            if (!result.success) {
                this.errorMessage = result.errors.getFirstError();
            }
            else {
                this.hide();
            }
        },
        show() {
            this.$refs.modal.show();
        },
        edit(post) {
            this.editTargetPost = post;
            this.tags = post.tagNames;
            this.show();
        },
        hide() {
            this.$refs.modal.hide();
            this.tags = [];
            this.$emit("hide");
        },
        ...mapActions(Modules.POSTS, {
            postJoke: Actions.Posts.CREATE,
            editJoke: Actions.Posts.EDIT
        }),
        ...mapActions(Modules.DUEL_ACTIONS, {
            postDuel: Actions.Duels.CREATE,
            postDuelResponse: Actions.Duels.RESPOND
        }),
        ...mapMutations(Modules.TAGS, {
            clearSelectedTags: Mutations.Tags.CLEAR_SELECTED
        })
    }
}
</script>

<style>

.modal-dialog {
    height: 100%;
    padding-bottom: 20px;
}

.modal-content {
    transform: translate(0%, 12%);
    border: none;
    padding-left: 15px;
    padding-right: 15px;
}

.modal-header {
    padding-bottom: 0.5em !important;
}

.modal-content .modal-body {
    padding: 12px 0 12px 0;
    min-height: 220px;
}

.create-joke-modal .modal-content .modal-footer {
    padding-right: 0;
}

.create-joke-modal .modal-footer {
    display: block;
}

.create-joke-modal {
    z-index: 2000;
}

</style>
