<template>
    <h5 class="privacy-modal-header"><slot/></h5>
</template>

<script>
export default {
name: "PrivacyModal_Header"
}
</script>

<style>

    .privacy-modal-header {
        font-size: 14pt;
    }
</style>
