import Vue from 'vue'
import App from './App.vue'
import Server from "@/server/Server"
import Locale from "@/locale/Locale"
import Store from "@/store/Store"
import Router from "@/routes/Router";
import "./plugins/vuex"
import "./plugins/vue-i18n"
import "./plugins/boostrap"
import "./plugins/vue-router"
import "./plugins/custom-icons"
import "./plugins/vue-mq"
import "./plugins/vue-js-popover"
import "./plugins/vue-clipboard"
import "./plugins/vue-social-sharing"
import "./plugins/vue-observe-visibility"
import "./plugins/vue-countdown"
import "./plugins/vue-expandable-image"
import "./plugins/vue-mobile-detection"
import "./plugins/vue-google-oauth2";
import "./plugins/vue-leaflet"
import EventHandlers from "@/store/EventHandlers";
import TagManager from "@/utils/TagManager";
import Notifications from "@/notifications/Notifications";


import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false;

const localizationClient = Locale.init();

const server = Server.createProxies();

const store = Store.init(server, localizationClient);

EventHandlers.init(store);

const router = Router.create(store);

let uri = window.location.search.substring(1);
let params = new URLSearchParams(uri);
if(params.get("is-mobile-app")){

  localStorage.setItem('is-mobile-app', true);
}


TagManager.init(router);

Notifications.init();


Sentry.init({
  Vue,
  dsn: "https://3b7e3cbd1f054dc3946e311d12de4ada@o573053.ingest.sentry.io/5723162",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  logErrors: true
});

new Vue({
  store   : store,
  i18n    : localizationClient,
  router  : router,
  render  : h => h(App),
}).$mount('#app');
