<template>
    <Layout_Leaderboard_Entry class="position-relative leaderboard-entry-separator">
        <span class="leaderboard-separator-dots">...</span>
    </Layout_Leaderboard_Entry>
</template>

<script>
import Layout_Leaderboard_Entry from "@/ui/components/leaderboard/Layout_Leaderboard_Entry";
export default {
    name: "Leaderboard_Entry_Separator",
    components: { Layout_Leaderboard_Entry }
}
</script>

<style>

    .leaderboard-entry-separator {
        background-color: #f6f6f6;
    }

    .leaderboard-separator-dots {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 32pt;
        letter-spacing: 6px;
        color: #81817d;
    }
</style>
