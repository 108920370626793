<template>
    <div class="icon-circle-container">
        <img class="icon-image" :src="src" :alt="alt" />
    </div>
</template>

<script>
    export default {
        name: "Icon_Circle",
        props : {
            src: String,
            alt: String
        }
    }
</script>

<style scoped>

    .icon-circle-container {
        display: inline-block;
        background-color: #868686;
        border-radius: 100%;
        width: 36px;
        height: 36px;
        padding: 5px;
    }

    .icon-circle-container.orange {
        background-color: #ff6154;
    }

    .icon-circle-container .icon-image {
        filter: brightness(1000%);
        max-width: 100%;
        max-height: 100%;
    }

</style>
