import WebNotificationsManager from "@/notifications/WebNotificationsManager";
import EventBus from "@/events/EventBus";
import Events from "@/events/Events";
import AjaxNotificationsManager from "@/notifications/AjaxNotificationsManager";
import Notification from "@/server/structures/Notification";
import firebase from "firebase/app";

let instance = null;
let token = null;
let mobileToken = null;

export default {

    async init() {

        EventBus.$on(Events.InitNotification, this.start);

    },

    async start() {

        mobileToken = localStorage.getItem('x-mobile-token');
        if (!mobileToken) {

            try {
                if (!firebase.messaging.isSupported()) {

                    instance = new AjaxNotificationsManager();
                } else if (!token) {

                    instance = createInstance();


                    token = await instance.getToken();

                    if (!token) {

                        instance = new AjaxNotificationsManager();
                    }


                }
            } catch (exc) {

                console.log("Failed to initialize notifications.")

                instance = new AjaxNotificationsManager();
            }
        }

        instance.onMessage(onForegroundMessage);

        token = mobileToken;
    },

    async getToken() {

        return token;
    }
}

/**
 *
 * @returns {WebNotificationsManager}
 */
function createInstance() {

    return new WebNotificationsManager();
}

// eslint-disable-next-line no-unused-vars
function onForegroundMessage(payload) {

    EventBus.$emit(Events.ReceivedNotification, payloadToNotification(payload));
}

function payloadToNotification(payload) {

    return new Notification({
        title: payload.notification.title,
        body: payload.notification.body,
        url: payload.notification.click_action,
        image: payload.notification.image,
        read: false
    });
}
