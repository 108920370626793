<template>
    <p class="errorMsg" v-if="errorMessage">{{errorMessage}}</p>
</template>

<script>
    export default {
        name: "Auth_ErrorMessage",
        props: {
            errorMessage: String
        }
    }
</script>

<style scoped>
    .errorMsg {
        color: #fff000;
        margin-bottom: 0;
    }
</style>
