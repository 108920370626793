<template>
    <SubmenuNav :submenus="submenus" :generate-url="generateSubmenuPath"/>
</template>

<script>

    import SubmenuNav from "@/ui/components/submenu_nav/SubmenuNav";
    import SubmenuBuilder from "@/utils/SubmenuBuilder";
    import Icons from "@/utils/Icons";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import RouterUtils from "@/routes/RouterUtils.js";
    import {mapGetters} from "vuex";

    export default {
        name: "Users_SubmenuNav",
        components: { SubmenuNav },
        props: { userSlug: String },
        data() {
            return { submenus: [] }
        },
        mounted() {
            this.updateSubmenus();
        },
        watch: {
            userSlug() {
                this.updateSubmenus();
            }
        },
        computed: {
            ...mapGetters(Modules.USERS, {
                isMe : Getters.Users.IS_ME
            })
        },
        methods: {
            updateSubmenus() {
                this.submenus = (new SubmenuBuilder('users.'))
                    .add('details', Icons.Users.Details)
                    .add('joke-trip', Icons.Users.JokeTrip)
                    .add('duels', Icons.Users.Duels)
                    .add('settings', Icons.Users.Settings, () => this.isMe(this.userSlug))
                    .get();
            },
            generateSubmenuPath(submenu) {
                return RouterUtils.generateUserUrl(this.userSlug, submenu);
            }
        }
    }
</script>
