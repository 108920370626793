import Icons from "@/utils/Icons";
import Vue from 'vue'

const CustomIcons = {
    install(Vue) {
        Object.defineProperty(Vue.prototype, '$icons', {
            get () { return Icons }
        });
    }
};

Vue.use(CustomIcons);
