import ApiResponse from "@/server/responses/ApiResponse";
import Tag from "@/server/structures/Tag";

export default class ApiResponse_TagSearch extends ApiResponse {

    _tags;

    get noMatchesFound() {
        return !this.matchesFound;
    }

    get matchesFound() {
        return this._tags.length !== 0;
    }

    get matches() {
        return this._tags;
    }

    get related() {
        return [];
    }

    get latestSearches() {
        return [];
    }

    constructor(rawResponse) {
        super(rawResponse);

        this._tags = rawResponse.data.data.map((rawTag) => new Tag(rawTag));
    }
}
