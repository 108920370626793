<template>
    <div class="stats-row">
        <span class="stats">
            {{ stats.challenger }}
        </span>
        <div v-if="icon !== undefined" class="stats-middle">
            <img :src="icon" />
            <span class="stats-text">
                {{ stats.text }}
            </span>
        </div>
        <span v-else class="stats-text-no-img">
            {{ stats.text }}
        </span>
        <span class="stats challenged">
            {{stats.challenged}}
        </span>
    </div>
</template>

<script>
    export default {
        name: "Duel_Statistics_Row",
        props: {
            icon: String,
            stats: Object
        },
    }
</script>

<style scoped>
    .stats-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        border-bottom: 1px solid rgba(198, 198, 198, 0.8);
        height: 2em;
    }
    .stats {
        flex-basis: 30%;
    }
    .stats.challenged {
        text-align: end;
    }
    .stats-middle {
        flex-basis: 40%;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        justify-items: center;
        align-items: center;
    }
    .stats-text-no-img {
        text-align: center;
        flex-basis: 40%;
    }
    .stats-middle img {
        margin-right: auto;
        width: 1.2em;
        filter: invert(58%) sepia(10%) saturate(8%) hue-rotate(319deg) brightness(90%) contrast(90%);
    }
    
    .duel-statistics.mobile-m .stats,
    .duel-statistics.mobile-s .stats {
        flex-basis: 25%;
    }
    
    .duel-statistics.mobile-m .stats-text-no-img,
    .duel-statistics.mobile-s .stats-text-no-img,
    .duel-statistics.mobile-m .stats-middle,
    .duel-statistics.mobile-s .stats-middle {
        flex-basis: 50%;
    }
</style>