<!-- The initial responses that appear before anything has been typed in the search field. -->

<template>
    <div class="search-preview">
        <div class="search-preview-section">
            <h5 class="header"> {{ $t("general.popular") }} </h5>
            <FeedTags_Button_Popular v-for="tag in popularTags" :key="tag.name" :tag="tag"/>
        </div>
    </div>
</template>

<script>
    import FeedTags_Button_Popular from "@/ui/components/feed/tags/FeedTags_Button_Popular";
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
    import Mutations from "@/store/Mutations";
    import Getters from "@/store/Getters";
    import Actions from "@/store/Actions";
    import Modules from "@/store/Modules";

    export default {
        name: "FeedTags_SearchPreview",
        components: { FeedTags_Button_Popular },
        mounted() {
            this.pullPopular();
        },
        computed: {
            ...mapState(Modules.TAGS, { popularTags: 'popular', latestTags: 'latest' }),
            ...mapGetters(Modules.TAGS, { isSelected: Getters.Tags.IS_SELECTED })
        },
        methods: {
            ...mapMutations(Modules.TAGS, { toggleTag: Mutations.Tags.TOGGLE_SELECT }),
            ...mapActions(Modules.TAGS, { pullPopular: Actions.Tags.PULL_POPULAR })
        }
    }
</script>

<style scoped>

    .search-preview {
        border-top: 1px solid #c9c9c9;
        min-height: 100vh;
    }

    .search-preview .header {
        text-transform: uppercase;
        color: #565656;
    }

    .search-preview .search-preview-section {
        padding: 2rem 2rem 0;
    }

</style>
