import ApiResponse from "@/server/responses/ApiResponse";
import Badge from "@/server/structures/Badge";

export default class ApiResponse_GetUserBadges extends ApiResponse {

    _badges;

    /** @returns {Badge[]} */
    get badges() {
        return this._badges;
    }

    constructor(rawResponse) {
        super(rawResponse);

        if(this.success) {
            this._badges = rawResponse.data.map((rawBadge) => {
                return new Badge(rawBadge);
            });
        }
    }
}
