<template>
    <Layout_PostCreation_Modal
        ref="post-modal"
        :mode="mode"
        :content-ready="readyForSubmission"
        :post-creation-data="postCreationData"
        :duel-response-id="duelResponseId"
        v-on:hide="reset">

        <Form_TextArea v-model="text" ref="text-input" :rows="7"
                       :placeholder="$t('posts.post-creation-modal-placeholder')" class="gray"/>

    </Layout_PostCreation_Modal>
</template>

<script>

import PostCreationData_Text from "@/server/post_creation/PostCreationData_Text";
import Layout_PostCreation_Modal from "@/ui/layouts/Layout_PostCreation_Modal";
import Form_TextArea from "@/ui/components/common/Form_TextArea";

const MinJokeLength = 6;

export default {
    name: "PostCreation_Modal_Text",
    components: { Layout_PostCreation_Modal, Form_TextArea },
    props: {
        mode: Number,
        duelResponseId: [Number, null]
    },
    data() {
        return { text: null }
    },
    computed: {
        readyForSubmission() {
            return (this.text && this.text.length >= MinJokeLength);
        },
        postCreationData() {
            return new PostCreationData_Text(this.text);
        },
        modal() {
            return this.$refs['post-modal'];
        }
    },
    methods: {
        show() {
            this.modal.show();
            setTimeout(() => this.$refs['text-input'].focus(), 100);
        },
        edit(post) {
            this.modal.edit(post);
            // setTimeout(() => this.$refs['text-input'].focus(), 100);
            this.text = post.fullText;
        },
        hide() {
            this.modal.hide();
        },
        reset() {
            this.text = null;
        }
    }
}
</script>
