<template>
    <b-dropdown
        no-caret
        id="post-dropdown"
        class="post-action-dropdown"
        ref="dropdown"
    >
        <template slot="button-content">
            <img
                class="dropdown-button"
                ref="socialItemImage"
                :src="icon"
                alt="action"
                v-popover="{ name : (authenticated ? '' : 'popover-login-report')}"
                @click="authenticated ? dropdownToggle() : ''"
            />
        </template>
        <b-dropdown-item
            @click="reportClicked()"
        >
            {{ $t('posts.report') }}
        </b-dropdown-item>
        <b-dropdown-item
            v-if="isMe(post.user.slug) && !isDuel"
            @click="editClicked()"
        >
            {{ $t('posts.edit') }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import EventBus from "@/events/EventBus";
    import Events from "@/events/Events";
    import Post from "@/server/structures/Post";
    import Bounce from 'bounce.js';

    const animation = new Bounce();
    animation.scale({
        from: { x: 0.4, y: 0.4 },
        to: { x: 1, y: 1 }
    });

    export default {
        name: "Post_ActionDropdown",
        props: {
            icon: String,
            post: Post,
            isDuel: Boolean
        },
        computed: {
            ...mapState(Modules.AUTH, { authenticated: "authenticated" }),
            ...mapGetters(Modules.USERS, {
                isMe : Getters.Users.IS_ME,
            })
        },
        methods: {
            dropdownToggle() {
                animation.applyTo(this.$refs.socialItemImage);

                let shown = Object.values(this.$refs["dropdown"].$el.classList).includes('show');
                if (shown) {
                    this.$refs["dropdown"].hide()
                } else {
                    this.$refs["dropdown"].show()
                }
            },
            reportClicked() {
                EventBus.$emit(Events.ReportClicked, this.post.id);
            },
            editClicked() {
                EventBus.$emit(Events.PostEdit, this.post);
            }
        }
    }
</script>

<style>
    .post-action-dropdown .dropdown-toggle {
        width: 2.7em;
        padding: 0;
        margin-right: 0.6em;
        background-color: #ffffff;
        border-color: #ffffff;
    }

    .post-action-dropdown .dropdown-toggle:hover,
    .post-action-dropdown .dropdown-toggle:active,
    .post-action-dropdown .dropdown-toggle:focus {
        outline: 0;
        border-color: rgba(254, 114, 78, 0.8) !important;
        background-color: #ffffff !important;
    }
    .post-action-dropdown.show > .btn-secondary.dropdown-toggle {
        background-color: #ffffff !important;
        border-color: rgba(254, 114, 78, 0.8) !important;
    }
    .post-action-dropdown .dropdown-menu{
        min-width: 60px;
    }
    .post-action-dropdown .dropdown-item {
        height: 1.5em;
        width: auto;
        padding-right: 1em;
        padding-left: 0.5em;
    }
    .post-action-dropdown .dropdown-item:active,
    .post-action-dropdown .dropdown-item:hover,
    .post-action-dropdown .dropdown-item:focus {
        background-color: #ffffff;
        color: black;
        text-decoration: none;
        outline: 0;
    }

    .post-action-dropdown .dropdown-button {
        border: 1px solid #e3e3e3 !important;
        border-radius: 4px;
        background: #ffffff;
        padding: 0;
        width: 90%;
    }
</style>
