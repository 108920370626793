<template>
    <b-sidebar :id="navId" right shadow class="sidebar-auth" no-header @shown="onShow">

        <template #default="{ hide }">

            <div class="header">
                <b-button variant="link" @click="close" class="close">
                    <b-img left :src="$icons.Common.ArrowRight" alt="arrow"></b-img>
                </b-button>

                <div class="text-center mb-4">
                    <b-img class="logo" :src="$icons.WhiteLogo" alt="JokeTrip"></b-img>
                </div>
            </div>

            <div class="title">{{ title }}</div>

            <div class="content">
                <slot v-bind:hide="close"></slot>
            </div>

            <Auth_LegalDisclaimer class="legal-disclaimer" :hidden="hideLegalDisclaimer"/>

            <button hidden ref="hideButton" @click="hide"></button>
        </template>
    </b-sidebar>
</template>

<script>
    import Auth_LegalDisclaimer from "@/ui/components/auth/Auth_LegalDisclaimer";
    import WindowEx from "@/utils/WindowEx";

    export default {
        name: "Layout_AuthSidebar",
        components: {Auth_LegalDisclaimer},
        props: {
            title: String,
            navId: String,
            hideLegalDisclaimer: Boolean
        },
        data() {
            return {
                savedScrollPos: null
            }
        },
        methods: {
            onShow() {
                this.savedScrollPos = WindowEx.saveScrollPos();
            },
            close() {
                WindowEx.restoreScrollPos(this.savedScrollPos);

                this.$refs["hideButton"].click();
                this.$emit("close");
            }
        }
    }
</script>

<style>

    /* Layout Login and Register */

    .sidebar-auth .b-sidebar {
        background: linear-gradient(120deg, rgba(253, 120, 78, 1) 0%, rgba(253, 75, 95, 1) 100%);
        width: 100%;
    }

    .sidebar-auth .b-sidebar .b-sidebar-body {
        overflow: visible;
        max-width: 400px;
        height: 100%;
        padding-left: 1.8em;
        padding-right: 1.8em;
        padding-bottom: 0.5em;
        color: white;
        flex-direction: column;
        display: flex;
        align-self: center;
    }

    .sidebar-auth .logo {
        width: 80%;
    }

    .sidebar-auth .title {
        font-size: 1.7em;
        height: 9%;
        color: white;
        font-weight: 600;
    }

    .sidebar-auth .close {
        position: absolute;
        top: 17px;
        right: 17px;
        width: 1.5em;
    }

    .sidebar-auth .close img {
        width: 1.5em;
        filter: brightness(600%);
    }

    .sidebar-auth .header {
        margin-top: 1.2em;
    }

    .sidebar-auth .title {
        margin-bottom: 0.25rem;
    }

    .sidebar-auth .content {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
    }

    .sidebar-auth .legal-disclaimer {
        line-height: 1.0rem;
        flex-grow: 1;
    }
</style>
