import {registerAndGetStore} from "vuex-stores";
import Module_User_Map from "@/store/dynamic_modules/Module_User_Map";
import Module_Post_Map from "@/store/dynamic_modules/Module_Post_Map";

export default class StoreFactory_Maps {

    static _store;
    static _usersApi;
    static _postsApi;

    static init(store, usersApi, postsApi) {
        this._store = store;
        this._usersApi = usersApi;
        this._postsApi = postsApi;
    }

    static getForPost(postId) {

        const module = { namespace: `postMap_${postId}`, ...Module_Post_Map(postId, this._postsApi)};

        return registerAndGetStore(this._store, module);
    }

    static getForUser(userSlug) {

        const module = { namespace: `userMap_${userSlug}`, ...Module_User_Map(userSlug, this._usersApi)};

        return registerAndGetStore(this._store, module);
    }
}
