<template>
    <Layout_Master>

        <template v-slot:sticky-header>

            <Feed_SubmenuNav :content-type="contentType" />

            <FeedTags v-model="searching"/>

        </template>

        <template v-slot:default>
            <main class="content">
                <PostCreation_Bar :mode="postModalMode" :text-prompt="postCreationPromptText"/>

                <router-view></router-view>
            </main>
        </template>

    </Layout_Master>
</template>

<script>

    import Layout_Master from "@/ui/layouts/Layout_Master";
    import FeedTags from "@/ui/components/feed/tags/FeedTags";
    import Feed_SubmenuNav from "@/ui/components/feed/Feed_SubmenuNav";
    import ScrollLock from "@/utils/ScrollLock";
    import PostCreation_Bar from "@/ui/components/feed/post_creation/PostCreation_Bar";
    import PostModalModes from "@/utils/PostModalModes";
    import RouterUtils from "@/routes/RouterUtils";

    export default {
        name: "FeedView",
        props : { contentType: String },
        components : { PostCreation_Bar, Feed_SubmenuNav, FeedTags, Layout_Master },
        data() {
            return {
                searching: false
            }
        },
        watch: {
            searching(value) {
                if(value) {
                    ScrollLock.lock();
                }
                else {
                    ScrollLock.unlock();
                }
            }
        },
        computed: {
            postCreationPromptText() {
                if(RouterUtils.isOnDuelFeed(this.$route.path)) {
                    return this.$t('duels.duel-creation-placeholder');
                }
                else {
                    return this.$t('posts.post-creation-placeholder');
                }
            },
            postModalMode() {
                return PostModalModes.Create;
            }
        }
    }
</script>

<style scoped>

    .content {
        padding: 171px 6px 6px;
        min-height: 100vh;
    }
</style>
