<template>
    <b-button
        variant="outline-light"
        class="share-option"
    >
        <img class="share-option-icon" :src="icon" :alt="alt"/>
        <div class="share-option-text">
            <slot></slot>
        </div>
    </b-button>
</template>

<script>
    export default {
        name: "Share_Option",
        props: {
            icon: String,
            alt: String
        }
    }
</script>

<style scoped>
    .share-option {
        height: 4.8rem;
        width: 4.8rem;
        margin-bottom: 10px;
        border: none;
    }

    .share-option-icon {
        width: 100%;
    }

    .share-option-text {
        font-size: 13px;
        color: #2b2727;
        text-align: center;
    }
</style>
