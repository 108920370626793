<template>
    <div>
        <Spinner class="orange small" v-if="loading" />
        <PostCreation_Button_Tag
                v-else
                :is-add-button="true"
                v-for="tag in suggestedTags"
                :key="tag.name"
                :name="tag.name"
                class="post-tag-suggestions"
                @click.native="$emit('post-tag-selected', tag)"
        />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import PostCreation_Button_Tag from "@/ui/components/feed/post_creation/PostCreation_Button_Tag";
    import Actions from "@/store/Actions";
    import Spinner from "@/ui/components/common/Spinner";

    const SearchResultLimit = 8;

    export default {
        name: "PostCreation_TagSuggestions",
        components: { PostCreation_Button_Tag, Spinner },
        props : {
            searchText : { type: String },
            selectedTags : { type: Array, required: true }
        },
        data() {
            return {
                tagSearchResult: null,
                loading: false
            }
        },
        watch : {
            async searchText(value) {
                if(!value) return;
                
                this.loading = true;
                this.tagSearchResult = await this.searchTags({
                    searchText: value,
                    resultLimit: SearchResultLimit
                });
                this.loading = false;
            }
        },
        computed: {
            suggestedTags() {

                let tagArray = [];

                if(!this.searchText) {
                    tagArray = this.getPopular.slice(0, SearchResultLimit);
                }
                else if(this.tagSearchResult) {
                    tagArray = this.tagSearchResult.matches;
                }

                return tagArray.filter((tag) => {
                    return !this.selectedTags.includes(tag.name);
                });
            },
            ...mapGetters(Modules.TAGS, {
                getPopular : Getters.Tags.POPULAR
            })
        },
        methods : {
            ...mapActions(Modules.TAGS, {
                searchTags : Actions.Tags.SEARCH
            })
        }
    }
</script>

<style scoped>
    * /deep/ .spinner {
        position: relative;
        left: 40%;
    }
</style>
