<template>
    <b-form class="w-100" :id="id">
        <slot></slot>
    </b-form>
</template>

<script>
    export default {
        name: "Auth_Form",
        props : {
            id: String
        }
    }
</script>
