
<!-- Sidebar that lets the users select what login method they would like ot use. -->

<template>
    <Layout_AuthSidebar ref="sidebar" :title="$t('auth.title-login')" nav-id="sidebar-login-select">
        <template #default="{ hide }">
            <Auth_Button_Black class="my-3" :icon="$icons.Auth.FacebookLogo" alt="Facebook">
                <Renderless_FacebookLogin v-on:login="onFacebookLogin">
                    {{ $t("auth.continue-with-facebook") }}
                </Renderless_FacebookLogin>
            </Auth_Button_Black>
            <Auth_Button_Black v-if="!is_mobile_app"  class="my-3" :icon="$icons.Auth.GoogleLogo" alt="Google" @click.native="onGoogleLogin">
                {{ $t("auth.continue-with-google") }}
            </Auth_Button_Black>
            <Auth_Button_Black class="my-3" :icon="null" @click.native="hide" v-b-toggle.sidebar-login>
                {{ $t("auth.continue-with-email") }}
            </Auth_Button_Black>

            <Auth_PromptLabel class="mt-4" :label="$t('auth.dont-have-an-account-q')">
                <Button_WhiteOutline class="px-4" @click.native="hide" v-b-toggle.sidebar-register>
                    {{ $t("auth.register") }}
                </Button_WhiteOutline>
            </Auth_PromptLabel>
        </template>
    </Layout_AuthSidebar>
</template>

<script>
    import Button_WhiteOutline from "@/ui/components/common/Button_WhiteOutline";
    import Auth_Button_Black from "@/ui/components/auth/Auth_Button_Black";
    import Auth_PromptLabel from "@/ui/components/auth/Auth_PromptLabel";
    import Layout_AuthSidebar from "@/ui/layouts/Layout_AuthSidebar";
    import Renderless_FacebookLogin from "@/ui/components/social_media/Renderless_FacebookLogin";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import {mapActions} from "vuex";

    export default {
        name: "Auth_Screen_LoginSelect",
        components: { Renderless_FacebookLogin, Layout_AuthSidebar, Auth_Button_Black, Button_WhiteOutline, Auth_PromptLabel},
        data(){
            return {
                is_mobile_app: false
            }
        },
        mounted() {
            this.is_mobile_app = localStorage.getItem('is-mobile-app')
        },
        methods: {
            async onFacebookLogin(token) {
                const result = await this.facebookLogin({token});

                if(result.success) { this.$refs.sidebar.close(); }
            },
            async onGoogleLogin() {
                const authCode = await this.$gAuth.signIn();
                const result = await this.googleLogin({token : authCode.getAuthResponse().access_token});

                if(result.success) { this.$refs.sidebar.close(); }
            },
            ...mapActions(Modules.AUTH, {
                facebookLogin : Actions.Auth.LOGIN_FACEBOOK,
                googleLogin : Actions.Auth.LOGIN_GOOGLE
            })
        }
    }
</script>
