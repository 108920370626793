<template>
    <b-button
        v-if="showFollowButton"
        class="button-follow-user"
        :class="[{following : user.isFollowing}, $mq]"
        alt="follow-icon"
        @click="handleClick()"
        v-popover="{ name : (isAuthenticated ? '' : 'popover-login-follow')}"
    >
        {{ user.isFollowing ? $t("users.follow-on") : $t("users.follow-off") }}
    </b-button>
</template>

<script>
import User from "@/server/structures/User";
import Modules from "@/store/Modules";
import Actions from "@/store/Actions";
import {mapActions, mapGetters} from "vuex";
import Getters from "@/store/Getters";

export default {
    name: "UserDetails_Button_Follow",
    props: {
        user: User
    },
    methods: {
        ...mapActions(Modules.USERS, {
            toggleFollow: Actions.Users.TOGGLE_FOLLOW
        }),
        handleClick() {
            if (!this.isAuthenticated) { return; }

            this.toggleFollow(this.user)
        },
    },
    computed: {
        showFollowButton() {
            return !this.isAuthenticated || !this.isMe(this.user.slug);
        },
        ...mapGetters(Modules.AUTH, { isAuthenticated: Getters.Auth.IS_AUTHENTICATED }),
        ...mapGetters(Modules.USERS, { isMe: Getters.Users.IS_ME })
    }
}
</script>

<style scoped>

.button-follow-user {
    max-width: 220px;
    border: 1px solid #d9d9d9;
    color: #2a2333;
    background-color: #ededed;
    padding: 0.42em 0.75em;
    font-size: 0.83em;
    font-weight: 500;
    margin-right: 3px;
    display: inline-block;
    border-radius: 15px;
}

.button-follow-user.mobile:hover,
.button-follow-user.mobile:focus {
    color: #2a2333 !important;
    background-color: #ededed !important;
}

.button-follow-user.following {
    color: #ffffff;
    background: linear-gradient(90deg, rgba(254, 114, 78, 1) 0%, rgba(255, 81, 94, 1) 100%);
    border: 1px solid white;
}

</style>
