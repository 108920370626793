import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_Login from "@/server/responses/ApiResponse_Login";
import ApiResponse_VerifyEmail from "@/server/responses/ApiResponse_VerifyEmail";
import ApiResponse from "@/server/responses/ApiResponse";

export default class ApiProxy_Auth extends ApiProxy {

    async register(name, email, password) {

        const rawResponse = await this.post('/registration', { name, email, password });

        return new ApiResponse(rawResponse);
    }

    async verifyEmail(expires, hash, id, userId, signature) {

        const rawResponse = await this.post('/email-verify', null, {
            params: { expires, hash, id, userId, signature }
        });

        return new ApiResponse_VerifyEmail(rawResponse);
    }

    async login(email, password) {

        const rawResponse = await this.post('/login', { email, password });

        return new ApiResponse_Login(rawResponse);
    }

    async forgotPassword(email) {

        const rawResponse = await this.post('/password-forgot', { email });

        return new ApiResponse(rawResponse);
    }

    async resetPassword(email, token, password) {

        const rawResponse = await this.post('/password-reset', { email, token, password });

        return new ApiResponse(rawResponse);
    }

    async changePassword(password, passwordConfirmation) {

        const rawResponse = await this.post('/change-password', {
            password : password,
            password_confirmation : passwordConfirmation
        });

        return new ApiResponse(rawResponse);
    }
}
