export default class WindowEx {

    static _scrollLockX = 0;

    static _scrollLockY = 0;

    static saveScrollPos() {
        return {
            x: window.scrollX,
            y: window.scrollY
        };
    }

    static restoreScrollPos(pos) {
        window.scrollTo(pos.x, pos.y)
    }

    static smoothScrollY(yPos) {
        window.scrollTo({
            top : yPos,
            behavior: 'smooth'
        });
    }

    static scrollToTop() {
        window.scrollTo(0, 0);
    }

    static disableScrolling() {
        WindowEx._scrollLockX = window.scrollX;
        WindowEx._scrollLockY = window.scrollY;
        window.addEventListener('scroll', this._lockScrolling)
    }

    static enableScrolling() {
        window.removeEventListener('scroll', this._lockScrolling);
    }

    static _lockScrolling() {
        window.scrollTo(WindowEx._scrollLockX, WindowEx._scrollLockY)
    }
}
