<template>
    <div class="social-item-holder">
        <button class="social-item-button" v-bind:class="{ selected : isSelected }" @click="handleClick()" >
            <img class="" ref="socialItemImage" :src="icon" alt="action"/>
        </button>
        <span class="count"> {{ count }} </span>
    </div>
</template>

<script>
    import Bounce from 'bounce.js';

    const animation = new Bounce();
    animation.scale({
        from: { x: 0.4, y: 0.4 },
        to: { x: 1, y: 1 }
    });

    export default {
        name: "Post_ActionIcon",
        props: {
            icon: String,
            count: Number,
            isSelected: Boolean
        },
        methods: {
            handleClick() {
                this.$emit('wasClicked');
                animation.applyTo(this.$refs.socialItemImage)
            }
        }
    }
</script>

<style>

    .social-item-holder {
        margin-right: 0.6em;
        display: flex;
        overflow: visible;
    }

    .social-item-holder .count {
        color: #6f6f6f;
        font-size: 0.9em;
        float: left;
        align-self: flex-end;
    }

    .social-item-button {
        border: 1px solid #e3e3e3 !important;
        border-radius: 4px;
        background: #ffffff;
        float: left;
        padding: 0.3em;
        margin-right: 0.26em;
    }

    .social-item-button.selected,
    .social-item-button:hover,
    .social-item-button:active,
    .social-item-button:focus {
        background: #ffffff !important;
        border-color: rgba(254, 114, 78, 0.8) !important;
        outline: none;
    }

    .social-item-button img {
        filter: grayscale(1) brightness(1);
        width: 1.6em;
    }

    .social-item-button.selected img {
        filter: none;
    }
</style>
