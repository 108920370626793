<template>
    <div class="badge-component" :class="{obtained : obtained}">
        <img class="badge-component-image" ref="badge-icon" :src="badge.imageUrl" alt="badge icon" @click="onClick"/>
    </div>
</template>

<script>
import Badge from "@/server/structures/Badge";
import Events from "@/events/Events";
import EventBus from "@/events/EventBus";
import Bounce from "bounce.js";
import {mapGetters} from "vuex";
import Modules from "@/store/Modules";
import Getters from "@/store/Getters";

const animation = new Bounce();
animation.scale({
    from: { x: 0.46, y: 0.46 },
    to: { x: 1, y: 1 }
});

export default {
    name: "Badges_Component",
    props: {
        badge: Badge
    },
    mounted() {
        // TODO : Check that this doesn't harm performance too much
        animation.applyTo(this.$refs["badge-icon"]);
    },
    computed: {
        obtained() {
            return this.badge.obtained;
        },
        ...mapGetters(Modules.AUTH, {
           isAuthenticated : Getters.Auth.IS_AUTHENTICATED
        })
    },
    methods: {
        onClick() {
            animation.applyTo(this.$refs["badge-icon"]);
            EventBus.$emit(Events.BadgeClicked, this.badge);
        }
    }
}
</script>

<style>

.badge-component {
    display:inline-block;
    width: 3.0em;
    height: 3.0em;
    margin: 7px;
    filter: grayscale(100%);
}

.badge-component.obtained {
    filter: none;
}

.badge-component-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

</style>
