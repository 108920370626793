<template>
    <Post_Feed :content-type="contentType" :filter="filter"/>
</template>

<script>

    import Post_Feed from "@/ui/components/feed/post/Post_Feed";

    export default {
        name: "FeedView_Jokes",
        components: { Post_Feed },
        props: {
            contentType: String,
            filter: String
        }
    }
</script>
