import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_GetComments from "@/server/responses/ApiResponse_GetComments";
import ApiResponse_PostComment from "@/server/responses/ApiResponse_PostComment";

export default class ApiProxy_Comments extends ApiProxy {

    async getCommentsForPost(postId, perPage, page) {

        const rawResponse = await this.get('/', {
            params: {
                object_type: 'post',
                object_id : postId,
                per_page: perPage,
                page: page
            }
        });

        return new ApiResponse_GetComments(rawResponse);
    }

    async postComment(postId, text) {

        const rawResponse = await this.post('/', {
            text,
            object_type: 'post',
            object_id: postId
        });

        return new ApiResponse_PostComment(rawResponse);
    }
}
