import AxiosApiClient from "@/server/AxiosApiClient";
import ApiProxy_Auth from "@/server/proxies/ApiProxy_Auth";
import ApiProxy_Tags from "@/server/proxies/ApiProxy_Tags";
import ApiProxy_Posts from "@/server/proxies/ApiProxy_Posts";
import ApiProxy_Comments from "@/server/proxies/ApiProxy_Comments";
import ApiProxy_Users from "@/server/proxies/ApiProxy_Users";
import ApiProxy_Localization from "@/server/proxies/ApiProxy_Localization";
import ApiProxy_Leaderboards from "@/server/proxies/ApiProxy_Leaderboards";
import ApiProxy_SocialProvider from "@/server/proxies/ApiProxy_SocialProvider";
import ApiProxy_Duels from "@/server/proxies/ApiProxy_Duels";
import ApiProxy_Badges from "@/server/proxies/ApiProxy_Badges";
import ApiProxy_Rankings from "@/server/proxies/ApiProxy_Rankings";
import ApiProxy_Notifications from "@/server/proxies/ApiProxy_Notifications";
import Device from "@/utils/Device";

const axiosClient = new AxiosApiClient();

export default {

    createProxies() {

        axiosClient.setDeviceName(Device.getName());

        return {
            apiClient:      axiosClient,
            auth:           new ApiProxy_Auth('/1/auth', axiosClient),
            tags:           new ApiProxy_Tags('/1/tags', axiosClient),
            posts:          new ApiProxy_Posts('/1/posts', axiosClient),
            comments:       new ApiProxy_Comments('/1/comments', axiosClient),
            users:          new ApiProxy_Users('/1/users', axiosClient),
            localization:   new ApiProxy_Localization('/1/localization', axiosClient),
            leaderboards:   new ApiProxy_Leaderboards('/1/leaderboards', axiosClient),
            social:         new ApiProxy_SocialProvider('/1/social', axiosClient),
            duels:          new ApiProxy_Duels('/1/duels', axiosClient),
            badges:         new ApiProxy_Badges('/1/badges', axiosClient),
            rankings:       new ApiProxy_Rankings('/1/week-rankings', axiosClient),
            notifications:  new ApiProxy_Notifications('/1/notifications', axiosClient)
        }
    }
};
