import MathEx from "@/utils/MathEx";

export default {
    lock : lock,
    unlock: unlock,
    clear: clear
}

let scrollLocks = 0;

function lock() {
    scrollLocks = MathEx.clamp(scrollLocks + 1, 0, Number.MAX_VALUE);
    updateScrollLock();
}

function unlock() {
    scrollLocks = MathEx.clamp(scrollLocks - 1, 0, Number.MAX_VALUE);
    updateScrollLock();
}

function clear() {
    scrollLocks = 0;
    updateScrollLock();
}

function updateScrollLock() {
    if(scrollLocks === 0) {
        enableScroll();
    }
    else {
        disableScroll();
    }
}

function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}


// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const Keys = {37: 1, 38: 1, 39: 1, 40: 1};

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    // eslint-disable-next-line getter-return
    get: function () { supportsPassive = true; }
}));

let wheelOpt = supportsPassive ? { passive: false } : false;
let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

function preventDefault(e) {
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
    if (Keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}
