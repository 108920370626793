<template>
    <div class="author-stat">
        <UserDetails_Icon_Small :icon="icon"/>
        <span class="author-stat-text">
            <slot></slot>
        </span>
    </div>
</template>

<script>
    import UserDetails_Icon_Small from "@/ui/components/users/details/UserDetails_Icon_Small";
    export default {
        name: "UserDetails_AuthorStat",
        components: { UserDetails_Icon_Small },
        props: {
            icon: String
        }
    }
</script>

<style>

    .author-stat {
        margin-top: 4px;
        margin-bottom: 5px;
    }

    .author-stat .author-stat-text {
        margin-left: 0.6em;
        position: relative;
        top: 2px;
        color: #515151;
    }

</style>
