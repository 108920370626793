import Random from "@/utils/Random";

const ArrayEx = {};

/** Returns a new array that contains only unique entries. */
ArrayEx.unique = function(array) {
    return array.filter((value, index, arr) => ArrayEx.indexOf(arr, value) === index);
};

/** Returns those elements that are only in the first array and not in the second. */
ArrayEx.difference = function(firstArr, secondArr) {

    if(firstArr.length === 0 && secondArr.length === 0) return firstArr;

    const equals = generateComparer(firstArr.length === 0 ? secondArr[0] : firstArr[0]);

    return firstArr.filter((firstEl) => {
        return !secondArr.some((secondEl) => equals(firstEl, secondEl));
    });
};

ArrayEx.indexOf = function(array, value) {

    const equals = generateComparer(value);

    for(let i = 0; i < array.length; i++) {
        if(equals(array[i], value)) { return i; }
    }

    return -1;
};

/** Appends the second array to the first, removing duplicate items. */
ArrayEx.concatUnique = function(firstArray, secondArray) {

    return ArrayEx.unique(firstArray.concat(secondArray));
};

/** Checks that the value exists in the given array. */
ArrayEx.containsValue = function(array, value) {

    const equals = generateComparer(value);

    return array.some((element) => equals(element, value));
};

/** Returns a copy of the given array with any entries equalling the value removed. */
ArrayEx.filterValue = function(array, value) {

    const equals = generateComparer(value);

    return array.filter((element) => !equals(element, value));
};

/** Given an instance of an object, generates a function for comparing two objects of its type. */
function generateComparer(typeInstance) {
    if(typeof typeInstance.equals === 'function') {
        return (first, second) => first.equals(second);
    }
    else {
        return (first, second) => first === second;
    }
}

ArrayEx.random = function(array) {
    return array[Random.range(0, array.length - 1)];
};

/**
    Chooses k unique random elements from a population sequence or set.

    Returns a new list containing elements from the population while
    leaving the original population unchanged.  The resulting list is
    in selection order so that all sub-slices will also be valid random
    samples.  This allows raffle winners (the sample) to be partitioned
    into grand prize and second place winners (the subslices).

    Members of the population need not be hashable or unique.  If the
    population contains repeats, then each occurrence is a possible
    selection in the sample.

    To choose a sample in a range of integers, use range as an argument.
    This is especially fast and space efficient for sampling from a
    large population:   sample(range(10000000), 60)

    Sampling without replacement entails tracking either potential
    selections (the pool) in a list or previous selections in a set.

    When the number of selections is small compared to the
    population, then tracking selections is efficient, requiring
    only a small set and an occasional reselection.  For
    a larger number of selections, the pool tracking method is
    preferred since the list takes less space than the
    set and it doesn't suffer from frequent reselections.
*/
ArrayEx.randomSample = function (population, k) {

    if (!Array.isArray(population))
        throw new TypeError("Population must be an array.");
    const n = population.length;
    if (k < 0 || k > n)
        throw new RangeError("Sample larger than population or is negative");

    const result = new Array(k);
    let setsize = 21;   // size of a small set minus size of an empty list

    if (k > 5)
        setsize += Math.pow(4, Math.ceil(Math.log(k * 3, 4)))

    if (n <= setsize) {
        // An n-length list is smaller than a k-length set
        const pool = population.slice();
        for (let i = 0; i < k; i++) {          // invariant:  non-selected at [0,n-i)
            const j = Math.random() * (n - i) | 0;
            result[i] = pool[j];
            pool[j] = pool[n - i - 1];       // move non-selected item into vacancy
        }
    } else {
        const selected = new Set();
        for (let i = 0; i < k; i++) {
            let j = Math.random() * n | 0;
            while (selected.has(j)) {
                j = Math.random() * n | 0;
            }
            selected.add(j);
            result[i] = population[j];
        }
    }

    return result;
};

export default ArrayEx;
