<template>
  <Layout_Info>
    <template v-slot:title>
      {{ $t("faq.frequently-asked-questions") }}
    </template>

    <template v-slot:content>
        <Info_Header> {{ $t("faq.duels") }}</Info_Header>
        <Info_SubHeader> {{ $t("faq.duels-header-1") }}</Info_SubHeader>
        <Info_Text> {{ $t("faq.duels-text-1-1") }}</Info_Text>

        <Info_SubHeader> {{ $t("faq.duels-header-2") }}</Info_SubHeader>
        <Info_Text> {{ $t("faq.duels-text-2-1") }}</Info_Text>

        <Info_SubHeader> {{ $t("faq.duels-header-3") }}</Info_SubHeader>
        <Info_List>
            <Info_ListBullet>{{ $t("faq.duels-bullet-3-1") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("faq.duels-bullet-3-2") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("faq.duels-bullet-3-3") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("faq.duels-bullet-3-4") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("faq.duels-bullet-3-5") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("faq.duels-bullet-3-6") }}</Info_ListBullet>
        </Info_List>

        <Info_SubHeader> {{ $t("faq.duels-header-4") }}</Info_SubHeader>
        <Info_Text> {{ $t("faq.duels-text-4-1") }}</Info_Text>

        <Info_SubHeader> {{ $t("faq.duels-header-5") }}</Info_SubHeader>
        <Info_Text> {{ $t("faq.duels-text-5-1") }}</Info_Text>


        <Info_Header> {{ $t("faq.activity-points") }}</Info_Header>
        <Info_SubHeader> {{ $t("faq.activity-points-question") }}</Info_SubHeader>
        <Info_Text> {{ $t("faq.activity-points-answer") }}</Info_Text>

        <Info_SubHeader> {{ $t("faq.activity-points-earn-question") }}</Info_SubHeader>
        <Info_List>
            <Info_ListBullet>{{ $t("faq.activity-points-earn-answer-1-1") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("faq.activity-points-earn-answer-1-2") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("faq.activity-points-earn-answer-1-3") }}</Info_ListBullet>
            <Info_ListBullet>{{ $t("faq.activity-points-earn-answer-1-4") }}</Info_ListBullet>
        </Info_List>
      <Info_Text> {{ $t("faq.activity-points-earn-answer-2") }}</Info_Text>
      <Info_List>
        <Info_ListBullet>{{ $t("faq.activity-points-earn-answer-2-1") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.activity-points-earn-answer-2-2") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.activity-points-earn-answer-2-3") }}</Info_ListBullet>
      </Info_List>

      <Info_SubHeader> {{ $t("faq.activity-points-purpose-question") }}</Info_SubHeader>
      <Info_Text> {{ $t("faq.activity-points-purpose-answer") }}</Info_Text>

      <Info_SubHeader> {{ $t("faq.activity-points-amount-question") }}</Info_SubHeader>
      <Info_List>
        <Info_ListBullet>{{ $t("faq.activity-points-amount-answer-1-1") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.activity-points-amount-answer-1-2") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.activity-points-amount-answer-1-3") }}</Info_ListBullet>
      </Info_List>
      <Info_Text> {{ $t("faq.activity-points-amount-answer-2") }}</Info_Text>

      <Info_Header> {{ $t("faq.author-stats-question") }}</Info_Header>
      <Info_Text> {{ $t("faq.author-stats-answer") }}</Info_Text>

      <Info_Header> {{ $t("faq.influencer-stat-question") }}</Info_Header>
      <Info_Text> {{ $t("faq.influencer-stat-answer") }}</Info_Text>

      <Info_Header> {{ $t("faq.badges-question") }}</Info_Header>
      <Info_Text> {{ $t("faq.badges-answer") }}</Info_Text>

      <Info_Header> {{ $t("faq.tags-header") }}</Info_Header>
      <Info_SubHeader> {{ $t("faq.tags-subheader-1") }}</Info_SubHeader>
      <Info_Text> {{ $t("faq.tags-answer-1") }}</Info_Text>
      <Info_SubHeader> {{ $t("faq.tags-subheader-2") }}</Info_SubHeader>
      <Info_Text> {{ $t("faq.tags-answer-2") }}</Info_Text>

      <Info_Header> {{ $t("faq.leaderboards-question") }}</Info_Header>
      <Info_Text> {{ $t("faq.leaderboards-answer-1") }}</Info_Text>
      <Info_SubHeader> {{ $t("faq.leaderboards-subheader-2") }}</Info_SubHeader>
      <Info_Text> {{ $t("faq.leaderboards-answer-2") }}</Info_Text>
      <Info_SubHeader> {{ $t("faq.leaderboards-subheader-3") }}</Info_SubHeader>
      <Info_Text> {{ $t("faq.leaderboards-answer-3") }}</Info_Text>
      <Info_SubHeader> {{ $t("faq.leaderboards-subheader-4") }}</Info_SubHeader>
      <Info_Text> {{ $t("faq.leaderboards-answer-4") }}</Info_Text>

      <Info_Header> {{ $t("faq.reporting-question") }}</Info_Header>
      <Info_Text> {{ $t("faq.reporting-answer-1") }}</Info_Text>
      <Info_Text> {{ $t("faq.reporting-answer-2") }}</Info_Text>
      <Info_List>
        <Info_ListBullet>{{ $t("faq.reporting-answer-2-1") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.reporting-answer-2-2") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.reporting-answer-2-3") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.reporting-answer-2-4") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.reporting-answer-2-5") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.reporting-answer-2-6") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.reporting-answer-2-7") }}</Info_ListBullet>
        <Info_ListBullet>{{ $t("faq.reporting-answer-2-8") }}</Info_ListBullet>
      </Info_List>

    </template>
  </Layout_Info>
</template>

<script>
import Layout_Info from "@/ui/layouts/Layout_Info";
import Info_Header from "@/ui/components/info/Info_Header";
import Info_Text from "@/ui/components/info/Info_Text";
import Info_SubHeader from "@/ui/components/info/Info_SubHeader";
import Info_ListBullet from "@/ui/components/info/Info_ListBullet";
import Info_List from "@/ui/components/info/Info_List";

export default {
  name: "InfoView_FAQ",
  components: { Info_List, Info_ListBullet, Layout_Info, Info_Header, Info_Text, Info_SubHeader }
}
</script>
