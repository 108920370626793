<template>
    <router-link
        class="joke-trip-router-link"
        :to="to"
        :target="target">
        <slot/>
    </router-link>
</template>

<script>
export default {
    name: "RouterLink_Default",
    props: {
        to: String,
        target: String
    }
}
</script>

<style>

    .joke-trip-router-link {
        color: #ffd500;
        text-decoration: underline;
    }
    .joke-trip-router-link:hover {
        color: navajowhite;
    }
</style>
