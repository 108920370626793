<template>
    <Layout_Master>
        <main class="content">

            <Badges_Section_Header/>

            <div class="p-3">
                <template v-if="statusIsLoading">
                    <Spinner class="orange center-absolute"/>
                </template>
                <template v-else-if="statusIsReady">
                    <Badges_Category v-for="category in categories" :key="category.name" :category="category"/>
                </template>
                <template v-else-if="statusIsError">
                    <Text_NoResults>{{ $t("errors.failed-badges-load") }}</Text_NoResults>
                </template>
            </div>
        </main>
    </Layout_Master>
</template>

<script>
import Layout_Master from "@/ui/layouts/Layout_Master";
import Badges_Section_Header from "@/ui/components/badges/Badges_Section_Header";
import Badges_Category from "@/ui/components/badges/Badges_Category";
import {mapActions, mapGetters} from "vuex";
import Modules from "@/store/Modules";
import Getters from "@/store/Getters";
import Actions from "@/store/Actions";
import ResourceStatus from "@/store/types/ResourceStatus";
import Spinner from "@/ui/components/common/Spinner";
import Text_NoResults from "@/ui/components/common/Text_NoResults";

export default {
    name: "View_Badges",
    components: { Text_NoResults, Spinner, Badges_Category, Badges_Section_Header, Layout_Master },
    mounted() {
        this.pullBadges();
    },
    computed: {
        statusIsReady() {
            return this.status === ResourceStatus.Ready;
        },
        statusIsLoading() {
            return this.status === ResourceStatus.Loading;
        },
        statusIsError() {
            return this.status === ResourceStatus.Error;
        },
        ...mapGetters(Modules.BADGES, {
            status: Getters.Badges.GET_STATUS,
            categories: Getters.Badges.GET_CATEGORIES
        })
    },
    methods: {
        ...mapActions(Modules.BADGES, {
            pullBadges: Actions.Badges.PULL
        })
    }
}
</script>

<style scoped>

.content {
    padding: 55px 3px 3px 3px;
    min-height: 100vh;
}
</style>
