<template>
    <Panel_White class="create-post-bar">

        <Bar_Experience_Small :status=" user ? user.activityStatus : null"/>

        <Avatar_Small :user="user"/>

        <PostCreation_Text :mode="mode" :text-prompt="textPrompt" :duel-response-id="duelResponseId" class="mx-2"/>

        <PostCreation_Image :mode="mode" :duel-response-id="duelResponseId" class="mr-3"/>
    </Panel_White>
</template>

<script>
    import {mapGetters} from "vuex";
    import Modules from "@/store/Modules";
    import Getters from "@/store/Getters";
    import Avatar_Small from "@/ui/components/common/Avatar_Small";
    import Bar_Experience_Small from "@/ui/components/common/Bar_Experience_Small";
    import Panel_White from "@/ui/components/common/Panel_White";
    import PostCreation_Text from "@/ui/components/feed/post_creation/PostCreation_Text";
    import PostCreation_Image from "@/ui/components/feed/post_creation/PostCreation_Image";

    export default {
        name: "PostCreation_Bar",
        components: { PostCreation_Image, PostCreation_Text, Panel_White, Bar_Experience_Small, Avatar_Small },
        computed: { ...mapGetters(Modules.USERS, { user : Getters.Users.GET_ME }) },
        props: {
            mode: Number,
            textPrompt: String,
            duelResponseId : [Number, null]
        }
    }
</script>

<style scoped>

    .create-post-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 60px;
        padding-left: 20px;
        margin-bottom: 7px;
    }
</style>
