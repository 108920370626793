

export default class MapArea {

    _data;

    get views() {
        return this._data.count;
    }

    static createEmpty() {
        return new MapArea({ count : 0 })
    }

    constructor(data) {
        this._data = data;
    }
}
