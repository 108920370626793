<template>
    <div class="duel-username-middle-text">
        <slot> </slot>
    </div>
</template>

<script>
    export default {
        name: "Duel_Header_UserIconsText",
        props: {
            status: String,
            result: [String, null]
        }
    }
</script>

<style>
    .duel-username-middle-text {
        font-size: 12px;
        font-weight: 700;
        color: #7c858b;
        text-transform: uppercase;
    }
</style>