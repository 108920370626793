<template>
    <Layout_UserDetailsSection class="user-details-section-badges" :info-text="$t('users.hint-badges')">

        <UserDetails_Header_Small class="w-100 mb-2">{{ $t("general.badges") }}</UserDetails_Header_Small>

        <template v-if="!badges">
            <!-- Add some kind of loading indication -->
        </template>
        <template v-else-if="resultsAreEmpty">
            <span class="no-badges-text">{{ $t("users.no-badges") }}</span>
        </template>
        <template v-else>
            <Badges_ArrowScroll v-if="badges" :badges="badges" scroll-class="white"/>
        </template>

    </Layout_UserDetailsSection>
</template>

<script>
    import Layout_UserDetailsSection from "@/ui/layouts/Layout_UserDetailsSection";
    import UserDetails_Header_Small from "@/ui/components/users/details/UserDetails_Header_Small";
    import Badges_ArrowScroll from "@/ui/components/badges/Badges_ArrowScroll";
    import {mapActions} from "vuex";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import User from "@/server/structures/User";

    export default {
        name: "UserDetails_Section_Badges",
        components: { Badges_ArrowScroll, UserDetails_Header_Small, Layout_UserDetailsSection },
        props: {
            user: User
        },
        data() {
            return {
                badges: null
            }
        },
        async mounted() {

            const result = await this.getBadges(this.user.slug);

            if(result.success) {
                this.badges = result.badges;
            }
        },
        computed: {
            resultsAreEmpty() {
                return this.badges.length === 0;
            }
        },
        methods: {
            ...mapActions(Modules.USERS, {
                getBadges: Actions.Users.GET_BADGES
            })
        }
    }
</script>

<style scoped>

    .user-details-section-badges {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .user-details-section-badges .no-badges-text {
        width: 100%;
        text-align: center;
        color: #5a5a58;
    }
</style>
