<template>
    <img class="avatar-large" :src="src ? src : $icons._Placeholder.Profile" alt=""/>
</template>

<script>
    export default {
        name: "Avatar_Large",
        props : { src: String }
    }
</script>

<style scoped>

    .avatar-large {
        width: 6em;
        height: 6em;
        border-radius: 100%;
    }
</style>
