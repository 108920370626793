<template>
    <label :for="this.for">
        <slot></slot>
    </label>
</template>

<script>
    export default {
        name: "Form_Label",
        props: { for: String }
    }
</script>
