<template>
    <li class="list-item">
        <span class="info-answer-text text">
            <slot></slot>
        </span>
    </li>
</template>

<script>
    export default {
        name: "Info_ListBullet"
    }
</script>

<style scoped>
    .list-item {
        font-weight: 700;
    }
    .text {
        font-weight: 400;
    }
</style>
