<template>
    <popover
            :name="name"
            triggers="focus"
            class="popover"
    >
        <h6 class="popover-header">{{ title }}</h6>

        <div v-if="text" class="popover-body">{{ text }}</div>

        <Button_Login class="login-button"/>
    </popover>
</template>

<script>
    import Button_Login from "@/ui/components/common/Button_Login";
    export default {
        name: "Popover_Login",
        components: { Button_Login },
        props: {
            name : String,
            title : String,
            text : String,
        }
    }
</script>

<style scoped>
    .popover {
        z-index: 1001 !important;
        border: none;
        padding: 0;
        box-shadow: 0 2px 26px 2px grey;
    }

    .popover .popover-header {
        /* Provided by vue-bootstrap. */
    }

    .popover .popover-body {
        /* Provided by vue-bootstrap. */
        font-size: 11pt;
    }

    .login-button {
        display: block;
        margin: 10px auto 12px;
    }
</style>
