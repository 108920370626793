<template>
    <h4 class="common-header-large"><slot/></h4>
</template>

<script>
    export default { name: "Header_Large" }
</script>

<style>
    .common-header-large {
        color: #575755;
    }
    .common-header-large.dark {
        color: #1d1c1c;
    }
    .common-header-large.orange {
        color: #fd7a4c;
    }
</style>
