<template>
    <ol class="nested-list">
        <slot></slot>
    </ol>
</template>

<script>
    export default {
        name: "Info_NestedList",
    }
</script>

<style scoped>
    .nested-list {
        padding-inline-start: 0px;
        list-style-position: inside;
        counter-reset: item; 
    }
</style>