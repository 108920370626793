<!-- Component for the black sidebar that appears when clicking the corner of the header. -->

<template>
    <b-sidebar id="sidebar"
               sidebar-class="custom-left-sidebar"
               bg-variant="dark"
               text-variant="light"
               no-header
               shadow
               :backdrop-variant="'dark'"
               backdrop
               v-on:shown="$emit('shown')"
               v-on:hidden="onHidden()"
    >
        <template #default="{ hide }">
            <div class="sidebar-header px-2">
                <b-button variant="link" @click="hide" left>
                    <b-img class="" left :src="require('../../../assets/top_nav/arrow-left.jpg')" alt="arrow"></b-img>
                </b-button>
                <b-link href="#">
                    <b-img class="logo" :src="$icons.Logo" alt="JokeTrip" @click="goHome"/>
                </b-link>
            </div>

            <nav class="mb-3">
                <b-nav vertical>

                    <Sidebar_Select_Content/>

                    <Sidebar_Button_Region ref="region-collapse" v-on:selected="hide" />

                    <Sidebar_Button @click.native="goHome" :icon="$icons.Home">{{ $t("general.home") }}</Sidebar_Button>

<!--                    <Sidebar_Button url="/rankings" :icon="$icons.TopTen">{{ $t("general.top-10-last-week") }}</Sidebar_Button>-->

                    <Sidebar_Button url="/badges" @click.native="hide" :icon="$icons.Badges">{{ $t("general.badges") }}</Sidebar_Button>

                    <Sidebar_Button url="/leaderboards" @click.native="hide" :icon="$icons.Leaderboard">{{ $t("general.leaderboard") }}</Sidebar_Button>

                    <Sidebar_Button v-if="authenticated" url="/favorites" :icon="$icons.Favorites">{{ $t("general.favorites") }}</Sidebar_Button>

<!--                    <Sidebar_Button :small="true" :icon="$icons.Notifications" :disabled="true">{{ $t("general.notifications") }}</Sidebar_Button>-->

                    <Sidebar_Button_Language ref="language-collapse" v-on:selected="hide"/>

                    <Sidebar_Button url="/faq" @click.native="hide" :small="true" :icon="$icons.Faq">{{ $t("general.faq") }}</Sidebar_Button>

                    <Sidebar_Button url="/about" @click.native="hide" :small="true" :icon="$icons.Faq">{{ $t("general.about-us") }}</Sidebar_Button>

                    <Sidebar_Button url="/terms" @click.native="hide" :small="true" :icon="$icons.Faq">{{ $t("general.terms") }}</Sidebar_Button>

                    <Sidebar_Button url="/privacy" @click.native="hide" :small="true" :icon="$icons.Faq">{{ $t("general.privacy") }}</Sidebar_Button>

<!--                    <Sidebar_Button :small="true" :icon="$icons.EliteUser" :disabled="true">{{ $t("general.elite-user") }}</Sidebar_Button>-->

                    <Sidebar_Button v-if="authenticated" @click.native="logout" :small="true" :icon="$icons.LogOut">
                        {{ $t("auth.log-out") }}
                    </Sidebar_Button>

                    <div class="link-block">
                        <a href="https://joketrip.com">joketrip.com</a>
                    </div>
                </b-nav>
            </nav>

            <div class="h-100 overflow-hidden" @click="hide"/>

            <button hidden ref="hideButton" @click="hide"></button>

        </template>
        <div class="custom-left-sidebar bg-dark"></div>
    </b-sidebar>
</template>

<script>

    import Sidebar_Button from "@/ui/components/sidebar/Sidebar_Button";
    import Actions from "@/store/Actions";
    import {mapActions, mapState} from "vuex";
    import Modules from "@/store/Modules";
    import RouterUtils from "@/routes/RouterUtils";
    import WindowEx from "@/utils/WindowEx";
    import Sidebar_Button_Language from "@/ui/components/sidebar/Sidebar_Button_Language";
    import Sidebar_Button_Region from "@/ui/components/sidebar/Sidebar_Button_Region";
    import Sidebar_Select_Content from "@/ui/components/sidebar/Sidebar_Select_Content";

    export default {
        name: "Sidebar",
        components: { Sidebar_Select_Content, Sidebar_Button_Language, Sidebar_Button, Sidebar_Button_Region },
        computed: {
            ...mapState(Modules.AUTH, ["authenticated"])
        },
        methods: {
            goHome() {
                RouterUtils.goHome(true);
                this._reloadPosts();
                this.hide();
            },
            logout() {
                WindowEx.scrollToTop();
                this._logout().then(() => {
                    this.goHome();
                });
            },
            hide() {
                this.$refs["hideButton"]?.click();
            },
            ...mapActions(Modules.AUTH, {
                _logout: Actions.Auth.LOGOUT
            }),
            ...mapActions(Modules.POSTS, {
                _reloadPosts : Actions.Posts.RELOAD
            }),
            onHidden() {
                this.$emit('hidden');
                this.$refs["region-collapse"].hide();
                this.$refs["language-collapse"].hide();
            }
        }
    }
</script>

<style>
    /* Sidebar */

    .custom-left-sidebar.b-sidebar .b-sidebar-body {
        overflow: hidden;
    }

    .custom-left-sidebar.bg-dark {
        background-color: #2a2333 !important;
        color: #ffffff;
    }

    .custom-left-sidebar .sidebar-header {
        border-bottom: 1px solid #373040;
        padding-top: 0.4em;
        padding-bottom: 0.4em;
    }

    .custom-left-sidebar a {
        color: #ffffff;
        text-transform: uppercase;
    }

    .custom-left-sidebar .logo {
        width: 8em;
    }

    /** Hovering */
    .sidebar-collapse-item:hover,
    .sidebar-button:hover {
        background-color: #372f43;
        cursor: pointer;
    }

    .sidebar-collapse-item:hover,
    .sidebar-button:hover a.nav-link {
        color: #ff6154 !important;
    }
    .link-block{
        z-index: 9999999;
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
    }
    .link-block a {
        color: #ff6154;
        text-transform: none;
    }

</style>
