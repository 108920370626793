<!-- The topmost part of the feed post, showing the users users and name as well as when the post was created.  -->

<template>
    <div class="user-row" :class="$mq">

        <Number_Rank v-if="rank" class="align-left" :value="rank"/>

        <Bar_Experience_Small :status="user.activityStatus"/>

        <Avatar_Small class="mr-2" :user="user"/>

        <div class="user-details">

            <TimeIndicator class="time-posted" :datetime="timestamp" :refresh="60" :long="true"/>

            <Post_Text_Username class="post-text-username" :user="user" />
        </div>

        <div v-if="viewCount" class="views-group">

            <Post_Text_Views>{{ $t('general.views') }} {{ viewCount }}</Post_Text_Views>
        </div>
    </div>
</template>

<script>
    import Post_Text_Username from "@/ui/components/feed/post/Post_Text_Username";
    import Post_Text_Views from "@/ui/components/feed/post/Post_Text_Views";
    import Bar_Experience_Small from "@/ui/components/common/Bar_Experience_Small";
    import Avatar_Small from "@/ui/components/common/Avatar_Small";
    import User from "@/server/structures/User";
    import TimeIndicator from "@/ui/components/common/TimeIndicator";
    import Number_Rank from "@/ui/components/common/Number_Rank";

    export default {
        name: "Post_Section_Header",
        components: { Number_Rank, TimeIndicator, Post_Text_Views, Post_Text_Username, Bar_Experience_Small, Avatar_Small },
        props: {
            user: User,
            timestamp: Date,
            viewCount: [Number, null],
            rank: Number
        }
    }
</script>

<style scoped>

    .user-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0 1em;
    }

    .user-row .time-posted {
        color: #b7b7b7;
        white-space: nowrap;
    }

    .user-row .views-group {
        margin-left: auto;
        white-space: nowrap;
    }

    .user-row .user-details {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    }

    .post-text-username {
        font-weight: 500;
    }

    .user-row.desktop .user-details .post-text-username,
    .user-row.tablet .user-details .post-text-username {
        margin-right: 0.6em;
    }

    .user-row.mobile .user-details,
    .user-row.mobile-m .user-details,
    .user-row.mobile-s .user-details{
        flex-direction: column;
        align-items: flex-start;
    }
    .user-row.mobile-s .user-details{
        width: 40%;
    }
    .user-row.mobile-m .user-details{
        width: 45%;
    }
    .user-row.mobile .user-details{
        width: 60%;
    }
    .user-row.mobile .user-details .time-posted,
    .user-row.mobile-m .user-details .time-posted,
    .user-row.mobile-s .user-details .time-posted {
        align-self: flex-start;
        margin-bottom: -8px;
    }

    .user-row.mobile .user-details .time-posted /deep/ .v-time-ago__text {
        font-size: 13px;
    }
</style>
