import ApiResponse from "@/server/responses/ApiResponse";
import User from "@/server/structures/User";

export default class ApiResponse_GetUsers extends ApiResponse {

    get users() {
        return this._rawResponse.data.data.map((rawUser) => {
            return new User(rawUser);
        });
    }

    get lastPage() {
        return this._rawResponse.data.last_page;
    }
}
