import ApiResponse from "@/server/responses/ApiResponse";
import BadgeCategory from "@/server/structures/BadgeCategory";

export default class ApiResponse_GetBadges extends ApiResponse {

    _badgeCategories;

    /** @returns {BadgeCategory[]} */
    get categories() {
        return this._badgeCategories;
    }

    constructor(rawResponse) {
        super(rawResponse);

        if(this.success) {
            this._badgeCategories = rawResponse.data.map((rawCategory) => {
                return new BadgeCategory(rawCategory);
            });
        }
    }
}
