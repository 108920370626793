<template>
    <Layout_Master>
        <div class="container">
            <h1 class="title">
                <slot name="title"></slot>
            </h1>
            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>
    </Layout_Master>
</template>

<script>

    import Layout_Master from "@/ui/layouts/Layout_Master";

    export default {
        name: 'Layout_Info',
        components: { Layout_Master }
    }
</script>

<style scoped>
    .container {
        padding-top: 70px;
        padding-left: 0;
        padding-right: 0;
        min-height: 100vh;
    }
    .title {
        font-weight: 400;
        color: black;
        text-align: center;
        font-size: 2.0rem;
        margin: 1.5rem 0.6rem 1rem;
    }
    .content {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        overflow: auto;
    }
</style>
