import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_GetRankings from "@/server/responses/ApiResponse_GetRankings";

export default class ApiProxy_Rankings extends ApiProxy {

    /**
     * Retrieves the top 10 posts last week in the given category
     * @param {string} category
     * @return {Promise<ApiResponse_GetRankings>}
     */
    async getRankedPosts(category) {
        return new ApiResponse_GetRankings(
            await this.get(`/post/${category}`)
        )
    }
}
