<!-- Used for the buttons in view submenus. -->

<template>
    <b-nav-item class="submenu-button" :class="[{ selected: selected }, $mq]" :active="selected" >
        <b-img class="nav-image-icon" :src="submenu.icon" alt="New"></b-img>
        <span>{{ $t(submenu.localeKey) }}</span>
        <RouterLink_Overlay :to="url"/>
        <div v-if="selected" class="overlay-absolute" @click="selectedClicked" />
        
    </b-nav-item>
</template>


<script>
    import RouterLink_Overlay from "@/ui/components/common/RouterLink_Overlay";

    export default {
        name: "SubmenuNav_Button",
        components: { RouterLink_Overlay },
        props: {
            submenu: Object,
            selected: Boolean,
            url: String
        },
        methods: {
            selectedClicked() {
                this.$emit('selected-clicked', this.submenu.name)
            }
        }
    }
</script>

<style scoped>

    .submenu-button {
        flex-grow: 1;
        width: auto;
        height: 4.0em;
        position: relative;
    }
    .submenu-button:hover {
        cursor: pointer;
        background-color: whitesmoke;
    }
    .submenu-button .nav-image-icon {
        width: 1.9em;
        align-self: center;
    }
    .submenu-button .nav-link {
        font-size: 0.9em;
        color: #6f6f6f;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0.8em 1.1vw 0.8em 1.1vw;
    }
    .submenu-button .nav-link.active {
        color: #fd7a4c;
    }
    .submenu-button .nav-link span {
        align-self: center;
        font-size: 0.85em;
        position:absolute;
        bottom: 0.45em;
    }
    .submenu-button.selected {
        background-color: whitesmoke;
    }
    .submenu-button.mobile {
    }
    .submenu-button.mobile .nav-link {
    }
    .submenu-button.mobile .nav-link span {
    }
</style>
