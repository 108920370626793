<template>
    <div class="duel-header" :class="$mq">
        <!-- First Row - User Experience, Icon, Flag(optional) and Text in the middle -->
        <Duel_Header_UserIconsRow :duel="duel">
            <template v-slot:challengerIcon>
                <slot name="challengerIcon"> </slot>
            </template>
            <template v-slot:iconRowText>
                <slot name="iconRowText"> </slot>
            </template>
            <template v-slot:challengedIcon>
                <slot name="challengedIcon"> </slot>
            </template>
        </Duel_Header_UserIconsRow>
        <!-- Second Row - Username and text in the middle -->
        <Duel_Header_UsernameRow :duel="duel" class="mb-1">
            <slot name="versusText"> </slot>
        </Duel_Header_UsernameRow>
        <!-- Third Row - Vote Buttons/Voting Bar(optional) -->
        <slot name="votingSection"></slot>
    </div>
</template>

<script>

    import Duel from "@/server/structures/Duel";
    import Duel_Header_UserIconsRow from "@/ui/components/duels/Duel_Header_UserIconsRow";
    import Duel_Header_UsernameRow from "@/ui/components/duels/Duel_Header_UsernameRow";

    export default {
        name: "Duel_Section_Header",
        components: { Duel_Header_UserIconsRow, Duel_Header_UsernameRow },
        props: {
            duel: Duel
        }
    }
</script>

<style>

    .duel-header {
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        border-bottom: 1px solid rgba(198, 198, 198, 0.8);
        display: flex;
        flex-direction: column;
    }
    .duel-header.mobile-m .time-left,
    .duel-header.mobile-s .time-left,
    .duel-header.mobile .time-left {
        font-size: 12px;
    }
</style>
