<template>
    <b-button
        pill
        class="share-button orange-gradient no-border mr-0 px-3"
    >
        <slot>{{ text ? text : $t("posts.share") }}</slot>
    </b-button>
</template>

<script>
    export default {
        name: "Button_Share",
        props: {
            text: [String, null]
        },
    }
</script>
