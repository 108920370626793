<template>
    <Layout_Duel_Header :duel="duel" >
        <template v-slot:challengerIcon>
            <img :src="$icons.DuelsIcons.UserChallenger" alt="User challenged icon"/>
        </template>
        <template v-slot:iconRowText>
            <countdown v-if="duel.isAccepted" class="time-left" :time="timeLeft">
                <template slot-scope="props">{{ props.days }} {{ $t('general.day-short') }} {{ props.hours }} {{ $t('general.hour-short') }} {{ props.minutes }} {{ $t('general.minute-short') }} {{$t('duels.left')}}</template>
            </countdown>
            <TimeIndicator v-else-if="duel.isFinished"  :datetime="duel.endDate" :refresh="60" :long="true"/>
        </template>
        <template v-slot:challengedIcon>
            <img :src="$icons.DuelsIcons.UserChallenged" alt="User challenged icon"/>
        </template>

        <template v-slot:versusText>
            <span v-if="duel.isFinished"> {{ duelResult }} </span>
            <span v-else class="active-text"> {{ $t("duels.versus") }} </span>
        </template>

        <template v-slot:votingSection>
            <Duel_Section_Voting :duel="duel" :isClosed="isClosed"/>
        </template>
    </Layout_Duel_Header>
</template>

<script>

    import Duel from "@/server/structures/Duel";
    import Duel_Section_Voting from "@/ui/components/duels/Duel_Section_Voting";
    import Layout_Duel_Header from "@/ui/components/common/Layout_Duel_Header";
    import {mapActions} from "vuex";
    import Actions from "@/store/Actions";
    import Modules from "@/store/Modules";
    import TimeIndicator from "@/ui/components/common/TimeIndicator";

    export default {
        name: "Duel_Section_Header",
        components: { TimeIndicator, Duel_Section_Voting, Layout_Duel_Header },
        props: {
            duel: Duel,
            isClosed: Boolean
        },
        data: function() {
            return {
                timeoutId: null
            }
        },
        mounted: function() {
            if(this.duel.isAccepted) {
                this.timeoutId = setTimeout(() => this.updateStatus(), this.timeLeft);
            }
        },
        beforeDestroy: function() {
            clearTimeout(this.timeoutId);
        },
        computed: {
            timeLeft() {
                return this.duel.endDate - Date.now();
            },
            duelResult() {
                return this.duel.challengerVotesCount >= this.duel.challengedVotesCount ? this.$t('duels.won') : this.$t('duels.lost');
            }
        },
        methods: {
            updateStatus() {
                this.setFinished(this.duel)
            },
            ...mapActions(Modules.DUEL_ACTIONS, {
                setFinished: Actions.Duels.SET_FINISHED
            })
        }
    }
</script>

<style scoped>

    * /deep/ .v-time-ago__text {
        font-weight: normal;
    }
    * /deep/ .active-text,
    .time-left {
        color: #fd7a4c;
    }

</style>
