import EventBus from "@/events/EventBus";
import Events from "@/events/Events";

let isMobileDevice;

export default {
    init(isMobile) {

        isMobileDevice = isMobile;
    },

    /**
     * Creates a share with the specified title and url. Tags are optional.
     * Will use the native sharing functionality on mobile, if supported.
     * @param {string} title
     * @param {string} url
     * @param {Array} tags
     */
    share(title, url, tags) {

        if(isMobileDevice && navigator.share) {
            navigator.share({ title, url }).catch((err) => {
                console.error("Share failed:", err.message);
            });
        } else if(localStorage.getItem('is-mobile-app')) {
            EventBus.$emit(Events.AddMobileAppEvent, {
                event: 'share', data: {title: title, url: url, tags: tags}
            });
        } else {
            EventBus.$emit(Events.ShareClicked, { title, tags, url });
        }
    }
}
