<template>
    <Layout_Master>
        <main class="content">
            <Header_Large class="text-center w-100 mb-2">{{ headerText }}</Header_Large>
            <slot/>
        </main>
    </Layout_Master>
</template>

<script>
import Layout_Master from "@/ui/layouts/Layout_Master";
import Header_Large from "@/ui/components/common/Header_Large";
export default {
    name: "Layout_UserFollowerView",
    components: { Header_Large, Layout_Master },
    props: { headerText: String }
}
</script>

<style scoped>

.content {
    padding: 62px 5px 0;
    min-height: 100vh;
}
</style>
