<template>
    <Layout_UserDetailsSection :info-text="$t('users.hint-author-stats')">

        <UserDetails_Header_Small>
            {{ $t("users.author-stats") }}
        </UserDetails_Header_Small>

        <div class="stats-container">
            <div class="stat-column">

                <UserDetails_AuthorStat :icon="$icons.Post.Like">
                    {{ $t('users.likes') }}: {{ user.likesCount }}
                </UserDetails_AuthorStat>

                <UserDetails_AuthorStat :icon="$icons.Post.Dislike">
                    {{ $t('users.dislikes') }}: {{ user.dislikesCount }}
                </UserDetails_AuthorStat>

                <UserDetails_AuthorStat :icon="$icons.Post.Share">
                    {{ $t('users.shares') }}: {{ user.sharesCount }}
                </UserDetails_AuthorStat>
            </div>
            <div class="stat-column pl-3">

                <UserDetails_AuthorStat :icon="$icons.Post.Favourite">
                    {{ $t('users.favorites') }}: {{ user.favoritesCount }}
                </UserDetails_AuthorStat>

                <UserDetails_AuthorStat :icon="$icons.Post.Comments">
                    {{ $t('users.comments') }}: {{ user.commentsCount }}
                </UserDetails_AuthorStat>
            </div>
        </div>
    </Layout_UserDetailsSection>
</template>

<script>
    import User from "@/server/structures/User";
    import UserDetails_AuthorStat from "@/ui/components/users/details/UserDetails_AuthorStat";
    import Layout_UserDetailsSection from "@/ui/layouts/Layout_UserDetailsSection";
    import UserDetails_Header_Small from "@/ui/components/users/details/UserDetails_Header_Small";

    export default {
        name: "UserDetails_Section_AuthorStats",
        components: { UserDetails_Header_Small, Layout_UserDetailsSection, UserDetails_AuthorStat },
        props : {
            user: User
        }
    }
</script>

<style scoped>

    .stats-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: stretch;
    }
    .stats-container .stat-column {
        flex-grow: 2;
    }
</style>
