<!-- Modal that shows up when a users wants to report a post. -->
<template>
    <b-modal ref="report-modal" id="modal-report-post" :title="$t('posts.report')">
        <p class="my-4">{{ $t("posts.report-reason") }}</p>
        <Form_TextArea
                ref="text-area"
                class="gray"
                :rows="5"
                :max-rows="5"
                v-model="text"
        />
        <template #modal-footer>
            <Button_Default @click.native="hide"> {{ $t("general.cancel") }} </Button_Default>
            <Button_Gradient @click.native="submit"> {{ $t("general.submit") }} </Button_Gradient>
        </template>
    </b-modal>
</template>

<script>
    import Form_TextArea from "@/ui/components/common/Form_TextArea";
    import Button_Gradient from "@/ui/components/common/Button_Gradient";
    import Button_Default from "@/ui/components/common/Button_Default";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import {mapActions} from "vuex";
    import EventBus from "@/events/EventBus";
    import Events from "@/events/Events";
    export default {
        name: "Post_Modal_Report",
        components: { Button_Default, Button_Gradient, Form_TextArea },
        data() {
            return {
                postId : null,
                text : null
            };
        },
        mounted() {
            EventBus.$on(Events.ReportClicked, this.show);
        },
        beforeDestroy() {
            EventBus.$off(Events.ReportClicked, this.show);
        },
        methods: {
            show(postId) {
                this.postId = postId;
                this.text = null;
                this.$refs['report-modal'].show();
                setTimeout(() => this.$refs['text-area'].focus(), 100);
            },
            submit() {
                this._report({ id : this.postId, text : this.text });
                this.hide();
            },
            hide() {
                this.$refs['report-modal'].hide();
            },
            ...mapActions(Modules.POSTS, {
                _report : Actions.Posts.REPORT
            })
        }
    }
</script>
