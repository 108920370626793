import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_GetDuel from "@/server/responses/ApiResponse_GetDuel";
import ApiResponse_GetDuels from "@/server/responses/ApiResponse_GetDuels";
import ApiResponse_CreateDuel from "@/server/responses/ApiResponse_CreateDuel";
import ApiResponse from "@/server/responses/ApiResponse";
import ApiResponse_ShareDuel from "@/server/responses/ApiResponse_ShareDuel";
import ApiResponse_GetDuelStats from "@/server/responses/ApiResponse_GetDuelStats";
import ApiResponse_GetDuelResults from "@/server/responses/ApiResponse_GetDuelResults";

export default class ApiProxy_Duels extends ApiProxy {

    /** Returns a duel by its unique code.
     * @param {string} code
     * @returns {Promise<ApiResponse_GetDuel>}
     */
    async getByCode(code) {
        return new ApiResponse_GetDuel(await this.get(`/${code}/by-code`));
    }

    /**
     * @param {string} shareCode
     * @returns {Promise<ApiResponse_GetDuel>}
     */
    async getByShareCode(shareCode) {
        return new ApiResponse_GetDuel(await this.get(`/${shareCode}/by-share`));
    }

    /**
     * @param {number} page
     * @param {Tag[]} tags
     * @returns {Promise<ApiResponse_GetDuels>}
     */
    async getFeed(page, tags) {
        return new ApiResponse_GetDuels(await this.get('/', {
            params : {
                tags : tags.map((tag) => tag.name),
                page : page
            }
        }));
    }

    async getUserDuels(page, userId, status) {
        return new ApiResponse_GetDuels(await this.get('/', {
            params : {
                user_id: userId,
                page : page,
                status : status
            }
        }));
    }

    /**
     * @param {Number} duelId
     * @returns {Promise<ApiResponse_GetDuelStats>}
     */
    async getStats(duelId) {

        return new ApiResponse_GetDuelStats(await this.get(`/${duelId}/stats`));
    }

    /**
     *
     * @param duelId
     * @returns {Promise<ApiResponse_GetDuelResults>}
     */
    async getResults(duelId) {

        return new ApiResponse_GetDuelResults(await this.get(`/${duelId}/results`));
    }

    /**
     * @param {APostCreationData} createPostData
     * @returns {Promise<ApiResponse_CreateDuel>}
     */
    async createDuel(createPostData) {

        const rawResponse = await this.post('/', createPostData.requestParams, createPostData.requestHeaders);

        return new ApiResponse_CreateDuel(rawResponse);
    }

    /** Creates a post in response to a duel.
     * @param {string} duelId
     * @param {APostCreationData} createPostData
     */
    async respondToDuel(duelId, createPostData) {

        const rawResponse = await this.post(`/${duelId}/answer`, createPostData.requestParams, createPostData.requestHeaders);

        return new ApiResponse_GetDuel(rawResponse);
    }

    /**
     * @param {number} duelId
     * @returns {Promise<ApiResponse_ShareDuel>}
     */
    async share(duelId) {

        return new ApiResponse_ShareDuel(await this.post(`/${duelId}/share`));
    }

    /**
     * @param {number} duelId
     * @param {string} type Determines who we are voting for - challenger|challenged
     * @returns {Promise<ApiResponse>}
     */
    async vote(duelId, type) {

        return new ApiResponse(await this.put(`/${duelId}/vote/${type}`));
    }
}
