<template>
    <p class="common-text-default w-100"><slot/></p>
</template>

<script>
export default {
    name: "Text_Default"
}
</script>

<style>

    .common-text-default {
        font-size: 1em;
        line-height: 1.5;
        white-space: pre-wrap;
    }
</style>
