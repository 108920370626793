<template>
    <div class="post-content">
        <Post_Section_Text v-if="post.isText" :post="post"/>
        <Post_Section_Image v-else :image-url="post.imageUrl"/>
    </div>
</template>

<script>
import Post_Section_Text from "@/ui/components/feed/post/Post_Section_Text";
import Post_Section_Image from "@/ui/components/feed/post/Post_Section_Image";
import Post from "@/server/structures/Post";

export default {
name: "Post_Section_Content",
    components: { Post_Section_Image, Post_Section_Text },
    props: { post: Post }
}
</script>

<style>
    .duel-post .post-content {
        border-left:2px solid #FF6255;
    }
    .duel-post.challenged .post-content {
        border-left-color: #F9B233;
    }
    .duel-post .post-content .post-content-text,
    .duel-post .post-content .post-image {
        margin-left: 0.2rem;
    }
</style>