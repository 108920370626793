<template>
    <div class="user-details-text-small">
        <span><slot></slot></span>
    </div>
</template>

<script>
    export default {
        name: "UserDetails_Text_Small"
    }
</script>

<style>

    .user-details-text-small {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #595959;
    }

    .user-details-text-small span {
        font-size: 10pt;
    }
</style>
