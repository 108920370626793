
/** Wrapper class for Tag objects returned from the server. */
export default class Tag {

    _data;

    get name() { return this._data.text; }

    get count() { return this._data.posts_count; }

    constructor(data) {
        this._data = data;
        this.equals = this.equals.bind(this);
    }

    equals(otherTag) {

        if(!(otherTag instanceof Tag)) return false;

        if(this === otherTag) return true;

        return this.name === otherTag.name;
    }
}
