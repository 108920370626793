<script>
import FormStatus from "@/store/types/FormStatus";

export default {
    name: "Mixin_Form",
    data() {
        return {
            status : FormStatus.Ready
        }
    },
    computed: {
        statusIsReady() {
            return this.status === FormStatus.Ready;
        },
        statusIsLoading() {
            return this.status === FormStatus.Loading;
        },
        statusIsSuccess() {
            return this.status === FormStatus.Success;
        },
        statusIsError() {
            return this.status === FormStatus.Error;
        }
    },
    methods: {
        setStatus(newStatus) {
            this.status = newStatus;
        }
    }
}
</script>
