import ModuleTemplate_Resource from "@/store/templates/ModuleTemplate_Resource";

/**
 * @param {string} postId
 * @param {ApiProxy_Posts} postsApi
 */
export default function (postId, postsApi) {

    return ModuleTemplate_Resource(async () => {

        const result = await postsApi.getMap(postId);

        if(result.success) {
            return { success : true, data : result.map }
        }
        else {
            return { success : false, errors : result.errors }
        }
    });
}
