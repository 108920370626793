<template>
    <Panel_White>
        <template v-if="duel">
            <Layout_Info>

                <template v-slot:title>
                    <DuelChallenge_Section_Title :is-challenger="iAmTheChallenger" />
                </template>

                <template v-slot:content>

                    <Post_Section_Header class="user-section" :user="duel.challenger" :timestamp="duel.challengerPost.createdAt" />

                    <DuelChallenge_Section_Explanation :is-challenger="iAmTheChallenger"/>

                    <Duels_Challenge_Post :post="duel.challengerPost"/>

                    <DuelChallenge_Section_Actions :duel="duel" :is-challenger="iAmTheChallenger"/>
                </template>
            </Layout_Info>
        </template>
    </Panel_White>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import Getters from "@/store/Getters";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import Layout_Info from "@/ui/layouts/Layout_Info";
    import Panel_White from "@/ui/components/common/Panel_White";
    import Post_Section_Header from "@/ui/components/feed/post/Post_Section_Header";
    import Duels_Challenge_Post from "@/ui/components/duels/Duels_Challenge_Post";
    import DuelChallenge_Section_Title from "@/ui/components/duel_challenge/DuelChallenge_Section_Title";
    import DuelChallenge_Section_Explanation from "@/ui/components/duel_challenge/DuelChallenge_Section_Explanation";
    import DuelChallenge_Section_Actions from "@/ui/components/duel_challenge/DuelChallenge_Section_Actions";
    import RouterUtils from "@/routes/RouterUtils";

    export default {
        name: "View_Duels_Challenge",
        components: { DuelChallenge_Section_Actions, DuelChallenge_Section_Title, Layout_Info, Post_Section_Header, DuelChallenge_Section_Explanation, Duels_Challenge_Post, Panel_White },
        props: {
            code : String
        },
        data() {
            return {
                duel : null
            }
        },
        computed: {
            iAmTheChallenger() {
                return this.isMe(this.duel.challenger.slug);
            },
            ...mapGetters(Modules.USERS, {
                isMe : Getters.Users.IS_ME,
                user : Getters.Users.GET_ME
            }),
        },
        async mounted() {
            const result = await this.getDuelByCode(this.code);
            if(!result.success) {
                return RouterUtils.goToDuelFeed()
            }

            this.duel = result.duel;

            if(!result.duel.isWaiting) {
                return RouterUtils.goToDuelFeed(this.code)
            }
        },
        methods: {
            ...mapActions(Modules.DUEL_ACTIONS, {
                getDuelByCode : Actions.Duels.GET_BY_CODE
            })
        }

    }
</script>

<style scoped>

    .user-section {
        padding-bottom: 0;
    }
</style>
