<template>
    <Users_Follower class="loading-blur" :user="fakeUser"/>
</template>

<script>
import Users_Follower from "@/ui/components/users/Users_Follower";
import User from "@/server/structures/User";

const FakeUser = new User({
    id: -1,
    slug: "some-person",
    username: "Some Username",
});

export default {
    name: "Users_Follower_Loading",
    components: { Users_Follower },
    data() {
        return { fakeUser: FakeUser }
    }
}

</script>
