
export default {
    AUTH : "auth",
    USERS : "profile",
    TAGS : "tags",
    POSTS : "posts",
    COMMENTS : "comments",
    LOCALIZATION : "localization",
    LEADERBOARDS : "leaderboards",
    FAVORITES : "favorites",
    DUEL_ACTIONS : "duels",
    BADGES : "badges",
    RANKINGS : "rankings",
    NOTIFICATIONS : "notifications"
}
