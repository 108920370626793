import ResourceStatus from "@/store/types/ResourceStatus";

export default function (loadCallback) {

    return {
        namespaced: true,
        state: () => ({
            status : ResourceStatus.Null,
            data : null,
            errors : null
        }),
        getters: {
            getStatus(state) {
                return state.status;
            },
            getErrors(state) {
                return state.errors;
            },
            getData(state) {
                return state.data;
            }
        },
        actions: {
            async load({state}) {

                state.status = ResourceStatus.Loading;

                state.data = null;

                state.errors = null;

                const result = await loadCallback();

                if(result.success) {

                    state.status = ResourceStatus.Ready;

                    state.data = result.data;
                }
                else {

                    state.status = ResourceStatus.Error;

                    state.errors = result.errors;
                }
            }
        }
    }
}
