<template>
    <div>
        <UserSettings_AvatarUpload :current-avatar="user.avatarUrl" v-model="avatarImageFile"/>

        <Form_Label class="mt-3" for="user-email">{{ $t("users.email") }}</Form_Label>
        <Form_InputField readonly id="user-email" class="gray" v-model="email"/>

        <Form_Label class="mt-3" for="user-name">{{ $t("users.name") }}</Form_Label>
        <Form_InputField id="user-name" class="gray" v-model="name"/>

        <Form_Label class="mt-3" for="user-description">{{ $t("users.description") }}</Form_Label>
        <Form_TextArea id="user-description" class="gray" v-model="description"/>

        <Button_Gradient class="mt-4 mx-auto w-100" :disabled="!dataWasModified" @click.native="save">
            {{ $t("general.save") }}
        </Button_Gradient>
    </div>
</template>

<script>
    import User from "@/server/structures/User";
    import Form_InputField from "@/ui/components/common/Form_InputField";
    import Form_TextArea from "@/ui/components/common/Form_TextArea";
    import Form_Label from "@/ui/components/common/Form_Label";
    import UserSettings_AvatarUpload from "@/ui/components/users/user_settings/UserSettings_AvatarUpload";
    import Button_Gradient from "@/ui/components/common/Button_Gradient";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import {mapActions} from "vuex";
    import RouterUtils from "@/routes/RouterUtils";

    export default {
        name: "UserSettings_ProfileEdit",
        components: { Button_Gradient, UserSettings_AvatarUpload, Form_Label, Form_TextArea, Form_InputField },
        props: { user: User },
        data() {
            return {
                name : null,
                description : null,
                avatarImageFile : null,
                email: null
            }
        },
        mounted() {
            this.name = this.user.name;
            this.description = this.user.description;
            this.email = this.user.email;
        },
        computed : {
            dataWasModified() {
                return this.imageWasModified || this.textWasModified;
            },
            imageWasModified() {
                return this.avatarImageFile !== null;
            },
            textWasModified() {
                return this.name !== this.user.name || this.description !== this.user.description;
            },
        },
        methods : {
            save() {
                if(this.imageWasModified) {
                    this.updateAvatar(this.avatarImageFile);
                }
                if(this.textWasModified) {
                    this.updateProfileTexts({
                        name : this.name,
                        description : this.description
                    });
                }
                RouterUtils.goToUser(this.user.slug);
            },
            ...mapActions(Modules.USERS, {
                updateAvatar : Actions.Users.UPDATE_AVATAR,
                updateProfileTexts : Actions.Users.UPDATE_PROFILE_TEXTS
            })
        }
    }
</script>
