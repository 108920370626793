<!-- The popup that appears to ask confirmation from the user regarding the privacy policy. -->

<template>
    <b-modal ref="modal" @hide="onHide">
        <template #modal-header>
            <h5>{{ $t("privacyPolicy.popup-text-link") }}</h5>
        </template>

        <template v-if="!showingMoreInfo">
            <PrivacyModal_Text>{{ $t("privacyPolicy.popup-text-1") }}</PrivacyModal_Text>
            <PrivacyModal_Text>{{ $t("privacyPolicy.popup-text-2") }}</PrivacyModal_Text>
            <router-link to="/privacy" target="_blank">{{ $t("privacyPolicy.popup-text-link") }}</router-link>
        </template>
        <template v-else>
            <PrivacyModal_Text>{{ $t("privacyPolicy.popup-more-info-1-1") }}</PrivacyModal_Text>
            <PrivacyModal_Text>{{ $t("privacyPolicy.popup-more-info-1-2") }}</PrivacyModal_Text>
            <PrivacyModal_Header>{{ $t("privacyPolicy.popup-more-info-2") }}</PrivacyModal_Header>
            <PrivacyModal_Text>{{ $t("privacyPolicy.popup-more-info-2-1") }}</PrivacyModal_Text>
            <PrivacyModal_Header>{{ $t("privacyPolicy.popup-more-info-3") }}</PrivacyModal_Header>
            <PrivacyModal_Text>{{ $t("privacyPolicy.popup-more-info-3-1") }}</PrivacyModal_Text>
            <PrivacyModal_Header>{{ $t("privacyPolicy.popup-more-info-4") }}</PrivacyModal_Header>
            <PrivacyModal_Text>{{ $t("privacyPolicy.popup-more-info-4-1") }}</PrivacyModal_Text>
            <PrivacyModal_Text>{{ $t("privacyPolicy.popup-more-info-4-2") }}</PrivacyModal_Text>
            <PrivacyModal_Text>{{ $t("privacyPolicy.popup-more-info-4-3") }}</PrivacyModal_Text>
            <PrivacyModal_Text>
                {{ $t("privacyPolicy.popup-more-info-4-4") }}
                <router-link to="/privacy" target="_blank">{{ $t("privacyPolicy.popup-text-link") }}</router-link>
            </PrivacyModal_Text>
        </template>

        <template #modal-footer>
            <Button_Default v-if="!showingMoreInfo" @click.native="showMoreInfo">{{ $t("privacyPolicy.popup-more-info") }}</Button_Default>
            <Button_Default v-else @click.native="hideMoreInfo">{{ $t("privacyPolicy.popup-hide-info") }}</Button_Default>
            <Button_Gradient @click.native="accept">{{ $t("privacyPolicy.popup-accept") }}</Button_Gradient>
        </template>
    </b-modal>
</template>

<script>

import Button_Gradient from "@/ui/components/common/Button_Gradient";
import Storage from "@/utils/local_storage/Storage";
import Button_Default from "@/ui/components/common/Button_Default";
import PrivacyModal_Text from "@/ui/components/privacy_modal/PrivacyModal_Text";
import PrivacyModal_Header from "@/ui/components/privacy_modal/PrivacyModal_Header";

export default {
    name: "PrivacyModal",
    components: { PrivacyModal_Header, PrivacyModal_Text, Button_Default, Button_Gradient },
    data() {
        return {
            showingMoreInfo: false
        }
    },
    mounted() {

        if (this.$route.meta.hidePrivacyPolicy) { return; }

        if (Storage.PrivacyPolicyAccepted.value) { return; }

        this.show();
    },
    methods: {
        show() {

            this.$refs["modal"].show();
        },
        hide() {

            this.$refs["modal"].hide();
        },
        showMoreInfo() {

            this.showingMoreInfo = true;
        },
        hideMoreInfo() {

            this.showingMoreInfo = false;
        },
        accept() {

            Storage.PrivacyPolicyAccepted.value = true;

            this.hide();
        },
        onHide(e) {

            if (Storage.PrivacyPolicyAccepted.value) return;

            e.preventDefault();
        }
    }
}
</script>
