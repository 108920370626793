export default class Badge {

    _data;

    /** @returns {string} */
    get name() {
        return this._data.name;
    }

    /** @returns {string} */
    get description() {
        return this._data.description;
    }

    /** @returns {string} */
    get imageUrl() {
        return this._data.image.url;
    }

    /**
     * Indicates if the current user has this badge.
     * @returns {boolean}
     */
    get obtained() {
        return this._data.given;
    }

    constructor(data) {
        this._data = data;
    }
}
