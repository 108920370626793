<template>
    <p class="info-answer-text">
        <slot></slot>
    </p>
</template>

<script>

    export default {
        name: "Info_Text"
    }
</script>

<style>
    .info-answer-text {
        color: black;
        font-size: 1.1rem;
        line-height: 1.4;
        margin-top: 0.3rem;
    }
</style>
