import GeojsonRegion from "@/utils/geojson/GeojsonRegion";

/**
 * Manages loading and caching of the geojson for the different regions of the map.
 */
export default {

    World : new GeojsonRegion("https://joketrip-images.s3.eu-central-1.amazonaws.com/geo-resources/countries.json"),

    States : new GeojsonRegion("https://joketrip-images.s3.eu-central-1.amazonaws.com/geo-resources/us-states.json"),

    preload() {
        this.World.load();
        this.States.load();
    },
}
