<!-- A component that visualizes timestamps via time-ago. Handles locale updating. -->

<template>
    <time-ago ref="time-indicator" :datetime="datetime" :refresh="refresh" :long="long" :locale="language"/>
</template>

<script>

import TimeAgo from "vue2-timeago";
import {mapGetters} from "vuex";
import Modules from "@/store/Modules";
import Getters from "@/store/Getters";

export default {
    name: "TimeIndicator",
    components: { TimeAgo },
    props: {
        datetime: Date,
        refresh: { type: [Number, Boolean, null], default: false },
        long: { type: Boolean, default: false }
    },
    watch: {
        language() {
            this.$forceUpdate();
        }
    },
    computed: {
        ...mapGetters(Modules.LOCALIZATION, { language : Getters.Localization.GET_LANGUAGE })
    }
}
</script>
