<!-- The generic component used to visualize a duel. -->

<template>
    <Panel_White class="duel-component py-2 px-3" :class="{'highlighted-duel': highlighted}">

        <Duel_Section_Header :duel="duel" :isClosed="isClosed" />

        <Duel_Post :headerText="challengerText" :isChallenger="true" :post="duel.challengerPost" :isClosed="isClosed" :show-actions="showPostActions"/>

        <Duel_Button_Open v-if="isClosed" :duel="duel" v-on:open="open"/>

        <Duel_Post :headerText="challengedText" :post="duel.challengedPost" :isClosed="isClosed" :show-actions="showPostActions"/>

        <b-collapse :visible="!isClosed">
            <div class="bottom-button-group my-1">

                <Duel_Button_Close v-if="!isClosed" v-on:close="close" />

                <Button_Share v-if="!isClosed" @click.native="onShareClick()">{{ $t("duels.share") }}</Button_Share>
            </div>
        </b-collapse>
    </Panel_White>
</template>

<script>
    import Duel from "@/server/structures/Duel";
    import Panel_White from "@/ui/components/common/Panel_White";
    import Duel_Section_Header from "@/ui/components/duels/Duel_Section_Header";
    import Duel_Post from "@/ui/components/duels/Duel_Post";
    import Duel_Button_Open from "@/ui/components/duels/Duel_Button_Open";
    import Duel_Button_Close from "@/ui/components/duels/Duel_Button_Close";
    import DuelVoteType from "@/server/types/DuelVoteType";
    import Button_Share from "@/ui/components/common/Button_Share";
    import { mapActions } from 'vuex';
    import Actions from "@/store/Actions";
    import Modules from "@/store/Modules";
    import ShareUtils from "@/utils/ShareUtils";

    export default {
        name: "Duel_Component",
        components: { Duel_Post, Duel_Section_Header, Panel_White, Duel_Button_Open, Duel_Button_Close, Button_Share },
        props: {
            duel: Duel,
            highlighted: Boolean
        },
        data: function() {
            return {
                isClosed: true,
                challengerPost: this.duel.challengerPost,
                challengedPost: this.duel.challengedPost,
            }
        },
        computed: {
            challengerText() {
                return this.duel.votedFor === DuelVoteType.Challenger ? this.$t('duels.your-vote') : this.$t('duels.challenger');
            },
            challengedText() {
                return this.duel.votedFor === DuelVoteType.Challenged ? this.$t('duels.your-vote') : this.$t('duels.challenged');
            },
            showPostActions() {
                return (this.duel.voted || this.duel.isParticipant || this.duel.isFinished);
            }
        },
        methods: {
            open() {
                this.isClosed = false;
                this.$emit("open");
            },
            close() {
                this.isClosed = true;
            },
            async onShareClick() {
                const response = await this.getShareCode(this.duel.id);

                if(response.success) {

                    ShareUtils.share(this.$t("duels.share-title"), response.url);
                }
            },
            ...mapActions(Modules.DUEL_ACTIONS, {
                getShareCode: Actions.Duels.SHARE
            })
        }
    }
</script>

<style>
    .duel-component {
        margin-bottom: 0.6em;
    }
    .duel-component.highlighted-duel {
        box-shadow: 0 2px 23px 2px grey;
    }
    .duel-component .joke-card.closed .post-closed-overlay {
        top: 0;
        height: 100%;
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
    }
    .duel-component .bottom-button-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }
</style>
