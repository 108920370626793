<template>
    <ul class="info-list">
        <slot></slot>
    </ul>
</template>

<script>
    export default {
        name: "Info_List"
    }
</script>
