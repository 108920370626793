<template>
    <div class="master-layout background-color-gray">

        <PrivacyModal/>

        <Auth_Screens/>

        <TopNav>
            <slot name="sticky-header"></slot>
        </TopNav>

        <Map_Sidebar/>

        <div class="main-container" :class="$mq">
            <slot></slot>
        </div>
    </div>
</template>

<script>

    import TopNav from "@/ui/components/top_nav/TopNav";
    import Auth_Screens from "@/ui/components/auth/Auth_Screens";
    import PrivacyModal from "@/ui/components/privacy_modal/PrivacyModal";

    import Map_Sidebar from "@/ui/components/map/Map_Sidebar";

    import GeojsonMap from '@/utils/geojson/GeojsonMap';

    export default {
        name: "Layout_Master",
        components : { PrivacyModal, Auth_Screens, TopNav, Map_Sidebar },
        async mounted() {
            GeojsonMap.preload();
        }
    }
</script>

<style scoped>

    .master-layout {
    }

    /* Main Container */
    .main-container {
    }

    .main-container.desktop {
        margin: auto;
        min-width: 500px;
        max-width: 800px;
    }

    @media screen and (max-width:575px){
        .main-container {
            padding-top: 0;
        }
    }

</style>
