<template>
    <Layout_LazyLoadFeed
            :load-margin-px="400"
            :last-page-reached="lastPageReached"
            :enable-loading="resultsAreReady"
            v-on:loadMore="loadMorePosts"
    >
        <Post_Component v-if="sharedPost" key="shared"
                      :previewMode="true" :post="sharedPost"
        />

        <Post_Component v-for="post in filteredPosts"
                v-bind:key="post.id"
                :post="post"
        />

        <template v-if="isLoading">
            <Post_List_Loading :count="10"/>
        </template>

        <Text_NoResults v-if="noResultsFound">
            {{ this.noResultsMessage }}
        </Text_NoResults>
        <Text_NoResults v-else-if="anErrorOccurred">
            {{ this.errorMessage }}
        </Text_NoResults>
    </Layout_LazyLoadFeed>
</template>

<script>
    import Post_Component from "@/ui/components/feed/post/Post_Component";
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
    import Modules from "@/store/Modules";
    import Actions from "@/store/Actions";
    import Mutations from "@/store/Mutations";
    import Text_NoResults from "@/ui/components/common/Text_NoResults";
    import WindowEx from "@/utils/WindowEx";
    import EventBus from "@/events/EventBus";
    import Events from "@/events/Events";
    import Layout_LazyLoadFeed from "@/ui/layouts/Layout_LazyLoadFeed";
    import Getters from "@/store/Getters";
    import FeedTypes from "@/store/types/FeedTypes";
    import CollectionStatus from "@/store/types/CollectionStatus";
    import Post_List_Loading from "@/ui/components/feed/post/Post_List_Loading";

    /** The offset by which we scroll the window to focus on a shared post. In pixels. */
    const ShareYOffset = 232;

    export default {
        name: "Post_Feed",
        components: { Post_List_Loading, Layout_LazyLoadFeed, Text_NoResults, Post_Component },
        props: {
            contentType: String,
            filter: String
        },
        data() {
          return {
            sharedPost: null
          }
        },
        mounted() {
            this._reload();
            EventBus.$on(Events.LoginSuccessful, this._reload);
            this.sharedPostCheck();
        },
        beforeDestroy () {
            EventBus.$off(Events.LoginSuccessful, this._reload);
        },
        watch: {
            selectedTags() { this._reload(); },
            contentType() { this._reload(); },
            filter() { this._reload(); },
            selectedRegion() { this._reload(); },
            '$route.query.s': () => { if(this) { this.sharedPostCheck(); } }
        },
        computed: {
            noResultsFound() {
                return this.resultsAreReady && this.posts && this.posts.length === 0;
            },
            noResultsMessage() {
                return this.filter === FeedTypes.NETWORK ? this.$t("posts.no-posts-found-networking") : this.$t("posts.no-posts-found");
            },
            errorMessage() {
                return this.$t("errors.loading-posts");
            },
            isLoading() {
                return this.statusIs(CollectionStatus.Loading);
            },
            anErrorOccurred() {
                return this.statusIs(CollectionStatus.Error);
            },
            resultsAreReady() {
                return this.statusIs(CollectionStatus.Ready);
            },
            filteredPosts() {
                if(!this.sharedPost) { return this.posts; }
                return this.posts.filter((post) => {
                    return post.id !== this.sharedPost.id;
                });
            },
            ...mapState(Modules.POSTS, { posts: 'posts', lastPageReached: 'lastPageReached' }),
            ...mapState(Modules.TAGS, { selectedTags : 'selected' }),
            ...mapGetters(Modules.POSTS, { statusIs : Getters.Posts.STATUS_IS }),
            ...mapGetters(Modules.LOCALIZATION, { selectedRegion : Getters.Localization.GET_REGION })
        },
        methods: {
            _reload() {
                this.sharedPost = null;

                WindowEx.scrollToTop();

                this.setFilters({
                    contentType : this.contentType,
                    feedType : this.filter
                });

                this.reloadPosts();
            },
            sharedPostCheck(){
                if(this.$route.query.s){
                    this.loadPostFromShareCode(this.$route.query.s);
                }
                else{
                    this.sharedPost = null;
                }
            },
            async loadPostFromShareCode(shareCode){

                let postResult = await this.getByShareCode(shareCode);
                this.sharedPost = postResult.post;

                WindowEx.smoothScrollY(ShareYOffset);
            },
            ...mapMutations(Modules.POSTS, {
                setFilters: Mutations.Posts.SET_FILTERS
            }),
            ...mapActions(Modules.POSTS, {
                reloadPosts: Actions.Posts.RELOAD,
                loadMorePosts: Actions.Posts.LOAD_MORE,
                getByShareCode: Actions.Posts.GET_BY_SHARE_CODE,
            })
        }
    }
</script>
