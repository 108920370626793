import ApiResponse from "@/server/responses/ApiResponse";
import LeaderboardEntry from "@/server/structures/LeaderboardEntry";


export default class ApiResponse_GetLeaderboard extends ApiResponse {

    get entries() {
        return this._rawResponse.data.map((rawEntry) => {
            return new LeaderboardEntry(rawEntry);
        })
    }
}
