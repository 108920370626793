import Actions from "@/store/Actions";
import Mutations from "@/store/Mutations";
import Getters from "@/store/Getters";
import ArrayEx from "@/utils/ArrayEx";
import Vue from 'vue'
import PostComment from "@/server/structures/PostComment";
import Modules from "@/store/Modules";

const CommentsPerPage = 5;

export default function (commentsApi) {

    return {
        namespaced: true,
        state: () => ({
        }),
        mutations : {
            [Mutations.Comments._BEGIN_LOAD_MORE](state, postId) {
                Vue.set(state, postId, {
                    ...state[postId],
                    loading : true,
                    currentPage : state[postId].currentPage + 1
                });
            },
            [Mutations.Comments._END_LOAD_MORE](state, {postId, comments, lastPage}) {
                Vue.set(state, postId, {
                    ...state[postId],
                    loading: false,
                    lastPage: lastPage,
                    comments: ArrayEx.concatUnique(state[postId].comments, comments)
                });
            },
            [Mutations.Comments._RESET_FOR_POST](state, postId) {
                Vue.set(state, postId, { currentPage : 0, comments : [], loading: false });
            },
            [Mutations.Comments._ADD_COMMENT](state, {postId, comment}) {
                state[postId].comments.push(comment);
            },
            [Mutations.Comments._REMOVE_COMMENT](state, {postId, comment}) {
                state[postId].comments = ArrayEx.filterValue(state[postId].comments, comment);
            }
        },
        getters : {
            [Getters.Comments.COMMENTS_PER_PAGE] : () => {
                return CommentsPerPage;
            },
            [Getters.Comments.GET_FOR_POST] : (state) => (postId) => {
                return state[postId].comments;
            },
            [Getters.Comments.HAS_MORE_COMMENTS] : (state) => (postId) => {
                return state[postId].currentPage < state[postId].lastPage;
            },
            [Getters.Comments.COMMENTS_ARE_LOADING] : (state) => (postId) => {
                return state[postId].loading;
            }
        },
        actions : {
            async [Actions.Comments.RELOAD]({commit, dispatch}, postId) {

                commit(Mutations.Comments._RESET_FOR_POST, postId);

                dispatch(Actions.Comments.LOAD_MORE, postId);
            },
            async [Actions.Comments.LOAD_MORE]({state, commit}, postId) {

                if(!state[postId]) { commit(Mutations.Comments._RESET_FOR_POST, postId); }

                commit(Mutations.Comments._BEGIN_LOAD_MORE, postId);

                const response = await commentsApi.getCommentsForPost(postId, CommentsPerPage, state[postId].currentPage);

                commit(Mutations.Comments._END_LOAD_MORE, {
                    postId : postId,
                    comments: response.comments,
                    lastPage: response.lastPage
                });
            },
            async [Actions.Comments.POST]({rootGetters, commit}, {postId, comment}) {

                const temporaryComment = PostComment.createTemporary(
                    rootGetters[`${Modules.USERS}/${Getters.Users.GET_ME}`].name,
                    rootGetters[`${Modules.USERS}/${Getters.Users.GET_ME}`].avatarUrl,
                    comment);

                commit(Mutations.Comments._ADD_COMMENT, { postId, comment: temporaryComment });

                const response = await commentsApi.postComment(postId, comment);

                commit(Mutations.Comments._REMOVE_COMMENT, { postId, comment: temporaryComment });

                if(response.success) {

                    commit(Mutations.Comments._ADD_COMMENT, { postId, comment: response.comment });

                    incrementPostComments(commit, postId);
                }
            }
        }
    };

    function incrementPostComments(commit, postId) {
        commit(`${Modules.POSTS}/${Mutations.Posts.INCREMENT_COMMENTS}`, postId, { root: true });
    }
}
