<template>
    <div>
        <div class="avatar-wrapper" @click="openUpload">
            <Avatar_Large class="avatar-current" :src="previewedAvatar" />
            <img class="avatar-overlay" :src="$icons.PostCreation.UploadImage" alt="avatar-upload-overlay"/>
        </div>

        <Input_ImageUpload ref="imageUpload" v-on:file-selected="onImageSelected"/>
    </div>
</template>

<script>
    import Avatar_Large from "@/ui/components/common/Avatar_Large";
    import Input_ImageUpload from "@/ui/components/common/Input_ImageUpload";
    import ImageFile from "@/utils/ImageFile";
    export default {
        name: "UserSettings_AvatarUpload",
        components: { Input_ImageUpload, Avatar_Large },
        props: { currentAvatar : String, value : ImageFile },
        computed : {
            previewedAvatar() {
                return this.value ? this.value.base64 : this.currentAvatar;
            }
        },
        methods : {
            openUpload() {
                this.$refs["imageUpload"].open();
            },
            onImageSelected(imageFile) {
                this.$emit('input', imageFile);
            }
        }
    }
</script>

<style scoped>

    .avatar-wrapper {
        margin-left: auto;
        margin-right: auto;
        width: min-content;
        height: min-content;
        position: relative;
    }

    .avatar-wrapper .avatar-current {
        filter: brightness(0.5);
    }

    .avatar-wrapper .avatar-overlay {
        filter: brightness(10) opacity(0.7);
        width: 3em;
        height: 3em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
</style>
