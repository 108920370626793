import ApiProxy from "@/server/proxies/ApiProxy";
import ApiResponse_Login from "@/server/responses/ApiResponse_Login";

/** Api for authentication via social media providers, such as facebook and google. */
export default class ApiProxy_SocialProvider extends ApiProxy {

    /**
     * Confirms a user login via facebook with the server.
     * @param {string} token
     * @returns {Promise<ApiResponse_Login>}
     */
    async facebookLogin(token) {
        return this._postLogin('facebook', token);
    }

    /**
     * Confirms a user login via google with the server.
     * @param {string} token
     * @returns {Promise<ApiResponse_Login>}
     */
    async googleLogin(token) {
        return this._postLogin('google', token);
    }

    async _postLogin(provider, token) {

        const rawResponse = await this.post(`/${provider}`, { token });

        return new ApiResponse_Login(rawResponse);
    }
}
