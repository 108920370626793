/**
 * Stores and retrieves geojson data for a single region.
 */
export default class GeojsonRegion {

    _url;

    _data;

    constructor(url) {
        this._url = url;
    }

    async get() {

        await this.load();

        return this._data;
    }

    async load() {
        if(this._data) { return; }

        const response = await fetch(this._url);

        this._data = response.json();
    }
}
