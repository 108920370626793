import Random from "@/utils/Random";
import User from "@/server/structures/User";

export default class PostComment {

    _data;

    get id() { return this._data.id; }

    get text() { return this._data.text; }

    get user() { return new User(this._data.user); }

    /** @deprecated Use user.name instead. */
    get userName() { return this._data.user.name; }

    /** @deprecated User user.slug instead */
    get userSlug() { return this._data.user.slug; }

    get avatarUrl() { return this._data.user.avatar.url; }

    static createTemporary(userName, avatarUrl, text) {
        return new PostComment({
            id: Random.range(-10000, -1),
            text: text,
            user: {
                name: userName,
                avatar: { url: avatarUrl }
            }
        });
    }

    constructor(data) {
        this._data = data;
    }

    equals(other) {

        if(!(other instanceof PostComment)) return false;

        if(this === other) return true;

        return this.id === other.id;
    }
}
