<template>
    <h5 class="common-header-medium"><slot/></h5>
</template>

<script>
    export default { name: "Header_Medium" }
</script>

<style>

    .common-header-medium {
        color: #575755;
    }
    .common-header-medium.light {
        color: #808080;
    }
</style>
