<template>
    <div>
        <Help_Section v-if="subcategory.description" :info-text="subcategory.description"/>
        <Header_Medium class="light">{{ subcategory.name }}</Header_Medium>
        <Badges_ArrowScroll :badges="subcategory.badges" scroll-class="gray"/>
    </div>
</template>

<script>
import BadgeSubcategory from "@/server/structures/BadgeSubcategory";
import Badges_ArrowScroll from "@/ui/components/badges/Badges_ArrowScroll";
import Header_Medium from "@/ui/components/common/Header_Medium";
import Help_Section from "@/ui/components/common/help/Help_Section";

export default {
    name: "Badges_Subcategory",
    components: { Help_Section, Header_Medium, Badges_ArrowScroll },
    props: {
        subcategory: BadgeSubcategory
    }
}
</script>

<style scoped>

</style>
