import ProxyObject from "@/server/structures/ProxyObject";
import Vue from "vue"

export default class User extends ProxyObject {

    _data;

    get id() { return this._data.id; }

    get slug() { return this._data.slug; }

    get name() { return this._data.name; }

    get email() { return this._data.email; }

    get avatarUrl() { return this._data.avatar?.url; }

    get fanCount() { return this._data.followers_count; }

    get fanOfCount() { return this._data.followings_count; }

    get description() { return this._data.description; }

    get activityPoints() { return this._data.sum_activities; }

    get activityPercentage() { return this._data.activity_percentage; }

    get likesCount() { return this._data.received_likes_count; }

    get dislikesCount() { return this._data.received_dislikes_count; }

    get sharesCount() { return this._data.received_shares_count; }

    get favoritesCount() { return this._data.received_favorites_count; }

    get commentsCount() { return this._data.received_comments_count; }

    get reactionPoints() { return this._data.sum_influencer_activities; }

    get activityStatus() { return this._data.activity_status; }

    get isFollowing() { return this._data.is_follows === true; }

    get winsCount() { return this._data.wins_count; }

    get lossesCount() { return this._data.losses_count; }

    set isFollowing(value) {
        Vue.set(this._data, "is_follows", value);
        this._data.followers_count += value ? 1 : -1;
    }

    constructor(data) {
        super();

        this._data = data;
    }

    serialize() {
        return JSON.stringify(this._data);
    }

    static deserialize(value) {
        return new User(JSON.parse(value));
    }

    copy() {
        return new User({ ...this._data });
    }

    apply(copy) {
        this._data = copy._data;
    }
}
