<template>
    <b-modal modal-class="badge-info-modal" ref="badge-modal" :hide-header="true" >


      <Header_Medium class="w-100 text-center">{{ badge.name }}</Header_Medium>
        <img class="badge-info-image" :src="badge.imageUrl" alt="badge icon"/>

        <template #modal-footer>
          <Text_Default>{{ badge.description }}</Text_Default>
        </template>

    </b-modal>
</template>

<script>
import Events from "@/events/Events";
import EventBus from "@/events/EventBus";
import Text_Default from "@/ui/components/common/Text_Default";
import Header_Medium from "@/ui/components/common/Header_Medium";

export default {
    name: "Badges_Modal_Info",
    components: { Header_Medium, Text_Default },
    data() {
        return {
            badge: {},
            is_show: true
        }
    },
    mounted() {

      let obj = this;

      window.onpopstate = function (event) {
        if(obj.is_show){
          event.preventDefault()
          history.go(1);
          obj.hide()
        }
      };

      EventBus.$on(Events.BadgeClicked, this.show);
    },
    beforeDestroy() {

        EventBus.$off(Events.BadgeClicked, this.show);
    },
    methods: {
      show(badge) {
        this.badge = badge;
        this.is_show = true;
        this.$refs["badge-modal"].show();
      },
      hide() {

        this.is_show = false;
        this.$refs["badge-modal"].hide();
      }
    }
}
</script>

<style>

    .badge-info-modal .modal-title {
        font-size: 18pt;
    }

    .badge-info-modal .badge-info-image {
        margin-top: 0.8em;
        margin-bottom: 1.2em;
        margin-left: 25%;
        width: 50%;
    }

</style>
