import VueRouter from "vue-router";
import FeedView from "@/ui/views/feed/FeedView";
import FeedView_Jokes from "@/ui/views/feed/FeedView_Jokes";
import FeedView_Duels from "@/ui/views/feed/FeedView_Duels";
import UserView from "@/ui/views/users/UserView";
import UserView_Details from "@/ui/views/users/UserView_Details";
import UserView_Map from "@/ui/views/users/UserView_Map";
import UserView_Settings from "@/ui/views/users/UserView_Settings";
import UserView_Duels from "@/ui/views/users/UserView_Duels";
import AuthView_VerifyEmail from "@/ui/views/auth/AuthView_VerifyEmail";
import AuthView_ResetPassword from "@/ui/views/auth/AuthView_ResetPassword";
import InfoView_FAQ from "@/ui/views/info/InfoView_FAQ";
import RouterUtils from "@/routes/RouterUtils";
import InfoView_TermsAndConditions from "@/ui/views/info/InfoView_TermsAndConditions";
import InfoView_AboutUs from "@/ui/views/info/InfoView_AboutUs";
import View_Leaderboard from "@/ui/views/View_Leaderboard";
import ScrollLock from "@/utils/ScrollLock";
import View_Favorites from "@/ui/views/View_Favorites";
import View_Duels_Challenge from "@/ui/views/duels/View_Duels_Challenge";
import UserView_Followers from "@/ui/views/users/UserView_Followers";
import UserView_Followings from "@/ui/views/users/UserView_Followings";
import InfoView_PrivacyPolicy from "@/ui/views/info/InfoView_PrivacyPolicy";
import View_Badges from "@/ui/views/View_Badges";
import View_WeekRankings from "@/ui/views/View_WeekRankings";
import View_Notifications from "@/ui/views/View_Notifications";

export default {
    create: createRouter
}

const routes = [
    { path: '/', redirect: '/j/all/new' },
    { path: '/j', redirect: '/j/all/new' },
    { path: '/j/:contentType', component: FeedView, props: true, redirect : '/j/:contentType/new',
        children : [
            { path: 'duels', component: FeedView_Duels },
            { path: ':filter', component: FeedView_Jokes, props: true },
        ]
    },
    { path: '/verification', component: AuthView_VerifyEmail },
    { path: '/password-reset', component: AuthView_ResetPassword },
    { path: '/u/:userSlug/followers', component: UserView_Followers, props: true },
    { path: '/u/:userSlug/followings', component: UserView_Followings, props: true },
    { path: '/u/:userSlug', component: UserView, props: true, redirect : '/u/:userSlug/details',
        children : [
            { path: 'details', component: UserView_Details },
            { path: 'joke-trip', component: UserView_Map },
            { path: 'duels', component: UserView_Duels },
            { path: 'settings', component: UserView_Settings, meta : { requiresAuth: true } },
        ]
    },
    { path: '/faq', component: InfoView_FAQ },
    { path: '/terms', component: InfoView_TermsAndConditions, meta : { hidePrivacyPolicy: true} },
    { path: '/about', component: InfoView_AboutUs, meta : { hidePrivacyPolicy: true} },
    { path: '/privacy', component: InfoView_PrivacyPolicy, meta : { hidePrivacyPolicy: true} },
    { path: '/favorites', component: View_Favorites, meta : { requiresAuth: true } },
    { path: '/leaderboards', component: View_Leaderboard },
    { path: '/badges', component: View_Badges },
    { path: '/duel/:code', component: View_Duels_Challenge, props: true },
    { path: '/rankings', component: View_WeekRankings },
    { path: '/notifications', component: View_Notifications }
];

function createRouter(store) {
    const router = new VueRouter({
        mode: 'history',
        routes
    });

    addAuthGuard(router, store);

    RouterUtils.init(router);

    return router;
}

function addAuthGuard(router, store) {
    router.beforeEach((to, from, next) => {

        ScrollLock.clear();

        if(requiresAuth(to) && !store.state.auth.authenticated) {
            next({ path: '/' });
        }
        else {
            next();
        }
    });
}

function requiresAuth(navigationTarget) {
    return navigationTarget.matched.some(path => path.meta.requiresAuth);
}
