
const HeaderMaxSymbols = 32;

/** Utility class for calculating what portion of a post is its header and what is its body. */
export default class PostText {

    _full;
    _header;
    _body;

    /** @type {string} */
    get header() {
        return this._header;
    }

    /** @type {string} */
    get body() {
        return this._body;
    }

    /**
     * Returns the full text of the post, including header and body.
     * @type {string}
     */
    get full() {
        return this._full;
    }

    constructor(text) {
        this._full = text;
        this._header = this._determineHeader(text);
        this._body = text.substr(this._header.length);
    }

    _determineHeader(text) {
        let headerCount = 0;
        let header = [];

        for(const word of text.split(/(\s)/)) {
            if(headerCount + word.length > HeaderMaxSymbols) {
                break;
            }
            if(word.includes('\n')) {
                break;
            }

            headerCount += word.length;
            header.push(word);
        }

        return header.join('');
    }

}
