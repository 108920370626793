<template>
    <div class="border-top border-bottom" style="padding-top: 0.5rem;">
        <Info_Text>{{ $t(`privacyPolicy.name-${type}`) }}</Info_Text>
        <Info_Text>{{ $t(`privacyPolicy.type-${type}`) }}</Info_Text>
        <Info_Text>{{ $t(`privacyPolicy.description-${type}`) }}</Info_Text>
        <Info_Text>{{ $t(`privacyPolicy.category-${type}`) }}</Info_Text>
        <Info_Text>{{ $t(`privacyPolicy.term-${type}`) }}</Info_Text>
        <Info_Text>{{ $t(`privacyPolicy.domain`) }} <router-link to="/">www.joketrip.com </router-link></Info_Text>
    </div>
</template>

<script>
    
    import Info_Text from "@/ui/components/info/Info_Text";

    export default {
        name: "Info_BiscuitType",
        components: {Info_Text},
        props: {
            type: String
        }
    }
</script>

<style>

</style>