<template>
    <b-button pill class="btn-outline-white">
        <span>
            <slot></slot>
        </span>
    </b-button>
</template>

<script>
    export default {
        name: "Button_WhiteOutline"
    }
</script>

<style scoped>

    .btn-outline-white,
    .btn-outline-white:hover,
    .btn-outline-white.btn-secondary:not(:disabled):not(.disabled).active,
    .btn-outline-white.btn-secondary:not(:disabled):not(.disabled):active,
    .btn-outline-white.btn-secondary.focus, .btn-secondary:focus {
        background: transparent;
        border-color: white;
    }
</style>
